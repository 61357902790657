import React, {useEffect, useState} from 'react';
import {getRoles,getModules,deleteRoleUtilisateur} from '../../../components/requests'
import {ModeleModule, ModeleRole} from '../../../components/Models';
import {Link} from "react-router-dom";
import {KTIcon} from '../../../_metronic/helpers';
import {LoadingData} from '../../../components/LoadingData';
import {RoleModal} from './RoleModal';
import Swal from 'sweetalert2';
import {useAuth} from "../../modules/auth";
import {shouldDisplayFonctionnalite} from "../../../components/helpers";


const RolesListPage = () => {

    let initialDataRoles =  [
        {
        id: 1,
        code: 'ADMIN',
        libelle: 'administrateur',
        },
        {
            id: 2,
            code: 'USER',
            libelle: 'Utilisateur',
        },
        {
            id: 3,
            code: 'CUSTOMER',
            libelle: 'Client',
        }
    ] 
    let initialDataModules = [
        {
            "id": 1,
            "code": "UTILI",
            "libelle": "Gestion utilisateurs",
            "fonctionnalites": [
                {
                    "id": 1,
                    "code": "UTILI-CREATION",
                    "libelle": "Creation",
                    "value":false

                },
                {
                    "id": 2,
                    "code": "UTILI-MODIF",
                    "libelle": "Modification",
                    "value":false
                },
                {
                    "id": 3,
                    "code": "UTILI-SUPPR",
                    "libelle": "Suppression",
                    "value":false
                }
            ]
        },
        {
            "id": 2,
            "code": "VEHIC",
            "libelle": "Gestion véhicules",
            "fonctionnalites": [
                {
                    "id": 1,
                    "code": "VEHIC-CREATION",
                    "libelle": "Creation",
                    "value":false
                },
                {
                    "id": 2,
                    "code": "VEHIC-MODIF",
                    "libelle": "Modification",
                    "value":false
                },
                {
                    "id": 3,
                    "code": "VEHIC-SUPPR",
                    "libelle": "Suppression",
                    "value":false
                }
            ]
        },
        {
            "id": 3,
            "code": "VENTE",
            "libelle": "Gestion ventes",
            "fonctionnalites": [
                {
                    "id": 1,
                    "code": "VENTE-CREATION",
                    "libelle": "Creation",
                    "value":false
                },
                {
                    "id": 2,
                    "code": "VENTE-MODIF",
                    "libelle": "Modification",
                    "value":false
                },
                {
                    "id": 3,
                    "code": "VENTE-SUPPR",
                    "libelle": "Suppression",
                    "value":false
                }
            ]
        },
        {
            "id": 4,
            "code": "PARAM",
            "libelle": "Parametrage",
            "fonctionnalites": [
                {
                    "id": 1,
                    "code": "PARAM-CREATION",
                    "libelle": "Creation",
                    "value":false
                },
                {
                    "id": 2,
                    "code": "PARAM-MODIF",
                    "libelle": "Modification",
                    "value":false
                },
                {
                    "id": 3,
                    "code": "PARAM-SUPPR",
                    "libelle": "Suppression",
                    "value":false
                }
            ]
        }
    ];
    
    const [dataRoles, setDataRoles] = useState<Array<ModeleRole>>(initialDataRoles);
    const [dataModules, setDataModules] = useState<Array<ModeleModule>|undefined>(initialDataModules);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isEdition, setIsEdition] = useState<boolean>(false);
    const [roleId, setRoleId] = useState<Number|undefined>(undefined);
    const {currentUser} = useAuth()

    useEffect(() => {
        getData()
    },[]);

    const getData = () => {
        setLoading(true);

        getRoles().then(rolesRequest => {
            if (rolesRequest?.status === 200) {
                if (rolesRequest?.data !== undefined) {
                    setDataRoles(rolesRequest?.data)
                    
                }
                
                getModules().then(req =>{
                    if (req?.status === 200) {
                        setDataModules(req?.data)
                    }
                    setLoading(false);

                }).catch((e) => {
                    setLoading(false);
                }); 
                
            }
                    
        }).catch((e) => {
            setLoading(false);
        });
    }

    const showRegisterModal = () => {
        setOpenModal(true);
        setIsEdition(false)
    }

    const showEditModal = (roleId:number|undefined) => {
        setOpenModal(true);
        setIsEdition(true)
        setRoleId(roleId);
    }
    
    
    const toggleShowModal = (val:boolean) =>{
        setOpenModal(val);
    }

    const addDataRole = (newData:ModeleRole) =>{
        let newDataRole =  [...dataRoles,newData]
        setDataRoles(newDataRole)
    }

    

    const updateDataRole = (role:ModeleRole|null) =>{
        if(role != null){
            const roles:ModeleRole[] = [...dataRoles];
            const updatedRoles = roles.map((r) => {
                return r.id === role.id ? role : r;
              });
              setDataRoles(updatedRoles);
        }
    }

    const deleteRole = (roleId) => {
        Swal.fire({
            title: 'Attention',
            text: 'Merci de confirmer la suppression',
            icon: 'warning',
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            confirmButtonColor: '#1BC5BD',
            cancelButtonColor: '#F64E60',
        }).then((alertResult) => {
            if (alertResult.isConfirmed) {
                setLoading(true)
                deleteRoleUtilisateur(roleId)
                  .then((response) => {
                      if (response?.status === 200) {
                          setLoading(false)
                          getData()
                      }
                  })
                  .catch((error) => {
                      if (error.response?.status === 423) {
                          setLoading(false)
                          Swal.fire({
                              title: 'Erreur',
                              text: 'Ce rôle ne peut pas être supprimé.',
                              icon: 'error',
                              confirmButtonText: 'Ok',
                              confirmButtonColor: '#F64E60',
                          })
                      }
                  })
            }
        })
    }

  return (
    <>
      {/* begin::Row  */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col  */}
        <div className='col-xxl-12'>
          <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Rôles</span>
              </h3>
                {shouldDisplayFonctionnalite(currentUser, 'AJOUTER ROLE') &&
                  <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Ajouter un rôle'
                  >
                    <Link
                      to=''
                      onClick={showRegisterModal}
                      className='btn btn-sm btn-light-primary'
                    >
                      <KTIcon iconName='plus' className='fs-3'/>
                      Créer un rôle

                    </Link>

                  </div>
                }
            </div>
            {/* end::Header */}
            {loading
              ?
              <LoadingData />
              :
              <>
              {shouldDisplayFonctionnalite(currentUser, 'VOIR ROLE') &&
              <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-150px'>Code</th>
                      <th className='min-w-140px'>Libellé</th>
                      <th className='min-w-100px text-end'>Actions</th>
                    </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                    {dataRoles.map((role) => {
                      return (
                        <tr key={role.id}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                  {role.code}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                              {role.libelle}
                            </p>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              {shouldDisplayFonctionnalite(currentUser, 'MODIFIER ROLE') &&
                              <Link
                                // to={`/roles/${role.id}/modifier`}
                                to='#'
                                onClick={() => showEditModal(role.id)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i className='bi bi-pencil fs-5'></i>
                              </Link>
                              }
                              {shouldDisplayFonctionnalite(currentUser, 'SUPPRIMER ROLE') &&
                              <p
                                onClick={() => {
                                  deleteRole(role.id)
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i className='bi bi-trash fs-5'></i>
                              </p>
                              }
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
              }
            </>
            }
          </div>
        </div>
        {/* end::Col  */}
      </div>
      {/* end::Row  */}
      {!isEdition && openModal && (
        <RoleModal
          show={openModal}
          roleId={undefined}
          isEdition={isEdition}
          showModal={toggleShowModal}
          addDataRole={addDataRole}
          updateDataRole=""
          modules ={dataModules}
          loading ={setLoading}
        />
      )}

        {isEdition && openModal && (
            <RoleModal
            show={openModal}
            roleId={roleId}
            isEdition = {isEdition}
            showModal={toggleShowModal}
            updateDataRole={updateDataRole}
            addDataRole=""
            modules ={dataModules}
            loading ={setLoading}
            />
        )}
    </>
  )

}
export {RolesListPage}