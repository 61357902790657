import React, {useEffect, useState} from "react";

const TextInput = (props) => {

    return (
        <>
            <input
                type={props.inputType}
                className={props.inputClassName}
                onChange={props.formik.handleChange}
                placeholder={props.inputPlaceholder}
                onBlur={props.onBlur}
                onKeyUp={props.onKeyUp}
                disabled={props.disabled}
                hidden={props?.hidden}
                value={props?.value}
                max={props?.max}
                min={props?.min}
                step={props?.step}
                required={props?.required}
                {...props.formik.getFieldProps(props.fieldName)}
            />
            {props?.formik.touched[props.fieldName] && props?.formik.errors[props?.fieldName] && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{props?.formik.errors[props?.fieldName]}</div>
                </div>
            )}
        </>
    )
}

export {TextInput}
