import axios from 'axios'
import {
  ClientParametrageQueryResponse,
  ModeleClientParametrage,
  ModelReferentielWithCode,
  ReferentielListQueryResponse, ReferentielQueryResponse,
} from './Models'
import {ClientQueryResponse, ModeleClient, ModulesQueryResponse} from '../Models'

const API_URL = process.env.REACT_APP_API_URL
const CLIENT_PARAMS_BASE_URL = `${API_URL}/client-parametrage`

export const getClientParametrage = async (
  clientId: string | undefined
): Promise<ClientParametrageQueryResponse> => {
  return await axios.get(`${CLIENT_PARAMS_BASE_URL}/${clientId}`);
}

export const editClientParametrage = async (
  clientId: string | undefined,
  clientParametrage: ModeleClientParametrage
): Promise<ClientParametrageQueryResponse | undefined> => {
  return await axios.post(`${CLIENT_PARAMS_BASE_URL}/${clientId}/edit`,clientParametrage)
}

export const getReferentielLists = async ():
  Promise<ReferentielListQueryResponse> => {
  return await axios.get(`${CLIENT_PARAMS_BASE_URL}/referentiel-lists`);
}
export const createReferentiel = async (
  referentiel: ModelReferentielWithCode
): Promise<ReferentielQueryResponse | undefined> => {
  return await axios.post(`${CLIENT_PARAMS_BASE_URL}/referentiel/add`, referentiel)
}