import React, {useEffect} from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.css'
import { French } from "flatpickr/dist/l10n/fr.js";
import {useState} from 'react'
import {useFormik} from "formik";
import {ModeleClientRemise} from '../../../../../../../components/Models'
import * as Yup from 'yup'
import {SelectInput} from "../../../../../../../components/inputs/SelectInput";
import {TextInput} from '../../../../../../../components/inputs/TextInput'
import moment from 'moment';


const fields = ['critereArt', 'critereFam', 'critereTyp', 'tauxArticle', 'tauxFamille', 'tauxType', 'typeCritere', 'codeCritere', 'dateDebutArt', 'dateDebutFam', 'dateDebutTyp', 'dateFinArt', 'dateFinFam', 'dateFinTyp'];
const clientRemiseSchema = Yup.object().shape(
  fields.reduce((acc, field) => {
    acc[field] = Yup.string().notRequired().nullable();
    return acc;
  }, {})
);

const RemiseForm = (props) => {

  const [typeCritere, setTypeCritere] = useState('')
  const [dataRemise, setDataRemise] = useState({})
  const clientId = props?.clientId

  const articles = ([{'value': 0, 'label': null, 'code':''},{'value': 1, 'label': 'art A', 'code':'Art1'},{'value': 2, 'label': 'art B', 'code':'Art2'},{'value': 3, 'label': 'art C', 'code':'Art3'}])
  const familles = ([{'value': 0, 'label': null, 'code':''},{'value': 1, 'label': 'fam A', 'code':'Art1'},{'value': 2, 'label': 'fam B', 'code':'Art1'},{'value': 3, 'label': 'fam C', 'code':'Art1'}])
  const types = ([{'value': 0, 'label': null, 'code':''},{'value': 1, 'label': 'typ A', 'code':'Art1'},{'value': 2, 'label': 'typ B', 'code':'Art1'},{'value': 3, 'label': 'typ C', 'code':'Art1'}])

  const [dateDebutArt, setdateDebutArt] = useState<Date | null>(null);
  const [dateFinArt, setdateFinArt] = useState<Date | null>(null);
  const [dateDebutFam, setdateDebutFam] = useState<Date | null>(null);
  const [dateFinFam, setdateFinFam] = useState<Date | null>(null);
  const [dateDebutTyp, setdateDebutTyp] = useState<Date | null>(null);
  const [dateFinTyp, setdateFinTyp] = useState<Date | null>(null);

  useEffect(() => {
    if (props.updDataRemise != null) {
      let criteria = ""
      if (props.updDataRemise.typeCritere === "Article") {
        if (props.updDataRemise.codeCritere === "1") criteria = "art A"
        if (props.updDataRemise.codeCritere === "2") criteria = "art B"
        if (props.updDataRemise.codeCritere === "3") criteria = "art C"
      } else if (props.updDataRemise.typeCritere === "Famille") {
        if (props.updDataRemise.codeCritere === "1") criteria = "fam A"
        if (props.updDataRemise.codeCritere === "2") criteria = "fam B"
        if (props.updDataRemise.codeCritere === "3") criteria = "fam C"
      } else if (props.updDataRemise.typeCritere === "Type") {
        if (props.updDataRemise.codeCritere === "1") criteria = "typ A"
        if (props.updDataRemise.codeCritere === "2") criteria = "typ B"
        if (props.updDataRemise.codeCritere === "3") criteria = "typ C"
      }
      let editDataRemise = {
        'id': props.updDataRemise.id,
        'critere': criteria,
        'critereArt': props.updDataRemise.typeCritere === "Article" ? props.updDataRemise.codeCritere : 0,
        'critereFam': props.updDataRemise.typeCritere === "Famille" ? props.updDataRemise.codeCritere : 0,
        'critereTyp': props.updDataRemise.typeCritere === "Type" ? props.updDataRemise.codeCritere : 0,
        'tauxArticle' : props.updDataRemise.typeCritere === "Article" ? props.updDataRemise.taux : 0,
        'tauxFamille' : props.updDataRemise.typeCritere === "Famille" ? props.updDataRemise.taux : 0,
        'tauxType' : props.updDataRemise.typeCritere === "Type" ? props.updDataRemise.taux : 0,
        'dateDebutArt': props.updDataRemise.typeCritere === "Article" && props.updDataRemise.dateDebut ? setdateDebutArt(new Date(props.updDataRemise.dateDebut)) : "",
        'dateDebutFam': props.updDataRemise.typeCritere === "Famille" && props.updDataRemise.dateDebut ? setdateDebutFam(new Date(props.updDataRemise.dateDebut)) : "",
        'dateDebutTyp': props.updDataRemise.typeCritere === "Type" && props.updDataRemise.dateDebut ? setdateDebutTyp(new Date(props.updDataRemise.dateDebut)) : "",
        'dateFinArt': props.updDataRemise.typeCritere === "Article" && props.updDataRemise.dateFin ? setdateFinArt(new Date(props.updDataRemise.dateFin)) : "",
        'dateFinFam': props.updDataRemise.typeCritere === "Famille" && props.updDataRemise.dateFin ? setdateFinFam(new Date(props.updDataRemise.dateFin)) : "",
        'dateFinTyp': props.updDataRemise.typeCritere === "Type" && props.updDataRemise.dateFin ? setdateFinTyp(new Date(props.updDataRemise.dateFin)) : "",
        'remiseFinAnnee':props.updDataRemise.remiseFinAnnee}
      setDataRemise(editDataRemise);
    }
  },[]);

  const handleValidation = (buttonId: string) => () => {
    if (buttonId === "Article") {
      setTypeCritere('Article');
    } else if (buttonId === "Famille") {
      setTypeCritere('Famille');
    } else if (buttonId === "Type") {
      setTypeCritere('Type');
    }
  };

  const handleChangeCritere = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = event.target.querySelector(`option[value="${selectedValue}"]`);
    const selectedText = selectedOption ? selectedOption.text : "";
    formik.setFieldValue("critere", selectedText);
  };

  const formik = useFormik<ModeleClientRemise>({
    initialValues: dataRemise,
    enableReinitialize: true,
    validationSchema: clientRemiseSchema,
    onSubmit: (values) => {
      let newRemise = [{critere: '', codeCritere: 0 , taux: 0, dateDebut: '', dateFin: ''}]
      const commonProps = {
        id: values.id??0,
        typeCritere: typeCritere,
        clientId: clientId ?? null,
        remiseFinAnnee: values.remiseFinAnnee ?? false,
      };
      if (typeCritere === "Article") {
        const dateDebutString = dateDebutArt? moment(dateDebutArt).format('YYYY-MM-DD') : '';
        const dateFinString = dateFinArt? moment(dateFinArt).format('YYYY-MM-DD') : '';
          newRemise = [{
            codeCritere : values.critereArt?? 0,
            taux: values.critereArt ? (values.tauxArticle ?? 0) : 0,
            critere: values.critereArt ? (values.critere ?? "") : "",
            dateDebut: dateDebutString,
            dateFin: dateFinString,
            ...commonProps
          }];
      } else if (typeCritere === "Famille") {
        const dateDebutString = dateDebutFam? moment(dateDebutFam).format('YYYY-MM-DD') : '';
        const dateFinString = dateFinFam? moment(dateFinFam).format('YYYY-MM-DD') : '';
          newRemise = [{
            codeCritere: values.critereFam?? 0,
            taux: values.critereFam ? (values.tauxFamille ?? 0) : 0,
            critere: values.critereFam ? (values.critere ?? "") : "",
            dateDebut: dateDebutString,
            dateFin: dateFinString,
            ...commonProps
          }];
      } else if (typeCritere === "Type") {
        const dateDebutString = dateDebutTyp? moment(dateDebutTyp).format('YYYY-MM-DD') : '';
        const dateFinString = dateFinTyp? moment(dateFinTyp).format('YYYY-MM-DD') : '';
          newRemise = [{
            codeCritere: values.critereTyp??0,
            taux: values.critereTyp ? (values.tauxType ?? 0) : 0,
            critere: values.critereTyp ? (values.critere ?? "") : "",
            dateDebut: dateDebutString,
            dateFin: dateFinString,
            ...commonProps
          }];
      }
      const updatedData = Object.assign({...dataRemise}, newRemise)
      props.submitForm(updatedData);
    },
  })


  return (
    <>
      <form onSubmit={formik.handleSubmit} className='form'>
        <div className='row mb-4'>
          <div className='col-md-3 col-sm-6'>
            <label className='fw-bold'>Article</label>
            <SelectInput
              value={formik.values.critereArt}
              id='Article'
              fieldName={'critereArt'}
              inputClassName={'form-select form-select-solid form-select-lg fw-bold'}
              options={articles}
              formik={formik}
              onChange={handleChangeCritere}
            />
          </div>
          <div className='col-md-3 col-sm-6'>
            <label className='fw-bold'>Taux</label>
            <TextInput
              fieldName={'tauxArticle'}
              inputPlaceholder={'Taux'}
              inputType={'number'}
              inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
              max={100}
              min={0}
              step={0.01}
              formik={formik}
            />
          </div>
          <div className='col-md-2 col-sm-6' id='day'>
            <label className='fw-bold'>Debut</label>
            <Flatpickr
              value={dateDebutArt}
              onChange={(selectedDates: Date[]) => setdateDebutArt(selectedDates[0] || null)}
              options={{dateFormat: 'd-m-Y', locale: French, maxDate: dateFinArt}}
              className='form-control flatpickr-input p-2'
              placeholder='Date debut'
              name='dateDebutArt'
              style={{height: '42px'}}
            />
          </div>
          <div className='col-md-2 col-sm-6'>
            <label className='fw-bold'>Fin</label>
            <Flatpickr
              value={dateFinArt}
              onChange={(selectedDates: Date[]) => setdateFinArt(selectedDates[0] || null)}
              options={{dateFormat: 'd-m-Y', locale: French, minDate: dateDebutArt}}
              className='form-control flatpickr-input p-2'
              placeholder='Date fin'
              name='dateFinArt'
              style={{height: '42px'}}
            />
          </div>
          <div className='col-md-2 d-flex flex-row align-items-end'>
            <button
              onClick={handleValidation('Article')}
              className='btn btn-primary'
              type='submit'
            >
              Appliquer
            </button>
          </div>
        </div>

        <div className='row mb-4'>
          <div className='col-md-3 col-sm-6'>
            <label className='fw-bold'>Famille</label>
            <SelectInput
              id='Famille'
              value={formik.values.critereFam}
              fieldName={'critereFam'}
              inputClassName={'form-select form-select-solid form-select-lg fw-bold'}
              options={familles}
              onChange={handleChangeCritere}
              formik={formik}
            />
          </div>
          <div className='col-md-3 col-sm-6'>
            <label className='fw-bold'>Taux</label>
            <TextInput
              fieldName={'tauxFamille'}
              inputPlaceholder={'Taux'}
              inputType={'number'}
              inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
              max={100}
              min={0}
              step={0.01}
              formik={formik}
            />
          </div>
          <div className='col-md-2 col-sm-6'>
            <label className='fw-bold'>Debut</label>
            <Flatpickr
              value={dateDebutFam}
              onChange={(selectedDates: Date[]) => setdateDebutFam(selectedDates[0] || null)}
              options={{dateFormat: 'd-m-Y', locale: French, maxDate: dateFinFam}}
              className='form-control flatpickr-input p-2'
              placeholder='Date debut'
              name='dateDebutFam'
              style={{height: '42px'}}
            />
          </div>
          <div className='col-md-2 col-sm-6'>
            <label className='fw-bold'>Fin</label>
            <Flatpickr
              value={dateFinFam}
              onChange={(selectedDates: Date[]) => setdateFinFam(selectedDates[0] || null)}
              options={{dateFormat: 'd-m-Y', locale: French, minDate: dateDebutFam}}
              className='form-control flatpickr-input p-2'
              placeholder='Date fin'
              name='dateFinFam'
              style={{height: '42px'}}
            />
          </div>
          <div className='col-md-2 d-flex flex-row align-items-end'>
            <button
              onClick={handleValidation('Famille')}
              className='btn btn-primary'
              type='submit'
            >
              Appliquer
            </button>
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-md-3 col-sm-6'>
            <label className='fw-bold'>Type</label>
            <SelectInput
              id='Type'
              value={formik.values.critereTyp}
              fieldName={'critereTyp'}
              inputClassName={'form-select form-select-solid form-select-lg fw-bold'}
              options={types}
              onChange={handleChangeCritere}
              formik={formik}
            />
          </div>
          <div className='col-md-3 col-sm-6'>
            <label className='fw-bold'>Taux</label>
            <TextInput
              fieldName={'tauxType'}
              inputPlaceholder={'Taux'}
              inputType={'number'}
              inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
              max={100}
              min={0}
              step={0.01}
              formik={formik}
            />
          </div>
          <div className='col-md-2 col-sm-6'>
            <label className='fw-bold'>Debut</label>
            <Flatpickr
              value={dateDebutTyp}
              onChange={(selectedDates: Date[]) => setdateDebutTyp(selectedDates[0] || null)}
              options={{dateFormat: 'd-m-Y', locale: French, maxDate: dateFinTyp}}
              className='form-control flatpickr-input p-2'
              placeholder='Date debut'
              name='dateDebutTyp'
              style={{height: '42px'}}
            />
          </div>
          <div className='col-md-2 col-sm-6'>
            <label className='fw-bold'>Fin</label>
            <Flatpickr
              value={dateFinTyp}
              onChange={(selectedDates: Date[]) => setdateFinTyp(selectedDates[0] || null)}
              options={{dateFormat: 'd-m-Y', locale: French, minDate: dateDebutTyp}}
              className='form-control flatpickr-input p-2'
              placeholder='Date fin'
              name='dateFinTyp'
              style={{height: '42px'}}
            />
          </div>
          <div className='col-md-2 col-sm-6 d-flex flex-row align-items-end'>
            <button
              onClick={handleValidation('Type')}
              className='btn btn-primary'
              type='submit'
            >
              Appliquer
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <input
              className='form-check-input my-3'
              type='checkbox'
              value={1}
              name={"remiseFinAnnee"}
              checked={!!formik.values.remiseFinAnnee}
              onChange={(e) => {
                formik.setFieldValue("remiseFinAnnee", e.target.checked);
              }}
            />
            <label className='fw-bold p-4'>Remise fin année</label>
          </div>
        </div>
        <TextInput
          fieldName={'critere'}
          inputType={'text'}
          hidden={true}
          formik={formik}
        />
      </form>
    </>
  )
}

export {RemiseForm}