import React, { useState, useEffect } from 'react';
import 'flatpickr/dist/flatpickr.css'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { TextInput } from '../../../../../components/inputs/TextInput';
import { SelectInput } from '../../../../../components/inputs/SelectInput';
import {
  ModeleClientParametrage,
  ModelReferentielInitialValue,
  ModelReferentielWithCode,
} from '../../../../../components/clientParametrage/Models'
import {
  caisses, codeClientWeb,
  commercials,
  groupeClients,
  modeReglements, reseaux, tableauDeBords,
  transporteurs, zonesTVA,
} from '../../../../../components/clientParametrage/Data'
import {Link, useNavigate} from 'react-router-dom'
import InputGroup from 'react-bootstrap/InputGroup';
import {DateInput} from '../../../../../components/inputs/DateInput'
import {RadioListInput} from '../../../../../components/inputs/RadioListInput'
import ReferentielModal from './ReferentielModal'
import {createReferentiel} from '../../../../../components/clientParametrage/requests'
import {useAuth} from "../../../../modules/auth";
import {shouldDisplayFonctionnalite} from "../../../../../components/helpers";

const clientParametrageSchema = Yup.object().shape({
    compteTiers: Yup.string().notRequired(),
    ribBanque: Yup.string().notRequired(),
    ribGuichet: Yup.string().notRequired(),
    ribCompte: Yup.string().notRequired(),
    ribCle: Yup.string().notRequired(),
    recalculTva: Yup.boolean().notRequired(),
    affichageRemise: Yup.boolean().notRequired(),
    caisse: Yup.string().notRequired(),
});

const ClientParametrageForm = (props) => {
      const navigate = useNavigate();
      const [data, setData] = useState<ModeleClientParametrage>(props.data);
      const [openModal, setOpenModal] = useState<boolean>(false)
      const [loading, setLoading] = useState(false);
      const [referentielLists, setReferentielLists] = useState({
        modeReglementList: props.referentielData.modeReglementList ?? modeReglements,
        clientGroupeList: props.referentielData.clientGroupeList ?? groupeClients,
        commercialList: props.referentielData.commercialList ?? commercials,
        transporteurList: props.referentielData.transporteurList ?? transporteurs,
        reseauxList: props.referentielData.reseauxList ?? reseaux,
      });
      const [clientId, setClientId] = useState(props.clientId);
      const [refHasCode, setRefHasCode] = useState<boolean>(false)
      const [referenceTitle, setReferenceTitle] = useState<string>('')
      const [idRef, setIdRef] = useState<number>(-1)
      const {currentUser} = useAuth()


  const afficherModalReferentiel = (idRef, refTitle, hasCode = false) => {
        setRefHasCode(hasCode);
        setIdRef(idRef)
        setReferenceTitle(refTitle);
        setOpenModal(true);
      };

      const submitReferentielForm = async (fieldsToUpdate: Partial<ModelReferentielWithCode>, callback): Promise<void> => {
        const updatedData = Object.assign({...ModelReferentielInitialValue}, fieldsToUpdate, {idReferentiel : idRef})
        var requestOk = false;
        try {
          const referentielRequest = await createReferentiel(updatedData);
          if (referentielRequest?.status === 200) {
            if (referentielRequest?.data?.value !== undefined && referentielRequest?.data?.value !== null) {
              const referentielKeys = [
                "modeReglementList",
                "clientGroupeList",
                "commercialList",
                "transporteurList",
                "reseauxList"
              ];

              if (idRef >= 0 && idRef < referentielKeys.length) {
                setReferentielLists(prev => ({
                  ...prev,
                  [referentielKeys[idRef]]: [...prev[referentielKeys[idRef]], referentielRequest?.data]
                }));
              }
            }
          }
        } catch (ex) {
          console.error(ex)
        }
        finally {
          callback();
        }
    }

    const formik = useFormik<ModeleClientParametrage>({
          initialValues: data,
          validationSchema: clientParametrageSchema,
          onSubmit: (values) => {
              setLoading(true);
              setTimeout(() => {
                  const updatedData =  Object.assign({...data}, values) ;
                  props.submitForm(updatedData, () => {
                      setLoading(false);
                  });
              }, 200);
          },
      });

    const [dateState, setDateState] = useState<any>({
      depuis: data.depuis ? new Date(data.depuis) : null,
      echeance: data.echeance ? new Date(data.echeance) : null,
    });

      return (
        <div>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row'>
                <div className='col-lg-6 overflow-auto h-600px fw-bold fs-6'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label '>Compte Tiers</label>
                    <TextInput
                      fieldName='compteTiers'
                      inputPlaceholder='Compte Tiers'
                      inputType='text'
                      inputClassName='form-control mb-3 '
                      formik={formik}
                    />
                  </div>
                  <div className='row mb-6'>
                    <div className='form-check  mb-6 '>
                      <input
                        type='checkbox'
                        className='form-check-input fw-bold fs-6'
                        checked={formik.values.recalculTva}
                        {...formik.getFieldProps('recalculTva')}
                      />
                      <label className='form-check-label'> Recalcul de la TVA </label>
                    </div>
                    <div className='form-check mb-6 '>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={formik.values.affichageRemise}
                        {...formik.getFieldProps('affichageRemise')}
                      />
                      <label className='form-check-label'> Ne pas afficher les remises </label>
                    </div>
                  </div>
                  <h3 className='row mb-6'>
                    <div className='col-3'>
                      <span className='text-gray-600 me-6'>Fin du mois</span>
                    </div>
                    <div className='col-9'>
                      <div className='separator border-secondary my-4' />
                    </div>
                  </h3>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      Mode de Règlement
                    </label>
                    <InputGroup size="sm" className='mb-3'>
                      <SelectInput
                        fieldName='idModeReglement'
                        inputClassName='form-select'
                        options={referentielLists.modeReglementList}
                        formik={formik}
                      />
                      <InputGroup.Text id='basic-addon2'>
                        <Link
                          to='#'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          onClick={() => afficherModalReferentiel(0, 'Mode de Règlement')}
                        >
                          <i className='fa fa-plus-circle text-primary fs-1'></i>
                        </Link>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className='row mb-6'>
                    <div className='col-lg-12 fv-row'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>Echeance</label>
                    </div>
                    <div className='fv-row'>
                      <DateInput
                        fieldName='echeance'
                        inputClassName='form-control'
                        value = {dateState.echeance}
                        setter={setDateState}
                        formik={formik}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Caisse</label>
                    <SelectInput
                      fieldName='caisse'
                      inputClassName='form-select'
                      options={caisses}
                      formik={formik}
                    />
                  </div>
                  <h3 className='row mb-6'>
                    <div className='col-4'>
                      <span className='text-gray-600 me-6'>R.I.B (si LCR)</span>
                    </div>
                    <div className='col-8'>
                      <div className='separator border-secondary my-4' />
                    </div>
                  </h3>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Etablissement</label>
                    <TextInput
                      fieldName='ribEtablissement'
                      inputType='text'
                      inputClassName='form-control mb-3 '
                      formik={formik}
                    />
                  </div>
                  <div className='row mb-6'>
                    <div className='col-lg-2 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Banque</label>
                      <TextInput
                        fieldName='ribBanque'
                        formik={formik}
                        inputClassName='form-control'
                        inputType='text'
                      />
                    </div>
                    <div className='col-lg-2 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Guichet</label>
                      <TextInput
                        fieldName='ribGuichet'
                        formik={formik}
                        inputClassName='form-control'
                        inputType='text'
                      />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Compte</label>
                      <TextInput
                        fieldName='ribCompte'
                        formik={formik}
                        inputClassName='form-control'
                        inputType='text'
                      />
                    </div>
                    <div className='col-lg-2 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Clé</label>
                      <TextInput
                        fieldName='ribCle'
                        formik={formik}
                        inputClassName='form-control'
                        inputType='text'
                      />
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>BIC</label>
                      <TextInput
                        fieldName='ribBic'
                        inputType='text'
                        inputClassName='form-control mb-3 '
                        formik={formik}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 overflow-auto fv-row h-600px px-10'>
                  <div className='row mb-6'>
                    <label className='col-form-label fw-bold fs-6'>En-cours Comptable maxi</label>
                    <TextInput
                      fieldName='encoursComptableMaxi'
                      inputType='text'
                      inputClassName='form-control mb-3'
                      formik={formik}
                    />
                  </div>
                  <div className='row mb-6'>
                    <div className='form-check  mb-6 '>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={formik.values.enCompte}
                        {...formik.getFieldProps('enCompte')}
                      />
                      <label className='form-check-label  fw-bold'> En Compte </label>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-form-label fw-bold fs-6'>Depuis</label>
                    <DateInput
                      fieldName='depuis'
                      inputClassName='form-control'
                      value = {dateState.depuis}
                      setter={setDateState}
                      formik={formik}
                    />
                  </div>
                  <div className='row mb-6'>
                    <label className='col-form-label fw-bold fs-6'>Code compte client</label>
                    <TextInput
                      fieldName='codeCompteClient'
                      inputType='text'
                      inputClassName='form-control mb-3'
                      formik={formik}
                    />
                  </div>
                  <div className='row mb-6'>
                    <label className='col-form-label fw-bold fs-6'>Groupe Client</label>
                    <InputGroup size="sm" className='mb-3'>
                      <SelectInput size="sm"
                        fieldName='idClientGroupe'
                        inputClassName='form-select'
                        options={referentielLists.clientGroupeList}
                        formik={formik}
                      />
                      <InputGroup.Text id='basic-addon2'>
                        <Link
                          to='#'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          onClick={() => afficherModalReferentiel(1, 'Groupe Client')}
                        >
                          <i className='fa fa-plus-circle text-primary fs-1'></i>
                        </Link>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-form-label fw-bold fs-6'>Commercial</label>
                    <InputGroup
                      size = "sm" className='mb-3'>
                      <SelectInput
                        fieldName='idCommercial'
                        inputClassName='form-select'
                        options={referentielLists.commercialList}
                        formik={formik}
                      />
                      <InputGroup.Text id='basic-addon2'>
                        <Link
                          to='#'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          onClick={() => afficherModalReferentiel(2, 'Commercial')}
                        >
                          <i className='fa fa-plus-circle text-primary fs-1'></i>
                        </Link>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-form-label fw-bold fs-6'>Transporteur</label>
                    <InputGroup
                      size = "sm" className='mb-3'>
                      <SelectInput
                        fieldName='idTransporteur'
                        inputClassName='form-select'
                        options={referentielLists.transporteurList}
                        formik={formik}
                      />
                      <InputGroup.Text id='basic-addon2'>
                        <Link
                          to='#'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          onClick={() => afficherModalReferentiel(3, 'Transporteur')}
                        >
                          <i className='fa fa-plus-circle text-primary fs-1'></i>
                        </Link>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  <h3 className='row mb-6'>
                    <div className='col-3'>
                      <span className='text-gray-700 me-6'>Zone TVA</span>
                    </div>
                    <div className='col-9'>
                      <div className='separator border-secondary my-4' />
                    </div>
                  </h3>
                  <div className='row mb-6 px-6'>
                    <RadioListInput
                      fieldName='zoneTva'
                      inputClassName='form-select'
                      optionList={zonesTVA}
                      formik={formik}
                    />
                  </div>
                  <h3 className='row mb-6'>
                    <div className='col-5'>
                      <span className='text-gray-700 me-6'>Tableau de Bord</span>
                    </div>
                    <div className='col-7'>
                      <div className='separator border-secondary my-4' />
                    </div>
                  </h3>
                  <div className='row mb-6 px-6'>
                    <RadioListInput
                      fieldName='tableauDeBord'
                      inputClassName='form-select'
                      optionList={tableauDeBords}
                      formik={formik}
                    />
                  </div>
                  <div className='row mb-6 px-6'>
                    <div className='col-11'>
                      <h3 className='row mb-6'>
                        <div className='col-4'>
                          <span className='text-gray-700 me-6'>Réseaux</span>
                        </div>
                        <div className='col-8'>
                          <div className='separator border-secondary my-4' />
                        </div>
                      </h3>
                    </div>
                    <div className='col-1'>
                      <Link
                        to='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        onClick={() => afficherModalReferentiel(4, 'Réseaux', true)}
                      >
                        <i className='fa fa-plus-circle text-primary fs-1'></i>
                      </Link>
                    </div>
                    <table className='table table-rounded table-flush'>
                      <thead>
                        <tr className='py-5 fw-bold  border-bottom border-gray-300 fs-6'>
                          <th></th>
                          <th>Code</th>
                          <th>Libelle</th>
                        </tr>
                      </thead>
                      <tbody>
                        {referentielLists.reseauxList.map((res, key) => (
                          <tr key={res.value} className='py-5 border-bottom border-gray-300 fs-6'>
                            <td>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value={res.value}
                                name='idResaux'
                                checked={formik.values.idReseaux?.some((r) => r === res.value)}
                                onChange={(e) => {
                                  const checked = e.target.checked
                                  const idReseauxActuels = formik.values.idReseaux ?? []
                                  const newReseaux = checked
                                    ? [...idReseauxActuels, res.value]
                                    : idReseauxActuels.filter((r) => r !== res.value)

                                  formik.setFieldValue('idReseaux', newReseaux)
                                }}
                              />
                            </td>
                            <td>{res.code}</td>
                            <td>{res.label}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <h3 className='row mb-6'>
                    <div className='col-5'>
                      <span className='text-gray-700 me-6'>Code Client Web</span>
                    </div>
                    <div className='col-7'>
                      <div className='separator border-secondary my-4' />
                    </div>
                  </h3>
                  <div className='row mb-6 px-6'>
                    <table className='table table-rounded table-flush'>
                      <thead>
                        <tr className='py-5 fw-bold  border-bottom border-gray-300 fs-6'>
                          <th></th>
                          <th>Code</th>
                          <th>Libelle</th>
                        </tr>
                      </thead>
                      <tbody>
                        {codeClientWeb.map((res, key) => (
                          <tr key={res.value} className='py-5 border-bottom border-gray-300 fs-6'>
                            <td>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value={res.value}
                                name='codeClientWeb'
                                checked={formik.values.codeClientWeb?.some(
                                  (ccw) => ccw.Code === res.code && ccw.Libelle === res.label
                                )}
                                onChange={(e) => {
                                  const checked = e.target.checked
                                  const codeClientWebActuels = formik.values.codeClientWeb ?? []
                                  const objectToAdd = {Code: res.code, Libelle: res.label}
                                  const newCodeClientWeb = checked
                                    ? [...codeClientWebActuels, objectToAdd]
                                    : codeClientWebActuels.filter(
                                        (r) => r.Code !== res.code && r.Libelle !== res.label
                                      )

                                  formik.setFieldValue('codeClientWeb', newCodeClientWeb)
                                }}
                              />
                            </td>
                            <td>{res.code}</td>
                            <td>{res.label}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {shouldDisplayFonctionnalite(currentUser, 'AJOUTER PARAMETRAGE') &&
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {loading ? 'Enregistrement...' : 'Enregistrer'}
              </button>
            </div>
            }
          </form>
          <ReferentielModal
            show={openModal}
            showModal={setOpenModal}
            hasCode={refHasCode}
            title={referenceTitle}
            submitForm={submitReferentielForm}
          />
        </div>
      )
};

export {ClientParametrageForm};
