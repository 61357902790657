import React from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {Navigate, Route, Routes} from 'react-router-dom'
import {CourrielListPage} from './CourrielListPage'

const CourrielWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Configuration courriel</PageTitle>
      <Routes>
        <Route
          path='/liste'
          element={
            <>
              <CourrielListPage />
            </>
          }
        />

        <Route index element={<Navigate to='/courriel/liste' />} />
      </Routes>
    </>
  )
}

export {CourrielWrapper}
