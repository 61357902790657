import React, {useEffect, useState} from 'react'
import {getPieceSuggestion, validateSuggestion} from '../../../components/requests'
import {useLocation} from 'react-router'

type DataType = {
  id: number
  nom_piece: string
  etat: string
  prix: string
  reference: string
  status: boolean
}

const SuggestionPage = () => {
  const location = useLocation()
  const [data, setData] = useState<Record<string, DataType[]> | null>(null)
  const [isTerminate, setIsTerminate] = useState<boolean>(false)
  const [checkedItems, setCheckedItems] = useState<{[key: number]: boolean | null}>({})
  const [loading, setLoading] = useState<boolean>(false)

  // Fonction pour récupérer les données
  const getData = () => {
    // format du lien: Immat+id_client_demande_proposee
    setLoading(true)
    const match = location.pathname.match(/\/suggestion\/([^\/]+)\/demande/)

    if (!match) return null // Si aucun match trouvé, retourne null

    const rawData = match[1]
    const result: Record<string, number[]> = {}

    rawData.split('-').forEach((part) => {
      const [immat, ids] = part?.split('+')
      result[immat] = ids?.split('_').map(Number)
    })
    if (result) {
      // Appel API pour récupérer les suggestions
      getPieceSuggestion(result).then((res) => {
        if (res?.status === 200 && res.data) {
          setData(res.data)
          setLoading(false)
        }
      })
    } else {
      console.log('Aucune correspondance trouvée.')
    }
  }

  useEffect(() => {
    getData()
  }, [location.pathname]) // Exécuter à chaque changement d'URL

  // Gérer l'acceptation
  const handleStatus = (id: number, status: boolean, immat: string) => {
    setCheckedItems((prev) => ({
      ...prev,
      [id]: status,
    }));

    setData((prevData) => {
      // Si immat existe et est bien un tableau d'objets Item
      if (!prevData || !prevData[immat]) return prevData // Si immat n'existe pas, retourne data inchangé

      // Mettre à jour le tableau d'objets en vérifiant l'id
      const updatedItems = prevData[immat].map((item) =>
        item.id === id ? {...item, status: status} : item
      )

      return {
        ...prevData, // On garde les autres immat inchangés
        [immat]: updatedItems, // Mise à jour spécifique de cet immat
      }
    })

    validateSuggestion(id, status).then((res) => {
      if (res && res.data && res.status == 200) {
        console.log('Proposition ', res.data)
      }
    })
  }

  const capitalizaFirstletter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase()
  }

  return (
    <>
      <div className='d-flex justify-content-center align-items-center h-100'>
        <div className='w-50 card'>
          {isTerminate && (
            <div className="text-center">
              <div className="mb-10 bg-light-info p-8 rounded">
                <div className="text-info">Les pièces ont été validées avec succès.</div>
              </div>
            </div>
          )}
          <div className='card-header d-flex align-items-center fs-2'>Proposition de pièce</div>
          <div className='card-body'>
            <table className='table table-hover table-rounded table-row-bordered table gy-7 gs-7'>
              <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th>Immatriculation</th>
                <th>Nom de la pièce</th>
                <th>Référence constructeur</th>
                <th>État</th>
                <th>Prix</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody className='align-middle'>
              {loading ? (
                <tr>
                  <td colSpan={6} className='p-4 text-center h-00px'>
                    <span className='spinner-border spinner-border-sm align-middle ms-2 w-30px h-30px'></span>
                  </td>
                </tr>
              ) : (
                data &&
                Object.entries(data).map(([immat, items]) =>
                  items.map((item, index) => (
                    <tr
                      key={item.id}
                      className={
                        item?.status === undefined
                          ? ''
                          : item?.status === true
                            ? 'table-success'
                            : 'table-danger'
                      }
                    >
                      <td>{immat}</td>
                      <td>{item.nom_piece}</td>
                      <td>{item.reference}</td>
                      <td>{capitalizaFirstletter(item.etat)}</td>
                      <td>{item.prix}</td>
                      <td>
                        <div className='d-flex gap-4'>
                          <img
                            src='/media/svg/custom/check.svg'
                            width={35}
                            style={{
                              cursor: checkedItems[item.id] === true ? 'not-allowed' : 'pointer',
                              opacity: checkedItems[item.id] === true ? 0.2 : 1,
                            }}
                            onClick={() =>
                              checkedItems[item.id] !== true && handleStatus(item.id, true, immat)
                            }
                          />
                          <img
                            src='/media/svg/custom/close.svg'
                            width={35}
                            style={{
                              cursor: checkedItems[item.id] === false ? 'not-allowed' : 'pointer',
                              opacity: checkedItems[item.id] === false ? 0.2 : 1,
                            }}
                            onClick={() =>
                              checkedItems[item.id] !== false &&
                              handleStatus(item.id, false, immat)
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                )
              )}
              </tbody>
            </table>
            <button type='button' className='btn btn-primary' onClick={() => setIsTerminate(true)}>
              Terminer
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SuggestionPage
