import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
    ModeleClientParametrage,
    modeleClientParametrageInitValues,
    ModelReferentielLists,
    ModelReferentielListsInitValues,
} from '../../../../../../components/clientParametrage/Models'
import {
    editClientParametrage,
    getClientParametrage,
    getReferentielLists,
} from '../../../../../../components/clientParametrage/requests'
import {LoadingData} from "../../../../../../components/LoadingData";
import {ClientParametrageForm} from '../../components/ClientParametrageForm'
import {toast, ToastContainer} from 'react-toastify'

const ClientParametrage = () => {
    const [data, setData] = useState<ModeleClientParametrage>(modeleClientParametrageInitValues);
    const [referentielData, setReferentielData] = useState<ModelReferentielLists>(ModelReferentielListsInitValues);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    let { clientId } = useParams();
    useEffect(() => {
        getClientParametrageData()
    }, [clientId]);

    const getReferentielData = () => {
        getReferentielLists().then( referentielListQueryResponse =>

        {
            if (referentielListQueryResponse?.status === 200) {
                const updatedReferentielData = Object.assign({...referentielData}, referentielListQueryResponse?.data);
                setReferentielData({...updatedReferentielData});
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    }
    const getClientParametrageData = () => {
        getClientParametrage(clientId).then(clientParametrageRequest => {
            if (clientParametrageRequest?.status === 200) {
                if (clientParametrageRequest?.data?.id !== undefined && clientParametrageRequest?.data?.id !== null) {
                    const updatedData = Object.assign({...data}, clientParametrageRequest?.data)
                    if (updatedData.modeReglement?.id !== undefined) {
                        updatedData.idModeReglement = updatedData.modeReglement?.id
                    }
                    if (updatedData.commercial?.id !== undefined) {
                        updatedData.idCommercial = updatedData.commercial?.id
                    }
                    if (updatedData.clientGroupe?.id !== undefined) {
                        updatedData.idClientGroupe = updatedData.clientGroupe?.id
                    }
                    if (updatedData.transporteur?.id !== undefined) {
                        updatedData.idTransporteur = updatedData.transporteur?.id
                    }
                    if (updatedData.reseaux !== undefined && updatedData.reseaux.length > 0) {
                        updatedData.idReseaux = updatedData.reseaux
                          ?.map(reseau => reseau?.id)
                          .filter((id): id is number => id !== undefined);
                    }
                    if (updatedData.rib !== undefined || updatedData.rib !== '') {
                        const rib = updatedData.rib;
                        updatedData.ribBanque = rib?.substring(0, 5);
                        updatedData.ribGuichet = rib?.substring(5, 10);
                        updatedData.ribCompte = rib?.substring(10, 21);
                        updatedData.ribCle = rib?.substring(21, 23);
                    }
                    setData({...updatedData})
                    getReferentielData()
                }
            } else {
                setLoading(false);
            }
        });
    }


    const submitForm = async (fieldsToUpdate: Partial<ModeleClientParametrage>, callback): Promise<void> => {
        const updatedData = Object.assign({...modeleClientParametrageInitValues}, fieldsToUpdate)

        var requestOk = false;
        try {
            updatedData.rib = `${updatedData.ribBanque}${updatedData.ribGuichet}${updatedData.ribCompte}${updatedData.ribCle}`;
            console.log(updatedData)
            const clientParametrageRequest = await editClientParametrage(clientId, updatedData);
            if (clientParametrageRequest?.status === 200) {
                if (clientParametrageRequest?.data?.id !== undefined && clientParametrageRequest?.data?.id !== null) {
                    requestOk = true;
                }
            }
        } catch (ex) {
            console.error(ex)
        } finally {
            callback();
            if (requestOk) {
                toast.success('Paramétrage ajouté avec succès!', {autoClose: 7000})
                setData({...updatedData})
            }
        }

    }

    return (
        <div className='card mb-5 mb-xl-10'>
            <div  className='card-header border-0' >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Parametrage client</h3>
                </div>
            </div>

            <div className='card-body p-9' >
                {loading
                  ?
                  <LoadingData />
                  :
                  <ClientParametrageForm clientId={clientId} data={data} referentielData={referentielData}
                                         submitForm={submitForm} />
                }
            </div>
            <ToastContainer position="bottom-right" autoClose={3000} />
        </div>
    )
}
export {ClientParametrage}