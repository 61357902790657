/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate , useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {getMe, getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .required('Nom d\'utilisateur requis'),
  password: Yup.string()
    .required('Mot de passe requis'),
})

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState<string | null>(null);
  //const message = 'test affichage message success';

  interface LocationState {
    message: string;
  }

  useEffect(() => {
    // Si un message est passé dans location.state, on le met dans l'état local
    if ((location.state as LocationState)?.message) {
      setMessage((location.state as LocationState)?.message);
      // Supprimer le message en redirigeant sans state
      navigate('/auth/login', { replace: true });
    }
  }, [(location.state as LocationState)?.message, navigate]);

  const formik = useFormik({
    initialValues: {username:"", password:""},
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.username, values.password)
        saveAuth(auth)
        const {data: user} = await getMe()
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Les identifiants de connexion saisis sont incorrects.')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <div className='card custom-card'>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='card-header border-0 mt-6'>
          <div className='d-flex flex-column flex-center w-100'>
            {/* begin::Logo */}
            <Link to='/' className='mb-2'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.svg')} className='h-75px' />
            </Link>
            {/* end::Logo */}
          </div>
        </div>
        <div className='card-body pt-2'>
          {/* begin::Heading */}
          <div className='text-center mb-6'>
            <h1 className='fw-bolder mb-1 gugi-font'>Se connecter</h1>
          </div>
          {/* begin::Heading */}

          {formik.status ? (
            <div className='mb-lg-5 alert alert-danger'>
              <div className='alert-text font-weight-bold' style={{fontSize: '11px'}}>
                {formik.status}
              </div>
            </div>
          ) : (
            message ? (
              <div className='mb-10 bg-light-info p-4 rounded'>
                <div className='text-info'>{message}</div>
              </div>
            ) : (
              <></>
            )
          )}

          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>Nom d'utilisateur</label>
            <input
              placeholder="Nom d'utilisateur"
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.username && formik.errors.username},
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
              type='text'
              name='username'
              autoComplete='off'
            />
            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.username}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mot de passe</label>
            <input
              type='password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Wrapper */}
          <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
            <div />

            {/* begin::Link */}
            <Link to='/auth/forgot-password' className='link-primary'>
              Mot de passe oublié ?
            </Link>
            {/* end::Link */}
          </div>
          {/* end::Wrapper */}

          {/* begin::Action */}
          <div className='d-grid mb-1'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continuer</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Veuillez patienter...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </div>
      </form>
    </div>
  )
}
