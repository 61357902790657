import {useLocation} from 'react-router'
import React, {useEffect, useState} from 'react'
import {ModelePieceItemStock} from '../../../../../components/Models'
import {
  getCatdata,
  getPieceInStock,
  getQuantityForOneDemandePiece,
} from '../../../../../components/requests'
import {ShopModal} from '../../Components/Televendeur/ShopModal'
import {Link} from 'react-router-dom'
import {LoadingData} from '../../../../../components/LoadingData'
import SwitchToggle from '../../../../../components/SwitchToggle'
import moment from 'moment'
import Carousel from '../../../../../components/Carousel'
import {toast, ToastContainer} from 'react-toastify'
import {usePanier} from './PanierContext'
import {Tooltip} from 'react-tooltip'

type LocationState = {
  piece: ModelePieceItemStock
  immat: string
  pieceId: number
  quantity: number
  nomTypePiece: string
  idTypePiece: number
  panier: any
  clientId: number
  demandeId: number
  demandeImmatId: number
  redirectToClientDemande: boolean
  refConst: string | null
  marque: string | null
  modele: string | null
}

type DetailVehicle = {
  marque: string | undefined
  modele: string | undefined
  dateMiseCirc: string | undefined
}

const DetailsPiece = () => {
  const location = useLocation()
  const state = location.state as LocationState | undefined
  const [detailVehicle, setDetailVehicle] = useState<DetailVehicle>({
    marque: '',
    modele: '',
    dateMiseCirc: '',
  })
  const [pieceSelected, setPieceSelected] = useState<number | undefined>(state?.pieceId)
  const [piecesInStockArr, setPiecesInStockArr] = useState<any>([])
  const [price, setPrice] = useState<number>(0)
  const {panier, setPanier} = usePanier()
  const [loading, setLoading] = useState(true)
  const [isOn, setIsOn] = useState(true)
  const [quantity, setQuantity] = useState<number>(0)
  const [imagesArray, setImagesArray] = useState<any>([])
  const [piecesImages, setPiecesImages] = useState<any>([])

  // pour récupérer les données immatriculation du vehicule (marque, modèle, date mise en circulation)
  const verifyCatdata = () => {
    if (state?.immat && state?.immat !== '') {
      try {
        getCatdata(state?.immat).then((res) => {
          if (res?.status === 200) {
            getQuantity()
            if (res?.data !== undefined) {
              setDetailVehicle({
                marque: res?.data?.smarque,
                modele: res.data.smodele,
                dateMiseCirc: res.data.sdatePme,
              })
            }
          }
        })
      } catch (ex) {
        console.error(ex)
      }
    }
  }

  const getQuantity = () => {
    getQuantityForOneDemandePiece(state?.demandeId, state?.demandeImmatId, state?.idTypePiece).then(
      (res) => {
        if (res && res.status === 200 && res.data !== undefined) {
          setQuantity(res.data)
          getPiecesInStock()
        }
      }
    )
  }

  // récupération des pièces en stock à afficher autres pièces
  const getPiecesInStock = () => {
    if (state?.pieceId) {
      try {
        getPieceInStock(state?.pieceId).then((res) => {
          if (res?.data !== undefined && res?.status === 200 && res.data.length > 0) {
            setImagesArray(res.data)
            setPiecesInStockArr(res.data)
            setLoading(false)
          }
        })
      } catch (ex) {
        console.error(ex)
      }
    }
  }

  const handleChangePiece = (id, price) => {
    setPieceSelected(id)
    setPrice(price)
  }

  const handlePanier = (piece, immat , idTypePiece) => {
    const prevPan = [...panier]
    const remain = quantity - prevPan.filter((item) => (item.immat === immat && item.idTypePiece === idTypePiece)).length
    setPanier((prevPanier) => {
      const exist = prevPanier.some((item) => item.id === piece.id)
      if (exist) {
        // Supprime l'objet s'il est déjà présent
        return prevPanier.filter((item) => item.id !== piece.id)
      } else if (!exist && remain > 0) {
        // Ajoute l'objet s'il n'est pas présent
        return [
          ...prevPanier,
          {
            id: piece.id,
            prix: piece.prix,
            refConstructeur: piece.piece.referenceConstructeur,
            statut: 'A_PROPO',
            etat: piece.etat.libelle,
            refCasier: piece.stockageBac,
            marque: detailVehicle.marque,
            modele: detailVehicle.modele,
            nomTypePiece: piece.piece.typePiece.nom,
            idTypePiece: state?.idTypePiece,
            immat: state?.immat,
            demandeImmatId: state?.demandeImmatId,
          },
        ]
      } else {
        toast.warning('Quantité maximale de pièce atteint dans le panier')
        return [...panier]
      }
    })
  }

  useEffect(() => {
    verifyCatdata()
  }, [])

  useEffect(() => {
    setPieceSelected(piecesInStockArr[0]?.piece_entre?.id)
    setPrice(piecesInStockArr[0]?.piece_entre?.prix)
  }, [piecesInStockArr])

  // pour l'affichage des images correspondantes aux pièces sélectionnées
  useEffect(() => {
    const newArr = [...imagesArray]
    setPiecesImages(newArr.flat().filter((item) => item.piece_entre.id === pieceSelected))
  }, [pieceSelected])

  if (!state?.piece) return <p>Aucune donnée disponible</p>

  const renderRowPiece = () => {
    return (
      piecesInStockArr.length > 0 &&
      piecesInStockArr.map((piece) => {
        const isInPanier = panier.some((item) => item.id === piece?.piece_entre?.id) // Vérifie si la pièce est dans le panier
        return (
          <div
            key={piece.piece_entre?.id}
            className={`d-flex flex-row justify-content-between my-4 p-3 rounded cursor-pointer p-5 transition-colors duration-200
            ${pieceSelected === piece?.piece_entre?.id ? 'bg-gray-200' : 'hover:bg-gray-200'}`}
            onClick={() => {
              handleChangePiece(piece?.piece_entre?.id, piece?.piece_entre?.prix)
            }}
          >
            <div className='d-flex gap-5'>
              <img
                src={
                  piece?.images?.length > 0
                    ? `https://koena.fr/photos/pieces/${piece.images[0]}`
                    : 'https://placehold.co/100x100'
                }
                className='rounded'
                alt={piece?.piece_entre?.piece?.typePiece?.nom}
                width={100}
                height={100}
              />
              <div>
                <p className='fs-5 fw-bold text-gray-700'>
                  {piece?.piece_entre?.piece?.typePiece?.nom || 'Nom inconnu'}
                </p>
                <p className='bold text-gray-500'>
                  {piece?.piece_entre?.prix ? `${piece?.piece_entre?.prix} €` : 'Prix inconnu'}
                </p>
              </div>
            </div>
            <img
              src={
                isInPanier ? '/media/svg/custom/bag-minus.svg' : '/media/svg/custom/bag-plus.svg'
              }
              alt={isInPanier ? 'delete to shop' : 'add to shop'}
              width='40'
              onClick={(e) => {
                e.stopPropagation() // Empêche le `onClick` du parent de s'exécuter
                handlePanier(piece?.piece_entre, state?.immat, piece?.piece_entre?.piece?.typePiece?.id) // Appelle ta fonction `handlePanier`
              }}
            />
          </div>
        )
      })
    )
  }
  //
  const renderPieceInStock = () => {
    const capitalizaFirstletter = (str: string = '') => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    }
    return (
      piecesInStockArr.length > 0 &&
      piecesInStockArr
        .filter((piece) => piece?.piece_entre?.id == pieceSelected)
        .map((piece, index) => {
          return (
            <div key={index} className='overflow-auto' style={{maxHeight: '300px'}}>
              <div className='d-flex justify-content-between'>
                <div className='mb-5 bold text-gray-600 fs-4'>Réference constructeur</div>
                <div className='mb-5 fs-4 bold'>
                  {piece.piece_entre?.piece?.referenceConstructeur || ''}
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='mb-5 bold text-gray-600 fs-4'>Prix constructeur</div>
                <div className='mb-5 fs-4 bold'>
                  {piece.piece?.prixConstructeur
                    ? `${piece.piece_entre?.piece?.prixConstructeur}€`
                    : ''}
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='mb-5 bold text-gray-600 fs-4'>Marque véhicule</div>
                <div className='mb-5 fs-4 bold'>{detailVehicle?.marque || ''}</div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='mb-5 bold text-gray-600 fs-4'>Modèle véhicule</div>
                <div className='mb-5 fs-4 bold'>{detailVehicle?.modele || ''}</div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='mb-5 bold text-gray-600 fs-4'>Etat</div>
                <div className='mb-5 fs-4 bold'>
                  {capitalizaFirstletter(piece.piece_entre?.etat?.libelle) || ''}
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='mb-5 bold text-gray-600 fs-4'>Date mise en circulation</div>
                <div className='mb-5 fs-4 bold'>
                  {detailVehicle?.dateMiseCirc == ''
                    ? ''
                    : moment(detailVehicle?.dateMiseCirc).format('DD/MM/YYYY')}
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='mb-5 bold text-gray-600 fs-4'>Réference casier</div>
                <div className='mb-5 fs-4 bold'>{piece.piece_entre?.stockageBac || ''}</div>
              </div>
            </div>
          )
        })
    )
  }

  return (
    <>
      <div className='card p-9'>
        {loading ? (
          <LoadingData />
        ) : (
          <div className='d-flex justify-content-around gap-20'>
            <div className='w-50'>
              <div className='d-flex justify-content-between'>
                <div className='fs-2 bolder'>
                  {piecesInStockArr[0]?.piece_entre?.piece?.typePiece?.nom}
                </div>
                <Link
                  to={`/demandes/enStock/${state?.idTypePiece}`}
                  state={{
                    panier: panier,
                    quantity: state?.quantity,
                    immatriculation: state?.immat,
                    clientId: state?.clientId,
                    demandeId: state?.demandeId,
                    demandeImmatId: state?.demandeImmatId,
                    redirectToClientDemande: state?.redirectToClientDemande,
                    refConst: state?.refConst,
                    marque: state?.marque,
                    modele: state?.modele,
                  }}
                  data-tooltip-id='tooltip'
                  data-tooltip-content='Revenir en arrière'
                  data-tooltip-place='right'
                >
                  <img src='/media/svg/custom/prev-arrow.svg' width={30} alt='previous action' />
                </Link>
              </div>
              <div className='my-6'>
                <Carousel
                  imagesArray={piecesImages[0]?.images ?? []}
                  loading={loading}
                  nomPiece={piecesInStockArr[0]?.piece_entre?.piece?.typePiece?.nom}
                />
              </div>
              <div>
                <div className='d-flex justify-content-between'>
                  <p className=''>{piecesInStockArr.length} en stock</p>
                </div>
                <div className='d-flex flex-row-reverse justify-content-between align-items-center'>
                  <div className='fs-2 mb-4 mt-4 text-end'>{price} €</div>
                </div>
              </div>
              <div className='d-flex mb-7 mt-7 gap-2'>
                <SwitchToggle checked={isOn} onChange={setIsOn} />
                <div>Envoyer une email au client</div>
              </div>
              <div className='d-flex justify-content-between'>
                <button
                  type='button'
                  className={
                    panier.some((item) => item.id === pieceSelected)
                      ? 'btn btn-danger'
                      : 'btn btn-primary'
                  }
                  onClick={() => {
                    const piece = piecesInStockArr.find((p) => p.piece_entre?.id === pieceSelected)
                    if (piece) handlePanier(piece.piece_entre, state.immat, piecesInStockArr[0]?.piece_entre?.piece?.typePiece?.id)
                  }}
                >
                  {panier.some((item) => item.id === pieceSelected)
                    ? 'Retirer du panier'
                    : 'Ajouter au panier'}
                </button>
                <ShopModal
                  clientId={state?.clientId}
                  demandeId={state?.demandeId}
                  panier={panier}
                  setPanier={setPanier}
                  redirectToClientDemande={state?.redirectToClientDemande}
                />
              </div>
            </div>
            <div className='card d-flex flex-column w-50'>
              <div className='card-header fs-2 d-flex align-items-center'>
                Information du produit
              </div>
              <div className='card-body'>
                <div>{renderPieceInStock()}</div>
              </div>
              <div className='border'>
                <div className='card-header fs-2 d-flex align-items-center'>
                  Autres pièces en stock
                </div>
                <div className='card-body card-scroll h-400px p-5'>{renderRowPiece()}</div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/*Affichage toast*/}
      <ToastContainer position='bottom-right' autoClose={3000} />
      {/*Affichage tooltip*/}
      <Tooltip id='tooltip' />
    </>
  )
}

export {DetailsPiece}
