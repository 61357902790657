import {RoleRegisterForm} from './components/RoleRegisterForm';
import {RoleEditForm} from './components/RoleEditForm';
import {Modal,Card} from 'react-bootstrap';
import {KTIcon} from '../../../_metronic/helpers'
import {ModeleModule} from '../../../components/Models';



type Props = {
  show: boolean,
  isEdition: boolean,
  roleId?:Number|undefined
  showModal: () => void,
  handleClose: () => void,
  addDataRole?:() => void,
  updateDataRole?:() => void,
  modules: ModeleModule[],
  loading:() => void
}


const RoleModal = ({ show,isEdition,roleId,showModal,addDataRole,updateDataRole,modules,loading }) => {
 return (
    <>
        <Modal
            size='lg'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered'
            show={show}
        >
           
            <div className='modal-content shadow-none'>
           
                <div className='modal-header d-flex justify-content-end border-0 p-3'>
                    <div className='btn btn-icon btn-sm btn-light-primary ms-2' onClick={()=>showModal(false)}>
                        <KTIcon className='fs-2' iconName='cross' />
                    </div>
                    
                </div>
                <div className='modal-body pt-0'>
                    <Card.Header className="text-center text-md-center border-0 mb-0 mt-md-0 mb-20">
                    <h2>{isEdition ? 'Modification rôle' :'Ajouter un nouveau rôle'}</h2>
                    </Card.Header>
                    {!isEdition && (<RoleRegisterForm showModalProp={showModal} addDataRole={addDataRole} modulesInitial={modules} setLoadingProp={loading}/>)}
                    {isEdition && (<RoleEditForm roleId={roleId} showModalProp={showModal} updateDataRole={updateDataRole} modulesInitial={modules} setLoadingProp={loading}/>)}

                </div>
            </div>
      </Modal>
    </>
  )
}
export {RoleModal}