import React, { useState, useEffect } from 'react';
import {KTSVG} from "../_metronic/helpers";

interface FileModalProps {
    fileUrl: string;
    onClose: () => void;
}

const DocumentModal: React.FC<FileModalProps> = ({ fileUrl, onClose }) => {
    const [content, setContent] = useState<JSX.Element | string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (fileUrl) {
            loadFileContent(fileUrl);  // Charger le contenu en fonction de l'URL
        }
    }, [fileUrl]);

    const loadFileContent = (fileUrl: string) => {
        const fileExtension = fileUrl.split('.').pop()?.toLowerCase();
        setLoading(true);
        switch (fileExtension) {
            case 'pdf':
                loadPDF(fileUrl);
                break;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                loadImage(fileUrl);
                break;
            default:
                setContent(<p>Format de fichier non pris en charge</p>);
                setLoading(false);
                break;
        }
    };

    const loadPDF = (fileUrl: string) => {
      setContent(
        <iframe
          src={fileUrl}
          title="iframe pdf"
          width='100%'
          height='600px'
        ></iframe>
      )
      setLoading(false)
    }

    const loadTextFile = (fileUrl: string) => {
        fetch(fileUrl)
            .then((response) => response.text())
            .then((data) => {
                setContent(<pre>{data}</pre>);
                setLoading(false);
            })
            .catch(() => {
                setContent(<p>Erreur lors du chargement du fichier texte.</p>);
                setLoading(false);
            });
    };

    const loadImage = (fileUrl: string) => {
        setContent(<img src={fileUrl} alt="Image" style={{ width: '100%' }} />);
        setLoading(false);
    };

    const handleClose = () => {
        onClose();
    };

    return (
      <>
        <div
          className='modal fade show'
          tabIndex={-1}
          id='testaffichageModal'
          style={{display: 'block' , background: '#808080ba'}}
          aria-modal="true"
          role="dialog"
        >
          <div className='modal-dialog modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Document</h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={handleClose}
                ></button>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTSVG
                    path='media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </div>
              <div className='modal-body'>
                {/*<p>Modal body text goes here.</p>*/}
                  {loading ? <p>Chargement...</p> : content}
              </div>
            </div>
          </div>
        </div>
      </>
    )
};

export default DocumentModal;
