import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {
  ModeleClient,
  ModeleClientDemande,
  modeleClientDemandeInitValues,
  modeleClientInitValues,
} from '../../../../components/Models'
import moment from 'moment'
import {renderNiveauDelai, renderStatut} from '../../../../components/renders'
import {KTIcon} from '../../../../_metronic/helpers'
import {Pagination} from '../../../../components/Pagination'
import {toast, ToastContainer} from 'react-toastify'
import Swal from 'sweetalert2'
import {useAuth} from '../../../modules/auth'
import {shouldDisplayFonctionnalite} from '../../../../components/helpers'
import {useFormik} from 'formik'
import {
  createClient,
  deleteDemande,
  getClientDemandeEtape,
  getDemandes,
} from '../../../../components/requests'
import ResearchVehicleModal from '../Components/ResearchVehicleModal'
import AddClientModal from '../Components/AddClientModal'
import ResearchClientModal from '../Components/ResearchClientModal'
import 'react-toastify/dist/ReactToastify.css';
import {useLocation} from 'react-router'
import { Tooltip } from 'react-tooltip'

const DemandListPage = (props) => {
  const {SelectedClient} = props
  const [data, setData] = useState<Array<ModeleClientDemande>>([])
  const [, setLoading] = useState(true)
  const [spinner, setSpinner] = useState(false)
  const [clientId, setClientId] = useState<number | null>(null)
  const [isCreateViaModal, setIsCreateViaModal] = useState<boolean>(false)
  const [openModalResVeh, setOpenModalResVeh] = useState<boolean>(false)
  const [nombreDePages, setNombreDePages] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const {currentUser} = useAuth()
  const [clientDemandeEtape, setclientDemandeEtape] = useState<any | undefined>([]);
  const [searchPage, setSearchPage] = useState<any>("")
  const [searchStatut, setSearchStatut] = useState<any>("")
  const [isSearch, setIsSearch] = useState(false)
  const [redirectToClientDemande, setRedirectToClientDemande] = useState<boolean>(true)
  const [hover, setHover] = useState<number | null>(null)
  const location = useLocation()
  const state = location.state as LocationState | null

  interface LocationState {
    showCancelToast: boolean
    showSubmitToast: boolean
  }

  // Récupération des données de demande en fonction de id client ou non
  const getClientDemandeData = async () => {
    setSpinner(true);
    SelectedClient != null ? setRedirectToClientDemande(true) : setRedirectToClientDemande(false)

    try {
      const clientDemandesRequest = await getDemandes(!isSearch ? currentPage : 1, searchPage, searchStatut, SelectedClient)
      if (clientDemandesRequest?.status === 200 && clientDemandesRequest?.data) {
        const { page, pages, total, data } = clientDemandesRequest.data;
        setCurrentPage(page);
        setNombreDePages(pages);
        setTotalPages(total);
        setData(data);
      }
    } catch (error) {
      console.error("Error fetching client demandes:", error);
    } finally {
      setLoading(false);
      setSpinner(false);
      setIsSearch(false);
    }
  };

  // Récupération des status des demandes
  const getCLientDemandeEtapeData = () => {
    getClientDemandeEtape().then(demandeEtapeRequest => {
      if (demandeEtapeRequest?.status === 200) {
        if (demandeEtapeRequest?.data !== undefined) {
          const transformedData = demandeEtapeRequest.data.map((item) => ({
               value: item.code,
               label: item.libelle,
          }));
          setclientDemandeEtape([...transformedData]);
          setLoading(false);
        }
      }
    }).catch((e) => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getCLientDemandeEtapeData();
  },[])

  //Suppresion d'une demande
  const handleDemandeDelete = (id: number) => {
    Swal.fire({
      title: 'Attention',
      text: 'Merci de confirmer la suppression',
      icon: 'warning',
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
      showCancelButton: true,
      confirmButtonColor: '#1BC5BD',
      cancelButtonColor: '#F64E60',
    }).then((alertResult) => {
      if (alertResult.isConfirmed) {
        const idToast = toast.loading("Suppression en cours...")
        deleteDemande(id).then(response => {
          if (response?.status === 204) {
            getClientDemandeData()
            setLoading(false)
            toast.update(idToast, { render: 'Demande supprimée avec succès.', type: 'success' , isLoading: false, autoClose: 3000})
          }
        }).catch(error => { //si code 500 ou autre que 204
          getClientDemandeData()
          setLoading(false);
          toast.update(idToast, { render: 'Erreur lors de la suppression.', type: 'error' , isLoading: false, autoClose: 3000})
        })
      }
    })
  }

  const formik = useFormik({
    initialValues: { allSearch: "" , statut : "" },
    onSubmit: (values, { setSubmitting }) => {
      setSearchPage(values.allSearch)
      setSearchStatut(values.statut)
    },
  });

  const OnchangeStatus = (value) => {
    formik.setFieldValue("statut", value);
    setIsSearch(true)
    formik.submitForm()
  }

  const submitClientCreateForm = async (
    fieldsToUpdate: Partial<ModeleClient>,
    callback
  ): Promise<void> => {
    const updatedData = Object.assign({...modeleClientInitValues}, fieldsToUpdate)
    try {
      const clientRequest = await createClient(updatedData)
      if (clientRequest?.status === 200) {
        if (clientRequest?.data?.id !== undefined && clientRequest?.data?.id !== null) {
          setClientId(clientRequest.data.id)
          setOpenModalResVeh(true)
        }
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      callback()
    }
  }

  // Re-rendu des listes de demande
  useEffect(() => {
    getClientDemandeData()
  }, [currentPage , searchPage , searchStatut])

  // Re-rendu des notifications Toast lors de la création/annulation demande
  useEffect(() => {
    if (state?.showCancelToast) {
      toast.info('Demande annulée');
    }
    if (state?.showSubmitToast) {
      toast.success('Demande crée avec succès')
    }
  }, [location.state])

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Demandes client</h3>
          </div>
          {shouldDisplayFonctionnalite(currentUser, 'AJOUTER DEMANDE') && (
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
            >
              <button
                type='button'
                className='btn btn-light-primary'
                data-bs-target={SelectedClient ? '#exampleModalSearchV' : '#exampleModalToggle'}
                data-bs-toggle='modal'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Ajouter
              </button>
            </div>
          )}
        </div>

        {/* debut champs recherche */}
        <form className='card-header border-0 pt-5 pb-5 gap-2' id='searchForm'  onSubmit={formik.handleSubmit}>
          <div className='col-lg-5'></div>
          <div className='col-lg-2'>
            <select
              name='statut'
              id='statut'
              className='form-select form-select-solid form-select-lg fw-bold'
              onChange={(e) => OnchangeStatus(e.target.value)}
            >
              <option value=''>Statut</option>
              {clientDemandeEtape.length ? ( clientDemandeEtape.map((etape) => {
                return <option key={etape.id} value={etape?.value}>{etape?.label}</option>
              })) : (
                <option value=''>Aucun statut trouvé</option>
              )}
            </select>
          </div>
          <div className='form-group d-flex col-lg-4'>
            <div className='input-group'>
              <input
                type='text'
                className='form-control h-100'
                {...formik.getFieldProps('allSearch')}
                name='allSearch'
                placeholder='Rechercher une demande'
              />
              <button type='submit' className='btn btn-light border-0 input-group-append h-100' onClick={() => setIsSearch(true)}>
                <i className='fas fa-search text-secondary fs-1'></i>
              </button>
            </div>
          </div>
        </form>
        {/* fin champs recherche */}

        {/*Modal that display Client researchBar*/}
        <ResearchClientModal setClientId={setClientId} setIsCreateViaModal={setIsCreateViaModal}/>
        {/*Modal création de client*/}
        <AddClientModal setClientId={setClientId} setOpenModalResVeh={setOpenModalResVeh} isCreateViaModal={isCreateViaModal} openModalResVeh={openModalResVeh}/>
        {/*Modal recherche de véhicule*/}
        <ResearchVehicleModal clientId={clientId || SelectedClient} demandeId={null} data={modeleClientDemandeInitValues} setClientId={setClientId} redirectToClientDemande={redirectToClientDemande}/>

        {shouldDisplayFonctionnalite(currentUser, 'VOIR DEMANDE') && (
          <div>
            <div className='card-body p-9'>
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-100px'>Numéro</th>
                    <th className='min-w-140px'>Assignée</th>
                    <th className='min-w-140px'>Date</th>
                    <th className='min-w-140px'>Délais</th>
                    <th className='min-w-140px'>Statut</th>
                    {!SelectedClient && (
                      <th className='min-w-140px'>Client</th>
                    )}
                    <th className='min-w-140px'>Immatriculation</th>
                    <th className='min-w-140px'>Centre</th>
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  {spinner ? (
                    <tr>
                      <td colSpan={9} className='p-4 text-center h-500px'>
                        <span className='spinner-border spinner-border-sm align-middle ms-2 w-30px h-30px'></span>
                      </td>
                    </tr>
                  ) : (
                    data.length ? (data.map((clientDemande, index) => {
                        return (
                          <tr key={clientDemande[0].id}>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <p className='text-gray-800 fw-bold text-hover-primary fs-6 mb-0'>
                                    {clientDemande[0].id}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p className='text-gray-800 fw-bold text-hover-primary d-block fs-6 mb-0'>
                                {clientDemande[0].utilisateurAssigne?.nom}{' '}
                                {clientDemande[0].utilisateurAssigne?.prenom}
                              </p>
                            </td>
                            <td>
                              <p className='text-gray-800 fw-bold text-hover-primary d-block fs-6 mb-0'>
                                {moment(clientDemande[0].dateCreation).format('DD/MM/YYYY')}
                              </p>
                            </td>
                            <td>
                              <p className='text-gray-800 fw-bold text-hover-primary d-block fs-6 mb-0'>
                                {renderNiveauDelai(clientDemande[0].dateCreation)}
                              </p>
                            </td>
                            <td>
                              <p className='text-gray-800 fw-bold text-hover-primary d-block fs-6 mb-0'>
                                {renderStatut(clientDemande[0].clientDemandeEtape)}
                              </p>
                            </td>
                            {
                              !SelectedClient && (
                                <td>
                                  {shouldDisplayFonctionnalite(currentUser, 'VOIR FICHE') ? (
                                    <Link
                                      to={`/vente/clients/${clientDemande[0].client?.id}/afficher`}
                                      className='text-gray-800 fw-bold text-hover-primary d-block fs-6 mb-0'
                                    >
                                      {clientDemande[0].client?.professionnel
                                        ? clientDemande[0].client?.nomSociete
                                        : clientDemande[0].client?.nom +
                                        ' ' +
                                        clientDemande[0].client?.prenom}
                                    </Link>
                                  ) : (
                                    <p className='text-gray-800 fw-bold text-hover-primary d-block fs-6 mb-0'>
                                      {clientDemande[0].client?.professionnel
                                        ? clientDemande[0].client?.nomSociete
                                        : clientDemande[0].client?.nom +
                                        ' ' +
                                        clientDemande[0].client?.prenom}
                                    </p>
                                  )}
                                </td>
                              )
                            }
                            <td>
                              {clientDemande['immatriculation']
                                ?.split(',')
                                .map((item, index) => (
                                  <p
                                    key={index}
                                    className='text-gray-800 fw-bold text-hover-primary d-block fs-6 mb-0'
                                  >
                                    {item.trim()}
                                  </p>
                                ))}
                            </td>
                            <td>
                              <p className='text-gray-800 fw-bold text-hover-primary d-block fs-6 mb-0'>
                                {clientDemande['centreNom']?.split(',').map((item, index) => (
                                  <p
                                    key={index}
                                    className='text-gray-800 fw-bold text-hover-primary d-block fs-6 mb-0'
                                  >
                                    {item.trim()}
                                  </p>
                                ))}
                              </p>
                            </td>
                            <td>
                              <div className='d-flex justify-content-end flex-shrink-0 gap-3'>
                                {shouldDisplayFonctionnalite(
                                  currentUser,
                                  'TRAITER DEMANDE'
                                ) && (
                                  <>
                                    <Link
                                      to={`/demandes/${clientDemande[0]?.client?.id}/traitement/${clientDemande[0].id}`}
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      state={{redirectToClientDemande: redirectToClientDemande}}
                                      data-tooltip-id="tooltip"
                                      data-tooltip-content="Traiter la demande"
                                      data-tooltip-place="left"
                                    >
                                      <img src='/media/svg/custom/File add.svg' width='27' />
                                    </Link>
                                  </>
                                )}
                                {clientDemande[0].clientDemandeEtape.code == 'NOUVE' ? (
                                  <span className='btn btn-icon btn-bg-light btn-sm'>
                                        <i
                                          key={clientDemande[0].id}
                                          className='bi bi-trash fs-2'
                                          onClick={() => handleDemandeDelete(clientDemande[0].id)}
                                          style={{
                                            color: hover === index ? '#f1416c' : '',
                                            transition: "color 0.3s ease",
                                          }}
                                          onMouseEnter={() => setHover(index)}
                                          onMouseLeave={() => setHover(null)}
                                          data-tooltip-id="tooltip"
                                          data-tooltip-content="Supprimer la demande"
                                          data-tooltip-place="right"
                                        ></i>
                                      </span>
                                ) : (
                                  <span className='btn btn-icon btn-bg-light btn-sm invisible'></span>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      }
                    ) ) : (
                      <tr>
                        <td colSpan={9} className='text-center'>
                          Aucun demande client trouvé
                        </td>
                      </tr>
                    )
                  )
                  }
                  </tbody>
                </table>
              </div>
              {/*Pagination del'interface*/}
              <Pagination
                totalPages={totalPages}
                nombreDePages={nombreDePages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                locationState={0}
              />
              {/*Affichage de la notification toast*/}
              <ToastContainer position='bottom-right' autoClose={3000} />
              {/*Affichage du tooltip*/}
              <Tooltip id="tooltip" />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {DemandListPage}
