import React from 'react'
import {useNavigate} from "react-router-dom";
import {ModeleCentre, modeleCentreInitValues} from "../../../components/Models";
import {CentreForm} from "./components/CentreForm";
import {createCentre} from "../../../components/requests";

const CentreAddPage = () => {
  const navigate = useNavigate();
  const submitForm = async (fieldsToUpdate: Partial<ModeleCentre>, callback): Promise<void> => {
    const updatedData = Object.assign({...modeleCentreInitValues}, fieldsToUpdate)

    var requestOk = false;
    try {
      const clientRequest = await createCentre(updatedData);
      if (clientRequest?.status === 200) {
        if (clientRequest?.data?.id !== undefined && clientRequest?.data?.id !== null) {
          requestOk = true;
          navigate(`/centres`);
        }
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      callback();
    }

  }


  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Créer un centre</h3>
        </div>
      </div>

      <div>
        <CentreForm centreId={null} data={modeleCentreInitValues} submitForm={submitForm} />
      </div>
    </div>
  )
}

export {CentreAddPage}
