import React, { useEffect, useState } from 'react'
import { getSearchPiece } from '../../../../../components/requests'  // Importe la fonction getSearchPiece
import { Link } from 'react-router-dom'

interface OnMarketProps {
  reference: string | undefined
  piece: any
  locationState: any
  index: number
  handleCount: any
}

const OnMarket = ({ reference, piece, locationState, index, handleCount }: OnMarketProps) => {
  const [text, setText] = useState<string | number>('Chargement...')
  const [data, setData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [disableLink, setDisableLink] = useState<boolean>(false)

  // Pour contrôler le signal, annuler les requêtes en cours lors d'un changement de page
  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const getDataPieces = async (ref: string) => {
      try {
        const res = await getSearchPiece(ref, signal)
        if (res && res.data && res.data.data) {
          setData(res.data)
          setText(res.data.data.length)
        } else {
          setText(0)
          setDisableLink(true)
        }
      } catch (error) {
        if (error instanceof Error && error.name !== 'AbortError') {
          setText(0)
          setDisableLink(true)
        }
      } finally {
        handleCount()
        setIsLoading(false)
      }
    }

    if (reference) {
      getDataPieces(reference)
    }

    return () => {
      controller.abort()
    }
  }, [reference, index])

  return (
    <>
      {isLoading ? (
        <span className="badge bg-light text-gray-500">Chargement...</span>
      ) : (
        disableLink ? (
            <span className="btn btn-icon btn-bg-light btn-sm">{text}</span>
          ) : (
          <Link
            to={`/demandes/onMarket/${piece[0]?.id}`}
            state={{
              data: data,
              idTypePiece: piece[0].typePiece.id,
              panier: locationState?.panier,
              quantity: locationState?.quantity,
              immatriculation: locationState?.immatriculation,
              clientId: locationState?.clientId,
              demandeId: locationState?.demandeId,
              demandeImmatId: locationState?.demandeImmatId,
            }}
            data-tooltip-id="tooltip"
            data-tooltip-content="Positionnement sur le marché"
            data-tooltip-place="left"
          >
            <span className="btn btn-icon btn-bg-light btn-sm">{text}</span>
          </Link>
        )
      )}
    </>
  )
}


export default OnMarket
