import {ModeleCourriel} from '../../../../components/Models'
import React, {useEffect, useState} from 'react'
import {editCourriel, getCourriel} from '../../../../components/requests'
import {useFormik} from 'formik'
import {TextInput} from '../../../../components/inputs/TextInput'
import {Eye, EyeSlash} from 'react-bootstrap-icons'
import {SelectInput} from '../../../../components/inputs/SelectInput'
import {typeSecuriteCourriel} from '../../../../components/Data'
import {Button} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {handleValidation} from './Validation'

const CourrielEditForm = (props) => {
  const initialValues: ModeleCourriel = {
    hote: '',
    identifiant: '',
    password: '',
    source: '',
    typeSecurite: 'SSL',
    port: 0,
    actif: false,
  }

  const [showPassword, setShowPassword] = useState(false)
  const [data, setData] = useState<ModeleCourriel>(initialValues)
  const {courrielId, showModalProp, updateDataCourriel, setLoadingProp} = props
  const [loadingModal, setLoadingModal] = useState(true)

  useEffect(() => {
    setLoadingModal(true)
    getCourriel(courrielId)
      .then((courrielRequest) => {
        if (courrielRequest?.status === 200) {
          if (courrielRequest?.data !== undefined) {
            const courrielEdited: ModeleCourriel = courrielRequest?.data
            setData(courrielEdited)
            setLoadingModal(false)

            formik.setValues(courrielEdited)
          } else {
            setLoadingModal(false)
          }
        } else {
          setLoadingModal(false)
        }
      })
      .catch((e) => {
        setLoadingModal(false)
      })
  }, [courrielId])

  const onSubmitHandler = (values) => {
    showModalProp(false)
    setLoadingProp(true)
    editCourriel(courrielId, values)
      .then((courrielRequest) => {
        if (courrielRequest?.status === 200) {
          if (courrielRequest?.data !== undefined) {
            updateDataCourriel(courrielRequest.data)
            setLoadingProp(false)
          } else {
            setLoadingProp(false)
          }
        } else {
          setLoadingProp(false)
        }
      })
      .catch((e) => {
        setLoadingProp(false)
      })
  }

  const formik = useFormik<ModeleCourriel>({
    initialValues: data,
    validationSchema: handleValidation,
    onSubmit: onSubmitHandler,
    validateOnBlur: true,
    validateOnChange: false,
  })

  return (
    <div>
      <form action='#' onSubmit={formik.handleSubmit}>
        {loadingModal ? (
          <div className='d-flex flex-column flex-center h-100 p-6'>
            <KTIcon iconName='loading' className='fs-3x text-primary mb-2' />
          </div>
        ) : (
          <div className='App'>
            <div className='row'>
              <div className='col-sm-12'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Hôte</label>
                <TextInput
                  fieldName={'hote'}
                  inputType={'text'}
                  inputClassName={'form-control form-control-lg mb-3 mb-lg-0'}
                  formik={formik}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Identifiant</label>
                <TextInput
                  fieldName={'identifiant'}
                  inputType={'text'}
                  inputClassName={'form-control form-control-lg mb-3 mb-lg-0'}
                  formik={formik}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Mot de passe
                </label>
                <div style={{position: 'relative'}}>
                  <TextInput
                    fieldName={'password'}
                    inputType={showPassword ? 'text' : 'password'}
                    inputClassName={'form-control form-control-lg mb-3 mb-lg-0'}
                    formik={formik}
                  />
                  <button
                    type='button'
                    className='btn btn-outline-secondary'
                    onClick={() => setShowPassword(!showPassword)}
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '23px',
                      transform: 'translateY(-50%)',
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer',
                    }}
                  >
                    {showPassword ? <EyeSlash size={20} /> : <Eye size={20} />}
                  </button>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Source</label>
                <TextInput
                  fieldName={'source'}
                  inputType={'text'}
                  inputClassName={'form-control form-control-lg mb-3 mb-lg-0'}
                  formik={formik}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                  Type de sécurité
                </label>
                <SelectInput
                  fieldName={'typeSecurite'}
                  inputClassName={'form-select form-select-lg fw-bold'}
                  options={typeSecuriteCourriel}
                  formik={formik}
                />
              </div>
              <div className='col-sm-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Port</label>
                <TextInput
                  fieldName={'port'}
                  inputType={'number'}
                  inputClassName={'form-control form-control-lg mb-3 mb-lg-0'}
                  formik={formik}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6 d-flex'>
                <label className='col-form-label fw-bold fs-6'>Actif</label>
                <input
                  style={{marginLeft: '10px'}}
                  className='form-check-input my-3'
                  type='checkbox'
                  value={1}
                  name={'actif'}
                  checked={!!formik.values.actif}
                  onChange={(e) => {
                    formik.setFieldValue('actif', e.target.checked)
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12' style={{textAlign: 'right', marginTop: '10px'}}>
                <Button variant='secondary me-5' onClick={() => showModalProp(false)}>
                  Annuler
                </Button>
                <Button variant='info' type='submit'>
                  Enregistrer
                </Button>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

export {CourrielEditForm}
