import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {ClientForm} from '../../shop/clients/components/ClientForm'
import {ModeleClient, modeleClientInitValues} from '../../../../components/Models'
import {createClient} from '../../../../components/requests'

const AddClientModal = (props) => {
  const {setClientId, setOpenModalResVeh, openModalResVeh, isCreateViaModal} = props
  const submitClientCreateForm = async (
    fieldsToUpdate: Partial<ModeleClient>,
    callback
  ): Promise<void> => {
    const updatedData = Object.assign({...modeleClientInitValues}, fieldsToUpdate)
    try {
      const clientRequest = await createClient(updatedData)
      if (clientRequest?.status === 200) {
        if (clientRequest?.data?.id !== undefined && clientRequest?.data?.id !== null) {
          console.log('client crée', clientRequest?.data)
          setClientId(clientRequest.data.id)
          setOpenModalResVeh(true)
        }
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      callback()
    }
  }

  return (
    <div
      className='modal fade'
      id='ModalClientCreate'
      aria-hidden='true'
      aria-labelledby='ModalClientCreate'
      tabIndex={-1}
    >
      <div className='modal-dialog modal-lg'>
        <div className='modal-content'>
          <div className='modal-header border-0'>
            <h5 className='modal-title' id='ModalClientCreate'>
              Création de client
            </h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <ClientForm
              clientId={null}
              data={modeleClientInitValues}
              submitForm={submitClientCreateForm}
              isCreateViaModal={isCreateViaModal}
              openModalResVeh={openModalResVeh}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddClientModal