import React, { createContext, useState, useContext, ReactNode } from 'react';

// Définir le type pour le contexte
interface PanierContextType {
  panier: any[];
  setPanier: React.Dispatch<React.SetStateAction<any[]>>;
}

// Créer le contexte
const PanierContext = createContext<PanierContextType | undefined>(undefined);

// Hook personnalisé pour utiliser le contexte Panier
export const usePanier = () => {
  const context = useContext(PanierContext);
  if (!context) {
    throw new Error('usePanier must be used within a PanierProvider');
  }
  return context;
};

// Définir le type pour les props de PanierProvider, incluant children
interface PanierProviderProps {
  children: ReactNode;  // Spécifie que children est de type ReactNode
}

// Provider qui encapsule les composants enfants
export const PanierProvider: React.FC<PanierProviderProps> = ({ children }) => {
  const [panier, setPanier] = useState<any[]>([]);
  return (
    <PanierContext.Provider value={{ panier, setPanier }}>
      {children}
    </PanierContext.Provider>
  );
};
