import React, {useEffect, useState} from 'react'
import {KTIcon} from "../../../_metronic/helpers";
import {Link} from "react-router-dom";
import {ModeleUtilisateur} from "../../../components/Models";
import {deleteUtilisateur, getUtilisateurs} from "../../../components/requests";
import {LoadingData} from "../../../components/LoadingData";
import Swal from "sweetalert2";
import {useFormik} from 'formik';
import {Pagination} from '../../../components/Pagination';
import {useAuth} from "../../modules/auth";
import {shouldDisplayFonctionnalite} from "../../../components/helpers";

const UtilisateursListPage = () => {
    const [data, setData] = useState<Array<ModeleUtilisateur>>([]);
    const [loading, setLoading] = useState(false)
    const [nombreDePages, setNombreDePages] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [searchPage, setSearchPage] = useState<string>("")
    const [isSearch, setIsSearch] = useState(false)
    const {currentUser} = useAuth()

    useEffect(() => {
      getUtilisateursData()
    }, [currentPage, searchPage])

    const getUtilisateursData = () => {
      setLoading(true)
      getUtilisateurs(!isSearch ? currentPage : 1, searchPage)
        .then((utilisateursRequest) => {
          setNombreDePages(utilisateursRequest?.pages)
          setTotalPages(utilisateursRequest?.total)
          setCurrentPage(utilisateursRequest?.page)
          if (utilisateursRequest?.data !== undefined) {
            setData(utilisateursRequest?.data)
            setLoading(false)
            setIsSearch(false)
          }
        })
        .catch((e) => {
          setLoading(false)
        })
    }

    const deleteUser = (utilisateurId) => {
        Swal.fire({
            title: 'Attention',
            text: 'Merci de confirmer la suppression',
            icon: 'warning',
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            confirmButtonColor: '#1BC5BD',
            cancelButtonColor: '#F64E60',
        }).then((alertResult) => {
            if (alertResult.isConfirmed) {
                setLoading(true)
                deleteUtilisateur(utilisateurId).then((response) => {
                    if (response?.status === 200) {
                        setLoading(false)
                        getUtilisateursData()
                    }
                })
            }
        })
    }

    const formik = useFormik({
        initialValues: { allSearch: "" },
        onSubmit: (values, { setSubmitting }) => {
            setSearchPage(values.allSearch);
        },
    });

    return (
        <>
            {/* begin::Row  */}
            <div className='row g-5 g-xl-8'>
                {/* begin::Col  */}
                <div className='col-xxl-12'>
                    <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
                        {/* begin::Header */}
                        <div className='card-header border-0 pt-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bold fs-3 mb-1'>Utilisateurs</span>
                            </h3>
                            {shouldDisplayFonctionnalite(currentUser, 'AJOUTER UTIL') &&
                            <div
                                className='card-toolbar'
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                data-bs-trigger='hover'
                                title='Click to add a user'
                            >
                                <Link
                                    to={`/utilisateurs/ajouter`}
                                    className='btn btn-sm btn-light-primary'
                                >
                                    <KTIcon iconName='plus' className='fs-3'/>
                                    Créer un utilisateur
                                </Link>
                            </div>
                            }
                        </div>
                        {/* end::Header */}

                        {/* debut champs recherche */}
                        <form className='card-header border-0 pt-5 pb-5' onSubmit={formik.handleSubmit}>
                            <div></div>
                            <div className='form-group d-flex col-lg-4'>
                                <div className='input-group'>
                                    <input
                                        type='text'
                                        className='form-control h-100'
                                        {...formik.getFieldProps('allSearch')}
                                        name='allSearch'
                                        placeholder='Rechercher un utilisateur'
                                    />
                                    <button type='submit' className='btn btn-light border-0 input-group-append h-100' onClick={() => setIsSearch(true)}>
                                        <i className='fas fa-search text-secondary fs-1'></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                        {/* fin champs recherche */}

                        {loading
                            ?
                            <LoadingData />
                            :
                            <>
                                {shouldDisplayFonctionnalite(currentUser, 'VOIR UTIL') &&
                                <div className='card-body py-3'>
                                    {/* begin::Table container */}
                                    <div className='table-responsive'>
                                        {/* begin::Table */}
                                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                            {/* begin::Table head */}
                                            <thead>
                                            <tr className='fw-bold text-muted'>
                                                <th className='min-w-150px'>Nom/Prénom</th>
                                                <th className='min-w-140px'>Nom d'utilisateur</th>
                                                <th className='min-w-150px'>Role</th>
                                                <th className='min-w-150px'>Centre</th>
                                                <th className='min-w-100px text-end'>Actions</th>
                                            </tr>
                                            </thead>
                                            {/* end::Table head */}
                                            {/* begin::Table body */}
                                            <tbody>
                                            { data.length ? (data.map((utilisateur) => {
                                                return (
                                                    <tr key={utilisateur.id}>
                                                        <td>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='d-flex justify-content-start flex-column'>
                                                                    <p className='text-dark fw-bold text-hover-primary fs-6 mb-0'>
                                                                        {utilisateur.nom} {utilisateur.prenom}
                                                                    </p>
                                                                    <span
                                                                        className='text-muted fw-semibold text-muted d-block fs-7'>
                                                                    {utilisateur.role?.libelle}
                                                                </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p
                                                                className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                                {utilisateur.pseudo}
                                                            </p>
                                                            <span
                                                                className='text-muted fw-semibold text-muted d-block fs-7'>
                                                                    {utilisateur.email}
                                                                </span>
                                                        </td>
                                                        <td>
                                                            <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                                {utilisateur.role?.libelle}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                                {utilisateur?.listeCentres?.flat().map(centre => centre.nom).join(', ')}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <div className='d-flex justify-content-end flex-shrink-0'>
                                                                {shouldDisplayFonctionnalite(currentUser, 'MODIFIER UTIL') &&
                                                                <Link
                                                                    to={`/utilisateurs/${utilisateur.id}/modifier`}
                                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                >
                                                                    <i className='bi bi-person-vcard fs-5'></i>
                                                                </Link>
                                                                }
                                                                {shouldDisplayFonctionnalite(currentUser, 'SUPPRIMER UTIL') &&
                                                                <p
                                                                  onClick={() => {deleteUser(utilisateur.id)}}
                                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                >
                                                                    <i className='bi bi-trash fs-5'></i>
                                                                </p>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) ) : (
                                                <tr>
                                                    <td colSpan={5} className='text-center'>
                                                        Aucun résultat trouvé
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                            {/* end::Table body */}
                                        </table>
                                        {/* end::Table */}
                                    </div>
                                    {/* end::Table container */}

                                    {/* begin::pagination*/}
                                    <Pagination
                                        totalPages={totalPages}
                                        nombreDePages={nombreDePages}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        locationState={0}
                                    />
                                    {/* end::pagination*/}

                                </div>
                                }
                            </>
                        }
                    </div>
                </div>
                {/* end::Col  */}
            </div>
            {/* end::Row  */}
        </>
    )
}

export {UtilisateursListPage}
