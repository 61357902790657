import React from 'react'

const VehicleData = (props) => {
  const {immatriculation, vehicleInfo, setImmatriculationValide, prevArrow} = props

  return (
    <>
      <div>
        <div className='row'>
          {!vehicleInfo && (
            <>
              <div className='d-flex mb-5'>
                <label className='col col-form-label fw-bold fs-3 text-gray-600'>
                  {immatriculation.stecMarque} {immatriculation.stecModele}{' '}
                  {immatriculation.sversion}
                </label>
                {
                  prevArrow && (
                    <div className='row mb-2'>
                      <div className='d-flex justify-content-end'>
                        <img
                          src='/media/svg/custom/prev-arrow.svg'
                          onClick={() => setImmatriculationValide(false)}
                          style={{
                            cursor: 'pointer',
                            width: '30px',
                          }}
                          data-tooltip-id="tooltip"
                          data-tooltip-content="Voir les immatriculations"
                          data-tooltip-place="right"
                        />
                      </div>
                    </div>
                  )
                }
              </div>
              <div className='col-lg-6'>
                <div className='row mb-2'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Date 1ère circulation
                  </label>
                  <p className={'col-lg-8 col-form-label mb-0'}>{immatriculation.sdatePme}</p>
                </div>
                <div className='row mb-2'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>N° série</label>
                  <p className={'col-lg-8 col-form-label mb-0'}>{immatriculation.snoSerie}</p>
                </div>
                <div className='row mb-2'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Carosserie</label>
                  <p className={'col-lg-8 col-form-label mb-0'}>{immatriculation.scarrosserie}</p>
                </div>
                <div className='row mb-2'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Code moteur</label>
                  <p className={'col-lg-8 col-form-label mb-0'}>{immatriculation.scodeMoteur}</p>
                </div>
                <div className='row mb-2'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Code moteur tecdoc</label>
                  <p className={'col-lg-8 col-form-label mb-0'}>
                    {immatriculation.stecCodeMoteurTecdocEquivalent}
                  </p>
                </div>
                <div className='row mb-2'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Code VIN</label>
                  <p className={'col-lg-8 col-form-label mb-0'}>{immatriculation.sVin}</p>
                </div>
                <div className='row mb-2'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Type</label>
                  <p className={'col-lg-8 col-form-label mb-0'}>{immatriculation.stypeVin}</p>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='row mb-2'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Cylindrée</label>
                  <p className={'col-lg-8 col-form-label mb-0'}>{immatriculation.stecCylindree}</p>
                </div>
                <div className='row mb-2'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Energie</label>
                  <p className={'col-lg-8 col-form-label mb-0'}>{immatriculation.stecEnergie}</p>
                </div>
                <div className='row mb-2'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Puissance CV</label>
                  <p className={'col-lg-8 col-form-label mb-0'}>{immatriculation.spuissanceCv}</p>
                </div>
                <div className='row mb-2'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Puissance fiscale</label>
                  <p className={'col-lg-8 col-form-label mb-0'}>
                    {immatriculation.spuissanceFiscale}
                  </p>
                </div>
                <div className='row mb-2'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Type boîte</label>
                  <p className={'col-lg-8 col-form-label mb-0'}>
                    {immatriculation.stypeBoiteVitesse}
                  </p>
                </div>
                <div className='row mb-2'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Couleur</label>
                  <p className={'col-lg-8 col-form-label mb-0'}>{immatriculation.scouleur}</p>
                </div>
                <div className='row mb-2'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Nombre portes</label>
                  <p className={'col-lg-8 col-form-label mb-0'}>{immatriculation.stecNbPortes}</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default VehicleData
