import {Response} from '../_metronic/helpers'

export interface ModeleSelectInput {
  value?: string
  label?: string
}

export interface ModeleRecherchePiece {
  reference?: string
}

export const modeleRecherchePieceValues: ModeleRecherchePiece = {
  reference: '',
}

export interface ModeleRechercheCatalogue {
  referenceConstructeur?: string
  referenceVehicule?: string
  referencePiece?: string
  catalogue?: string | number
}

export const modeleRechercheCatalugeValues: ModeleRechercheCatalogue = {
  referenceConstructeur: '',
  referenceVehicule: '',
  referencePiece: '',
  catalogue: '1',
}

export interface ModeleRechercheVehicule {
  immatriculation?: string
  cnit?: string
}

export const modeleRechercheVehiculeValues: ModeleRechercheVehicule = {
  immatriculation: '',
  cnit: '',
}

export interface ModelePieceItem {
  codePiece?: string
  typePiece?: string
  referenceConstructeur?: string
  prixConstructeur?: string
  prixVenteMin?: string
  prixVenteMax?: string
  stock?: number
}

export interface ModeleVehiculeAndPieces {
  vehicule?: ModeleVehicule | undefined
  donneesImmatriculation?: ModeleImmatriculation | undefined
  donneesPieces?: Array<ModelePieceItem> | undefined
}

export type VehiculeAndPiecesQueryResponse = Response<ModeleVehiculeAndPieces>

export interface ModelePays {
  id?: number
  code2?: string
  code3?: string
  nom?: string
}

export interface ModeleNaf {
  id?: number
  code?: string
  libelle?: string
}

export interface ModeleCentre {
  id?: number
  code?: string
  nom?: string
  adresse?: string
  complementAdresse?: string
  codePostal?: string
  ville?: string
  telephone?: string
  email?: string
  siret?: string
  pays?: ModelePays
  idPays?: number
}

export const modeleCentreInitValues: ModeleCentre = {
  code: '',
  nom: '',
  adresse: '',
  complementAdresse: '',
  codePostal: '',
  ville: '',
  telephone: '',
  email: '',
  siret: '',
  idPays: 75,
}

export interface ModeleClientDocument {
  id?: number
  client?: ModeleClient
  nom?: string
}

export interface ModeleCategorieTypePiece {
  id?: number
  code?: string
  nom?: string
}

export interface ModeleSousCategorieTypePiece {
  id?: number
  code?: string
  nom?: string
  categorie?: ModeleCategorieTypePiece
}

export interface ModeleTypePiece {
  id?: number
  code?: string
  nom?: string
  sousCategorie?: ModeleSousCategorieTypePiece
}

export interface ModeleUtilisateurRole {
  id?: number
  code?: string
  libelle?: string
}

export interface ModeleUtilisateur {
  id?: number
  nom?: string
  prenom?: string
  email?: string
  pseudo?: string
  roles?: Array<string>
  centres?: Array<string>
  u_centre?: Array<string>
  idRole?: number
  role?: ModeleUtilisateurRole
  password?: string
  passwordConfirmation?: string
  listeFonctionnalites?: Array<string>
  listeModules?: Array<string>
  photoProfil?: string
  listeCentres?: ModeleCentre[]
}

export const modeleUtilisateurInitValues: ModeleUtilisateur = {
  nom: '',
  prenom: '',
  email: '',
  pseudo: '',
  roles: [],
  password: '',
  passwordConfirmation: '',
  listeFonctionnalites: [],
  listeModules: [],
  idRole: 1,
  centres: [],
  u_centre: [],
  listeCentres: [],
}

export interface ModeleEncours {
  id?: number
  date?: string
  numero?: string
  totalTtc?: number
  resteAPayer?: number
}

export type UtilisateursQueryResponse = Response<Array<ModeleUtilisateur>>
export type UtilisateurQueryResponse = Response<ModeleUtilisateur>
export type CentreQueryResponse = Response<ModeleCentre>
export type CentresQueryResponse = Response<Array<ModeleCentre>>
export type GenresQueryResponse = Response<Array<ModeleGenre>>
export type GenreQueryResponse = Response<ModeleGenre>
export type CataloguesQueryResponse = Response<Array<ModeleCatalogue>>
export type CatalogueQueryResponse = Response<ModeleCatalogue>
export type ClientEncoursQueryResponse = Response<Array<ModeleEncours>>
export type CourrielQueryResponse = Response<ModeleCourriel>

export interface ModeleVehiculeGroupe {
  id?: number
  code?: string
}

export interface ModeleVehiculeType {
  id?: number
  code?: string
  idCentreDefaut?: number
  centreDefaut?: ModeleCentre
}

export interface ModeleVehiculeMarque {
  id?: number
  nom?: string
  popularite?: number
}

export interface ModeleVehiculeModele {
  id?: number
  idMarque?: number
  Marque?: ModeleVehiculeMarque
  nom?: string
  phase?: string
  cylindre?: string
  puissance?: string
  codeMoteur?: string
  debutPhase?: string
  finPhase?: string
  idType?: number
  Type?: ModeleVehiculeType
}

export interface ModeleVehicule {
  id?: number
  idModele?: number
  modele?: ModeleVehiculeModele
  idGroupe?: number
  groupe?: ModeleVehiculeGroupe
  cnit?: string
}

export interface ModelePieceEntre {
  id?: number
  id_piece?: number
  id_vehicule_entre?: number
  id_etat?: number
  code?: string
  prix?: number
  prix_transport?: number
  taux_tva?: number
  id_reserve_demande?: number
  date_reserve_demande?: string
  id_site?: number
  poids?: number
  commentaire?: string
  non_mecanisable?: boolean
  stockage_allee?: string
  stockage_bac?: string
  stockage_date?: string
}

export interface ModeleImmatriculation {
  id?: number
  sCodeErreur?: string
  sMessageErreur?: string
  sNomPartenaire?: string
  sNomPartenaireBase?: string
  sNomLogiciel?: string
  sLicence?: string
  sImmatriculationSiv?: string
  sVin?: string
  sCnit?: string
  stypeVin?: string
  smarque?: string
  smarqueCarrosserie?: string
  smodele?: string
  smodeleEtude?: string
  smodelePrf?: string
  sversion?: string
  senergie?: string
  sgenre?: string
  sgenreCg?: string
  scarrosserie?: string
  scarrosserieCg?: string
  snbVolumes?: string
  snbPortes?: string
  sdatePme?: string
  spuissanceKw?: string
  stypeBoiteVitesse?: string
  stypeVarianteVersion?: string
  spuissanceFiscale?: string
  sco2?: string
  sconsommationMixte?: string
  sconsommationUrbaine?: string
  sconsommationExtraUrbaine?: string
  sdepollution?: string
  scouleur?: string
  sdateCg?: string
  spoidsVide?: string
  spoidsTotalRoulant?: string
  spoidsTotalRoulantPrf?: string
  sempattement?: string
  shauteur?: string
  slargeur?: string
  slongueur?: string
  snbPlacesAssises?: string
  snbCylindres?: string
  snbSoupapes?: string
  smodeInjection?: string
  snbVitesses?: string
  sturbo?: string
  stransmission?: string
  scylindree?: string
  spuissanceCv?: string
  snoSerie?: string
  snoSiren?: string
  scodeMoteur?: string
  sprix?: string
  stecCatid?: string
  stecMarque?: string
  stecCodeMoteur?: string
  stecCodeMoteurTecdocEquivalent?: string
  stecEnergie?: string
  stecCylindree?: string
  stecPuissanceKw?: string
  stecAnneeDeb?: string
  stecAnneeFin?: string
  stecPhase?: string
  stecModele?: string
  stecCarrosserie1?: string
  stecCarrosserie2?: string
  stecNbPortes?: string
  stecTransmission?: string
  stecBoiteVitesse1?: string
  stecBoiteVitesse2?: string
  stecVersion1?: string
  stecVersion2?: string
  stecVersion3?: string
}

export type ImmatriculationQueryResponse = Response<ModeleImmatriculation>

/*
CLIENTS
 */

export interface ModeleClient {
  id?: number
  professionnel?: boolean
  nomSociete?: string
  nom?: string
  prenom?: string
  adresse?: string
  complementAdresse?: string
  codePostal?: string
  ville?: string
  idPays?: number
  pays?: ModelePays
  siren?: string
  telephone?: string
  mobile?: string
  email?: string
  siteWeb?: string
  codeNaf?: string
  libelleCodeNaf?: string
  shopCustomerId?: number
  naf?: ModeleNaf
}

export const modeleClientInitValues: ModeleClient = {
  professionnel: true,
  idPays: 75,
  codeNaf: '',
  libelleCodeNaf: '',
}

export type ClientsQueryResponse = Response<Array<ModeleClient>>
export type ClientQueryResponse = Response<ModeleClient>

export interface ModeleClientOtherData {
  ca?: number
  demandesOk?: number
  demandesPasOk?: number
}

export const modeleClientOtherDataInitValues: ModeleClientOtherData = {
  ca: 0,
  demandesOk: 0,
  demandesPasOk: 0,
}

export type ClientOtherDataQueryResponse = Response<ModeleClientOtherData>

export interface ModeleClientAdresse {
  id?: number
  libelle?: string
  adresse?: string
  complementAdresse?: string
  codePostal?: string
  ville?: string
  idPays?: number
  pays?: ModelePays
  telephone?: string
  siret?: string
  idClient?: number
  client?: ModeleClient
  adressePrimaireLivraison?: string
  cpPrimaireLivraison?: string
  villePrimaireLivraison?: string
  adresseSecondaireLivraison?: string
  cpSecondaireLivraison?: string
  villeSecondaireLivraison?: string
  idPaysPrimaireLivraison?: number
  paysPrimaireLivraison?: ModelePays
  idpaysSecondaireLivraison?: number
  paysSecondaireLivraison?: ModelePays
  adresseFacturation?: string
  cpFacturation?: string
  villeFacturation?: string
  idPaysFacturation?: number
  paysFacturation?: ModelePays
}

export interface ModeleClientRemise {
  id?: number
  critere?: string
  critereArt?: number
  critereFam?: number
  critereTyp?: number
  codeCritere?: number
  typeCritere?: string
  taux?: number
  tauxArticle?: number
  tauxFamille?: number
  tauxType?: number
  dateDebutArt?: string
  dateDebutFam?: string
  dateDebutTyp?: string
  dateFinArt?: string
  dateFinFam?: string
  dateFinTyp?: string
  dateDebut?: string
  dateFin?: string
  idClient?: number
  remiseFinAnnee?: number
  client?: ModeleClient
}

export const modeleClientRemiseInitValues: ModeleClientRemise = {
  critere: '',
  critereArt: 0,
  critereFam: 0,
  critereTyp: 0,
  codeCritere: 0,
  typeCritere: '',
  taux: 0,
  tauxArticle: 0,
  tauxFamille: 0,
  tauxType: 0,
  dateDebutArt: '',
  dateDebutFam: '',
  dateDebutTyp: '',
  dateFinArt: '',
  dateFinFam: '',
  dateFinTyp: '',
  dateDebut: '',
  dateFin: '',
  idClient: 0,
  remiseFinAnnee: 0,
}

export const modeleClientAdresseInitValues: ModeleClientAdresse = {
  idPays: 75,
  idPaysPrimaireLivraison: 75,
  idpaysSecondaireLivraison: 75,
  idPaysFacturation: 75,
}

export type ClientAdressesQueryResponse = Response<Array<ModeleClientAdresse>>
export type ClientAdresseQueryResponse = Response<ModeleClientAdresse>

export interface ModeleClientContact {
  id?: number
  nom?: string
  prenom?: string
  telephone?: string
  mobile?: string
  email?: string
  posteOccupe?: string
  principal?: boolean
  client?: ModeleClient
}

export interface ModeleClientDocument {
  id?: number
  nom?: string
  dateCreation?: string
}

export const modeleClientContactInitValues: ModeleClientContact = {}

export type ClientContactsQueryResponse = Response<Array<ModeleClientContact>>
export type ClientContactQueryResponse = Response<ModeleClientContact>

/*
DEMANDES
 */

export interface ModeleClientDemandeEtape {
  id?: number
  code?: string
  libelle?: string
}

export interface ModeleClientDemande {
  id?: number
  dateCreation?: string
  sms?: boolean
  appel?: boolean
  immatriculation?: string
  commentaire?: string
  idCentre?: number
  centre?: ModeleCentre
  idClient?: number
  client?: ModeleClient
  utilisateurSaisie?: ModeleUtilisateur
  idUtilisateurAssigne?: number
  utilisateurAssigne?: ModeleUtilisateur
  clientDemandeEtape?: ModeleClientDemandeEtape
  pieces?: Array<ModeleClientDemandePiece>
  donneesImmatriculation?: ModeleImmatriculation
  cnit?: string
  typePiece: ModeleTypePiece
  refConst?: string | null
  marque?: string | null
  modele?: string | null
}

export const modeleClientDemandeInitValues: ModeleClientDemande = {
  pieces: [],
  immatriculation: '',
  typePiece: {},
}

export type ClientDemandesQueryResponse = Response<Array<ModeleClientDemande>>
export type ClientDemandeQueryResponse = Response<ModeleClientDemande>

export interface ModeleClientDemandePiece {
  id?: number
  referenceManuelle?: string
  commentaire?: string
  neuf?: boolean
  occasion?: boolean
  dispoNeuf?: boolean
  dispoOccasion?: boolean
  delaiNeuf?: number
  delaiOccasion?: number
  prixUnitaire?: number
  idDemande?: number
  demande?: ModeleClientDemande
  idTypePiece?: number
  nomTypePiece?: string
  typePiece?: ModeleTypePiece
  numeroPieceEntre?: string
  idPieceEntre?: number
  pieceEntre?: ModelePieceEntre
  immat?: string
  quantite?: number | string
  refConst?: string | null
  marque?: string | null
  modele?: string | null
}

export const modeleDemandePieceInitValues: ModeleClientDemandePiece = {
  referenceManuelle: '',
  commentaire: '',
  neuf: false,
  occasion: false,
  dispoNeuf: false,
  dispoOccasion: false,
  delaiNeuf: 0,
  delaiOccasion: 0,
  prixUnitaire: 0,
  quantite: 1,
}

export interface ModelePieceItemStock {
  id?: number
  prixDefaut?: string
  prixConstructeurDate?: string
  typePiece?: ModeleTypePiece
  referenceConstructeur?: string
  prixConstructeur?: string
  prixVenteMin?: string
  prixVenteMax?: string
  stock?: number
  numero_vehicule_entre?: number
}

export interface ModeleGenre {
  id?: number
  code?: string
  libelle?: string
  idCentre?: number
  centre?: ModeleCentre
  idGroupe?: number
}

export const modeleGenreInitValues: ModeleGenre = {
  code: '',
  libelle: '',
  idCentre: 0,
  idGroupe: 0,
}

export interface ModeleEditCentre {
  value: number | undefined
  label: string | undefined
}

export interface ModeleRole {
  id?: number
  code?: string
  libelle?: string
}

export interface ModeleRoleAndModules {
  code: string
  libelle: string
  modules?: any
  status?: boolean
}

export interface ModeleResultSearchCatalogue {
  cataloguePieces: []
  cataloguesDoc: []
}

export interface fonctionnaliteModele {
  id: number
  code: string
  libelle: string
}

export interface ModeleModule {
  id: number
  code: string
  libelle: string
  fonctionnalites: Array<fonctionnaliteModele>
}

export interface ModeleCatalogue {
  id?: number
  nom: string
  token: string
  identiteNom: string
  identitePrenom: string
  raisonSociale: string
  civilite: string
  siret: string
  telephone1: string
  telephone2: string
  mail: string
  tvaIntraCommunautaire: string
  adresseRaisonSociale: string
  adresseNom: string
  adressePrenom: string
  adresseNumVoie: string
  adresseNomVoie: string
  adresseTypeVoie: string
  adresseCodePostal: string
  adresseVille: string
  adressePays: string
  adresseTelephone: string
  codeClientProgiciel: string
  tauxRemiseCatalogue: number
  regles: Array<ModeleRegle[]>
  formatCatalogue: string
}

export interface ModeleRegle {
  c: string
  o: string
  v: string
}

export const modeleRoleInitValues: ModeleRole = {
  code: '',
  libelle: '',
}

export type RolesQueryResponse = Response<Array<ModeleRole>>
export type RoleQueryResponse = Response<ModeleRoleAndModules>
export type ModulesQueryResponse = Response<Array<ModeleModule>>
export type RechercheCatalogueQueryResponse = Response<ModeleResultSearchCatalogue>
export type ModeleHistoriqueFacturationResponse = Response<Array<ModeleHistoriqueFacturation>>
export type ClientDemandePiecesQueryResponse = Response<Array<ModeleClientDemandePiece>>
export type ClientDemandePieceQueryResponse = Response<ModeleClientDemandePiece>
export type RemiseQueryResponse = Response<ModeleClientRemise>
export type CourrielsQueryResponse = Response<Array<ModeleCourriel>>

export type AnyQueryResponse = Response<any>

export interface ModeleClientAcompte {
  id?: number
  date?: string
  numeroDocument?: string
  acompteVerse?: string
  acompteUtilise?: string
  acompteRestant?: string
  client?: ModeleClient
}

export interface ModeleHistoriqueFacturation {
  id?: number | null | undefined
  date?: string
  numero?: string
  montant?: string
}

export const modeleClientAcompteInitValues: ModeleClientAcompte = {}

export type ClientAcomptesQueryResponse = Response<Array<ModeleClientAcompte>>
export type ClientAcompteQueryResponse = Response<ModeleClientAcompte>

export type ClientDocumentsQueryResponse = Response<Array<ModeleClientDocument>>
export type ClientDocumentQueryResponse = Response<ModeleClientDocument>

export interface ModeleCourriel {
  id?: number
  hote?: string
  identifiant?: string
  password?: string
  source?: string
  typeSecurite?: string
  port?: number
  actif?: boolean
}