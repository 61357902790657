export const modeReglements = [
    {
        value: 1,
        label: "cheque",
    },
    {
        value: 2,
        label: "par espèce",
    },
    {
        value: 3,
        label: "Virement",
    },
];
export const caisses = [
    {
        value: "caisse 001",
        label: "caisse 001",
    },
    {
        value: "caisse 002",
        label: "caisse 002",
    },
    {
        value: "Caisse Centrale",
        label: "Caisse Centrale",
    },
];
export const groupeClients = [
    {
        value: "1",
        label: "VIP",
    },
    {
        value: "2",
        label: "Normal",
    }
];
export const commercials = [
    {
        value: "1",
        label: "comm001",
    },
    {
        value: "2",
        label: "comm002",
    }
];
export const transporteurs = [
    {
        value: "1",
        label: "transporteur001",
    },
    {
        value: "2",
        label: "transporteur002",
    }
];
export const zonesTVA = [
    {
        value: "France",
        label: "France",
    },
    {
        value: "CEE Hors TVA",
        label: "CEE Hors TVA",
    }
];
export const tableauDeBords = [
    {
        value: "Professionnel",
        label: "Professionnel",
    },
    {
        value: "Particulier",
        label: "Particulier",
    },
    {
        value: "Divers",
        label: "Divers",
    }
];
export const reseaux = [
    {
        value: 1,
        label: "MOTOEXPERT", // libelle
        code: "MOTOEXPERT",
    },
    {
        value: 2,
        label: "MOTO INTERMEDIAIRE", // libelle
        code: "MOTO INTERMEDIAIRE",
    },
    {
        value:3,
        label: "MOTOJUNIOR", // libelle
        code: "MOTOJUNIOR",
    },
];
export const codeClientWeb = [
    {
        value: "WEB123", //
        label: "Site Officiel", // libelle
        code: "WEB123", // libelle
    },
    {
        value: "WEB456",
        label: "Marketplace",
        code: "WEB456",
    },
    {
        value: "MOTOJUNIOR##MOTOJUNIOR",
        label: "MOTOJUNIOR",
        code: "MOTOJUNIOR",
    },
];
