import React, {useEffect, useState} from 'react'
import {ModeleCourriel} from '../../../components/Models'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import {LoadingData} from '../../../components/LoadingData'
import {CourrielModal} from './CourrielModal'
import {checkCourrielConnexion, deleteCourriel, getCourriels} from '../../../components/requests'
import Swal from 'sweetalert2'
import {shouldDisplayFonctionnalite} from '../../../components/helpers'
import {useAuth} from '../../modules/auth'
import {useFormik} from 'formik'
import {Pagination} from '../../../components/Pagination'

const CourrielListPage = () => {
  const [dataCourriels, setDataCourriels] = useState<Array<ModeleCourriel>>([])
  const [loading, setLoading] = useState(false)
  const [nombreDePages, setNombreDePages] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchPage, setSearchPage] = useState<string>('')
  const [isSearch, setIsSearch] = useState(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isEdition, setIsEdition] = useState<boolean>(false)
  const [courrielId, setCourrielId] = useState<Number | undefined>(undefined)
  const {currentUser} = useAuth()

  useEffect(() => {
    getCourrielData()
  }, [currentPage, searchPage])

  const getCourrielData = () => {
    setLoading(true)
    getCourriels(!isSearch ? currentPage : 1, searchPage)
      .then((courrielRequest) => {
        setNombreDePages(courrielRequest?.pages)
        setTotalPages(courrielRequest?.total)
        setCurrentPage(courrielRequest?.page)
        if (courrielRequest?.data !== undefined) {
          setDataCourriels(courrielRequest?.data)
          setLoading(false)
          setIsSearch(false)
        }
      })
      .catch((e) => {
        setLoading(false)
      })
  }

  const showRegisterModal = () => {
    setOpenModal(true)
    setIsEdition(false)
  }

  const showEditModal = (courrielId: number | undefined) => {
    setOpenModal(true)
    setIsEdition(true)
    setCourrielId(courrielId)
  }

  const toggleShowModal = (val: boolean) => {
    setOpenModal(val)
  }

  const addDataCourriel = (newData: ModeleCourriel) => {
    let newDataCourriel = [...dataCourriels, newData]
    setDataCourriels(newDataCourriel)
  }

  const updateDataCourriel = (courriel: ModeleCourriel | null) => {
    if (courriel != null) {
      const courriels: ModeleCourriel[] = [...dataCourriels]
      const updatedCourriels = courriels.map((c) => {
        return c.id === courriel.id ? courriel : c
      })
      setDataCourriels(updatedCourriels)
    }
  }

  const delCourriel = (courrielId) => {
    Swal.fire({
      title: 'Attention',
      text: 'Merci de confirmer la suppression',
      icon: 'warning',
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
      showCancelButton: true,
      confirmButtonColor: '#1BC5BD',
      cancelButtonColor: '#F64E60',
    }).then((alertResult) => {
      if (alertResult.isConfirmed) {
        setLoading(true)
        deleteCourriel(courrielId)
          .then((response) => {
            if (response?.status === 200) {
              setLoading(false)
              getCourrielData()
            }
          })
          .catch((error) => {
            setLoading(false)
          })
      }
    })
  }

  const testCourrielConnexion = (courrielId) => {
    Swal.fire({
      title: 'Chargement...',
      text: 'Vérification de la connexion...',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })

    checkCourrielConnexion(courrielId)
      .then((response) => {
        if (response?.status === 200) {
          Swal.fire({
            title: 'Succès',
            text: 'Connexion réussie',
            icon: 'success',
            confirmButtonColor: '#1BC5BD',
          })
        } else {
          Swal.fire({
            title: 'Échec',
            text: 'Échec de la connexion',
            icon: 'error',
            confirmButtonColor: '#F64E60',
          })
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Erreur',
          text: "Une erreur s'est produite lors de la connexion",
          icon: 'error',
          confirmButtonColor: '#F64E60',
        })
      })
  }

  const formik = useFormik({
    initialValues: {allSearch: ''},
    onSubmit: (values, {setSubmitting}) => {
      setSearchPage(values.allSearch)
    },
  })

  return (
    <>
      {/* begin::Row  */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col  */}
        <div className='col-xxl-12'>
          <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Configurations</span>
              </h3>
              {shouldDisplayFonctionnalite(currentUser, 'AJOUTER MAIL CLIENT') && (
                <div
                  className='card-toolbar'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Cliquez pour ajouter une configuration de courriel'
                >
                  <Link to='' onClick={showRegisterModal} className='btn btn-sm btn-light-primary'>
                    <KTIcon iconName='plus' className='fs-3' />
                    Ajouter
                  </Link>
                </div>
              )}
            </div>
            {/* end::Header */}

            {/* debut champs recherche */}
            <form
              className='card-header border-0 pt-5 pb-5 d-flex justify-content-end'
              onSubmit={formik.handleSubmit}
            >
              <div className='form-group d-flex col-lg-4'>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control h-100'
                    {...formik.getFieldProps('allSearch')}
                    name='allSearch'
                    placeholder='Rechercher une source'
                  />
                  <button
                    type='submit'
                    className='btn btn-light border-0 input-group-append h-100'
                    onClick={() => setIsSearch(true)}
                  >
                    <i className='fas fa-search text-secondary fs-4'></i>
                  </button>
                </div>
              </div>
            </form>
            {/* fin champs recherche */}

            {loading ? (
              <LoadingData />
            ) : (
              <>
                {shouldDisplayFonctionnalite(currentUser, 'VOIR MAIL CLIENT') && (
                  <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th style={{width: '25%'}}>Source</th>
                            <th style={{width: '25%'}}>Hôte</th>
                            <th style={{width: '25%'}}>Identifiant</th>
                            <th style={{width: '15%'}}>Type de sécurité</th>
                            <th style={{width: '5%'}}>Port</th>
                            <th style={{width: '5%'}}>Actif</th>
                            <th className='text-end' style={{width: '10%'}}>
                              Actions
                            </th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {dataCourriels.length ? (
                            dataCourriels.map((courriel) => {
                              return (
                                <tr key={courriel.id}>
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex justify-content-start flex-column fw-bold'>
                                        {courriel.source}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                      {courriel.hote}
                                    </p>
                                  </td>
                                  <td>
                                    <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                      {courriel.identifiant}
                                    </p>
                                  </td>
                                  <td>
                                    <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                      {courriel.typeSecurite}
                                    </p>
                                  </td>
                                  <td>
                                    <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                      {courriel.port}
                                    </p>
                                  </td>
                                  <td>
                                    <p className='text-dark fw-bold d-block fs-6 mb-0'>
                                      <i
                                        className={`bi ${
                                          courriel.actif ? 'bi-check-circle' : 'bi-x-circle'
                                        } fs-5`}
                                        style={{color: courriel.actif ? 'green' : 'red'}}
                                      ></i>
                                    </p>
                                  </td>

                                  <td>
                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                      {shouldDisplayFonctionnalite(
                                        currentUser,
                                        'MODIFIER MAIL CLIENT'
                                      ) && (
                                        <Link
                                          to='#'
                                          onClick={() => showEditModal(courriel.id)}
                                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        >
                                          <i className='bi bi-pencil fs-5'></i>
                                        </Link>
                                      )}
                                      {shouldDisplayFonctionnalite(
                                        currentUser,
                                        'SUPPRIMER MAIL CLIENT'
                                      ) && (
                                        <p
                                          onClick={() => {
                                            delCourriel(courriel.id)
                                          }}
                                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        >
                                          <i className='bi bi-trash fs-5'></i>
                                        </p>
                                      )}
                                      {shouldDisplayFonctionnalite(
                                        currentUser,
                                        'CONNEXION MAIL CLIENT'
                                      ) && (
                                        <p
                                          onClick={() => {
                                            testCourrielConnexion(courriel.id)
                                          }}
                                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                          title='Tester la connexion'
                                        >
                                          <i className='bi bi-envelope-check fs-5'></i>
                                        </p>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan={6} className='text-center'>
                                Aucun résultat trouvé
                              </td>
                            </tr>
                          )}
                        </tbody>
                        {/* end::Table body */}
                      </table>
                      {/* end::Table */}
                    </div>
                    {/* end::Table container */}

                    {/* begin::pagination*/}
                    <Pagination
                      totalPages={totalPages}
                      nombreDePages={nombreDePages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      locationState={0}
                    />
                    {/* end::pagination*/}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* end::Col  */}
      </div>
      {/* end::Row  */}
      {!isEdition && openModal && (
        <CourrielModal
          show={openModal}
          isEdition={isEdition}
          courrielId={undefined}
          showModal={toggleShowModal}
          addDataCourriel={addDataCourriel}
          updateDataCourriel=''
          loading={setLoading}
        />
      )}
      {isEdition && openModal && (
        <CourrielModal
          show={openModal}
          courrielId={courrielId}
          isEdition={isEdition}
          showModal={toggleShowModal}
          updateDataCourriel={updateDataCourriel}
          addDataCourriel=''
          loading={setLoading}
        />
      )}
    </>
  )
}

export {CourrielListPage}
