import {useFormik} from 'formik'
import {createCourriel} from '../../../../components/requests'
import {ModeleCourriel} from '../../../../components/Models'
import React, {useState} from 'react'
import {TextInput} from '../../../../components/inputs/TextInput'
import {SelectInput} from '../../../../components/inputs/SelectInput'
import {typeSecuriteCourriel} from '../../../../components/Data'
import {Button} from 'react-bootstrap'
import {Eye, EyeSlash} from 'react-bootstrap-icons'
import {handleValidation} from './Validation'

const CourrielRegisterForm = (props) => {
  const initialValues: ModeleCourriel = {
    hote: '',
    identifiant: '',
    password: '',
    source: '',
    typeSecurite: 'SSL',
    port: 0,
    actif: false,
  }

  const [showPassword, setShowPassword] = useState(false)
  const [data, setData] = useState<ModeleCourriel>(initialValues)
  const {showModalProp, addDataCourriel, setLoadingProp} = props

  const onSubmitHandler = (values) => {
    showModalProp(false)
    setLoadingProp(true)
    createCourriel(values)
      .then((courrielRequest) => {
        if (courrielRequest?.status === 200) {
          if (courrielRequest?.data !== undefined) {
            addDataCourriel(courrielRequest.data)
            setLoadingProp(false)
          } else {
            setLoadingProp(false)
          }
        } else {
          setLoadingProp(false)
        }
      })
      .catch((e) => {
        setLoadingProp(false)
      })
  }

  const formik = useFormik<ModeleCourriel>({
    initialValues: data,
    validationSchema: handleValidation,
    onSubmit: onSubmitHandler,
    validateOnBlur: true,
    validateOnChange: false,
  })

  return (
    <div>
      <form action='#' onSubmit={formik.handleSubmit}>
        <div className='App'>
          <div className='row'>
            <div className='col-sm-12'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Hôte</label>
              <TextInput
                fieldName={'hote'}
                inputType={'text'}
                inputClassName={'form-control form-control-lg mb-3 mb-lg-0'}
                formik={formik}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Identifiant</label>
              <TextInput
                fieldName={'identifiant'}
                inputType={'text'}
                inputClassName={'form-control form-control-lg mb-3 mb-lg-0'}
                formik={formik}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Mot de passe</label>
              <div style={{position: 'relative'}}>
                <TextInput
                  fieldName={'password'}
                  inputType={showPassword ? 'text' : 'password'}
                  inputClassName={'form-control form-control-lg mb-3 mb-lg-0'}
                  formik={formik}
                />
                <button
                  type='button'
                  className='btn btn-outline-secondary'
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '23px',
                    transform: 'translateY(-50%)',
                    border: 'none',
                    background: 'transparent',
                    cursor: 'pointer',
                  }}
                >
                  {showPassword ? <EyeSlash size={20} /> : <Eye size={20} />}
                </button>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Source</label>
              <TextInput
                fieldName={'source'}
                inputType={'text'}
                inputClassName={'form-control form-control-lg mb-3 mb-lg-0'}
                formik={formik}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                Type de sécurité
              </label>
              <SelectInput
                fieldName={'typeSecurite'}
                inputClassName={'form-select form-select-lg fw-bold'}
                options={typeSecuriteCourriel}
                formik={formik}
              />
            </div>
            <div className='col-sm-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Port</label>
              <TextInput
                fieldName={'port'}
                inputType={'number'}
                inputClassName={'form-control form-control-lg mb-3 mb-lg-0'}
                formik={formik}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6 d-flex'>
              <label className='col-form-label fw-bold fs-6'>Actif</label>
              <input
                style={{marginLeft : '10px'}}
                className='form-check-input my-3'
                type='checkbox'
                value={1}
                name={'actif'}
                checked={!!formik.values.actif}
                onChange={(e) => {
                  formik.setFieldValue('actif', e.target.checked)
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12' style={{textAlign: 'right', marginTop: '10px'}}>
              <Button variant='secondary me-5' onClick={() => showModalProp(false)}>
                Annuler
              </Button>
              <Button variant='info' type='submit'>
                Enregistrer
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export {CourrielRegisterForm}
