import React, {useState, useEffect, useRef} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {LoadingData} from '../../../../../../components/LoadingData';
import {toAbsoluteUrl , KTSVG} from '../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import {
  deleteClientDocument,
  getClientDocument,
  getCompanyBySiret, getUtilisateur
} from '../../../../../../components/requests';
import axios from "axios";
import {ModeleClientDocument} from "../../../../../../components/Models";
import DocumentModal from "../../../../../../components/DocumentModal";
import {useAuth} from "../../../../../modules/auth";
import {shouldDisplayFonctionnalite} from "../../../../../../components/helpers";


const API_URL = process.env.REACT_APP_API_URL
const REGISTER_DOCUMENT_URL = `${API_URL}/client-document/register-document`


const ClientDocument = () => {
  const navigate = useNavigate();
  const { clientId } = useParams<{ clientId: string }>();
  const [data, setData] = useState<ModeleClientDocument[]>([]);
  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileDocuments, setFileDocuments] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const {currentUser} = useAuth()

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const deleteDocument = (documentId) => {
    Swal.fire({
      title: 'Attention',
      text: 'Merci de confirmer la suppression',
      icon: 'warning',
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
      showCancelButton: true,
      confirmButtonColor: "#1BC5BD",
      cancelButtonColor: "#F64E60",
    }).then(alertResult => {
      if (alertResult.isConfirmed) {

        deleteClientDocument(documentId).then(response => {
          if (response?.status === 200) {
            clientDocument()
          }
        })

      }
    })
  }

  const addClientDocument = async () => {
    const formData = new FormData();
    if(fileDocuments !== null){
      setLoading(true)
      formData.append("clientDocument", fileDocuments,fileDocuments.name);
      if(clientId){
        formData.append("clientId", clientId);
      }
      axios
          .post(REGISTER_DOCUMENT_URL, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            setLoading(false)
            setFileName(null);
            clientDocument()
            setFileDocuments(null);
          })
          .catch((error) => {
            const errorMessage = error.response.data.message || "Erreur inconnue du serveur.";
            Swal.fire({
              title: 'Error',
              text: errorMessage,
              icon: 'error',
              confirmButtonText: 'OK',
              confirmButtonColor: "#1BC5BD",
            });
            setLoading(false)
            setFileName(null);
            setFileDocuments(null);
          });
    }
  }

  const showModal = (fileUrl:string) => {
    setSelectedFile(fileUrl);
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileDocument = event.target.files ? event.target.files[0] : null;
    if (fileDocument) {
      setFileName(fileDocument.name);
      setFileDocuments(fileDocument);
    } else {
      setFileName(null);
      setFileDocuments(null);
    }
  };

  useEffect(() => {
    clientDocument()
  }, [clientId]);


  const clientDocument = () => {
    getClientDocument(clientId).then(clientDocumentRequest => {
      if (clientDocumentRequest !== undefined && clientDocumentRequest !== null) {
        if (!Array.isArray(clientDocumentRequest)) {
          setData(prevData => [...prevData, clientDocumentRequest]);  // Ajouter l'objet à un tableau existant
        } else {
          setData(clientDocumentRequest);  // Si c'est déjà un tableau, on remplace `data`
        }
      }

      setLoading(false);

    }).catch((e) => {
      setLoading(false);
    });
  }

  return (
    <>
      {/*      {loading
        ?
          <LoadingData />
        :*/}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body'>
          <div className='row align-items-center'>
            <div
              className='col-lg-4 col-sm-12 d-flex flex-column align-items-center justify-content-center mt-5 mb-2'
              style={{height: '100%'}}
            >
              {shouldDisplayFonctionnalite(currentUser, 'AJOUTER DOCUMENT') && (
                <>
                  <img alt='Pic' src={toAbsoluteUrl('/media/svg/files/up-icon.svg')} />
                  <span style={{display: 'block', height: '5em'}}></span>
                  <div className='input-group'>
                    <input
                      ref={fileInputRef}
                      type='file'
                      name='client-document'
                      accept='.png, .jpg, .jpeg, .pdf '
                      style={{display: 'none'}}
                      onChange={handleFileChange}
                    />
                    <a
                      className='btn btn-secondary'
                      onClick={handleButtonClick}
                      style={{borderRadius: '4px 0 0 4px'}}
                    >
                      Choisir un fichier
                    </a>
                    <input
                      type='text'
                      className='form-control'
                      value={fileName ? fileName : 'Insérer votre fichier ici'}
                      readOnly
                    />
                  </div>
                  <span style={{display: 'block', height: '2em'}}></span>
                  <div className='d-flex flex-column align-items-center'>
                    <button className='btn btn-primary' onClick={() => addClientDocument()}>
                      {/*Ajouter*/}
                      {!loading && 'Ajouter'}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Ajout...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className='col-lg-8 col-sm-12 d-flex justify-content-lg-start justify-content-sm-center'>
              <div
                style={{
                  border: '2px solid #666',
                  height: '400px',
                  maxHeight: '410px',
                  minWidth: '100%',
                  borderRadius: '10px',
                  backgroundColor: 'aliceblue',
                  padding: '1em',
                }}
              >
                <table
                  className='table table-bordered table-hover'
                  style={{maxWidth: '800px', tableLayout: 'fixed', margin: 'auto'}}
                >
                  <thead
                    style={{
                      display: 'table',
                      width: '100%',
                      textAlign: 'center',
                      background: 'white',
                    }}
                  >
                    <tr>
                      <th className='border-0'>Fichiers</th>
                      <th className='border-0'>Action</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{height: '320px', overflowY: 'auto', display: 'block', width: '100%'}}
                  >
                    {data.length ? (
                      data.map((clientDocument) => {
                        return (
                          <tr
                            style={{display: 'table', width: '100%', tableLayout: 'fixed'}}
                            key={clientDocument.id}
                          >
                            <td className='text-center'>
                              <a
                                href='#'
                                onClick={() => {
                                  const fileUrl = `${API_URL}/uploads/clientDocument/${clientDocument.client?.id}/${clientDocument.nom}`
                                  showModal(fileUrl)
                                }}
                              >
                                {clientDocument.nom}
                              </a>
                            </td>
                            {shouldDisplayFonctionnalite(currentUser, 'SUPPRIMER DOCUMENT') ? (
                              <td>
                                <div className='d-flex justify-content-center flex-shrink-0'>
                                  <p
                                    onClick={() => {
                                      deleteDocument(clientDocument.id)
                                    }}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm m-0'
                                  >
                                    <i className='bi bi-trash fs-5'></i>
                                  </p>
                                </div>
                              </td>
                            ) : (
                              <td>
                                <div className='d-flex justify-content-center flex-shrink-0'>
                                </div>
                              </td>
                            )}
                          </tr>
                        )
                      })
                    ) : (
                      <tr className='justify-content-center d-flex'>
                        <td colSpan={2} className='text-center'>
                          Aucun document trouvé
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*      }*/}

      {/* Afficher le modal si isModalOpen est true */}
      {isModalOpen && selectedFile && <DocumentModal fileUrl={selectedFile} onClose={closeModal} />}
    </>
  )
}

export {ClientDocument}