import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {ModeleClientDemande} from '../../../../../../components/Models'
import {getClientDemandes} from '../../../../../../components/requests'
import {useAuth} from '../../../../../modules/auth'
import {DemandListPage} from '../../../../demande/pages/DemandListPage'

const ClientDemandes = () => {
  const navigate = useNavigate()
  let {clientId} = useParams()
  const [data, setData] = useState<Array<ModeleClientDemande>>([])
  const [loading, setLoading] = useState(true)
  const {currentUser} = useAuth()

  useEffect(() => {
    getClientData()
  }, [clientId])

  const getClientData = () => {
    getClientDemandes(clientId).then((clientDemandesRequest) => {
      if (clientDemandesRequest?.status === 200) {
        if (clientDemandesRequest?.data !== undefined) {
          setData(clientDemandesRequest?.data.reverse())
          setLoading(false)
        }
      } else {
        navigate(`/vente/clients/liste`)
      }
    })
  }

  return <DemandListPage SelectedClient={clientId} />
}

export {ClientDemandes}
