import React, {useEffect, useState} from 'react'
import {Link, useParams} from "react-router-dom";
import {ClientForm} from "../shop/clients/components/ClientForm";
import {
    ModeleClient,
    modeleClientInitValues,
    ModeleImmatriculation,
    ModelePieceItem, ModeleRecherchePiece, modeleRecherchePieceValues,
    ModeleRechercheVehicule, modeleRechercheVehiculeValues, ModeleVehicule
} from "../../../components/Models";
import * as Yup from "yup";
import {createClient, getClients, getSearchPiece, getSearchVehiculeAndPieces} from "../../../components/requests";
import {useFormik} from "formik";
import {TextInput} from "../../../components/inputs/TextInput";
import {reference} from "@popperjs/core";
import {formatDate, shouldDisplayFonctionnalite} from "../../../components/helpers";
import {useAuth} from "../../modules/auth";

const recherchePieceSchema = Yup.object().shape({
    reference: Yup.string().notRequired(),
})

const RecherchePiecePage = () => {
    let { reference } = useParams();
    const [data, setData] = useState<ModeleRecherchePiece>(modeleRecherchePieceValues);
    const [resultData, setResultData] = useState<any | undefined>([]);
    const [loading, setLoading] = useState(false)
    const [noExistingPiece, setNoExistingPiece] = useState(false)
    const {currentUser} = useAuth()

    useEffect(() => {
        if (data.reference) {
            setLoading(true)
            setNoExistingPiece(false);
            submitForm();
        }
    }, [data]);

    useEffect(() => {
        if (reference) {
            formik.setFieldValue("reference", reference);
            const updatedData = Object.assign({...data}, {reference})

            setData(updatedData);
        }
    }, [reference]);

    const formik = useFormik<ModeleRecherchePiece>({
        initialValues: data,
        validationSchema: recherchePieceSchema,
        onSubmit: (values) => {
            //setLoading(true)
            setTimeout(() => {
                const updatedData = Object.assign({...data}, values)

                setData(updatedData);
            }, 200)
        },
    })

    const submitForm = async (): Promise<void> => {
        setLoading(true);
        try {
            getSearchPiece(data.reference).then(requestResponse => {
                if (requestResponse?.status === 200) {
                    if (requestResponse?.data !== undefined) {
                        if (requestResponse?.data.success === false) {
                            setNoExistingPiece(true);
                        }
                        setResultData(requestResponse?.data);

                        console.log("requestResponse", requestResponse.data)

                        setLoading(false);
                    }
                }
            });
        } catch (ex) {
            console.error(ex)
            setLoading(false);
        }
    }

    return (
        <>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Recherche</h3>
                    </div>
                </div>

                <div>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body border-top p-9'>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Référence constructeur</label>

                                <div className='col-lg-8 fv-row'>
                                    <TextInput
                                        fieldName={'reference'}
                                        inputPlaceholder={'Référence'}
                                        inputType={'text'}
                                        inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                        </div>
                        {shouldDisplayFonctionnalite(currentUser, 'RECHERCHER PIECES') &&
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                {!loading && 'Rechercher'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                  Recherche...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                                )}
                            </button>
                        </div>
                        }
                    </form>
                </div>
            </div>

            {noExistingPiece &&
                <div>
                    <div className='row g-5 g-xl-8'>
                        {/* begin::Col  */}
                        <div className='col-xxl-12'>
                            <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>

                                <div className='card-body py-3'>
                                    <p className={"text-danger mt-4"}>Aucune pièce trouvée avec cette référence</p>
                                </div>
                            </div>
                        </div>
                        {/* end::Col  */}
                    </div>
                </div>
            }

            {resultData?.success &&
                <div>
                    <div className='row g-5 g-xl-8'>
                        {/* begin::Col  */}
                        <div className='col-xxl-12'>
                            <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
                                {/* begin::Header */}
                                <div className='card-header border-0 pt-5'>
                                    <h3 className='card-title align-items-start flex-column'>
                                        <span className='card-label fw-bold fs-3 mb-1'>Informations</span>
                                    </h3>
                                </div>
                                {/* end::Header */}
                                <div className='card-body py-3'>
                                    {resultData?.piece &&
                                        <div>
                                            <p className="text-primary h6 mb-4">Pièce recherchée
                                                : {resultData?.piece?.typePiece?.nom}</p>
                                            <p>Véhicules compatibles : {resultData?.vehicules?.join(' | ')}</p>
                                            {resultData?.piece?.prixConstructeur > 0
                                                ?
                                                <div>
                                                    <p>Prix constructeur
                                                        : {resultData?.piece?.prixConstructeur.toFixed(2)}&nbsp;€</p>
                                                    {resultData?.piece?.prixConstructeurDate &&
                                                        <p>Mise à jour du prix constructeur
                                                            : {formatDate(resultData?.piece?.prixConstructeurDate)}</p>
                                                    }
                                                    {resultData?.avgPrice &&
                                                        <div>
                                                            <p>Prix moyen en vente par GSR
                                                                : {resultData?.avgPrice.toFixed(2)}&nbsp;€</p>
                                                            <p>Différence avec le prix constructeur :
                                                                -{((1 - ((resultData?.avgPrice) / resultData?.piece?.prixConstructeur)) * 100).toFixed(2)}&nbsp;%</p>
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <p>Prix constructeur inconnu</p>
                                            }
                                        </div>
                                    }
                                    {resultData?.stock &&
                                        <p className="text-success h6 mb-4">Pièces en stock
                                            : {resultData?.stock.length}</p>
                                    }
                                    {resultData?.nbResults &&
                                        <p className="text-success h6 mb-4">Pièces sur le marché
                                            : {resultData?.nbResults}</p>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* end::Col  */}
                    </div>

                    <div className='row g-5 g-xl-8'>
                        {/* begin::Col  */}
                        <div className='col-xxl-12'>
                            <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
                                {/* begin::Header */}
                                <div className='card-header border-0 pt-5'>
                                    <h3 className='card-title align-items-start flex-column'>
                                        <span className='card-label fw-bold fs-3 mb-1'>Pièces en stock</span>
                                    </h3>
                                </div>
                                {/* end::Header */}
                                <div className='card-body py-3'>
                                    {/* begin::Table container */}
                                    <div className='table-responsive'>
                                        {/* begin::Table */}
                                        <table
                                            className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                            {/* begin::Table head */}
                                            <thead>
                                            <tr className='fw-bold text-muted'>
                                                <th className='min-w-140px'>Code</th>
                                                <th className='min-w-140px'>Commentaire</th>
                                                <th className='min-w-140px'>Etat</th>
                                                <th className='min-w-140px'>Prix € HT</th>
                                                <th className='min-w-140px'>Livraison € HT</th>
                                                <th className='min-w-140px'>Total € HT</th>
                                            </tr>
                                            </thead>
                                            {/* end::Table head */}
                                            {/* begin::Table body */}
                                            <tbody>
                                            {resultData?.stock?.map((piece, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <p
                                                                className={`text-dark d-block fs-6 mb-0`}>
                                                                {piece.code}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p
                                                                className={`text-dark d-block fs-6 mb-0`}>
                                                                {piece.commentaire}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p
                                                                className={`text-dark d-block fs-6 mb-0`}>
                                                                {piece.etat?.libelle.toUpperCase()}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p
                                                                className={`text-dark d-block fs-6 mb-0`}>
                                                                {(piece.prix).toFixed(2)}&nbsp;€
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p
                                                                className={`text-dark d-block fs-6 mb-0`}>
                                                                {(piece.prixTransport).toFixed(2)}&nbsp;€
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p
                                                                className={`text-dark d-block fs-6 mb-0`}>
                                                                {(piece.prix + piece.prixTransport).toFixed(2)}&nbsp;€
                                                            </p>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                            {/* end::Table body */}
                                        </table>
                                        {/* end::Table */}
                                    </div>
                                    {/* end::Table container */}
                                </div>
                            </div>
                        </div>
                        {/* end::Col  */}
                    </div>

                    <div className='row g-5 g-xl-8'>
                        {/* begin::Col  */}
                        <div className='col-xxl-12'>
                            <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
                                {/* begin::Header */}
                                <div className='card-header border-0 pt-5'>
                                    <h3 className='card-title align-items-start flex-column'>
                                        <span className='card-label fw-bold fs-3 mb-1'>Positionnement sur le marché</span>
                                    </h3>
                                </div>
                                {/* end::Header */}
                                <div className='card-body py-3'>
                                    {/* begin::Table container */}
                                    <div className='table-responsive'>
                                        {/* begin::Table */}
                                        <table
                                            className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                            {/* begin::Table head */}
                                            <thead>
                                            <tr className='fw-bold text-muted'>
                                                <th className=''></th>
                                                <th className='min-w-140px'>N°</th>
                                                <th className='min-w-140px'>Vendeur</th>
                                                <th className='min-w-140px'>Prix € HT</th>
                                                <th className='min-w-140px'>Livraison € HT</th>
                                                <th className='min-w-140px'>Total € HT</th>
                                            </tr>
                                            </thead>
                                            {/* end::Table head */}
                                            {/* begin::Table body */}
                                            <tbody>
                                            {resultData?.data?.map((piece, i) => {
                                                return (
                                                    <tr key={i}
                                                        className={`table-${piece.color} ${piece.highlight ? "font-weight-bold" : ""}`}>
                                                        <td>
                                                            <p
                                                                className={`text-dark ${piece.highlight ? "fw-bold" : ""} d-block fs-6 mb-0`}>
                                                                {piece.highlight &&
                                                                    String.fromCharCode(10132)
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p
                                                                className={`text-dark ${piece.highlight ? "fw-bold" : ""} d-block fs-6 mb-0`}>
                                                                {i + 1}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p
                                                                className={`text-dark ${piece.highlight ? "fw-bold" : ""} d-block fs-6 mb-0`}>
                                                                {piece.seller}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p
                                                                className={`text-dark ${piece.highlight ? "fw-bold" : ""} d-block fs-6 mb-0`}>
                                                                {(piece.price / 1.2).toFixed(2)}&nbsp;€
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p
                                                                className={`text-dark ${piece.highlight ? "fw-bold" : ""} d-block fs-6 mb-0`}>
                                                                {(piece.shipping / 1.2).toFixed(2)}&nbsp;€
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p
                                                                className={`text-dark ${piece.highlight ? "fw-bold" : ""} d-block fs-6 mb-0`}>
                                                                {(piece.total / 1.2).toFixed(2)}&nbsp;€
                                                            </p>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                            {/* end::Table body */}
                                        </table>
                                        {/* end::Table */}
                                    </div>
                                    {/* end::Table container */}
                                </div>
                            </div>
                        </div>
                        {/* end::Col  */}
                    </div>
                </div>
            }

        </>
    )
}

export {RecherchePiecePage}
