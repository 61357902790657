import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {ModeleRechercheCatalogue, modeleRechercheCatalugeValues} from '../../../components/Models'
import * as Yup from 'yup'
import {getCatalogues, searchCatalog} from '../../../components/requests'
import {useFormik} from 'formik'
import {TextInput} from '../../../components/inputs/TextInput'
import {SelectInput} from '../../../components/inputs/SelectInput'
import {toast, ToastContainer} from 'react-toastify'
import {DetailsModal} from '../catalogues/components/DetailsModal'

const rechercheCatalogueSchema = Yup.object().shape({
  catalogue: Yup.string().required('Ce champs est réquis.'),
  referenceConstructeur: Yup.string().notRequired(),
  referenceVehicule: Yup.string().notRequired(),
  referencePiece: Yup.string().notRequired(),
})

const RechercheCataloguePage = () => {
  let {catalogue, referenceConstructeur, referenceVehicule, referencePiece} = useParams()
  const [data, setData] = useState<ModeleRechercheCatalogue>(modeleRechercheCatalugeValues)
  const [resultData, setResultData] = useState<any | undefined>([])
  const [optionsCatalogue, setOptionsCatalogue] = useState<any | undefined>([])
  const [loading, setLoading] = useState(false)
  const [noExistingCatalog, setNoExistingCatalog] = useState(false)
  const [dataFiltered, setDataFiltered] = useState<any>([])

  useEffect(() => {
    getCatalogues()
      .then((rolesRequest) => {
        if (rolesRequest?.status === 200) {
          if (rolesRequest?.data !== undefined) {
            const optionsCatal = rolesRequest?.data?.map((item) => ({
              value: item.id,
              label: item.nom,
            }))

            setOptionsCatalogue(optionsCatal)
          }
        } else {
          setLoading(false)
        }
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (data.referenceConstructeur || data.referenceVehicule || data.referencePiece) {
      setLoading(true)
      setNoExistingCatalog(false)
      submitForm(data)
    }
  }, [data])

  /* useEffect(() => {
    if (catalogue ) {
      formik.setFieldValue("catalogue",catalogue);
      if(referenceConstructeur){
        formik.setFieldValue("referenceConstructeur", referenceConstructeur);
      }

      if(referenceVehicule){
        formik.setFieldValue("referenceVehicule", referenceVehicule);
      }
      if(referencePiece){
        formik.setFieldValue("referencePiece", referencePiece);
      }

      const updatedData = Object.assign({...data}, {catalogue,referenceConstructeur,referenceVehicule,referencePiece})

      setData(updatedData);
    }
  }, [catalogue,referenceConstructeur,referenceVehicule,referencePiece]); */

  const formik = useFormik<ModeleRechercheCatalogue>({
    initialValues: data,
    validationSchema: rechercheCatalogueSchema,
    onSubmit: (values: ModeleRechercheCatalogue): void => {
      if (
        values.referenceConstructeur === '' &&
        values.referenceVehicule === '' &&
        values.referencePiece === ''
      ) {
        toast.error("Veuillez compléter la recherche avec l'un des champs de référence!", {
          autoClose: 7000,
        })
        //alert('Veuillez compléter la recherche avec l\'un des champs de référence!')
        return
      }
      setTimeout(() => {
        const updatedData: ModeleRechercheCatalogue = Object.assign({...data}, values)
        setData(updatedData)
        submitForm(updatedData)
      }, 200)
    },
    validateOnChange: false,
    validateOnBlur: true, //
  })

  const submitForm = async (d: ModeleRechercheCatalogue = {}) => {
    setLoading(true)
    try {
      searchCatalog(d).then((requestResponse) => {
        if (requestResponse?.status === 200) {
          if (requestResponse?.data !== undefined) {
            if (requestResponse?.data?.cataloguePieces?.length === 0) {
              setNoExistingCatalog(true)
            }

            setResultData(requestResponse?.data)
            setLoading(false)
          }
        }
      })
    } catch (ex) {
      console.error(ex)
      setLoading(false)
    }
  }

  const showDetail = (cat: any) => {
    setDataFiltered(cat)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Recherche</h3>
          </div>
        </div>

        <div>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <div className='col-lg-6 fv-row'>
                  <label className=' col-form-label fw-bold fs-6'>Catalogues</label>
                  <SelectInput
                    fieldName={'catalogue'}
                    inputPlaceholder={'Modèles de catalogue'}
                    inputClassName={'form-control form-control-lg mb-3 mb-lg-0'}
                    options={optionsCatalogue}
                    formik={formik}
                  />
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label fw-bold fs-6'>Par référence constructeur</label>
                  <TextInput
                    fieldName={'referenceConstructeur'}
                    inputPlaceholder={'Référence constructeur'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>

                <div className='col-lg-6 fv-row'>
                  <label className=' col-form-label fw-bold fs-6'>Par référence véhicule</label>
                  <TextInput
                    fieldName={'referenceVehicule'}
                    inputPlaceholder={'Référence véhicule'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label fw-bold fs-6'>Par référence de pièce</label>
                  <TextInput
                    fieldName={'referencePiece'}
                    inputPlaceholder={'Référence de pièce'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Rechercher'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Recherche...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      {noExistingCatalog && (
        <div>
          <div className='row g-5 g-xl-8'>
            {/* begin::Col  */}
            <div className='col-xxl-12'>
              <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
                <div className='card-body py-3'>
                  <p className={'text-danger mt-4'}>Aucune pièce trouvée...</p>
                </div>
              </div>
            </div>
            {/* end::Col  */}
          </div>
        </div>
      )}

      {resultData?.length !== 0 && resultData?.cataloguePieces?.length !== 0 && (
        <div>
          <div className='row g-5 g-xl-8'>
            {/* begin::Col  */}
            <div className='col-xxl-12'>
              <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Pièces</span>
                  </h3>
                </div>
                {/* end::Header */}
                <div className='card-body py-3'>
                  {/* begin::Table container */}
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-140px'>Code Produit</th>
                          <th className='min-w-140px'>Code NE</th>
                          <th className='min-w-140px'>Réf Constructeur</th>
                          <th className='min-w-140px'>Type de pièce</th>
                          <th className='min-w-140px'>Catégorie</th>
                          <th className='min-w-140px'>Sous-catégorie</th>
                          <th className='min-w-140px'>Véhicule Origine</th>
                          <th className='min-w-140px'>Modèle</th>
                          <th className='min-w-140px'>VIN</th>
                          <th className='min-w-140px'>Année</th>
                          <th className='min-w-140px'>Km</th>
                          <th className='min-w-140px'>Tarif (€ HT)</th>
                          <th className='min-w-140px'>Site</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {resultData?.cataloguePieces?.map((catalogue, i) => {
                          return (
                            <tr
                              key={i}
                              data-bs-target='#modal_details_cat'
                              data-bs-toggle='modal'
                              data-bs-dismiss='modal'
                              onClick={() => showDetail(catalogue)}
                            >
                              <td>
                                <p
                                  className={`text-dark d-block text-hover-primary cursor-pointer fs-6 mb-0`}
                                >
                                  {catalogue?.code}
                                </p>
                              </td>
                              <td>
                                <p
                                  className={`text-dark d-block text-hover-primary cursor-pointer fs-6 mb-0`}
                                >
                                  {catalogue?.vehiculeEntre?.numero}
                                </p>
                              </td>
                              <td>
                                <p
                                  className={`text-dark d-block text-hover-primary cursor-pointer fs-6 mb-0`}
                                >
                                  {catalogue?.piece?.referenceConstructeur}
                                </p>
                              </td>
                              <td>
                                <p
                                  className={`text-dark d-block text-hover-primary cursor-pointer fs-6 mb-0`}
                                >
                                  {catalogue?.piece?.typePiece?.nom}
                                </p>
                              </td>
                              <td>
                                <p
                                  className={`text-dark d-block text-hover-primary cursor-pointer fs-6 mb-0`}
                                >
                                  {catalogue?.piece?.typePiece?.sousCategorie?.categorie?.nom}
                                </p>
                              </td>

                              <td>
                                <p
                                  className={`text-dark d-block text-hover-primary cursor-pointer fs-6 mb-0`}
                                >
                                  {catalogue?.piece?.typePiece?.sousCategorie?.nom +
                                    ` (` +
                                    catalogue?.piece?.typePiece?.sousCategorie?.code +
                                    `)`}
                                </p>
                              </td>

                              <td>
                                <p
                                  className={`text-dark d-block text-hover-primary cursor-pointer fs-6 mb-0`}
                                >
                                  {catalogue?.vehiculeEntre?.vehicule?.modele?.marque?.nom}
                                </p>
                              </td>

                              <td>
                                <p
                                  className={`text-dark d-block text-hover-primary cursor-pointer fs-6 mb-0`}
                                >
                                  {catalogue?.vehiculeEntre?.vehicule?.modele?.nom}
                                </p>
                              </td>

                              <td>
                                <p
                                  className={`text-dark d-block text-hover-primary cursor-pointer fs-6 mb-0`}
                                >
                                  {catalogue?.vehiculeEntre?.vin.toUpperCase()}
                                </p>
                              </td>
                              <td>
                                <p
                                  className={`text-dark d-block text-hover-primary cursor-pointer fs-6 mb-0`}
                                >
                                  {catalogue?.vehiculeEntre?.annee}
                                </p>
                              </td>
                              <td>
                                <p
                                  className={`text-dark d-block text-hover-primary cursor-pointer fs-6 mb-0`}
                                >
                                  {catalogue?.vehiculeEntre?.km}
                                </p>
                              </td>

                              <td>
                                <p
                                  className={`text-dark d-block text-hover-primary cursor-pointer fs-6 mb-0`}
                                >
                                  {catalogue?.prix}
                                </p>
                              </td>
                              <td>
                                <p
                                  className={`text-dark d-block text-hover-primary cursor-pointer fs-6 mb-0`}
                                >
                                  {catalogue?.site?.code}
                                </p>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                  </div>
                  {/* end::Table container */}
                </div>
              </div>
            </div>
            {/* end::Col  */}
          </div>
        </div>
      )}
      <ToastContainer position='bottom-right' autoClose={3000} />
      <DetailsModal data={dataFiltered} />
    </>
  )
}

export {RechercheCataloguePage}
