import React, {useEffect, useRef, useState} from 'react'
import Select  from 'react-select'
import * as Yup from "yup";
import {useFormik} from "formik";
import {TextInput} from "../../../../components/inputs/TextInput";
import {ModeleCentre, ModeleEditCentre, ModeleUtilisateur} from "../../../../components/Models";
import {SelectInput} from "../../../../components/inputs/SelectInput";
import {useNavigate} from "react-router-dom";
import {pays, roles} from "../../../../components/Data";
import axios from "axios";
import Swal from 'sweetalert2';
import {getElementError} from '@testing-library/react'
import {getCentres, validatePseudoEmail, validatePseudoEmailEdit} from "../../../../components/requests";

const API_URL = process.env.REACT_APP_API_URL
const REGISTER_PDP_URL = `${API_URL}/utilisateur/register-pdp`
const pdp_url = `${API_URL}/uploads/pdp/`

const utilisateurSchema = Yup.object().shape({
    nom: Yup.string().required("Le nom est requis"),
    prenom: Yup.string().required("Le prénom est requis"),
    email: Yup.string()
        .required("L'adresse mail est requise")
        .email("L'adresse mail n'est pas valide")
        .test('email-check', 'L\'email existe déjà', async function(value) {
            if (!value) return true;
            try {
                const response = await validatePseudoEmail(value , null);
                if(response.data && response.data.exists){
                    return this.createError({ message: 'L\'adresse mail existe déjà' });
                }
            } catch (error) {
                console.error(error);
            }
            return true;
        }),
    pseudo: Yup.string()
        .required("Le nom d'utilisateur est requis")
        .test('pseudo-check', 'Le nom d\'utilisateur existe déjà', async function(value) {
            if (!value) return true;
            try {
                const response = await validatePseudoEmail(null , value);
                if(response.data && response.data.exists){
                    return this.createError({ message: 'Le nom d\'utilisateur existe déjà' });
                }
            } catch(error) {
                console.error(error);
            }
            return true;
        }),
    password: Yup.string().required("Le mot de passe est requis"),
    passwordConfirmation: Yup.string()
        .required('La confirmation de mot de passe est requise')
        .oneOf([Yup.ref('password')], 'Les deux mots de passe doivent correspondre'),
})

const UtilisateurForm = (props) => {
    const navigate = useNavigate();
    const [utilisateurId, setUtilisateurId] = useState(props.utilisateurId);
    const [data, setData] = useState<ModeleUtilisateur>(props.data)
    const [centreSelected, setCentreSelected] = useState<ModeleCentre[]>(props.centreSelected ?? [])
    const [loading, setLoading] = useState(false)
    const [photo, setPhoto] = useState<string | undefined>(props.data?.photoProfil ? `${pdp_url}${props.data.photoProfil}` : undefined);
    const [centres, setCentres] = useState<Array<ModeleEditCentre>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [searchPage, setSearchPage] = useState<string>("")
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);

    const handleButtonClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

    const getCentresData = () => {
      getCentres(currentPage , searchPage).then(centresRequest => {
        if (centresRequest?.data !== undefined) {
          const transformedData = centresRequest.data.map((item) => ({
            value: item.id,
            label: item.nom,
          }));
          setCentres([...transformedData]);
        }
      }).catch((e) => {
      });
    }

    const filePdpChange = (event) => {
      const filePdp = event.target.files ? event.target.files[0] : null;
      if (filePdp) {
        setFileName(filePdp.name);
        const formData = new FormData();
        formData.append("photoProfil", filePdp, filePdp.name);
        setPhoto(URL.createObjectURL(filePdp));
        axios
          .post(REGISTER_PDP_URL, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            formik.setFieldValue("photoProfil", response.data.fileName);
          })
          .catch((error) => {
            formik.setFieldValue("photoProfil", "");
            const errorMessage = error.response.data.message || "Erreur inconnue du serveur.";
            Swal.fire({
              title: 'Error',
              text: errorMessage,
              icon: 'error',
              confirmButtonText: 'OK',
              confirmButtonColor: "#1BC5BD",
            });
          });
      } else {
        setFileName(null);
      }
    };

    useEffect(() => {
      getCentresData();
      return () => {
        if (photo) {
          URL.revokeObjectURL(photo);
        }
      };
    }, [photo,utilisateurId]);

    const utilisateurEditSchema = Yup.object().shape({
        nom: Yup.string().required("Le nom est requis"),
        prenom: Yup.string().required("Le prénom est requis"),
        email: Yup.string()
            .required("L'adresse mail est requise")
            .email("L'adresse mail n'est pas valide")
            .test('email-check', 'L\'email existe déjà', async function(value) {
                if (!value) return true;
                try{
                    const response = await validatePseudoEmailEdit(value , '' , utilisateurId);
                    if(response.data && response.data.exists){
                        return this.createError({ message: 'L\'adresse mail existe déjà' });
                    }
                }catch(error){
                    console.error(error);
                }
                return true;
            }),
        pseudo: Yup.string()
            .required("Le nom d'utilisateur est requis")
            .test('pseudo-check', 'Le nom d\'utilisateur est requis', async function(value) {
                if (!value) return true;
                try{
                    const response = await validatePseudoEmailEdit('',value,utilisateurId);
                    if(response.data && response.data.exists){
                        return this.createError({ message: 'Le nom d\'utilisateur existe déjà' });
                    }
                }catch(error){
                    console.error(error);
                }
                return true;
            }),
        password: Yup.string().notRequired(),
        passwordConfirmation: Yup.string().when(['password'], {
            is: (password) => password && password.length > 0,
            then: () =>
                Yup.string()
                    .required('La confirmation de mot de passe est requise')
                    .oneOf([Yup.ref('password')], 'Les deux mots de passe doivent correspondre'),
            otherwise: () => Yup.string().notRequired(),
        }),
    })

    const formik = useFormik<ModeleUtilisateur>({
        initialValues: data,
        validationSchema: utilisateurId ? utilisateurEditSchema : utilisateurSchema,
        onSubmit: (values) => {
            setLoading(true)
            setTimeout(() => {
                const updatedData = Object.assign({...data}, values)
                props.submitForm(updatedData, response => {
                    formik.resetForm();
                    setLoading(false);
                });
            }, 200)
        },
        validateOnBlur: true,
        validateOnChange: false,
    })

    const handleChangeCentre = (selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        formik.setFieldValue('u_centre', selectedValues);
        setCentreSelected(selectedOptions);
    }

    // Fonction pour valider un champ spécifique sur la perte de focus
    const handleBlurWithValidation = (e) => {
        const { name } = e.target;
        formik.handleBlur(e);
        formik.validateField(name);
    };

    return (
      <div>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row'>
              <div className='col-lg-8 col-sm-12'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Nom complet
                  </label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <TextInput
                          fieldName={'nom'}
                          inputPlaceholder={'Nom'}
                          inputType={'text'}
                          inputClassName={
                            'form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          }
                          onBlur={handleBlurWithValidation}
                          formik={formik}
                        />
                      </div>

                      <div className='col-lg-6 fv-row'>
                        <TextInput
                          fieldName={'prenom'}
                          inputPlaceholder={'Prenom'}
                          inputType={'text'}
                          inputClassName={
                            'form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          }
                          onBlur={handleBlurWithValidation}
                          formik={formik}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Identification
                  </label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <TextInput
                          fieldName={'email'}
                          inputPlaceholder={'Email'}
                          inputType={'text'}
                          inputClassName={
                            'form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          }
                          onChange={formik.handleChange}
                          onBlur={handleBlurWithValidation}
                          formik={formik}
                        />
                      </div>

                      <div className='col-lg-6 fv-row'>
                        <TextInput
                          fieldName={'pseudo'}
                          inputPlaceholder={"Nom d'utilisateur"}
                          inputType={'text'}
                          inputClassName={
                            'form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          }
                          onChange={formik.handleChange}
                          onBlur={handleBlurWithValidation}
                          formik={formik}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label
                    className={`col-lg-4 col-form-label ${
                      utilisateurId === null ? 'required' : ''
                    } fw-bold fs-6`}
                  >
                    Mot de passe
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <TextInput
                      fieldName={'password'}
                      inputPlaceholder={'Mot de passe'}
                      inputType={'password'}
                      inputClassName={
                        'form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      }
                      onBlur={handleBlurWithValidation}
                      formik={formik}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label
                    className={`col-lg-4 col-form-label ${
                      utilisateurId === null ? 'required' : ''
                    } fw-bold fs-6`}
                  >
                    Confirmation mot de passe
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <TextInput
                      fieldName={'passwordConfirmation'}
                      inputPlaceholder={'Confirmation mot de passe'}
                      inputType={'password'}
                      inputClassName={
                        'form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      }
                      onBlur={handleBlurWithValidation}
                      formik={formik}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className={`col-lg-4 col-form-label required fw-bold fs-6`}>
                    Rôle utilisateur
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <SelectInput
                      fieldName={'idRole'}
                      inputClassName={'form-select form-select-solid form-select-lg fw-bold'}
                      options={roles}
                      formik={formik}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className={`col-lg-4 col-form-label fw-bold fs-6`}>Centre</label>
                  <div className='col-lg-8 fv-row'>
                    <Select
                      className='react-select-styled react-select-solid react-select-lg'
                      classNamePrefix='react-select'
                      options={centres}
                      fieldName='u_centre'
                      placeholder='Sélectionner centres'
                      isMulti
                      value={centreSelected}
                      onChange={(e) => handleChangeCentre(e)}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-sm-12'>
                <div className='input-group'>
                  <input
                    ref={fileInputRef}
                    type='file'
                    name='photo-profil'
                    accept='.png, .jpg, .jpeg'
                    style={{display: 'none'}}
                    onChange={filePdpChange}
                  />
                  <a
                    className='btn btn-secondary'
                    onClick={handleButtonClick}
                    style={{borderRadius: '4px 0 0 4px'}}
                  >
                    Choisir un fichier
                  </a>
                  <input
                    type='text'
                    className='form-control'
                    value={fileName ? fileName : 'Insérer votre fichier ici'}
                    readOnly
                  />
                </div>
                {photo && (
                  <div className='pdp-image d-flex justify-content-lg-center mt-10'>
                    <img
                      src={photo || undefined}
                      alt='Photo de profil'
                      style={{
                        width: '150px',
                        height: '150px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                        marginTop: '10px',
                        boxShadow: '0 2px 6px #666',
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            {utilisateurId !== null && (
              <button
                type='button'
                className='btn btn-light me-3'
                disabled={loading}
                onClick={() => {
                  formik.resetForm()
                  navigate(`/utilisateurs`)
                }}
              >
                Annuler les modifications
              </button>
            )}
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Enregistrer'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Enregistrement...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    )
}

export {UtilisateurForm}
