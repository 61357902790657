import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from "react-router-dom";
import Swal from 'sweetalert2'
import {ModeleClientAdresse} from "../../../../../../components/Models";
import {LoadingData} from "../../../../../../components/LoadingData";
import {deleteClientAdresse, getClientAdresses} from "../../../../../../components/requests";
import {useAuth} from "../../../../../modules/auth";
import {shouldDisplayFonctionnalite} from "../../../../../../components/helpers";

const ClientAdresses = () => {
    const navigate = useNavigate();
    let { clientId } = useParams();
    const [data, setData] = useState<Array<ModeleClientAdresse>>([]);
    const [loading, setLoading] = useState(true)
    const {currentUser} = useAuth()

    useEffect(() => {
        getClientData()
    }, [clientId]);

    const deleteAdresse = (adresseId) => {
        Swal.fire({
            title: 'Attention',
            text: 'Merci de confirmer la suppression',
            icon: 'warning',
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            confirmButtonColor: "#1BC5BD",
            cancelButtonColor: "#F64E60",
        }).then(alertResult => {
            if (alertResult.isConfirmed) {
                deleteClientAdresse(clientId, adresseId).then(response => {
                    if (response?.status === 200) {
                        getClientData()
                    }
                })
            }
        })
    }

    const getClientData = () => {
        getClientAdresses(clientId).then(clientAdressesRequest => {
            if (clientAdressesRequest?.status === 200) {
                if (clientAdressesRequest?.data !== undefined) {
                    setData(clientAdressesRequest?.data)
                    setLoading(false);
                }
            } else {
                navigate(`/vente/clients/liste`);
            }
        });
    }

    return (
        <>
            {loading
                ?
                <LoadingData/>
                :
                <>
                    <div className='card mb-5 mb-xl-10'>
                        <div
                            className='card-header border-0'
                        >
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Adresses client</h3>
                            </div>
                            {shouldDisplayFonctionnalite(currentUser, 'AJOUTER ADRESSE') &&
                            <Link to={`/vente/clients/${clientId}/afficher/adresses/ajouter`} className='btn btn-primary align-self-center'>
                                Ajouter
                            </Link>
                            }
                        </div>

                        <div>
                            <div className='card-body p-9'>


                                {/* begin::Table container */}
                                <div className='table-responsive'>
                                    {/* begin::Table */}
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                        {/* begin::Table head */}
                                        <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='min-w-150px'>Libelle</th>
                                            <th className='min-w-140px'>Adresse</th>
                                            <th className='min-w-100px text-end'>Actions</th>
                                        </tr>
                                        </thead>
                                        {/* end::Table head */}
                                        {/* begin::Table body */}
                                        <tbody>
                                        {data.map((clientAdresse) => {
                                            return (
                                                <tr key={clientAdresse.id}>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='d-flex justify-content-start flex-column'>
                                                                <p
                                                                    className='text-dark fw-bold text-hover-primary fs-6 mb-0'>
                                                                    {clientAdresse.libelle !== null
                                                                        ?
                                                                        clientAdresse.libelle
                                                                        :
                                                                        "Adresse sans nom"
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                            {clientAdresse.adresse}<br/>
                                                            {clientAdresse.complementAdresse}<br/>
                                                            {clientAdresse.codePostal} {clientAdresse.ville}<br/>
                                                            {clientAdresse.pays?.nom}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                                            {shouldDisplayFonctionnalite(currentUser, 'SUPPRIMER ADRESSE') &&
                                                            <p
                                                                onClick={() => {deleteAdresse(clientAdresse.id)}}
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            >
                                                                <i className='bi bi-trash fs-5'></i>
                                                            </p>
                                                            }
                                                            {shouldDisplayFonctionnalite(currentUser, 'MODIFIER ADRESSE') &&
                                                            <Link
                                                                to={`/vente/clients/${clientId}/afficher/adresses/${clientAdresse.id}/modifier`}
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            >
                                                                <i className='bi bi-pencil fs-5'></i>
                                                            </Link>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                        {/* end::Table body */}
                                    </table>
                                    {/* end::Table */}
                                </div>
                                {/* end::Table container */}
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export {ClientAdresses}
