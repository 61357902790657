import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'

const Pagination = ({totalPages, nombreDePages, currentPage, setCurrentPage, locationState}) => {
  const nbPagesArr: number[] = []
  let nbPages = 1
  const location = useLocation()

  // Remplir le tableau des pages
  while (nbPages <= nombreDePages) {
    nbPagesArr.push(nbPages)
    nbPages++
  }

  // Fonction pour générer la pagination avec des '...'
  const generatePagination = () => {
    const pagesToShow: (number | string)[] = []
    const maxPagesToShow = 5 // Nombre de pages visibles avant les '...'
    const start = Math.max(currentPage - 2, 1) // 2 pages avant
    const end = Math.min(currentPage + 2, nombreDePages) // 2 pages après

    // Ajouter les pages précédentes, le premier '...' si nécessaire
    if (start > 1) {
      pagesToShow.push(1) // Ajouter la première page
      if (start > 2) pagesToShow.push('...') // Ajouter '...' si il y a des pages manquantes
    }

    for (let i = start; i <= end; i++) {
      pagesToShow.push(i) // Ajouter les pages visibles autour de la page courante
    }

    // Ajouter '...' avant la dernière page si nécessaire
    if (end < nombreDePages) {
      if (end < nombreDePages - 1) pagesToShow.push('...') // Ajouter '...' si des pages sont manquantes
      pagesToShow.push(nombreDePages) // Ajouter la dernière page
    }

    return pagesToShow
  }

  const pagesToDisplay = generatePagination()

  return totalPages ? (
    <div>
      <ul className='pagination'>
        <li
          className={`page-item previous ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1)
            }
          }}
        >
          <Link to={location.pathname} state={locationState} className='page-link'>
            <i className='previous'></i>
          </Link>
        </li>

        {pagesToDisplay.map((page, i) => (
          <li
            key={i}
            className={`page-item ${page === currentPage ? 'active' : ''} ${
              page === '...' ? 'disabled' : ''
            }`}
            onClick={() => {
              if (page !== '...') {
                setCurrentPage(page)
              }
            }}
          >
            <Link to={location.pathname} state={locationState} className='page-link'>
              {page}
            </Link>
          </li>
        ))}

        <li
          className={`page-item next ${currentPage === nombreDePages ? 'disabled' : ''}`}
          onClick={() => {
            if (currentPage < nombreDePages) {
              setCurrentPage(currentPage + 1)
            }
          }}
        >
          <Link to={location.pathname} state={locationState} className='page-link'>
            <i className='next'></i>
          </Link>
        </li>
      </ul>
    </div>
  ) : null
}

export { Pagination };
