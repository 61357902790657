import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {ModeleClient, modeleClientDemandeInitValues} from '../../../../../components/Models'
import {getClients} from '../../../../../components/requests'
import {LoadingData} from '../../../../../components/LoadingData'
import {useFormik} from 'formik'
import {Pagination} from '../../../../../components/Pagination'
import {useAuth} from '../../../../modules/auth'
import {shouldDisplayFonctionnalite} from '../../../../../components/helpers'
import ResearchVehicleModal from '../../../demande/Components/ResearchVehicleModal'
import {Tooltip} from 'react-tooltip'

const ClientsListPage = () => {
  const [data, setData] = useState<Array<ModeleClient>>([])
  const [loading, setLoading] = useState(false)
  const [nombreDePages, setNombreDePages] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchPage, setSearchPage] = useState<string>('')
  const [isSearch, setIsSearch] = useState(false)
  const [selectedId, setSelectedId] = useState<number>()
  const {currentUser} = useAuth()
  const [redirectToClientDemande] = useState<boolean>(true)

  useEffect(() => {
    getClientsData()
  }, [currentPage, searchPage])

  const getClientsData = () => {
    setLoading(true)
    getClients(!isSearch ? currentPage : 1, searchPage).then((clientsRequest) => {
      setNombreDePages(clientsRequest?.pages)
      setTotalPages(clientsRequest?.total)
      setCurrentPage(clientsRequest?.page)
      if (clientsRequest?.data !== undefined) {
        setData(clientsRequest?.data)
        setLoading(false)
        setIsSearch(false)
      }
    })
  }

  const formik = useFormik({
    initialValues: {allSearch: ''},
    onSubmit: (values, {setSubmitting}) => {
      setSearchPage(values.allSearch)
    },
  })

  return (
    <>
      {/* begin::Row  */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col  */}
        <div className='col-xxl-12'>
          <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Clients</span>
              </h3>
              {shouldDisplayFonctionnalite(currentUser, 'AJOUTER CLIENT') && (
                <div
                  className='card-toolbar'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Click to add a user'
                >
                  <Link to={`/vente/clients/ajouter`} className='btn btn-sm btn-light-primary'>
                    <KTIcon iconName='plus' className='fs-3' />
                    Créer un client
                  </Link>
                </div>
              )}
            </div>
            {/* debut champs recherche */}
            <form className='card-header border-0 pt-5 pb-5' onSubmit={formik.handleSubmit}>
              <div></div>
              <div className='form-group d-flex col-lg-4'>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control h-100'
                    {...formik.getFieldProps('allSearch')}
                    name='allSearch'
                    placeholder='Rechercher un client'
                  />
                  <button
                    type='submit'
                    className='btn btn-light border-0 input-group-append h-100'
                    onClick={() => setIsSearch(true)}
                  >
                    <i className='fas fa-search text-secondary fs-1'></i>
                  </button>
                </div>
              </div>
            </form>
            {/* fin champs recherche */}

            {/* end::Header */}
            {loading ? (
              <LoadingData />
            ) : (
              <>
                {shouldDisplayFonctionnalite(currentUser, 'VOIR CLIENT') && (
                  <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-150px'>Client</th>
                            <th className='min-w-140px'>Société</th>
                            <th className='min-w-140px'>Localisation</th>
                            <th className='min-w-140px'>Type</th>
                            <th className='min-w-100px text-end'>Actions</th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {data.length ? (
                            data.map((client) => {
                              return (
                                <>
                                  <ResearchVehicleModal
                                    clientId={selectedId}
                                    data={modeleClientDemandeInitValues}
                                    redirectToClientDemande={redirectToClientDemande}
                                  />

                                  <tr key={client.id}>
                                    <td>
                                      <div className='d-flex align-items-center'>
                                        <div className='d-flex justify-content-start flex-column'>
                                          {shouldDisplayFonctionnalite(
                                            currentUser,
                                            'VOIR FICHE'
                                          ) ? (
                                            <Link
                                              to={`/vente/clients/${client.id}/afficher`}
                                              className='text-dark fw-bold text-hover-primary fs-6 mb-0'
                                            >
                                              {client.nom} {client.prenom}
                                            </Link>
                                          ) : (
                                            <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                              {client.nom} {client.prenom}
                                            </p>
                                          )}
                                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            Groupe (GPART01, GPART02)
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                        {client.nomSociete}
                                      </p>
                                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        Type société (Garage, carosserie...)
                                      </span>
                                    </td>
                                    <td>
                                      <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                        {client.ville}
                                      </p>
                                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        {client.codePostal} {client.pays?.nom}
                                      </span>
                                    </td>
                                    <td>
                                      {client.professionnel ? (
                                        <span
                                          className={`badge badge-light-warning fw-bolder me-auto px-4 py-3`}
                                        >
                                          Professionnel
                                        </span>
                                      ) : (
                                        <span
                                          className={`badge badge-light-danger fw-bolder me-auto px-4 py-3`}
                                        >
                                          Particulier
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <div className='d-flex justify-content-end flex-shrink-0'>
                                        {shouldDisplayFonctionnalite(
                                          currentUser,
                                          'AJOUTER CLIENT DEMANDE'
                                        ) && (
                                          <>
                                            <div
                                              data-bs-target='#exampleModalSearchV'
                                              data-bs-toggle='modal'
                                              data-bs-dismiss='modal'
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                              onClick={() => {
                                                setSelectedId(client.id)
                                              }}
                                              data-tooltip-id="tooltip"
                                              data-tooltip-content="Créer une demande"
                                              data-tooltip-place="left"
                                            >
                                              <i className='bi bi-bag-plus-fill fs-5'></i>
                                            </div>
                                          </>
                                        )}
                                        {shouldDisplayFonctionnalite(currentUser, 'VOIR FICHE') && (
                                          <Link
                                            to={`/vente/clients/${client.id}/afficher`}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content="Détail du client"
                                            data-tooltip-place="right"
                                          >
                                            <i className='bi bi-person-vcard fs-5'></i>
                                          </Link>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan={5} className='text-center'>
                                Aucun résultat trouvé
                              </td>
                            </tr>
                          )}
                        </tbody>
                        {/* end::Table body */}
                      </table>
                      {/* end::Table */}
                    </div>
                    {/* end::Table container */}

                    {/* begin::pagination*/}
                    <Pagination
                      totalPages={totalPages}
                      nombreDePages={nombreDePages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      locationState={0}
                    />
                    {/* end::pagination*/}
                    {/*Affichage du tooltip*/}
                    <Tooltip id="tooltip" />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* end::Col  */}
      </div>
      {/* end::Row  */}
    </>
  )
}

export {ClientsListPage}
