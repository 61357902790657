import axios from 'axios'
import {
  AnyQueryResponse,
  CatalogueQueryResponse,
  CataloguesQueryResponse,
  CentreQueryResponse,
  ClientAcomptesQueryResponse,
  ClientAdresseQueryResponse,
  ClientAdressesQueryResponse,
  ClientContactQueryResponse,
  ClientContactsQueryResponse,
  ClientDemandeQueryResponse,
  ClientDemandesQueryResponse,
  ClientDocumentQueryResponse,
  ClientDocumentsQueryResponse,
  ClientEncoursQueryResponse,
  ClientOtherDataQueryResponse,
  ClientQueryResponse,
  CourrielQueryResponse,
  CourrielsQueryResponse,
  GenreQueryResponse,
  ImmatriculationQueryResponse,
  ModeleCatalogue,
  ModeleCentre,
  ModeleClient,
  ModeleClientAdresse,
  ModeleClientContact,
  ModeleClientDemande,
  ModeleClientDemandePiece,
  ModeleClientDocument,
  ModeleClientRemise,
  ModeleCourriel,
  ModeleGenre,
  ModeleHistoriqueFacturationResponse,
  ModeleRechercheCatalogue,
  ModeleRole,
  ModeleRoleAndModules,
  ModeleUtilisateur,
  ModulesQueryResponse,
  RechercheCatalogueQueryResponse,
  RemiseQueryResponse,
  RoleQueryResponse,
  RolesQueryResponse,
  UtilisateurQueryResponse,
  VehiculeAndPiecesQueryResponse,
} from './Models'
import {Response} from '../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const CLIENT_BASE_URL = `${API_URL}/client`
const DEMANDE_BASE_URL = `${API_URL}/demande`
const UTILISATEUR_BASE_URL = `${API_URL}/utilisateur`
const PIECE_BASE_URL = `${API_URL}/piece`
const CENTRE_BASE_URL = `${API_URL}/centre`
const GENRE_BASE_URL = `${API_URL}/vehicule/type`
const ROLE_BASE_URL = `${API_URL}/utilisateur-role`
const ACOMPTE_BASE_URL = `${API_URL}/client-acompte`
const CLIENT_DOCUMENT_BASE_URL = `${API_URL}/client-document`
const CLIENT_DEMANDE_ETAPE_BASE_URL = `${API_URL}/client_demande_etape`
const ACCESS_DENIED_BASE_URL = `/error/access-denied`
const COURRIEL_BASE_URL = `${API_URL}/mail-client`

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response) {
      const {code, message} = error.response.data

      if (code == 500 && message == 'Access denied to this fonctionnalite') {
        window.location.href = ACCESS_DENIED_BASE_URL
      }
    }
    return Promise.reject(error)
  }
)

export const getCatdata = async (
  immatriculation: string | undefined
): Promise<ImmatriculationQueryResponse | undefined> => {
  return await axios.get(`${API_URL}/catdata?immat=${immatriculation}`)
}
export const getCatdataCnit = async (
  cnit: string | undefined
): Promise<ImmatriculationQueryResponse | undefined> => {
  return await axios.get(`${API_URL}/catdataCnit?cnit=${cnit}`)
}

export const getShopToken = async (): Promise<Response<string>> => {
  return await axios.get(`${API_URL}/shop_token`)
}

export const getUtilisateurs = async (page, search): Promise<any> => {
  const response = await axios.get(`${UTILISATEUR_BASE_URL}?page=${page}&search=${search}`)
  return response.data
}

export const getUtilisateur = async (
  utilisateurId: string | undefined
): Promise<any> => {
  return await axios.get(`${UTILISATEUR_BASE_URL}/${utilisateurId}`)
}

export const createUtilisateur = async (
  utilisateur: ModeleUtilisateur
): Promise<UtilisateurQueryResponse | undefined> => {
  return await axios.post(UTILISATEUR_BASE_URL, utilisateur)
}

export const editUtilisateur = async (
  utilisateurId: string | undefined,
  utilisateur: ModeleUtilisateur
): Promise<UtilisateurQueryResponse | undefined> => {
  return await axios.post(`${UTILISATEUR_BASE_URL}/${utilisateurId}/edit`, utilisateur)
}

export const deleteUtilisateur = async (
  utilisateurId: string | undefined
): Promise<UtilisateurQueryResponse | undefined> => {
  return await axios.delete(`${UTILISATEUR_BASE_URL}/${utilisateurId}/delete`)
}

export const getClients = async (page, search): Promise<any> => {
   const response =   await axios
        .get(`${CLIENT_BASE_URL}?page=${page}&search=${search}`)
    return response.data
}

export const getAllClients = async (): Promise<any> => {
  const response =   await axios
    .get(`${CLIENT_BASE_URL}`)
  return response.data
}

export const getClient = async (clientId: string | undefined): Promise<ClientQueryResponse> => {
  return await axios.get(`${CLIENT_BASE_URL}/${clientId}`)
}

export const getClientOtherData = async (
  clientId: string | undefined
): Promise<ClientOtherDataQueryResponse> => {
  return await axios.get(`${CLIENT_BASE_URL}/${clientId}/others`)
}

export const createClient = async (
  client: ModeleClient
): Promise<ClientQueryResponse | undefined> => {
  return await axios.post(CLIENT_BASE_URL, client)
}

export const editClient = async (
  clientId: string | undefined,
  client: ModeleClient
): Promise<ClientQueryResponse | undefined> => {
  return await axios.post(`${CLIENT_BASE_URL}/${clientId}/edit`, client)
}

export const getClientContacts = async (
  clientId: string | undefined
): Promise<ClientContactsQueryResponse> => {
  return await axios.get(`${CLIENT_BASE_URL}/${clientId}/contacts`)
}

export const getClientContact = async (
  clientId: string | undefined,
  contactId: string | undefined
): Promise<ClientContactQueryResponse> => {
  return await axios.get(`${CLIENT_BASE_URL}/${clientId}/contact/${contactId}`)
}

export const createClientContact = async (
  clientId: string | undefined,
  contact: ModeleClientContact
): Promise<ClientContactQueryResponse | undefined> => {
  return await axios.post(`${CLIENT_BASE_URL}/${clientId}/contact`, contact)
}

export const editClientContact = async (
  clientId: string | undefined,
  contactId: string | undefined,
  contact: ModeleClientContact
): Promise<ClientContactQueryResponse | undefined> => {
  return await axios.post(`${CLIENT_BASE_URL}/${clientId}/contact/${contactId}`, contact)
}

export const deleteClientContact = async (
  clientId: string | undefined,
  contactId: string | undefined
): Promise<ClientContactQueryResponse | undefined> => {
  return await axios.delete(`${CLIENT_BASE_URL}/${clientId}/contact/${contactId}`)
}

export const getClientAdresses = async (
  clientId: string | undefined
): Promise<ClientAdressesQueryResponse> => {
  return await axios.get(`${CLIENT_BASE_URL}/${clientId}/adresses`)
}

export const getClientAdresse = async (
  clientId: string | undefined,
  adresseId: string | undefined
): Promise<ClientAdresseQueryResponse> => {
  return await axios.get(`${CLIENT_BASE_URL}/${clientId}/adresse/${adresseId}`)
}

export const createClientAdresse = async (
  clientId: string | undefined,
  adresse: ModeleClientAdresse
): Promise<ClientAdresseQueryResponse | undefined> => {
  return await axios.post(`${CLIENT_BASE_URL}/${clientId}/adresse`, adresse)
}

export const editClientAdresse = async (
  clientId: string | undefined,
  adresseId: string | undefined,
  adresse: ModeleClientAdresse
): Promise<ClientAdresseQueryResponse | undefined> => {
  return await axios.post(`${CLIENT_BASE_URL}/${clientId}/adresse/${adresseId}`, adresse)
}

export const deleteClientAdresse = async (
  clientId: string | undefined,
  adresseId: string | undefined
): Promise<ClientAdresseQueryResponse | undefined> => {
  return await axios.delete(`${CLIENT_BASE_URL}/${clientId}/adresse/${adresseId}`)
}

export const getClientDemandes = async (
  clientId: string | undefined
): Promise<ClientDemandesQueryResponse> => {
  return await axios.get(`${CLIENT_BASE_URL}/${clientId}/demandes`)
}

export const getDemandes = async (page: any, search: any, statut: any, clientId: number | null | undefined): Promise<any> => {
  if (clientId == null) {
    return await axios
      .get(`${DEMANDE_BASE_URL}?page=${page}&search=${search}&statut=${statut}`)
  } else {
    return await axios
      .get(`${DEMANDE_BASE_URL}/${clientId}/?page=${page}&search=${search}&statut=${statut}`)
  }
}

export const deleteDemande = async (demandeId): Promise<any> => {
    return axios
        .delete(`${DEMANDE_BASE_URL}/${demandeId}`)
}

export const getClientDemandeImmat = async (demandeId: string | undefined): Promise<any> => {
    return await axios
        .get(`${API_URL}/demande/immat/${demandeId}`)
}

export const postClientDemandeAssign = async (
  clientId: string | undefined | null,
  demandeId: string | undefined | null,
  userId: number | null
): Promise<ClientDemandeQueryResponse> => {
  if (userId) {
    return await axios.post(`${CLIENT_BASE_URL}/${clientId}/demande/${demandeId}/assign`, {
      user: userId,
    })
  } else {
    return await axios.post(`${CLIENT_BASE_URL}/${clientId}/demande/${demandeId}/assign`, {})
  }
}

export const createClientDemande = async (
  clientId: string | undefined,
  demande: ModeleClientDemande,
  pieces: Array<ModeleClientDemandePiece | undefined>
): Promise<ClientDemandeQueryResponse | undefined> => {
  return await axios.post(`${CLIENT_BASE_URL}/${clientId}/demande`, {
    ...demande,
    pieces: JSON.stringify(pieces),
  })
}

export const getDemandePieceSuggestion = async (
  demandeId: string | undefined,
  demandePieceId: number | undefined
): Promise<Response<string | null>> => {
  return await axios.get(`${PIECE_BASE_URL}/demande/${demandeId}/suggestion/${demandePieceId}`)
}

export const getSearchVehiculeAndPieces = async (
  immatriculation: string | undefined,
  cnit: string | undefined
): Promise<VehiculeAndPiecesQueryResponse | undefined> => {
  if (immatriculation) {
    return await axios.get(`${API_URL}/searchvandp?immatriculation=${immatriculation}`)
  } else if (cnit) {
    return await axios.get(`${API_URL}/searchvandp?cnit=${cnit}`)
  }
}

export const getSearchPiece = async (
  reference: string | undefined,
  signal?: AbortSignal
): Promise<any | undefined> => {
  if (!reference) {
    console.error('La référence est undefined ou vide');
    return undefined;
  }

  try {
    return await axios.get(`${API_URL}/searchp?reference=${reference}`, {
      signal,
    });
  } catch (error: unknown) {
    if (error instanceof Error) {
      if (error.name === 'AbortError') {
        console.log('Requête annulée');
      } else {
        console.error('Erreur lors de la récupération des données :', error.message);
      }
    } else {
      console.error('Erreur inconnue');
    }
    return undefined;
  }
};

export const getCompanyBySiret = async (siret: string): Promise<any> => {
  const response = await axios.post(`${CLIENT_BASE_URL}/getInfoCompanyViaSiret`, {siret})
  return response.data
}

export const createCentre = async (
  centre: ModeleCentre
): Promise<CentreQueryResponse | undefined> => {
  return await axios.post(CENTRE_BASE_URL, centre)
}

export const getCentres = async (page,search): Promise<any> => {
    const response = await axios
        .get(`${CENTRE_BASE_URL}?page=${page}&search=${search}`) ;
    return response.data ;
}

export const getCentre = async (centreId: string | undefined): Promise<CentreQueryResponse> => {
  return await axios.get(`${CENTRE_BASE_URL}/${centreId}`)
}

export const editCentre = async (
  centreId: string | undefined,
  centre: ModeleCentre
): Promise<CentreQueryResponse | undefined> => {
  return await axios.post(`${CENTRE_BASE_URL}/${centreId}/edit`, centre)
}

export const deleteCentre = async (
  centreId: string | undefined
): Promise<CentreQueryResponse | undefined> => {
  return await axios.delete(`${CENTRE_BASE_URL}/${centreId}/delete`)
}
export const createGenre = async (genre: ModeleGenre): Promise<GenreQueryResponse | undefined> => {
  return await axios.post(GENRE_BASE_URL, genre)
}

export const getGenres = async (page,search): Promise<any> => {
    const response = await axios
        .get(`${GENRE_BASE_URL}?page=${page}&search=${search}`) ;
    return response.data ;
}

export const editGenre = async (
  genreId: string | undefined,
  genre: ModeleGenre
): Promise<GenreQueryResponse | undefined> => {
  return await axios.post(`${GENRE_BASE_URL}/${genreId}/edit`, genre)
}

export const deleteGenre = async (
  genreId: string | undefined
): Promise<GenreQueryResponse | undefined> => {
  return await axios.delete(`${GENRE_BASE_URL}/${genreId}/delete`)
}

export const getGenre = async (genreId: string | undefined): Promise<GenreQueryResponse> => {
  return await axios.get(`${GENRE_BASE_URL}/${genreId}`)
}

export const getResultSeach = async (allSearch: string): Promise<any> => {
  const response = await axios.post(`${CLIENT_BASE_URL}/clientSearch`, {allSearch})
  return response.data
}
export const getRoles = async (): Promise<RolesQueryResponse> => {
  return await axios.get(`${ROLE_BASE_URL}`)
}

export const getModules = async (): Promise<ModulesQueryResponse> => {
  return await axios.get(`${API_URL}/module`)
}

export const getRole = async (roleId: string | undefined): Promise<RoleQueryResponse> => {
  return await axios.get(`${ROLE_BASE_URL}/${roleId}`)
}

export const createRole = async (
  role: ModeleRoleAndModules
): Promise<RoleQueryResponse | undefined> => {
  return await axios.post(`${API_URL}/utilisateur-role`, role)
}

export const editRole = async (
  roleId: string | undefined,
  role: ModeleRole
): Promise<RoleQueryResponse | undefined> => {
  return await axios.post(`${ROLE_BASE_URL}/${roleId}/edit`, role)
}

export const getResultSearchUser = async (allSearch: string): Promise<any> => {
  const response = await axios.post(`${UTILISATEUR_BASE_URL}/userSearch`, {allSearch})
  return response.data
}
export const centresearch = async (search: string): Promise<any> => {
  const response = await axios.post(`${CENTRE_BASE_URL}/searchCentre`, {search})
  return response.data
}

export const genreSearch = async (search: string): Promise<any> => {
  const response = await axios.post(`${GENRE_BASE_URL}/vehiculeTypeSearch`, {search})
  return response.data
}

export const deleteRoleUtilisateur = async (
  roleId: string | undefined
): Promise<RoleQueryResponse | undefined> => {
  return await axios.delete(`${ROLE_BASE_URL}/${roleId}/delete`)
}

export const getCatalogues = async (): Promise<CataloguesQueryResponse> => {
  return await axios.get(`${API_URL}/catalogue`)
}

export const getClientEncours = async (
  clientId: string | undefined
): Promise<ClientEncoursQueryResponse> => {
  return await axios.get(`${API_URL}/client-encours-paiement/${clientId}`)
}

export const getCatalogue = async (
  idCatalogue: string | undefined
): Promise<CatalogueQueryResponse> => {
  return await axios.get(`${API_URL}/catalogue/${idCatalogue}`)
}

export const createCatalogue = async (
  catalogue: ModeleCatalogue
): Promise<CatalogueQueryResponse | undefined> => {
  return await axios.post(`${API_URL}/catalogue`, catalogue)
}

export const editCatalogue = async (
  idCatalogue: string | undefined,
  catalogue: ModeleCatalogue
): Promise<CatalogueQueryResponse | undefined> => {
  return await axios.post(`${API_URL}/catalogue/${idCatalogue}/edit`, catalogue)
}

export const deleteCatalogue = async (
  idCatalogue: string | undefined
): Promise<CatalogueQueryResponse | undefined> => {
  return await axios.delete(`${API_URL}/catalogue/${idCatalogue}/delete`)
}

export const getSearchPieceRefConst = async (
  reference: string | undefined
): Promise<AnyQueryResponse | undefined> => {
  return await axios.get(`${PIECE_BASE_URL}/piece_ref_cons?refConst=${reference}`)
}

export const postDemandes = async (
  clientId: string | undefined,
  immatCentre: any,
  immatPieces: any
): Promise<any> => {
  return await axios.post(`${CLIENT_BASE_URL}/${clientId}/demande`, {
    immatCentreArray: immatCentre,
    demandePieceArray: immatPieces,
  })
}

export const getCategoriePiece = async (
  typePieceId: string | undefined
): Promise<AnyQueryResponse | undefined> => {
  return await axios.get(`${API_URL}/type/piece/${typePieceId}`)
}

/*export const getCentre = async (centre: string | undefined): Promise<AnyQueryResponse | undefined> => {
    return await axios
      .get(`${API_URL}/centre/${centre}`)
}*/

export const getListePieceViaType = async (idTypePiece: string | number | undefined , page , searchRef, marque, modele): Promise<any> => {
  const response = await axios
      .get(`${PIECE_BASE_URL}/piece_type_piece/${idTypePiece}?page=${page}&reference=${searchRef}&marque=${marque}&modele=${modele}`)
  return response.data;
}

export const getPieceInStock = async (
  idPiece: string | number | undefined
): Promise<AnyQueryResponse | undefined> => {
  return await axios.get(`${PIECE_BASE_URL}/piece_in_stock/${idPiece}`)
}

export const getPieceSuggestion = async (
  piecesArr: any | undefined | null
): Promise<AnyQueryResponse | undefined> => {
  return await axios.post(`${DEMANDE_BASE_URL}/piece/proposee`, {
    piecesArr: piecesArr,
  })
}

export const postGenreByCode = async (
  code: string | number | undefined | null
): Promise<AnyQueryResponse | undefined> => {
  return await axios.post(`${API_URL}/vehicule/type/code`, {
    code: code,
  })
}

export const postEmailPropos = async (
  panier: any | undefined | null,
  clientId: number | string | undefined,
  demandeId: number | string | undefined
): Promise<AnyQueryResponse | undefined> => {
  return await axios.post(`${DEMANDE_BASE_URL}/${clientId}/traiter/${demandeId}`, {
    panier: panier,
  })
}

export const getDemandeProposee = async (idClientDemande:  number | undefined): Promise<AnyQueryResponse | undefined> => {
  return await axios
      .get(`${DEMANDE_BASE_URL}/demande-proposee/${idClientDemande}`)
}

export const validateSuggestion = async (idPieceProposee: number | undefined, status: boolean | undefined): Promise<AnyQueryResponse | undefined> => {
    return await axios
      .post(`${DEMANDE_BASE_URL}/piece/proposee/${idPieceProposee}/valider`, {
          'status' : status
      })
}

export const getNbrePieceAcceptees = async (
  idPieceProposee: string | number | undefined
): Promise<AnyQueryResponse | undefined> => {
  return await axios.get(`${DEMANDE_BASE_URL}/piece/proposee/${idPieceProposee}`)
}
export const validatePseudoEmail = async (
  email: string | undefined | null = '',
  pseudo: string | undefined | null = ''
): Promise<any> => {
  if (email) {
    return await axios.post(`${UTILISATEUR_BASE_URL}/validatePseudoEmail`, {email})
  } else if (pseudo) {
    return await axios.post(`${UTILISATEUR_BASE_URL}/validatePseudoEmail`, {pseudo})
  }
}

export const validatePseudoEmailEdit = async (
  email: string | undefined | null = '',
  pseudo: string | undefined | null = '',
  utilisateurId: number | string | undefined
): Promise<any> => {
  if (email) {
    return await axios.post(`${UTILISATEUR_BASE_URL}/${utilisateurId}/validatePseudoEmailEdit`, {
      email,
    })
  } else if (pseudo) {
    return await axios.post(`${UTILISATEUR_BASE_URL}/${utilisateurId}/validatePseudoEmailEdit`, {
      pseudo,
    })
  }
}

export const getQuantityForOneDemandePiece = async (
  idDemande: string | number | undefined,
  idDemandeImmat: string | number | undefined,
  typePiece: string | number | undefined
): Promise<AnyQueryResponse | undefined> => {
  return await axios.get(`${DEMANDE_BASE_URL}/piece/${idDemande}/${idDemandeImmat}/${typePiece}`)
}

export const searchCatalog = async (
  search: ModeleRechercheCatalogue
): Promise<RechercheCatalogueQueryResponse | undefined> => {
  return await axios.post(`${API_URL}/mdb/catalogue/search`, search)
}

export const getClientAcomptes = async (
  clientId: string | undefined
): Promise<ClientAcomptesQueryResponse> => {
  return await axios.get(`${ACOMPTE_BASE_URL}/${clientId}`)
}
export const getClientDocuments = async (
  clientId: string | undefined
): Promise<ClientDocumentsQueryResponse> => {
  return await axios.get(`${CLIENT_BASE_URL}/${clientId}/documents`)
}
export const getClientsRemises = async (
  clientId: string | undefined
): Promise<RemiseQueryResponse> => {
  return await axios.get(`${CLIENT_BASE_URL}/${clientId}/remises`)
}

export const createRemise = async (
  clientId: number | undefined,
  remise: ModeleClientRemise
): Promise<RemiseQueryResponse | undefined> => {
  return await axios.post(`${CLIENT_BASE_URL}/${clientId}/remise`, remise)
}

export const editRemise = async (
  clientId: number | undefined,
  remiseId: string | undefined,
  remise: ModeleClientRemise
): Promise<RemiseQueryResponse | undefined> => {
  return await axios.post(`${CLIENT_BASE_URL}/${clientId}/${remiseId}/edit`, remise)
}

export const deleteClientDocument = async (
  documentId: string | undefined,
): Promise<ClientDocumentQueryResponse | undefined> => {
  return await axios.delete(`${CLIENT_DOCUMENT_BASE_URL}/${documentId}/delete`)
}

export const getClientDocument = async (
  client: string | undefined
): Promise<ModeleClientDocument> => {
  const response = await axios.get(`${CLIENT_DOCUMENT_BASE_URL}/${client}`)
  return response.data
}
export const getHistoriqueFacturations = async (
  clientId: string | undefined
): Promise<ModeleHistoriqueFacturationResponse> => {
  return await axios.get(`${API_URL}/client-historique-facturation/${clientId}`)
}
export const deleteRemise = async (
  remiseId: string | undefined
): Promise<RemiseQueryResponse | undefined> => {
  return await axios.delete(`${CLIENT_BASE_URL}/${remiseId}/delete`)
}

export const getClientDemandeEtape = async (): Promise<any> => {
  return await axios.get(`${CLIENT_DEMANDE_ETAPE_BASE_URL}`)
}

export const getCourriels = async (page, search): Promise<any> => {
  const response = await axios.get(`${COURRIEL_BASE_URL}?page=${page}&search=${search}`)
  return response.data
}

export const getCourriel = async (
  idCourriel: string | undefined
): Promise<CourrielQueryResponse> => {
  return await axios.get(`${COURRIEL_BASE_URL}/${idCourriel}`)
}

export const createCourriel = async (
  courriel: ModeleCourriel
): Promise<CourrielQueryResponse | undefined> => {
  return await axios.post(`${COURRIEL_BASE_URL}`, courriel)
}

export const editCourriel = async (
  idCourriel: string | undefined,
  courriel: ModeleCourriel
): Promise<CourrielQueryResponse | undefined> => {
  return await axios.post(`${COURRIEL_BASE_URL}/${idCourriel}/edit`, courriel)
}

export const deleteCourriel = async (
  idCourriel: string | undefined
): Promise<CourrielQueryResponse | undefined> => {
  return await axios.delete(`${COURRIEL_BASE_URL}/${idCourriel}/delete`)
}

export const checkCourrielConnexion = async (
  idCourriel: string | undefined
): Promise<CourrielQueryResponse | undefined> => {
  return await axios.post(`${COURRIEL_BASE_URL}/${idCourriel}/check`)
}

export const getInfoVehicle = async (marque: string | undefined | null, modele: string | undefined | null): Promise<any> => {
  return await axios
    .get(`${DEMANDE_BASE_URL}/info-vehicle?marque=${marque}&modele=${modele}`)
}
