import React, {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
  ModeleClient,
  modeleClientDemandeInitValues,
  modeleClientInitValues,
} from '../../../../components/Models'
import {createClient} from '../../../../components/requests'
import {DemandeForm} from '../Components/DemandeForm'
import {useLocation} from 'react-router'
import {KTIcon} from '../../../../_metronic/helpers'
import { Tooltip } from 'react-tooltip'

type LocationState = {
  redirectToClientDemande: boolean
}

const DemandClientCreate = () => {
  const navigate = useNavigate();
  let { clientId } = useParams();
  const location = useLocation()
  const state = location.state as LocationState

  const submitForm = async (fieldsToUpdate: Partial<ModeleClient>, callback): Promise<void> => {
    const updatedData = Object.assign({...modeleClientInitValues}, fieldsToUpdate)

    var requestOk = false;
    try {
      const clientRequest = await createClient(updatedData);
      if (clientRequest?.status === 200) {
        if (clientRequest?.data?.id !== undefined && clientRequest?.data?.id !== null) {
          requestOk = true;
          navigate(`/vente/clients/${clientRequest?.data?.id}/afficher`);
        }
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      callback();
    }

  }
  // Close modal when navigating to "demande/createClient"
  useEffect(() => {
    const closeModalIfOnCreateClient = () => {
      if (window.location.pathname === '/demandes/create/:id') {
        // Select all modal-backdrop elements
        const backdrops = document.querySelector('.modal');
        if (backdrops){
          backdrops.remove()
        }
      }
    };
    closeModalIfOnCreateClient();
  }, [window.location.pathname])
  // console.log(window.location.pathname)
  return (
    <>
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0 justify-content-between w-100'>
          <h3 className='fw-bolder m-0'>Créer une demande</h3>
          <div
            data-tooltip-id="add-tooltip"
            data-tooltip-content="Ajouter une immatriculation"
            data-tooltip-place="bottom"
          >
            <button
              type='button'
              className='btn btn-light-primary'
              data-bs-target='#exampleModalSearchV'
              data-bs-toggle='modal'
            >
              <KTIcon
                iconName='plus'
                className='fs-1'
              />
            </button>
          </div>
        </div>
      </div>
      <div>
        {/* Récupération de l'id du client qu'on vient de créer et redirection vers "DemandeAdd" après avoir effectué l'enregistrement*/}
        <DemandeForm
          clientId={clientId}
          demandeId={null}
          data={modeleClientDemandeInitValues}
          submitForm={submitForm}
          redirectToClientDemande={state.redirectToClientDemande}
        />
      </div>
    </div>
      {/*Affichage du tooltip*/}
      <Tooltip id="add-tooltip" />
    </>
  )
}

export {DemandClientCreate}
