import React from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {Navigate, Route, Routes} from 'react-router-dom'
import {DemandListPage} from './pages/DemandListPage'
import {DemandClientCreate} from './pages/DemandClientCreate'
import {DemandeTraitement} from './pages/DemandeTraitement'
import {DispoPiece} from './pages/Televendeur/DispoPiece'
import {DetailsPiece} from './pages/Televendeur/DetailsPiece'
import {ListePieceStock} from './pages/Televendeur/ListePieceStock'
import ListePieceMarket from './pages/Televendeur/ListePieceMarket'
import {PanierProvider} from './pages/Televendeur/PanierContext'
//import 'react-tooltip/dist/react-tooltip.css'

  const DemandesWrapper = () => {


  return (
      <>
        <PageTitle breadcrumbs={[]}>Demandes</PageTitle>
        <Routes>
          <Route path='/liste' element={
            <>
              <DemandListPage />
            </>
          }
          />
          <Route path='/createClient' element={
            <>
              <DemandClientCreate />
            </>
          }
          />
          <Route path='/create/:clientId' element={
            <>
              <DemandClientCreate />
            </>
          }
          />
          <Route path='/:clientId/traitement/:demandId' element={
            <PanierProvider>
              <DemandeTraitement />
            </PanierProvider>
          }
          />
          <Route path='/pieceDispo/:pieceId' element={
            <>
              <DispoPiece />
            </>
          }
          />
          <Route path='/pieceDetail/detail/:pieceStock' element={
            <PanierProvider>
              <DetailsPiece />
            </PanierProvider>
          }
          />
          <Route path='/enStock/:pieceId' element={
            <PanierProvider>
              <ListePieceStock />
            </PanierProvider>
          }
          />
          <Route path='/onMarket/:pieceId' element={
            <PanierProvider>
              <ListePieceMarket />
            </PanierProvider>
          }
          />
          <Route index element={<Navigate to='/demandes/liste' />} />
        </Routes>
      </>
  )
}

export {DemandesWrapper}
