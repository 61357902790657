import {Link, useParams} from 'react-router-dom'
import {getListePieceViaType} from '../../../../../components/requests'
import React, {useEffect, useState} from 'react'
import {ModelePieceItemStock} from '../../../../../components/Models'
import {useLocation} from 'react-router'
import {LoadingData} from '../../../../../components/LoadingData'
import {ShopModal} from '../../Components/Televendeur/ShopModal'
import {usePanier} from './PanierContext'
import {useFormik} from 'formik'
import {Pagination} from '../../../../../components/Pagination'
import OnMarket from '../../Components/Televendeur/OnMarket'
import {ToastContainer} from 'react-toastify'
import { Tooltip } from 'react-tooltip'

type LocationState = {
  panier: any
  immatriculation: string
  quantity: number
  setPanierArray: any
  clientId: number
  demandeId: number
  demandeImmatId: number
  redirectToClientDemande: boolean
  refConst: string | null
  marque: string | null
  modele: string | null
}

const ListePieceStock = (props) => {
  const params = useParams()
  const [listPiecesArr, setListPieceArr] = useState<ModelePieceItemStock[]>([])
  const [idTypePiece] = useState(params.pieceId)
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const locationState = location.state as LocationState | null
  const {panier, setPanier} = usePanier()
  const [activePieces, setActivePieces] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [counter, setCounter] = useState<number>(0)
  const [nombreDePages, setNombreDePages] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchPage, setSearchPage] = useState<string>(locationState?.refConst ?? '')
  const [isSearch, setIsSearch] = useState(false)
  const [disableLink, setDisableLink] = useState<boolean>(false)

  // Pour compter le nombre de requête exécutée pour le chargement de lot
  const handleCount = () => {
    setCounter((count) => count + 1)
  }

  // Pour lister les pièces
  const getListePieces = () => {
    setLoading(true)
    getListePieceViaType(
      idTypePiece,
      !isSearch ? currentPage : 1,
      searchPage,
      locationState?.marque,
      locationState?.modele
    ).then((response) => {
      if (response?.data !== undefined) {
        setNombreDePages(response?.pages)
        setTotalPages(response?.total)
        setCurrentPage(response?.page)
        setListPieceArr(response?.data)
        setCounter(0)
        setActivePieces([])
        setIsSearch(false)
        setLoading(false)
      }
    })
  }

  const formik = useFormik({
    initialValues: {reference: ''},
    onSubmit: (values, {setSubmitting}) => {
      setSearchPage(values.reference)
    },
  })

  // Charger le positionnement sur le marché par lot de 5
  const itemsPerBatch = 5

  // Gestion des lots
  const handleLoadNextBatch = async () => {
    if (isLoading) return

    setIsLoading(true)
    let batchStart = activePieces.length
    let batchEnd = Math.min(batchStart + itemsPerBatch, listPiecesArr.length)

    if (batchStart >= listPiecesArr.length) {
      setIsLoading(false)
      return
    }

    // Ajout des nouveaux indices dans le nouveau lot
    const newActivePieces: any = []

    for (let i = batchStart; i < batchEnd; i++) {
      newActivePieces.push(i)
    }

    setActivePieces((prevActive) => [...prevActive, ...newActivePieces])

    let requestsInProgress = newActivePieces.length

    await Promise.all(
      newActivePieces.map(async (index) => {
        await new Promise((resolve) => setTimeout(resolve, 5))
        requestsInProgress -= 1
        if (requestsInProgress === 0) {
          setIsLoading(false)
        }
      })
    )
  }

  useEffect(() => {
    if (
      counter == activePieces.length ||
      (listPiecesArr.length > 0 &&
        activePieces.length === 0 &&
        activePieces.length < listPiecesArr.length)
    ) {
      handleLoadNextBatch()
    }
  }, [listPiecesArr, counter])

  const renderFormRowPieces = () => {
    return listPiecesArr.map((piece, index) => {
      let codeSousCat = piece[0]?.typePiece?.sousCategorie.code
      let codeCat = piece[0]?.typePiece?.sousCategorie.categorie.code
      let vehiculeEntre = piece.numero_vehicule_entre
      let prixVenteMin = piece.prixVenteMin
      let prixVenteMax = piece.prixVenteMax
      return (
        <tr key={index}>
          <td>
            {codeCat}
            {codeSousCat}
            {piece[0]?.typePiece?.code}
            {vehiculeEntre}
          </td>
          <td>{piece[0]?.typePiece?.nom}</td>
          <td>{piece[0]?.referenceConstructeur}</td>
          <td>{piece[0]?.prixConstructeur ? piece[0]?.prixConstructeur : 'N/C'}</td>
          <td>{piece[0]?.prixConstructeur ? prixVenteMin : 'N/C'}</td>
          <td>{piece[0]?.prixConstructeur ? prixVenteMax : 'N/C'}</td>
          <td>{piece.stock}</td>
          <td>
            {/* Pour gérer les lots */}
            {activePieces.includes(index) ? (
              <OnMarket
                reference={piece[0]?.referenceConstructeur}
                piece={piece}
                locationState={locationState}
                index={index}
                handleCount={handleCount}
              />
            ) : (
              <span className='badge bg-light text-gray-500'>Chargement...</span>
            )}
          </td>
          <td>
            {piece.stock != 0 && (
              <Link
                to={`/demandes/pieceDetail/detail/${piece[0]?.id}`}
                state={{
                  panier: locationState?.panier,
                  piece: listPiecesArr,
                  immat: locationState?.immatriculation,
                  pieceId: piece[0]?.id,
                  quantity: locationState?.quantity,
                  idTypePiece: piece[0]?.typePiece?.id,
                  clientId: locationState?.clientId,
                  demandeId: locationState?.demandeId,
                  demandeImmatId: locationState?.demandeImmatId,
                  redirectToClientDemande: locationState?.redirectToClientDemande,
                  refConst: locationState?.refConst,
                  marque: locationState?.marque,
                  modele: locationState?.modele,
                }}
                data-tooltip-id='tooltip'
                data-tooltip-content='Détail de la pièce'
                data-tooltip-place='right'
              >
                <img src='/media/svg/custom/info.svg' width={30} />
              </Link>
            )}
          </td>
        </tr>
      )
    })
  }

  // Récupération de la liste des pièces
  useEffect(() => {
    getListePieces()
  }, [currentPage, searchPage])

  return (
    <div className='card'>
      <div className='card-header d-flex justify-content-between pt-9 align-items-center border-bottom-0'>
        <Link
          to={`/demandes/${locationState?.clientId}/traitement/${locationState?.demandeId}`}
          state={{panier: panier, clientId:locationState?.clientId, demandeId:locationState?.demandeId, redirectToClientDemande:locationState?.redirectToClientDemande}}
          data-tooltip-id="tooltip"
          data-tooltip-content="Revenir en arrière"
          data-tooltip-place="right"
        >
          <img src='/media/svg/custom/prev-arrow.svg' width={40} />
        </Link>
        <div>
          <ShopModal
            clientId={locationState?.clientId}
            demandeId={locationState?.demandeId}
            panier={panier}
            setPanier={setPanier}
          />
        </div>
      </div>
      {/* debut champs recherche */}
      <form className='card-header border-0 pt-5 pb-5' onSubmit={formik.handleSubmit}>
        <div></div>
        <div className='form-group d-flex col-lg-4'>
          <div className='input-group'>
            <input
              type='text'
              className='form-control h-100'
              {...formik.getFieldProps('reference')}
              name='reference'
              placeholder='Recherche par reference constructeur'
            />
            <button
              type='submit'
              className='btn btn-light border-0 input-group-append h-100'
              onClick={() => setIsSearch(true)}
            >
              <i className='fas fa-search text-secondary fs-1'></i>
            </button>
          </div>
        </div>
      </form>
      {/* fin champs recherche */}
      <div className='card-body'>
        {loading ? (
          <LoadingData />
        ) : (
          <>
            <table className='table table-row-dashed table-row-gray-300 gy-7'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th>Code pièce</th>
                  <th>Type pièce</th>
                  <th>Ref. Constructeur</th>
                  <th>Prix contructeur</th>
                  <th>Prix vente min</th>
                  <th>Prix vente max</th>
                  <th>En stock</th>
                  <th>Sur le marché</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {listPiecesArr.length > 0 ? (
                  renderFormRowPieces()
                ) : (
                  <tr>
                    <td colSpan={9} className='text-center'>
                      <div>Pas de pièce en stock</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* begin::pagination*/}
            <Pagination
              totalPages={totalPages}
              nombreDePages={nombreDePages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              locationState={locationState}
            />
            {/* end::pagination*/}

            {/*Affichage notification toast*/}
            <ToastContainer position='bottom-right' autoClose={3000} />
            {/*Affichage du tooltip*/}
            <Tooltip id="tooltip" />
          </>
        )}
      </div>
    </div>
  )
}
export {ListePieceStock}
