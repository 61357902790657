import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Navigate, Route, Routes} from 'react-router-dom'
import {CataloguesListPage} from './CataloguesListPage'


const CataloguesWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Gestion des catalogues</PageTitle>
      <Routes>
        <Route
          path='/liste'
          element={
            <>
              <CataloguesListPage />
            </>
          }
        />
      
        <Route index element={<Navigate to='/catalogues/liste' />} />
      </Routes>
    </>
  )
}

export {CataloguesWrapper}
