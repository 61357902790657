import {ModeleClient, ModeleClientDemandePiece, ModeleSelectInput} from '../Models'
import {Response} from '../../_metronic/helpers'

export interface ModeleClientParametrage {
  id?: number;
  echeance?: string;
  client?: ModeleClient;
  compteTiers?: string;
  recalculTva?: boolean;
  affichageRemise?: boolean;
  zoneTva?: string;
  modeReglement?: ModeleModeReglement;
  idModeReglement: number;
  caisse?: string;
  ribEtablissement?: string;
  rib?: string;
  ribBanque?: string;
  ribGuichet?: string;
  ribCompte?: string;
  ribCle?: string;
  ribBic?: string;
  encoursComptableMaxi?: string;
  enCompte?: boolean;
  depuis?: string;
  codeCompteClient?: string;
  clientGroupe?: ModeleClientGroupe;
  idClientGroupe: number;
  commercial?: ModeleCommercial;
  idCommercial?: number;
  transporteur?: ModeleTransporteur;
  idTransporteur?: number;
  tableauDeBord?: string;
  reseaux?: Array<ModeleResaux>;
  idReseaux?: Array<number>;
  codeClientWeb?: Array<{ Code: string; Libelle: string }>;
}

export interface ModeleModeReglement {
  id?: number;
  libelle?: string;
}

export interface ModeleClientGroupe {
  id?: number;
  name?: string;
}
export interface ModeleTransporteur {
  id?: number;
  libelle?: string;
}
export interface ModeleCommercial {
  id?: number;
  libelle?: string;
}
export interface ModeleResaux {
  id?: number;
  libelle?: string;
  code?: string;
}

export const modeleClientParametrageInitValues:
  ModeleClientParametrage = {
  idModeReglement: 1,
  idCommercial : 1,
  idClientGroupe: 1,
  idTransporteur: 1,
}

export interface ModelReferentiel {
 value?: number;
 label?: string;
}
export interface ModelReferentielWithCode {
  value?: number;
  label?: string;
  code?: string;
  idReferentiel?: number;
}

export interface ModelReferentielLists {
  modeReglementList : Array<ModelReferentiel>,
  clientGroupeList : Array<ModelReferentiel>,
  commercialList : Array<ModelReferentiel>,
  transporteurList : Array<ModelReferentiel>,
  reseauxList : Array<ModelReferentielWithCode>
}
export const ModelReferentielListsInitValues: ModelReferentielLists = {
  modeReglementList : [],
  clientGroupeList : [],
  commercialList : [],
  transporteurList : [],
  reseauxList : []
}
export const ModelReferentielInitialValue: ModelReferentielWithCode = {};
export type ClientParametrageQueryResponse = Response<ModeleClientParametrage>
export type ReferentielListQueryResponse = Response<ModelReferentielLists>
export type ReferentielQueryResponse = Response<ModelReferentielWithCode>