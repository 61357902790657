import {KTSVG} from '../../../../../_metronic/helpers'
import React, {useEffect, useRef} from 'react'
import Swal from 'sweetalert2'
import {postEmailPropos} from '../../../../../components/requests'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useLocation} from 'react-router'

const ShopModal = (props: any) => {
  const {
    panier,
    setPanier,
    clientId,
    demandeId,
    setPiecesProps,
    redirectToClientDemande,
    setDemandeClient,
    handleDismiss,
    panierFiltered
  } = props
  const navigate = useNavigate()
  const location = useLocation()
  const hasRun = useRef(false)
  const modalRef = useRef<HTMLDivElement | null>(null)

  const handlePanier = (idPiece: number) => {
    const prevPanier = [...panier]
    const newPan =     prevPanier.filter((item) => item.id != idPiece)
    setPanier(newPan)
    if (panierFiltered) panierFiltered(newPan)
  }

  const capitalizaFirstletter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  const handleSubmit = () => {
    Swal.fire({
      title: 'Attention',
      text: 'Merci de confirmer la soumission',
      icon: 'warning',
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
      showCancelButton: true,
      confirmButtonColor: '#1BC5BD',
      cancelButtonColor: '#F64E60',
    }).then((alertResult) => {
      if (alertResult.isConfirmed) {
        const idToastMailSend = toast.loading('Envoi des propositions en cours...')
        if (setPanier) setPanier([])
        postEmailPropos(panier, clientId, demandeId).then((res) => {
          if (location.pathname == `/demandes/${clientId}/traitement/${demandeId}`) {
            toast.update(idToastMailSend, {
              render: 'Envoi des propositions terminé  avec succès',
              type: 'success',
              isLoading: false,
              autoClose: 3000,
            })
          } else {
            navigate(`/demandes/${clientId}/traitement/${demandeId}`, {
              state: {
                redirectToClientDemande: redirectToClientDemande,
                showSendedEmailToast: true,
                idToastMailSend,
              },
            })
          }
          if (res?.status === 200 && res.data !== undefined) {
            if (setDemandeClient) setDemandeClient(res.data[0]?.demande)
            let newPanier: any = [...panier]
            newPanier = newPanier.map((item) => ({
              ...item,
              statut: 'PROPO',
            }))
            setPanier(newPanier)
            if (setPiecesProps) setPiecesProps(res.data)
          }
        })
      }
    })
  }

  return (
    <>
      <div className='text-end'>
        <button
          type='button'
          className='btn btn-light-primary d-flex gap-4 align-items-center'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_1'
          style={{
            border: '1px solid #e5f5bb',
          }}
          onClick={handleDismiss}
        >
          <i className='bi bi-cart2' style={{fontSize: '20px'}}></i>
          Voir le panier
        </button>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1' ref={modalRef}>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Resumé du panier</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={handleDismiss}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <table className='table table-row-dashed table-row-gray-300 gy-7'>
                <thead>
                  <tr className='fw-bolder fs-6 text-gray-800'>
                    <th>Nom de la pièce</th>
                    <th>Immatriculation</th>
                    <th>Référence constructeur</th>
                    <th>Prix</th>
                    <th>Etat</th>
                    <th>Statut</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {panier?.length > 0 ? (
                    panier?.map((piece: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{piece.nomTypePiece}</td>
                          <td>{piece.immat}</td>
                          <td>{piece.refConstructeur}</td>
                          <td>{piece.prix}</td>
                          <td>{capitalizaFirstletter(piece.etat)}</td>
                          <td>{piece?.statut == 'A_PROPO' ? 'A proposée' : 'Proposée'}</td>
                          <td>
                            <img
                              src='/media/svg/custom/bag-minus.svg'
                              width='25'
                              onClick={() => piece?.statut === 'A_PROPO' && handlePanier(piece.id)}
                              style={{
                                cursor: piece?.statut === 'A_PROPO' ? 'pointer' : 'not-allowed',
                                opacity: piece?.statut === 'A_PROPO' ? 1 : 0.5,
                              }}
                              alt='Supprimer'
                            />
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={7} className='text-center'>
                        <div>(Panier vide pour le moment)</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                onClick={handleDismiss}
              >
                Fermer
              </button>
              {panier.length > 0 && panier.some((item) => item.statut !== 'PROPO') && (
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => handleSubmit()}
                  data-bs-dismiss='modal'
                >
                  Valider le panier
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ShopModal}
