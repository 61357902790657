import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {ShopWrapper} from '../pages/shop/ShopWrapper'
import {VehiculesWrapper} from '../pages/vehicules/VehiculesWrapper'
import {RolesWrapper} from '../pages/roles/RolesWrapper'
import {UtilisateursWrapper} from '../pages/utilisateurs/UtilisateursWrapper'
import {CentreWrapper} from '../pages/centre/CentreWrapper'
import {GenresWrapper} from '../pages/genres/GenresWrapper'
import {CataloguesWrapper} from '../pages/catalogues/CataloguesWrapper'
import {RechercheCatalogueWrapper} from '../pages/RechercheCatalogue/RechercheCatalogueWrapper'

import {DemandesWrapper} from '../pages/demande/DemandesWrapper'
import {CourrielWrapper} from '../pages/courriel/CourrielWrapper'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='vente/*' element={<ShopWrapper />} />
        <Route path='vehicules/*' element={<VehiculesWrapper />} />
        <Route path='utilisateurs/*' element={<UtilisateursWrapper />} />
        <Route path='centres/*' element={<CentreWrapper />} />
        <Route path='genres/*' element={<GenresWrapper />} />
        <Route path='roles/*' element={<RolesWrapper />} />
        <Route path='catalogues/*' element={<CataloguesWrapper />} />
        <Route path='recherche-catalogue/*' element={<RechercheCatalogueWrapper />} />
        <Route path='courriel/*' element={<CourrielWrapper />} />

        <Route path='demandes/*' element={<DemandesWrapper />} />
        {/* Lazy Modules */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
