import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom'
import {LoadingData} from '../../../components/LoadingData'
import {CatalogueModal} from './CatalogueModal'
import {ModeleCatalogue} from '../../../components/Models'
import {getCatalogues, deleteCatalogue} from '../../../components/requests'
import {KTIcon} from '../../../_metronic/helpers'
import {useAuth} from "../../modules/auth";
import {shouldDisplayFonctionnalite} from "../../../components/helpers";

const CataloguesListPage = () => {
  const initialDataCatalogue = []
  const [dataCatalogues, setDataCatalogues] = useState<Array<ModeleCatalogue>>(initialDataCatalogue)
  const [loading, setLoading] = useState(false)
  const [isEdition, setIsEdition] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [catalogueId, setCatalogueId] = useState<Number | undefined>(undefined)
  const {currentUser} = useAuth()


  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    setLoading(true)
    getCatalogues()
      .then((rolesRequest) => {
        if (rolesRequest?.status === 200) {
          if (rolesRequest?.data !== undefined) {
            setDataCatalogues(rolesRequest?.data)
            setLoading(false)
          }
        } else {
          setLoading(false)
        }
      })
      .catch((e) => {
        setLoading(false)
      })
  }

  const showRegisterModal = () => {
    setOpenModal(true)
    setIsEdition(false)
  }

  const toggleShowModal = (val: boolean) => {
    setOpenModal(val)
  }

  const showEditModal = (catalogueId: number | undefined) => {
    setOpenModal(true)
    setIsEdition(true)
    setCatalogueId(catalogueId)
  }

  const addDataCatalogue = (newData: ModeleCatalogue) => {
    let newDataRole = [...dataCatalogues, newData]
    setDataCatalogues(newDataRole)
  }

  const updateDataCatalogue = (role: ModeleCatalogue | null) => {
    if (role != null) {
      console.log(role)
      const roles: ModeleCatalogue[] = [...dataCatalogues]
      const updatedRoles = roles.map((r) => {
        return r.id === role.id ? role : r
      })
      setDataCatalogues(updatedRoles)
    }
  }

  const deleteCatal = (idCatalogue) => {
    Swal.fire({
      title: 'Attention',
      text: 'Merci de confirmer la suppression',
      icon: 'warning',
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
      showCancelButton: true,
      confirmButtonColor: '#1BC5BD',
      cancelButtonColor: '#F64E60',
    }).then((alertResult) => {
      if (alertResult.isConfirmed) {
        setLoading(true)
        deleteCatalogue(idCatalogue)
          .then((response) => {
            if (response?.status === 200) {
              
              const newDataCatalogues = dataCatalogues.filter(f => f.id !== idCatalogue);
              setDataCatalogues(newDataCatalogues)
              setLoading(false)
              //getData()
             

            }
          })
          .catch((e) => {
            setLoading(false)
          })
      }
    })
  }

  return (
    <>
      {/* begin::Row  */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col  */}
        <div className='col-xxl-12'>
          <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Gestion des catalogues</span>
              </h3>
              {shouldDisplayFonctionnalite(currentUser, 'AJOUTER CATALOGUE') &&
              <div
                className='card-toolbar'
                Catalogue-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to add a user'
              >
                <button className='btn btn-sm btn-light-primary' onClick={showRegisterModal}>
                  <KTIcon iconName='plus' className='fs-3'/>
                  Ajouter un catalogue
                </button>
                {/* <CatalogueAddPage isOpen={isModalOpen} onClose={closeModal} /> */}
              </div>
              }
            </div>
            {loading ? (
              <LoadingData />
            ) : (
              <>
              {shouldDisplayFonctionnalite(currentUser, 'VOIR CATALOGUE') &&
              <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>Nom</th>
                        <th className='min-w-140px'>Token FTP</th>
                        <th className='min-w-100px text-end'>Actions</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {dataCatalogues.map((catalogue) => {
                        return (
                          <tr key={catalogue.id}>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {catalogue.nom}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {catalogue.token}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex justify-content-end flex-shrink-0'>
                                {shouldDisplayFonctionnalite(currentUser, 'MODIFIER CATALOGUE') &&
                                <Link
                                  to='#'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  onClick={() => showEditModal(catalogue.id)}
                                >
                                  <i className='bi bi-pencil fs-5'></i>
                                </Link>
                                }
                                {shouldDisplayFonctionnalite(currentUser, 'SUPPRIMER CATALOGUE') &&
                                <p
                                  onClick={() => deleteCatal(catalogue.id)}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                  <i className='bi bi-trash fs-5'></i>
                                </p>
                                }
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
              }
              </>
            )}
          </div>
        </div>
      </div>

      {!isEdition && openModal && (
        <CatalogueModal
          show={openModal}
          catalogueId={undefined}
          isEdition={isEdition}
          showModal={toggleShowModal}
          addDataCatalogue={addDataCatalogue}
          updateDataCatalogue=''
          loading={setLoading}
        />
      )}

      {isEdition && openModal && (
        <CatalogueModal
          show={openModal}
          catalogueId={catalogueId}
          isEdition={isEdition}
          showModal={toggleShowModal}
          updateDataCatalogue={updateDataCatalogue}
          addDataCatalogue=''
          loading={setLoading}
        />
      )}
    </>
  )
}

export {CataloguesListPage}
