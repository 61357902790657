import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestResetPassword} from '../core/_requests'
import { useSearchParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';



const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, 'Minimum 6 caractères.')
        .required('Mot de passe requis.'),
    passwordConfirmation: Yup.string()
        .required('Mot de passe requis.')
        .oneOf([Yup.ref('password')], 'Les deux mots de passe doivent correspondre.')
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get('token');
  const formik = useFormik({
    initialValues: {password:"",passwordConfirmation:""},
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
        setHasErrors(undefined)
      setTimeout(() => {
        requestResetPassword(values.password,token)
          .then(data => {
              if(data.data){
                  setHasErrors(false)
                  setTimeout(() => {
                      navigate(`/auth/login`)
                  }, 2000);
              }else{
                  setHasErrors(true)
              }
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <div className='card custom-card p-8'>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='fw-bolder mb-3 gugi-font'>Réinitialiser le mot de passe</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            Veuillez entrer le nouveau mot de passe.
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              La validité de votre token a dépassé 24h , veuillez regenerer à nouveau
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Le mot de passe a été modifié avec succes</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mot de passe</label>
            <input
                type='password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                    'form-control',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                )}
            />
            {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
            )}
          </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Confirmer le mot de passe</label>
            <input
                type='password'
                autoComplete='off'
                {...formik.getFieldProps('passwordConfirmation')}
                className={clsx(
                    'form-control',
                    {
                      'is-invalid': formik.touched.passwordConfirmation && formik.errors.passwordConfirmation,
                    },
                    {
                      'is-valid': formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
                    }
                )}
            />
            {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.passwordConfirmation}</span>
                  </div>
                </div>
            )}
          </div>
        {/* end::Form group */}

        {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button type='submit' className='btn btn-primary me-4' id='kt_password_reset_submit' disabled={loading}>
                  {!loading && 'Valider'}
                  {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                    Veuillez patienter...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                  )}
              </button>

              <Link to='/auth/login'>
                  <button
                      type='button'
                      id='kt_login_password_reset_form_cancel_button'
                      className='btn btn-light'
                      disabled={formik.isSubmitting || !formik.isValid}
                  >
                      Annuler
                  </button>
              </Link>{' '}
          </div>
          {/* end::Form group */}
      </form>
    </div>
  )
}
