import * as Yup from 'yup'

export const handleValidation = Yup.object().shape({
  hote: Yup.string().required("Le nom de l'hôte est requis"),
  identifiant: Yup.string().required("L'identifiant est requis"),
  password: Yup.string().required('Le mot de passe est requis'),
  source: Yup.string().required('La source est requise'),
  port: Yup.number()
    .min(0, 'La valeur doit être supérieure ou égale à 0')
    .required('Le numéro de port est requis'),
})
