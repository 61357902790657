import {Link} from 'react-router-dom'

const DispoPiece = () => {
  return (
    <>
      <div className='title mb-10 fs-2 bold'>Disponibilité de la pièce demandée</div>
      <div className='d-flex justify-content-between'>
        <div className='d-flex gap-10'>
          <div>
            <img src='https://placehold.co/200x200' className="rounded"/>
            <div className='mt-5 text-center'>00000000</div>
          </div>
          <div className="d-flex flex-column justify-content-evenly gap-20">
            <div>
              <div className='fs-3 mb-8'>Pédale de frein</div>
              <div>Peugeot 308</div>
            </div>
            <div>
              <div className="d-flex gap-5">
                <Link to={`pieceDetail/detail/1`} className="border-right">07 en stock</Link>
                <Link to={`pieceDetail/surLeMarche/1`}>08 sur le marché</Link>
              </div>
            </div>
          </div>
        </div>
        <div>150.92$</div>
      </div>
    </>
  )
}

export {DispoPiece}
