/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import '../../../_metronic/assets/css/login.css';

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div>
      <div className='d-flex justify-content-end'>
        <svg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 310 310' fill='none'>
          <path
            d='M310 309.2C275.6 309.7 241.3 310.1 214.4 294.1C187.6 278 168.4 245.4 150.1 220.1C131.9 194.7 114.6 176.5 94.0001 156.9C73.4001 137.3 49.3001 116.3 33.2001 89.9C17.1001 63.5 9.00005 31.8 0.800049 0H310V309.2Z'
            fill='#9AC331'
          />
        </svg>
      </div>
      <div className='position-absolute top-50 start-50 translate-middle w-lg-25 w-md-50 w-sm-100 w-spec-auth'>
        <Outlet />
      </div>
      <div className='bottom-icon'>
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 310 310" fill="none">
          <path
            d="M0 0.799988C28.8 14.3 57.6 27.8 88.7 37C119.8 46.3 153.3 51.4 174 70.5C194.7 89.7 202.6 123 223.3 147.8C244 172.6 277.6 188.8 294.1 214.4C310.5 240.1 309.9 275 309.2 310H0V0.799988Z"
            fill="#9AC331" />
        </svg>
      </div>
    </div>
  )
}

export {AuthLayout}