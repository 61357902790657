import {DemandeStatut} from './Televendeur/DemandeStatut'
import React, {useState} from 'react'
import {ModeleImmatriculation} from '../../../../components/Models'
import {getCatdata} from '../../../../components/requests'
import VehicleData from './VehicleData'
import { Tooltip } from 'react-tooltip'


const VehicleInfo = (props) => {
  const {data, tempsEcoule, immatsArray, setAssign, clientId, redirectToClientDemande} = props
  const [immatriculation, setImmatriculation] = useState<ModeleImmatriculation>({})
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({})
  const [immatriculationValide, setImmatriculationValide] = useState<boolean>(false)

  const verifyCatdata = async (immat: string) => {
    if (!immat) return;

    setLoading((prev) => ({ ...prev, [immat]: true }));

    try {
      const immatriculationRequest = await getCatdata(immat);
      if (immatriculationRequest?.status === 200 && immatriculationRequest?.data) {
        setImmatriculation(immatriculationRequest.data);
        setImmatriculationValide(true);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading((prev) => ({ ...prev, [immat]: false }));
    }
  };

  const renderFormRowImmat = () => {
    return immatsArray?.map((object, index) => {
      return (
        <tr key={index}>
          <td>
            <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>{object.value}</p>
          </td>
          <td>{object.centreLabel}</td>
          <td>
            <div className='d-flex gap-3'>
              {loading[object.value] ? (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              ) : (
                <i className='bi bi-eye fs-2 ms-2'
                   onClick={() => verifyCatdata(object.value)}
                   style={{ cursor: 'pointer'}}
                   data-tooltip-id="tooltip"
                   data-tooltip-content="Détail de l'immatriculation"
                   data-tooltip-place="right"
                ></i>
              )}
            </div>
          </td>
        </tr>
      )
    })
  }

  return (
    <>
      <div className='row'>
        <div className='col-lg-4'>
          <DemandeStatut data={data} tempsEcoule={tempsEcoule} setIsAssign={setAssign} clientId={clientId} redirectToClientDemande={redirectToClientDemande}/>
        </div>
        <div className='col-lg-8'>
          {!immatriculationValide ? (
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Immatriculation</th>
                  <th className='min-w-150px'>Centre</th>
                  <th className='min-w-150px'>Actions</th>
                </tr>
                </thead>
                <tbody>{renderFormRowImmat()}</tbody>
              </table>
            </div>
          ) : (
            <>
              <VehicleData immatriculation={immatriculation} setImmatriculationValide={setImmatriculationValide} prevArrow={true}/>
            </>
          )}
        </div>
      </div>
      {/*Affichage du tooltip*/}
      <Tooltip id="tooltip" />
    </>
  )
}
export {VehicleInfo}