import {Modal,Card} from 'react-bootstrap';
import {KTIcon} from '../../../_metronic/helpers'
import {CatalogueRegisterForm} from './components/CatalogueRegisterForm';
import {CatalogueEditForm} from './components/CatalogueEditForm';



type Props = {
  show: boolean,
  isEdition: boolean,
  catalogueId?:Number|undefined
  showModal: () => void,
  handleClose: () => void,
  addDataCatalogue?:() => void,
  updateDataCatalogue?:() => void,
  loading:() => void
}


const CatalogueModal = ({ show,isEdition,catalogueId,showModal,addDataCatalogue,updateDataCatalogue,loading }) => {

 return (
    <>
        <Modal
            size='xl'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered'
            show={show}
        >
           
            <div className='modal-content shadow-none'>
           
                <div className='modal-header d-flex justify-content-end border-0 p-3'>
                    <div className='btn btn-icon btn-sm btn-light-primary ms-2' onClick={()=>showModal(false)}>
                        <KTIcon className='fs-2' iconName='cross' />
                    </div>
                    
                </div>
                <div className='modal-body pt-0'>
                    <Card.Header className="text-center text-md-center border-0 mb-0 mt-md-0 mb-15">
                    <h2>{isEdition ? 'Modifier un catalogue' :'Ajouter un catalogue'}</h2>
                    </Card.Header>
                    {!isEdition && (<CatalogueRegisterForm showModalProp={showModal} addDataCatalogue={addDataCatalogue}  setLoadingProp={loading}/>)}
                    {isEdition && (<CatalogueEditForm catalogueId={catalogueId} showModalProp={showModal} updateDataCatalogue={updateDataCatalogue}  setLoadingProp={loading}/>)}

                </div>
            </div>
      </Modal>
    </>
  )
}
export {CatalogueModal}