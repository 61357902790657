import {useEffect, useState} from 'react'

const Carousel = ({loading, imagesArray, nomPiece}) => {
  const [img, setImg] = useState<any>([])
  const [load, setLoad] = useState<boolean>(loading)

  useEffect(() => {
    setLoad(true)
    if (imagesArray) {
      setImg(imagesArray)

      // pour réinitialiser l'indicateur du carrousel
      setTimeout(() => {
        if (imagesArray?.length > 0) {
          setLoad(false)
        }
      }, 1)
    }
  }, [imagesArray])

  // affichage des images
  const renderImage = () => {
    return (
      <div className='carousel-inner pt-8'>
        { load || img?.length == 0 ? (
          <div className='carousel-item active'>
            <img src='https://placehold.co/600x400' className='rounded' alt=''/>
          </div>
        ) : (
        img?.map((image, i) => (
            <div className={`carousel-item ${i === 0  ? 'active' : ''}`} key={i}>
              <img
                src={`https://koena.fr/photos/pieces/` + image}
                className='rounded'
                width={600}
                height={400}
                alt={nomPiece ?? ''}
              />
            </div>
          ))
        )}
      </div>
    )
  }

  // affichage des indicateurs correspondants
  const renderDotIndicators = () => {
    return (
      <div className='d-flex align-items-center justify-content-between flex-wrap'>
        <ol className='p-0 m-0 carousel-indicators carousel-indicators-dots'>
          {img?.map((image, i) =>
            !load && (
              <li
                data-bs-target='#kt_carousel_1_carousel'
                data-bs-slide-to={i}
                key={i}
                className={i === 0  ? 'active' : ''}
              ></li>
            )
          )}
        </ol>
      </div>
    )
  }

  return (
    <div>
      <div
        id='kt_carousel_1_carousel'
        className='carousel carousel-custom slide'
        data-bs-ride='carousel'
        data-bs-interval='5000'
      >
        {renderImage()}
        <div className='d-flex justify-content-center'>{renderDotIndicators()}</div>
      </div>
    </div>
  )
}

export default Carousel;
