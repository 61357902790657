import React, {useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {TextInput} from '../../../../../components/inputs/TextInput'
import {ModeleClient} from '../../../../../components/Models'
import {SelectInput} from '../../../../../components/inputs/SelectInput'
import {pays} from '../../../../../components/Data'
import {useNavigate} from 'react-router-dom'
import {getCompanyBySiret} from '../../../../../components/requests'
import {toast, ToastContainer} from 'react-toastify'

let yopSiret = Yup.string()
  .matches(/^\d{14}$/, 'Le SIRET doit contenir exactement 14 chiffres')
  .required('Le SIRET est requis');

const siretSchema = Yup.object({
  siret: yopSiret, // Pas besoin de `.shape()` ici
});


let clientSchemaSociety = Yup.object().shape({
  professionnel: Yup.string().default('1'),
  nomSociete: Yup.string().required('Le nom de la société est requis'),
  nom: Yup.string().required('Le nom est requis'),
  prenom: Yup.string().required('Le prénom est requis'),
  adresse: Yup.string().required("L'adresse est requise"),
  complementAdresse: Yup.string().notRequired(),
  codePostal: Yup.string().required('Le code postal est requis'),
  ville: Yup.string().required('La ville est requise'),
  siren: yopSiret,
  codeNaf: Yup.string().required('Le code naf est requis'),
  libelleCodeNaf: Yup.string().required('Le libelle code naf est requis'),
  telephone: Yup.string().when(['mobile'], {
    is: (mobile) => !mobile || mobile.length < 1,
    then: () =>
      Yup.string()
        .required('Numéro de fixe ou mobile requis')
        .matches(
          /^(?:\+33|33|0)[\s.-]*[1-9](?:[\s.-]?\d{2}){4}$/,
          'Format du numéro de téléphone invalide'
        ),
    otherwise: () => Yup.string().notRequired()
        .matches(
            /^(?:\+33|33|0)[\s.-]*[1-9](?:[\s.-]?\d{2}){4}$/,
            'Format du numéro de téléphone invalide'
        ),
  }),
  mobile: Yup.string()
    .notRequired()
    .matches(/^(?:\+33|33|0)[\s.-]*[1-9](?:[\s.-]?\d{2}){4}$/, 'Format du mobile invalide'),
  email: Yup.string()
    .required("L'adresse mail est requise")
    .email("L'adresse mail n'est pas valide"),
  siteWeb: Yup.string().notRequired(),
})

let clientSchemaParticular = Yup.object().shape({
  professionnel: Yup.string().default('1'),
  nomSociete: Yup.string().when(['professionnel'], {
    is: (professionnel) =>
      professionnel === true ? true : Boolean(parseInt(professionnel.toString())),
    then: () => Yup.string().required('Le nom de la société est requis'),
    otherwise: () => Yup.string().notRequired(),
  }),
  codeNaf: Yup.string().notRequired(),
  libelleCodeNaf: Yup.string().notRequired(),
  nom: Yup.string().required('Le nom est requise'),
  prenom: Yup.string().required('Le prénom est requise'),
  adresse: Yup.string().required("L'adresse est requise"),
  complementAdresse: Yup.string().notRequired(),
  codePostal: Yup.string().required('Le code postal est requis'),
  ville: Yup.string().required('La ville est requise'),
  siren: Yup.string().notRequired(),
  telephone: Yup.string().when(['mobile'], {
    is: (mobile) => !mobile || mobile.length < 1,
    then: () =>
      Yup.string()
        .required('Numéro de fixe ou mobile requis')
        .matches(
          /^(?:\+33|33|0)[\s.-]*[1-9](?:[\s.-]?\d{2}){4}$/,
          'Format du numéro de téléphone invalide'
        ),
    otherwise: () => Yup.string().notRequired()
        .matches(/^(?:\+33|33|0)[\s.-]*[1-9](?:[\s.-]?\d{2}){4}$/, 'Format du mobile invalide'),
  }),
  mobile: Yup.string()
    .notRequired()
    .matches(/^(?:\+33|33|0)[\s.-]*[1-9](?:[\s.-]?\d{2}){4}$/, 'Format du mobile invalide'),
  email: Yup.string()
    .required("L'adresse mail est requise")
    .email("L'adresse mail n'est pas valide"),
  siteWeb: Yup.string().notRequired(),
})

const ClientForm = (props) => {
  //initilisation model client proprieté api
  let initialData = {}
  const size = Object.keys(props.data).length
  if (size > 2) {
    initialData = props.data
  } else {
    const obj: ModeleClient = {
      siren: '',
      nomSociete: '',
      nom: '',
      prenom: '',
      adresse: '',
      complementAdresse: '',
      codePostal: '',
      ville: '',
      telephone: '',
      mobile: '',
      email: '',
      siteWeb: '',
      codeNaf: '',
      libelleCodeNaf: '',
    }
    initialData = {...props.data, ...obj}
  }

  const navigate = useNavigate()
  const [clientId, setClientId] = useState(props.clientId)
  const [data, setData] = useState<ModeleClient>(initialData)
  const [loading, setLoading] = useState(false)
  const [loadingSiret, setLoadingSiret] = useState(false)
  const [professional, setProfessional] = useState(props.data?.professionnel)
  const [useModal, setUseModal] = useState(false)
  const modalCreateCliRef = useRef<HTMLSpanElement | null>(null)
  const [modalExists, setModalExists] = useState(false)

  const formik = useFormik<ModeleClient>({
    initialValues: data,
    validationSchema: () =>
      Yup.lazy(() => {
        if (professional) {
          return clientSchemaSociety
        }
        return clientSchemaParticular
      }),

    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign({...data}, values)

        props.submitForm(updatedData, response => {
          formik.resetForm()
          setLoading(false)
        })
      }, 200)
    },
  })

  // déclenchement du modal de recherche de véhicule après la création du client
  useEffect(() => {
    if (props.openModalResVeh) {
      modalCreateCliRef.current?.click()
    }
  }, [props?.openModalResVeh])

  const isProfessionel = (value) => {
    if (value === false || value === true) {
      return value
    }
    return Boolean(parseInt(value.toString()))
  }

  // evènemment search siret
  const searchCustomerBySiren = async () => {
    const siren = formik.values.siren
    try {
      await siretSchema.validate({siret: siren})
      if(!siren) return false;
      setLoadingSiret(true)
      try {
        const data = await getCompanyBySiret(siren)
        if (data) {
          const dataSiret = {
            siren: siren,
            nom: data[0]['nom'] ?? props.data.nom,
            prenom: data[0]['prenom'] ?? props.data.prenom,
            nomSociete: data[0]['nomSociete'],
            adresse: data[0]['adresse'],
            complementAdresse: data[0]['complementAdresse'],
            codePostal: data[0]['codePostal'],
            ville: data[0]['ville'],
            pays: data[0]['pays'],
            codeNaf: data[0]['codeNaf'],
            libelleCodeNaf: data[0]['libelleCodeNaf'],
            professionnel: true,
            idPays: data[0]['idPays'],
            telephone: formik.values.telephone,
            mobile: formik.values.mobile,
            email: formik.values.email,
            siteWeb: formik.values.siteWeb,
          }

          formik.setValues(dataSiret)

          setLoadingSiret(false)
        } else {
          formik.setValues(initialData)
          console.log('Aucune donnée trouvée pour ce SIRET.')
        }
      } catch (error) {
        props.data.siren && formik.setFieldValue("siren", props.data.siren);
        toast.error('Le siret n\'est pas valide', {autoClose: 4000})
        console.error('Erreur lors de la récupération des données :', error)
        setLoadingSiret(false)
      }
    } catch (errSiret) {
    }
  }

  useEffect(() => {
    // Vérifie si une modal avec l'ID "clientCreatedModal" est présente dans le DOM
    const modal = document.getElementById('ModalClientCreate')
    setModalExists(!!modal)
    setUseModal(props?.isCreateViaModal ?? false)
  }, [])

  // pour déterminer si c'est lors de la création de demande ou pas
  useEffect(() => {
    setUseModal(props?.isCreateViaModal ?? false)
  }, [props])

  return (
    <div>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Type de client</label>

            <div className='col-lg-8 fv-row'>
              <div className='d-flex align-items-center mt-3'>
                <label className='form-check form-check-inline form-check-solid me-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value={0}
                    name={'professionnel'}
                    onChange={formik.handleChange}
                    defaultChecked={!formik.values.professionnel}
                    onClick={() => setProfessional(false)}
                  />
                  <span className='fw-bold ps-2 fs-6'>Particulier</span>
                </label>

                <label className='form-check form-check-inline form-check-solid'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value={1}
                    name={'professionnel'}
                    onChange={formik.handleChange}
                    defaultChecked={formik.values.professionnel}
                    onClick={() => setProfessional(true)}
                  />
                  <span className='fw-bold ps-2 fs-6'>Société</span>
                </label>
              </div>
            </div>
          </div>

          <div
            className='row mb-6'
            style={!isProfessionel(formik.values.professionnel) ? {display: 'none'} : {}}
          >
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Siret</label>

            <div className='col-lg-4 fv-row'>
              <TextInput
                fieldName={'siren'}
                inputPlaceholder={'SIRET'}
                inputType={'text'}
                inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                formik={formik}
              />
            </div>
            <div className='col-lg-4 fv-row'>
              <button
                type='button'
                className='btn btn-primary'
                disabled={loadingSiret}
                onClick={() => searchCustomerBySiren()}
              >
                {!loadingSiret && 'Rechercher'}
                {loadingSiret && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Recherche...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>

          <div
            className='row mb-6'
            style={!isProfessionel(formik.values.professionnel) ? {display: 'none'} : {}}
          >
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Code naf</label>

            <div className='col-lg-8'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'codeNaf'}
                    inputPlaceholder={'Code naf'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>

                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'libelleCodeNaf'}
                    inputPlaceholder={'Libelle code naf'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nom complet</label>

            <div className='col-lg-8'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'nom'}
                    inputPlaceholder={'Nom'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>

                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'prenom'}
                    inputPlaceholder={'Prenom'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className='row mb-6'
            style={!isProfessionel(formik.values.professionnel) ? {display: 'none'} : {}}
          >
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
              Nom de la société
            </label>

            <div className='col-lg-8 fv-row'>
              <TextInput
                fieldName={'nomSociete'}
                inputPlaceholder={'Société'}
                inputType={'text'}
                inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                formik={formik}
              />
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
              Adresse principale
            </label>

            <div className='col-lg-8'>
              <div className='row mb-4'>
                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'adresse'}
                    inputPlaceholder={'Adresse'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>

                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'complementAdresse'}
                    inputPlaceholder={"Complément d'adresse"}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'codePostal'}
                    inputPlaceholder={'Code postal'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>

                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'ville'}
                    inputPlaceholder={'Ville'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <SelectInput
                    fieldName={'idPays'}
                    inputClassName={'form-select form-select-solid form-select-lg fw-bold'}
                    options={pays}
                    formik={formik}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Téléphone</label>

            <div className='col-lg-8'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'telephone'}
                    inputPlaceholder={'Fixe'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>

                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'mobile'}
                    inputPlaceholder={'Mobile'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

            <div className='col-lg-8 fv-row'>
              <TextInput
                fieldName={'email'}
                inputPlaceholder={'Email'}
                inputType={'text'}
                inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                formik={formik}
              />
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Site web</label>

            <div className='col-lg-8 fv-row'>
              <TextInput
                fieldName={'siteWeb'}
                inputPlaceholder={'Site web'}
                inputType={'text'}
                inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                formik={formik}
              />
            </div>
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          {clientId !== null && (
            <button
              type='button'
              className='btn btn-light me-3'
              disabled={loading}
              onClick={() => {
                formik.resetForm()
                navigate(`/vente/clients/${clientId}/afficher`)
              }}
            >
              Annuler les modifications
            </button>
          )}
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Enregistrer'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Enregistrement...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>

          {useModal && (
            <>
              <span
                ref={modalCreateCliRef}
                data-bs-target='#exampleModalSearchV'
                data-bs-toggle='modal'
              ></span>
              <button className='btn btn-light ml-5' data-bs-dismiss='modal' type='button'>
                Fermer
              </button>
            </>
          )}
        </div>
      </form>
      <ToastContainer position='bottom-right' autoClose={3000} />
    </div>
  )
}

export {ClientForm}
