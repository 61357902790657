import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {ModeleClient, modeleClientInitValues} from "../../../../../components/Models";
import {ClientForm} from "../components/ClientForm";
import {LoadingData} from "../../../../../components/LoadingData";
import {editClient, getClient} from "../../../../../components/requests";

const ClientEditPage = () => {
    const navigate = useNavigate();
    let { clientId } = useParams();
    const [data, setData] = useState<ModeleClient>(modeleClientInitValues);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getClientData()
    }, [clientId]);

    const getClientData = () => {
        getClient(clientId).then(clientRequest => {
            if (clientRequest?.status === 200) {
                if (clientRequest?.data?.id !== undefined && clientRequest?.data?.id !== null) {
                    const updatedData = Object.assign({...data}, clientRequest?.data)
                    if (updatedData.pays?.id !== undefined) {
                        updatedData.idPays = updatedData.pays?.id
                    }
                    if (updatedData.naf?.id !== undefined) {
                        updatedData.codeNaf = updatedData.naf?.code
                        updatedData.libelleCodeNaf = updatedData.naf?.libelle
                    }
                    setData({...updatedData})
                }
                setLoading(false);
            } else {
                navigate(`/vente/clients/liste`);
            }
        });
    }

    const submitForm = async (fieldsToUpdate: Partial<ModeleClient>, callback): Promise<void> => {
        const updatedData = Object.assign({...data}, fieldsToUpdate)

        var requestOk = false;
        try {
            const clientRequest = await editClient(clientId, updatedData);
            if (clientRequest?.status === 200) {
                requestOk = true;
            }
        } catch (ex) {
            console.error(ex)
        } finally {
            callback();
            if (requestOk) {
                setData({...updatedData})
                navigate(`/vente/clients/${clientId}/afficher`);
            }
        }
    }

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Modifier le client</h3>
                </div>
            </div>

            <div>
                {loading
                    ?
                    <LoadingData />
                    :
                    <ClientForm clientId={clientId} data={data} submitForm={submitForm}/>
                }
            </div>
        </div>
    )
}

export {ClientEditPage}
