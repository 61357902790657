import * as yup from "yup";
export const handleValidation = yup.object().shape({
    nom:yup.string().required("Ce champs est requis."),
    token:yup.string().required("Ce champs est requis."),
    mail: yup.string().email("L'adresse mail n'est pas valide"),
    regles: yup.array().of(
        yup.array().of(
      yup.object().shape({
        c: yup.string().required("Ce champs est requis."),
        o: yup.string().required("Ce champs est requis."),
        v: yup.string().required("Ce champs est requis.")
      })

    )
    )
  });
  