import React, {useEffect, useState} from 'react'
import {getClients} from '../../../components/requests'
import {Tooltip} from 'react-tooltip'

interface ModeleClient {
  id: number
  nom: string
  prenom: string
}

const SearchBar = (props, {placeholder}: {placeholder: string}) => {
  const [searchTerm, setSearchTerm] = useState<string>('') // Texte de recherche
  const [filteredData, setFilteredData] = useState<ModeleClient[]>([]) // Données filtrées
  const [clientFound, setClientFound] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(true)
  const {close, setClose, setClientId} = props

  // Désactivation bouton de rechercher client
  useEffect(() => {
    searchTerm.length != 0 ? setDisable(false) : setDisable(true)
  }, [searchTerm])

  // Gestion de la recherche
  const handleSearch = () => {
    setClose(false)
    if (searchTerm.length != 0) {
      setLoading(true)
      getClients(1, searchTerm).then((res) => {
        setLoading(false)
        if (res.data.length != 0) {
          setFilteredData(res.data)
          setClientFound(false)
        } else {
          setClientFound(true)
          setFilteredData([])
        }
      })
    }
  }

  return (
    <>
      <div>
        <div className='d-flex gap-2'>
          <input
            type='text'
            placeholder={placeholder || 'Rechercher...'}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className='form-control form-control-solid'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch()
              }
            }}
          />

          <button
            type='button'
            className={`btn btn-light border-0 input-group-append h-100 ${
              disable ? 'disabled' : ''
            }`}
            onClick={() => handleSearch()}
            disabled={disable}
          >
            {loading ? (
              <span className='spinner-border spinner-border-sm align-middle ms-2 w-20px h-20px'></span>
            ) : (
              <i className='fas fa-search text-secondary fs-1'></i>
            )}
          </button>
        </div>

        {!close && clientFound && (
          <h6 className='alert alert-danger d-flex justify-content-center mt-10'>
            Aucun résultat trouvé.
          </h6>
        )}

        {!close && filteredData.length > 0 && (
          <ul style={{listStyleType: 'none', padding: 0, marginTop: '10px'}}>
            {filteredData.map((client) => (
              <li key={client.id} style={{padding: '5px 0'}}>
                <div className='d-flex justify-content-between align-items-center'>
                  <strong>{client.nom}</strong>
                  <div>{client.prenom}</div>
                  <span
                    onClick={() => setClientId(client.id)}
                    className='text-primary'
                    style={{cursor: 'pointer'}}
                  >
                    <i
                      className='bi-file-earmark-plus fs-2x'
                      data-bs-target='#exampleModalSearchV'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                      data-tooltip-id='modal-tooltip'
                      data-tooltip-content='Effectuer une demande'
                      data-tooltip-place='top'
                    ></i>
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      {/*Affichage du tooltip*/}
      <Tooltip id='modal-tooltip' />
    </>
  )
}

export default SearchBar
