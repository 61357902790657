import React, {useEffect, useRef, useState} from 'react'
import {Button, Col, Form, Row} from 'react-bootstrap'
import SelectInput from './SelectInput'
import {ErrorMessage, Field, FieldArray, Form as FormMk, Formik} from 'formik'
import {handleValidation} from './Validation'
import {Link} from 'react-router-dom'
import {
  availablesCriterias,
  availablesOperators,
  civiliteCataloques,
  formatCataloques,
} from './../data'
import {ModeleCatalogue} from '../../../../components/Models'
import {editCatalogue, getCatalogue} from '../../../../components/requests'
import {LoadingData} from '../../../../components/LoadingData'
import {KTIcon} from '../../../../_metronic/helpers'

const CatalogueEditForm = (props) => {
  const initialCatal = {
    id: 0,
    nom: '',
    token: '',
    identiteNom: '',
    identitePrenom: '',
    raisonSociale: '',
    civilite: '',
    siret: '',
    telephone1: '',
    telephone2: '',
    mail: '',
    tvaIntraCommunautaire: '',
    adresseRaisonSociale: '',
    adresseNom: '',
    adressePrenom: '',
    adresseNumVoie: '',
    adresseNomVoie: '',
    adresseTypeVoie: '',
    adresseCodePostal: '',
    adresseVille: '',
    adressePays: '',
    adresseTelephone: '',
    codeClientProgiciel: '',
    tauxRemiseCatalogue: 0,
    formatCatalogue: '',
    regles: [
      [
        {
          c: '',
          o: '',
          v: '',
        },
      ],
    ],
  }
  const formRef = useRef<HTMLFormElement | null>(null)
  const {catalogueId, showModalProp, updateDataCatalogue, setLoadingProp} = props
  const [initialValues, setInitialValues] = useState<ModeleCatalogue>(initialCatal)
  const [loadingModal, setLoadingModal] = useState(true)

  useEffect(() => {
    setLoadingModal(true)
    getCatalogue(catalogueId)
      .then((catalogueRequest) => {
        if (catalogueRequest?.status === 200) {
          if (catalogueRequest?.data !== undefined) {
            const catalogueEdited: ModeleCatalogue = catalogueRequest?.data
            console.log(catalogueEdited)
            setInitialValues(catalogueEdited)
            setLoadingModal(false)
          } else {
            setLoadingModal(false)
          }
        } else {
          setLoadingModal(false)
        }
      })
      .catch((e) => {
        setLoadingModal(false)
      })
  }, [catalogueId])

  const removeFromList = (i, values, setValues) => {
    const regles = [...values.regles]
    regles.splice(i, 1)
    setValues({...values, regles})
  }

  const removeChildFromList = (i, index, childRegle, values, setValues) => {
    const regles = [...values.regles]
    regles[i].splice(index, 1)
    setValues({...values, regles})
  }

  const updateForm = (values, setValues) => {
    // update dynamic form
    let regles = [...values.regles]
    regles.push([
      {
        c: '',
        o: '',
        v: '',
      },
    ])
    setValues({...values, regles})
  }

  const updateChildForm = (index, childRegle, values, setValues) => {
    const regles = [...values.regles]
    const c = regles[index][0].c
    if (c === '') {
      return
    }
    regles[index].push({
      c: c !== '' ? c : 'none',
      o: '',
      v: '',
    })

    setValues({...values, regles})
  }

  const handleSubmit = (values) => {
    showModalProp(false)
    setLoadingProp(true)
    editCatalogue(catalogueId, values)
      .then((roleRequest) => {
        if (roleRequest?.status === 200) {
          if (roleRequest?.data !== undefined) {
            updateDataCatalogue(roleRequest.data)
            setLoadingProp(false)
          } else {
            setLoadingProp(false)
          }
        } else {
          setLoadingProp(false)
        }
      })
      .catch((e) => {
        setLoadingProp(false)
      })
  }

  const handleValidate = (values) => {
    if (!values.nom || !values.token) {
      if (formRef.current) {
        formRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
      }
    }
  }

  return (
    <div className='App'>
      {loadingModal ? (
        <LoadingData />
      ) : (
        <Formik
          validationSchema={handleValidation}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setValues}) => (
            <FormMk ref={formRef}>
              <h3 className='d-flex align-items-center mb-3'>
                <span className='text-gray-600 me-6 pr-3'>Paramètres du Catalogue</span>
                <span className='separator border-secondary my-4 flex-grow-1'></span>
              </h3>
              <Row>
                <Col md={6}>
                  <Form.Group id='nom' className='mb-4'>
                    <Form.Label className='required'>Nom :</Form.Label>
                    <Field name='nom' className='form-control' />
                    <ErrorMessage component='div' name='nom' className='errorMessage' />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group id='token' className='mb-4'>
                    <Form.Label className='required'>Token FTP :</Form.Label>
                    <Field name='token' className='form-control' />
                    <ErrorMessage component='div' name='token' className='errorMessage' />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group id='tauxRemiseCatalogue' className='mb-4'>
                    <Form.Label>Taux remise catalogue :</Form.Label>
                    <div className='d-flex align-items-center justify-content-center gap-3'>
                      <Field
                        type='number'
                        min='0'
                        step='0.01'
                        name='tauxRemiseCatalogue'
                        className='form-control'
                      />
                      <span>%</span>
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Label>Format catalogue :</Form.Label>
                  <SelectInput
                    options={formatCataloques}
                    className='form-control'
                    name='formatCatalogue'
                  />
                </Col>
              </Row>
              <h3 className='d-flex align-items-center mb-3 mt-6'>
                <span className='text-gray-600 me-6 pr-3'>Informations Générales</span>
                <span className='separator border-secondary my-4 flex-grow-1'></span>
              </h3>
              <Row>
                <Col md={3}>
                  <Form.Group id='identiteNom' className='mb-4'>
                    <Form.Label>Nom Identité :</Form.Label>
                    <Field name='identiteNom' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='identitePrenom' className='mb-4'>
                    <Form.Label>Prénom Identité :</Form.Label>
                    <Field name='identitePrenom' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='raisonSociale' className='mb-4'>
                    <Form.Label>Raison Sociale :</Form.Label>
                    <Field name='raisonSociale' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='civilite' className='mb-4'>
                    <Form.Label>Civilité :</Form.Label>
                    <SelectInput
                      options={civiliteCataloques}
                      className='form-control'
                      name='civilite'
                      defaultActive='true'
                      noMarginBottom='true'
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group id='siret' className='mb-4'>
                    <Form.Label>Siret :</Form.Label>
                    <Field name='siret' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='telephone1' className='mb-4'>
                    <Form.Label>Téléphone 1 :</Form.Label>
                    <Field name='telephone1' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='telephone2' className='mb-4'>
                    <Form.Label>Téléphone 2 :</Form.Label>
                    <Field name='telephone2' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='mail' className='mb-4'>
                    <Form.Label>Mail :</Form.Label>
                    <Field name='mail' className='form-control' />
                    <ErrorMessage component='div' name='mail' className='errorMessage' />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group id='tvaIntraCommunautaire' className='mb-4'>
                    <Form.Label>TVA Intracommunautaire :</Form.Label>
                    <Field name='tvaIntraCommunautaire' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='adresseRaisonSociale' className='mb-4'>
                    <Form.Label>Adresse Raison Sociale :</Form.Label>
                    <Field name='adresseRaisonSociale' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='adresseNom' className='mb-4'>
                    <Form.Label>Adresse Nom :</Form.Label>
                    <Field name='adresseNom' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='adressePrenom' className='mb-4'>
                    <Form.Label>Adresse Prénom :</Form.Label>
                    <Field name='adressePrenom' className='form-control' />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group id='adresseNumVoie' className='mb-4'>
                    <Form.Label>Adresse Num Voie :</Form.Label>
                    <Field name='adresseNumVoie' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='adresseNomVoie' className='mb-4'>
                    <Form.Label>Adresse Nom Voie :</Form.Label>
                    <Field name='adresseNomVoie' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='adresseTypeVoie' className='mb-4'>
                    <Form.Label>Adresse Type Voie :</Form.Label>
                    <Field name='adresseTypeVoie' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='adresseCodePostal' className='mb-4'>
                    <Form.Label>Adresse Code Postal :</Form.Label>
                    <Field name='adresseCodePostal' className='form-control' />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='row mb-4'>
                <Col md={3}>
                  <Form.Group id='adresseVille' className='mb-4'>
                    <Form.Label>Adresse Ville :</Form.Label>
                    <Field name='adresseVille' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='adressePays' className='mb-4'>
                    <Form.Label>Adresse Pays :</Form.Label>
                    <Field name='adressePays' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='adresseTelephone' className='mb-4'>
                    <Form.Label>Adresse Téléphone :</Form.Label>
                    <Field name='adresseTelephone' className='form-control' />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group id='codeClientProgiciel' className='mb-4'>
                    <Form.Label>Code Client Progiciel :</Form.Label>
                    <Field name='codeClientProgiciel' className='form-control' />
                  </Form.Group>
                </Col>
              </Row>
              <h3 className='d-flex align-items-center mb-3 mt-10'>
                <span className='text-gray-600 me-6 pr-3'>Règles</span>
                <span className='separator border-secondary my-4 flex-grow-1'></span>
              </h3>
              <Row className='mb-4'>
                <Col md={4}>
                  <Button variant='light-primary' onClick={(e) => updateForm(values, setValues)}>
                    <KTIcon iconName='plus' className='fs-3' />
                    Ajouter une règle
                  </Button>
                </Col>
              </Row>
              <FieldArray name='regles'>
                {() =>
                  values.regles.map((regle, i) => {
                    return (
                      <Row key={i}>
                        <Col md={1} style={{marginTop: '12px'}}>
                          {i === 0 && (
                            <Link
                              to='#'
                              style={{margin: '3px'}}
                              className='pointer'
                              onClick={() => updateChildForm(i, regle, values, setValues)}
                            >
                              <i className='fa fa-plus text-info' style={{fontSize: '20px'}}></i>
                            </Link>
                          )}
                          {values.regles.length > 1 && i !== 0 && (
                            <div>
                              <Link
                                to='#'
                                style={{margin: '3px'}}
                                className='pointer'
                                onClick={() => updateChildForm(i, regle, values, setValues)}
                              >
                                <i className='fa fa-plus text-info' style={{fontSize: '20px'}}></i>
                              </Link>
                              <Link
                                to='#'
                                className='pointer'
                                style={{margin: '3px'}}
                                onClick={() => removeFromList(i, values, setValues)}
                              >
                                <i className='fa fa-x text-danger' style={{fontSize: '16px'}}></i>
                              </Link>
                            </div>
                          )}
                        </Col>
                        {regle.map((item, index) => {
                          return (
                            <>
                              {index !== 0 && (
                                <Col
                                  md={index !== 0 ? 4 : 3}
                                  style={{marginTop: '12px', textAlign: 'right'}}
                                >
                                  <Link
                                    to='#'
                                    style={{margin: '3px'}}
                                    className='pointer'
                                    onClick={() =>
                                      removeChildFromList(i, index, item, values, setValues)
                                    }
                                  >
                                    <i
                                      className='fa fa-x text-danger'
                                      style={{fontSize: '16px'}}
                                    ></i>
                                  </Link>
                                </Col>
                              )}
                              <Col key={index} md={3} className={index !== 0 ? 'hidden_' : ''}>
                                <SelectInput
                                  options={availablesCriterias}
                                  className='form-control'
                                  name={`regles.${i}.${index}.c`}
                                />
                              </Col>

                              <Col md={3}>
                                <SelectInput
                                  options={availablesOperators}
                                  className='form-control'
                                  name={`regles.${i}.${index}.o`}
                                />
                              </Col>

                              <Col md={5}>
                                <div style={{marginBottom: '26px'}} className='form-group'>
                                  <Field
                                    name={`regles.${i}.${index}.v`}
                                    className='form-control'
                                    placeholder=''
                                  />
                                  <ErrorMessage
                                    component='div'
                                    name={`regles.${i}.${index}.v`}
                                    className='errorMessage'
                                  />
                                </div>
                              </Col>
                            </>
                          )
                        })}
                      </Row>
                    )
                  })
                }
              </FieldArray>
              <Row className='mt-5'>
                <Col md={12} style={{textAlign: 'right'}}>
                  <Button variant='secondary me-5' onClick={() => showModalProp(false)}>
                    Annuler
                  </Button>
                  <Button variant='info' type='submit' onClick={() => handleValidate(values)}>
                    Enregistrer
                  </Button>
                </Col>
              </Row>
            </FormMk>
          )}
        </Formik>
      )}
    </div>
  )
}

export {CatalogueEditForm}
