import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import Carousel from '../../../../components/Carousel'
import moment from 'moment/moment'

const DetailsModal = ({data}) => {
  const [imgArr, setImgArr] = useState<any>([])

  useEffect(() => {
    let arrTemp: any = []
    data?.images?.length > 0 &&
      data?.images?.map((i) => {
        arrTemp.push(i.image)
      })
    setImgArr(arrTemp)
  }, [data])

  const renderInfo = () => {
    const capitalizaFirstletter = (str: string = '') => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    }
    return (
      <div className='overflow-auto' style={{maxHeight: '300px'}}>
        <div className='d-flex justify-content-between'>
          <div className='mb-5 bold text-gray-600 fs-4'>Réference constructeur</div>
          <div className='mb-5 fs-4 bold'>{data?.piece?.referenceConstructeur || ''}</div>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='mb-5 bold text-gray-600 fs-4'>Prix constructeur</div>
          <div className='mb-5 fs-4 bold'>{data?.prix ?? ''} €</div>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='mb-5 bold text-gray-600 fs-4'>Marque véhicule</div>
          <div className='mb-5 fs-4 bold'>
            {data?.vehiculeEntre?.vehicule?.modele?.marque?.nom || ''}
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='mb-5 bold text-gray-600 fs-4'>Modèle véhicule</div>
          <div className='mb-5 fs-4 bold'>{data?.vehiculeEntre?.vehicule?.modele.nom || ''}</div>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='mb-5 bold text-gray-600 fs-4'>Etat</div>
          <div className='mb-5 fs-4 bold'>{capitalizaFirstletter(data?.etat?.libelle) || ''}</div>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='mb-5 bold text-gray-600 fs-4'>Date première immatriculation</div>
          <div className='mb-5 fs-4 bold'>
            {data?.vehiculeEntre?.datePremiereImmat == ''
              ? ''
              : moment(data?.vehiculeEntre?.datePremiereImmat).format('DD/MM/YYYY')}
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='mb-5 bold text-gray-600 fs-4'>Réference casier</div>
          <div className='mb-5 fs-4 bold'>{data?.stockageBac || ''}</div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='modal_details_cat'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Détails de {data?.piece?.typePiece?.nom}</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-md-6'>
                  <Carousel
                    loading={true}
                    imagesArray={imgArr}
                    nomPiece={data?.piece?.typePiece?.nom}
                  />
                  <div>
                    <div className='d-flex flex-row-reverse justify-content-between align-items-center'>
                      <div className='fs-2 mb-4 mt-4 text-end'>{data?.prix} €</div>
                    </div>
                  </div>
                </div>
                <div className='card d-flex flex-column w-50'>
                  <div className='card-header fs-2 d-flex align-items-center'>
                    Information du produit
                  </div>
                  <div className='card-body'>
                    <div>{renderInfo()}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {DetailsModal}
