import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
  ModeleClient,
  modeleClientInitValues,
  ModeleClientOtherData,
  modeleClientOtherDataInitValues,
} from '../../../../../components/Models'
import {KTIcon} from '../../../../../_metronic/helpers'
import {LoadingData} from '../../../../../components/LoadingData'
import {getClient, getClientOtherData} from '../../../../../components/requests'
import {useLocation} from 'react-router'
import {shouldDisplayFonctionnalite, toGmapQuery} from '../../../../../components/helpers'
import {useAuth} from "../../../../modules/auth";

const ClientShowPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  let {clientId} = useParams()
  const [data, setData] = useState<ModeleClient>(modeleClientInitValues)
  const [otherData, setOtherData] = useState<ModeleClientOtherData>(modeleClientOtherDataInitValues)
  const [loading, setLoading] = useState(true)
  const {currentUser} = useAuth()

  useEffect(() => {
    getClientData()
  }, [clientId])

  const getClientData = () => {
    getClient(clientId).then((clientRequest) => {
      if (clientRequest?.status === 200) {
        if (clientRequest?.data?.id !== undefined && clientRequest?.data?.id !== null) {
          const updatedData = Object.assign({...data}, clientRequest?.data)
          if (updatedData.pays?.id !== undefined) {
            updatedData.idPays = updatedData.pays?.id
          }
          setData({...updatedData})
          getClientDataOthers()
        }
        setLoading(false)
      } else {
        navigate(`/vente/clients/liste`)
      }
    })
  }

  const getClientDataOthers = () => {
    getClientOtherData(clientId).then((clientRequest) => {
      if (clientRequest?.status === 200) {
        const updatedData = Object.assign({...data}, clientRequest?.data)
        setOtherData({...updatedData})
      }
    })
  }

  const calcRatio = (demandesOk, demandesPasOk) => {
    if (demandesOk === undefined || demandesOk === 0) {
      return 0.0
    }
    if (demandesPasOk === undefined || demandesPasOk === 0) {
      return 100.0
    }
    return ((demandesOk / (demandesOk + demandesPasOk)) * 100).toFixed(2)
  }

  return (
    <>
      {loading ? (
        <LoadingData />
      ) : (
        <>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <p className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 mb-0'>
                          {data.professionnel ? (
                            <>{data.nomSociete}</>
                          ) : (
                            <>
                              {data.nom} {data.prenom}
                            </>
                          )}
                        </p>
                        {data.professionnel ? (
                          <span className={`badge badge-light-warning ms-2`}>Professionnel</span>
                        ) : (
                          <span className={`badge badge-light-danger ms-2`}>Particulier</span>
                        )}
                      </div>

                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                        <a
                          href={`https://www.google.com/maps/search/query=${toGmapQuery(
                            data.pays?.nom + ' ' + data.codePostal + ' ' + data.ville
                          )}`}
                          target={'_blank'}
                          className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                        >
                          <KTIcon iconName='geolocation' className='fs-4 me-1' />
                          {data.pays?.nom}, {data.codePostal} {data.ville}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                      <div className='d-flex flex-wrap'>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>{otherData.ca}€</div>
                          </div>

                          <div className='fw-bold fs-6 text-gray-400'>CA</div>
                        </div>

                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>
                              {(otherData.demandesOk !== undefined ? otherData.demandesOk : 0) +
                                (otherData.demandesPasOk !== undefined
                                  ? otherData.demandesPasOk
                                  : 0)}
                            </div>
                          </div>

                          <div className='fw-bold fs-6 text-gray-400'>Demandes</div>
                        </div>

                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>
                              {calcRatio(otherData.demandesOk, otherData.demandesPasOk)}%
                            </div>
                          </div>

                          <div className='fw-bold fs-6 text-gray-400'>Conversion</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex overflow-auto h-55px'>
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  {shouldDisplayFonctionnalite(currentUser, 'VOIR FICHE') &&
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/vente/clients/${clientId}/afficher` && 'active')
                      }
                      to={`/vente/clients/${clientId}/afficher`}
                    >
                      Fiche client
                    </Link>
                  </li>
                  }
                  {shouldDisplayFonctionnalite(currentUser, 'VOIR PARAMETRAGE') &&
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/vente/clients/${clientId}/afficher/parametrage` && 'active')
                      }
                      to={`/vente/clients/${clientId}/afficher/parametrage`}
                    >
                      Paramétrage
                    </Link>
                  </li>
                  }
                  {shouldDisplayFonctionnalite(currentUser, 'VOIR ADRESSE') &&
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/vente/clients/${clientId}/afficher/adresses` &&
                          'active')
                      }
                      to={`/vente/clients/${clientId}/afficher/adresses`}
                    >
                      Adresses
                    </Link>
                  </li>
                  }
                  {shouldDisplayFonctionnalite(currentUser, 'VOIR CONTACTS') &&
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/vente/clients/${clientId}/afficher/contacts` &&
                          'active')
                      }
                      to={`/vente/clients/${clientId}/afficher/contacts`}
                    >
                      Contacts
                    </Link>
                  </li>
                  }
                  {shouldDisplayFonctionnalite(currentUser, 'VOIR DOCUMENT') &&
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/vente/clients/${clientId}/afficher/document` && 'active')
                      }
                      to={`/vente/clients/${clientId}/afficher/document`}
                    >
                      Document
                    </Link>
                  </li>
                  }
                  {shouldDisplayFonctionnalite(currentUser, 'VOIR HISTORIQUE') &&
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname ===
                          `/vente/clients/${clientId}/afficher/historique-facturation` && 'active')
                      }
                      to={`/vente/clients/${clientId}/afficher/historique-facturation`}
                    >
                      Historique facturation
                    </Link>
                  </li>
                  }
                  {shouldDisplayFonctionnalite(currentUser, 'VOIR ENCOURS') &&
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname ===
                          `/vente/clients/${clientId}/afficher/client-encours-paiement` && 'active')
                      }
                      to={`/vente/clients/${clientId}/afficher/client-encours-paiement`}
                    >
                      En cours
                    </Link>
                  </li>
                  }
                  {shouldDisplayFonctionnalite(currentUser, 'VOIR REMISES') &&
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/vente/clients/${clientId}/afficher/remises` && 'active')
                      }
                      to={`/vente/clients/${clientId}/afficher/remises`}
                    >
                      Remises
                    </Link>
                  </li>
                  }
                  {shouldDisplayFonctionnalite(currentUser, 'VOIR ACOMPTES') &&
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/vente/clients/${clientId}/afficher/adresses` &&
                          'active')
                      }
                      to={`/vente/clients/${clientId}/afficher/acomptes`}
                    >
                      Acomptes
                    </Link>
                  </li>
                  }
                  {shouldDisplayFonctionnalite(currentUser, 'VOIR CLIENT DEMANDES') &&
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/vente/clients/${clientId}/afficher/demandClient` &&
                          'active')
                      }
                      to={`/vente/clients/${clientId}/afficher/demandClient`}
                    >
                      Demandes
                    </Link>
                  </li>
                  }
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export {ClientShowPage}
