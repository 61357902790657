import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Navigate, Route, Routes} from 'react-router-dom'
import {RechercheCataloguePage} from './RechercheCataloguePage'


const RechercheCatalogueWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Recherche des catalogues</PageTitle>
      <Routes>
        <Route
          path='/liste'
          element={
            <>
              <RechercheCataloguePage />
            </>
          }
        />
      
        <Route index element={<Navigate to='/recherche-catalogue/liste' />} />
      </Routes>
    </>
  )
}

export {RechercheCatalogueWrapper}
