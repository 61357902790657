import Flatpickr from "react-flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import React, {useRef} from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import {Link} from 'react-router-dom'
import moment from 'moment'

const DateInput = (props) => {
  const flatpickrRef = useRef<Flatpickr | null>(null);
  const handleChange = ([date]) => {
    //props.setter(date);
    props.formik.setFieldValue(props.fieldName, moment(date).format('YYYY-MM-DD'));
  };

  return (
    <>
      <InputGroup
        size = "sm" className="mb-3">
        <Flatpickr
          ref={flatpickrRef}
          name = {props.fieldName}
          value = {props.value}
          className={props.inputClassName}
          options={{
            locale: French,
          }}
          onChange={handleChange}
        />
        <InputGroup.Text id="basic-addon2">
          <Link
            to='#'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={(e) => {
              e.preventDefault(); // Empêche le comportement par défaut du lien
              flatpickrRef?.current?.flatpickr?.open(); // Ouvre le Flatpickr
            }}
          >
            <i className='fa fa-calendar fs-1'></i>
          </Link>
        </InputGroup.Text>
      </InputGroup>


      {props.formik.touched[props.fieldName] && props.formik.errors[props.fieldName] && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{props.formik.errors[props.fieldName]}</div>
        </div>
      )}
    </>
  )
}

export {DateInput}
