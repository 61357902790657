import React, {useEffect, useState} from 'react'
import {KTIcon} from "../../../_metronic/helpers";
import {Link} from "react-router-dom";
import {ModeleCentre} from "../../../components/Models";
import {getCentres, deleteCentre} from "../../../components/requests";
import {LoadingData} from "../../../components/LoadingData";
import Swal from 'sweetalert2';
import {useFormik} from 'formik';
import {Pagination} from "../../../components/Pagination";
import {useAuth} from "../../modules/auth";
import {shouldDisplayFonctionnalite} from "../../../components/helpers";

const CentreListPage = () => {
  const [data, setData] = useState<Array<ModeleCentre>>([]);
  const [loading, setLoading] = useState(true);

  const [nombreDePages, setNombreDePages] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchPage, setSearchPage] = useState<string>("")
  const [isSearch, setIsSearch] = useState(false)
  const {currentUser} = useAuth()

  useEffect(() => {
    getCentreData()
  }, [currentPage , searchPage]);

  const getCentreData = () => {
    setLoading(true);
    getCentres(!isSearch ? currentPage : 1 , searchPage).then(centresRequest => {
      setNombreDePages(centresRequest?.pages);
      setTotalPages(centresRequest?.total);
      setCurrentPage(centresRequest?.page);
        if (centresRequest?.data !== undefined) {
          setData(centresRequest?.data)
          setLoading(false);
          setIsSearch(false);
        }
    }).catch((e) => {
      setLoading(false);
    });
  }

  const delCentre = (centreId) => {
    Swal.fire({
      title: 'Attention',
      text: 'Merci de confirmer la suppression',
      icon: 'warning',
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
      showCancelButton: true,
      confirmButtonColor: "#1BC5BD",
      cancelButtonColor: "#F64E60",
    }).then(alertResult => {
      if (alertResult.isConfirmed) {
        setLoading(true)
        deleteCentre(centreId).then(response => {
          if (response?.status === 200) {
            setLoading(false)
            getCentreData()
          }
        }).catch((error) => {
          if (error.response?.status === 423) {
            setLoading(false)
            Swal.fire({
              title: 'Erreur',
              text: 'Ce centre ne peut pas être supprimé.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#F64E60',
            })
          }
        })
      }
    })
  }

  const formik = useFormik({
    initialValues: {search: ''},
    onSubmit: (values, {setSubmitting}) => {
      setIsSearch(true)
      setSearchPage(values.search)
    },
  })

  return (
    <>
      {/* begin::Row  */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col  */}
        <div className='col-xxl-12'>
          <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Centre</span>
              </h3>
              {shouldDisplayFonctionnalite(currentUser, 'AJOUTER CENTRE') &&
                <div
                  className='card-toolbar'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Click to add a centre'
                >
                  <Link to={`/centres/ajouter`} className='btn btn-sm btn-light-primary'>
                    <KTIcon iconName='plus' className='fs-3' />
                    Créer un centre
                  </Link>
                </div>
              }
            </div>
            {/* end::Header */}

            {/* debut champs recherche */}
            <form className='card-header border-0 pt-5 pb-5' onSubmit={formik.handleSubmit}>
              <div></div>
              <div className='form-group d-flex col-lg-4'>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control h-100'
                    {...formik.getFieldProps('search')}
                    name='search'
                    placeholder='Rechercher un centre'
                  />
                  <button
                    type='submit'
                    className='btn btn-light border-0 input-group-append h-100'
                    onClick={() => setIsSearch(true)}
                  >
                    <i className='fas fa-search text-secondary fs-1'></i>
                  </button>
                </div>
              </div>
            </form>
            {/* fin champs recherche */}

            {loading ? (
              <LoadingData />
            ) : (
              <>
                {shouldDisplayFonctionnalite(currentUser, 'VOIR CENTRE') &&
                  <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th>Code</th>
                            <th className='min-w-140px'>Nom</th>
                            <th className='min-w-140px'>Adresse</th>
                            <th className='min-w-100px text-end'>Actions</th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {data.length ? (
                            data.map((centre) => {
                              return (
                                <tr key={centre.id}>
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex justify-content-start flex-column'>
                                        {centre.code}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                      {centre.nom}
                                    </p>
                                  </td>
                                  <td>
                                    <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                      {centre.adresse && centre.ville
                                        ? `${centre.adresse}, ${centre.ville}`
                                        : centre.adresse || centre.ville}
                                    </p>
                                  </td>
                                  <td>
                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                      {shouldDisplayFonctionnalite(currentUser, 'MODIFIER CENTRE') &&
                                        <Link
                                          to={`/centres/${centre.id}/modifier`}
                                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        >
                                          <i className='bi bi-pencil fs-5'></i>
                                        </Link>
                                      }
                                      {shouldDisplayFonctionnalite(currentUser, 'SUPPRIMER CENTRE') &&
                                        <p
                                          onClick={() => {
                                            delCentre(centre.id)
                                          }}
                                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        >
                                          <i className='bi bi-trash fs-5'></i>
                                        </p>
                                      }
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan={4} className='text-center'>
                                Aucun résultat trouvé
                              </td>
                            </tr>
                          )}
                        </tbody>
                        {/* end::Table body */}
                      </table>
                      {/* end::Table */}
                    </div>
                    {/* end::Table container */}

                    {/* begin::pagination*/}
                    <Pagination
                      totalPages={totalPages}
                      nombreDePages={nombreDePages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      locationState={0}
                    />
                    {/* end::pagination*/}
                  </div>
                }
              </>
            )}
          </div>
        </div>
        {/* end::Col  */}
      </div>
      {/* end::Row  */}
    </>
  )
}

export {CentreListPage}
