import React from 'react'
import {Card, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {CourrielRegisterForm} from './components/CourrielRegisterForm'
import {CourrielEditForm} from './components/CourrielEditForm'

const CourrielModal = ({
  show,
  isEdition,
  courrielId,
  showModal,
  addDataCourriel,
  updateDataCourriel,
  loading,
}) => {
  return (
    <>
      <Modal
        size='lg'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        show={show}
      >
        <div className='modal-content shadow-none'>
          <div className='modal-header d-flex justify-content-end border-0 p-3'>
            <div
              className='btn btn-icon btn-sm btn-light-primary ms-2'
              onClick={() => showModal(false)}
            >
              <KTIcon className='fs-2' iconName='cross' />
            </div>
          </div>
          <div className='modal-body pt-0'>
            <Card.Header className='text-center text-md-center border-0 mb-0 mt-md-0 mb-20'>
              <h2>
                {isEdition
                  ? 'Modifier une configuration de courriel'
                  : 'Ajouter une configuration de courriel'}
              </h2>
            </Card.Header>
            {!isEdition && (
              <CourrielRegisterForm
                showModalProp={showModal}
                addDataCourriel={addDataCourriel}
                setLoadingProp={loading}
              />
            )}
            {isEdition && (
              <CourrielEditForm
                courrielId={courrielId}
                showModalProp={showModal}
                updateDataCourriel={updateDataCourriel}
                setLoadingProp={loading}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export {CourrielModal}
