import { useState, useEffect } from "react";
import {getNbrePieceAcceptees} from '../../../../../components/requests'

const PieceStatus = ({ piece }) => {
  const [statusText, setStatusText] = useState<string>("Chargement...");
  const [statusTextProposee, setStatusTextProposee] = useState<string>();
  const [isProposed, setIsProposed] = useState<boolean>(false)

  const getDataStatusPieces = async (idDemandePiece: number): Promise<string> => {
    try {
      const res = await getNbrePieceAcceptees(idDemandePiece);
      if (res && res.data) {
        let accepted = 0;

        res.data.forEach((item: { isAccepted: boolean }) => {
          if (item.isAccepted) accepted++;
        });

        return `${accepted}/${res.data.length} Accepté(s)`;
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    }
    return "0/0 Accepté(s)";
  };

  useEffect(() => {
    setStatusTextProposee(`${piece.proposee ?? 0}/${piece.quantite ?? 0} Proposée(s)`)
    if (piece.demande.clientDemandeEtape.code == 'ACCEP' ||piece.demande.clientDemandeEtape.code == 'REFUS') {
      setIsProposed(true)
      getDataStatusPieces(piece.id).then(setStatusText)
    }
  }, [piece])

  return (
    <>
      <span className='badge bg-info text-white'>{statusTextProposee}</span>
      {!(statusText != '0/0 Accepté(s)') ||
        (isProposed && (
          <span
            className={`${statusText=='Chargement...' ? 'badge bg-light text-gray-500' : 'badge bg-primary text-white'} `}
          >
            {statusText}
          </span>
        ))}
    </>
  )
};

export default PieceStatus;
