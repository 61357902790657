import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from "react-router-dom";
import {ModeleClient, modeleClientInitValues} from "../../../../../../components/Models";
import {LoadingData} from "../../../../../../components/LoadingData";
import {getClient} from "../../../../../../components/requests";
import {useAuth} from "../../../../../modules/auth";
import {shouldDisplayFonctionnalite} from "../../../../../../components/helpers";

const ClientDetails = () => {
    const navigate = useNavigate();
    let { clientId } = useParams();
    const [data, setData] = useState<ModeleClient>(modeleClientInitValues);
    const [loading, setLoading] = useState(true)
    const {currentUser} = useAuth()

    useEffect(() => {
        getClientData()
    }, [clientId]);

    const getClientData = () => {
        getClient(clientId).then(clientRequest => {
            if (clientRequest?.status === 200) {
                if (clientRequest?.data?.id !== undefined && clientRequest?.data?.id !== null) {
                    const updatedData = Object.assign({...data}, clientRequest?.data)
                    if (updatedData.pays?.id !== undefined) {
                        updatedData.idPays = updatedData.pays?.id
                    }
                    if (updatedData.naf?.id !== undefined) {
                        updatedData.codeNaf = updatedData.naf?.code
                        updatedData.libelleCodeNaf = updatedData.naf?.libelle
                    }
                    setData({...updatedData})
                }
                setLoading(false);
            } else {
                navigate(`/vente/clients/liste`);
            }
        });
    }

    return (
        <>
            {loading
                ?
                <LoadingData/>
                :
                <>
                    <div className='card mb-5 mb-xl-10'>
                        <div
                            className='card-header border-0'
                        >
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Fiche client</h3>
                            </div>
                            {shouldDisplayFonctionnalite(currentUser, 'MODIFIER CLIENT') &&
                            <Link to={`/vente/clients/${clientId}/modifier`} className='btn btn-primary align-self-center'>
                                Modifier
                            </Link>
                            }
                        </div>

                        <div>
                            <div className='card-body p-9'>

                                {data.professionnel &&
                                    <div className='row mb-7'>
                                        <label className='col-lg-4 fw-bold text-muted'>Société</label>

                                        <div className='col-lg-8'>
                                            <span className='fw-bolder fs-6 text-dark'>{data.nomSociete}</span>
                                        </div>
                                    </div>
                                }

                                {data.professionnel &&
                                    <div className='row mb-7'>
                                        <label className='col-lg-4 fw-bold text-muted'>SIRET</label>

                                        <div className='col-lg-8'>
                                            <span className='fw-bolder fs-6 text-dark'>{data.siren}</span>
                                        </div>
                                    </div>
                                }

                                {data.professionnel &&
                                    <div className='row mb-7'>
                                        <label className='col-lg-4 fw-bold text-muted'>Code naf</label>

                                        <div className='col-lg-8'>
                                            <span className='fw-bolder fs-6 text-dark'>{data.codeNaf}</span>
                                        </div>
                                    </div>
                                }

                                {data.professionnel &&
                                    <div className='row mb-7'>
                                        <label className='col-lg-4 fw-bold text-muted'>Libelle naf</label>

                                        <div className='col-lg-8'>
                                            <span className='fw-bolder fs-6 text-dark'>{data.libelleCodeNaf}</span>
                                        </div>
                                    </div>
                                }

                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>Nom complet</label>

                                    <div className='col-lg-8'>
                                        <span className='fw-bolder fs-6 text-dark'>{data.nom} {data.prenom}</span>
                                    </div>
                                </div>

                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>Adresse principale</label>

                                    <div className='col-lg-8 fv-row'>
                                            <span className='fw-bold fs-6'>
                                                {data.adresse}<br/>
                                                {data.complementAdresse}<br/>
                                                {data.codePostal} {data.ville}<br/>
                                                {data.pays?.nom}
                                            </span>
                                    </div>
                                </div>

                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>Téléphone</label>

                                    <div className='col-lg-8 fv-row'>
                                            <span className='fw-bold fs-6'>
                                                {data.telephone}<br/>
                                                {data.mobile}
                                            </span>
                                    </div>
                                </div>

                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>Email</label>

                                    <div className='col-lg-8 fv-row'>
                                            <span className='fw-bold fs-6'>
                                                {data.email}
                                            </span>
                                    </div>
                                </div>

                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>Site web</label>

                                    <div className='col-lg-8 fv-row'>
                                            <span className='fw-bold fs-6'>
                                                {data.siteWeb}
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export {ClientDetails}
