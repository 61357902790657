import {ErrorMessage, useField} from 'formik'
import React from 'react'
import './../index.css'

const SelectInput = (props) => {
  const [field] = useField(props)

  return (
    <>
      <div style={{marginBottom: props.noMarginBottom ? '0' : '26px'}} className='form-group'>
        <select {...field} {...props}>
          <option value='' disabled={props.defaultActive ? false : true}>
            Choisir...
          </option>
          {props.options &&
            props.options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              )
            })}
        </select>
        <ErrorMessage component='div' name={field.name} className='errorMessage' />
      </div>
    </>
  )
}

export default SelectInput

const errorMessage = {
  color: 'red',
  position: 'absolute',
  fontSize: '11px',
}
