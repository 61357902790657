import React from "react";

const SelectInput = (props) => {
  // Fonction qui appelle props.onChange si elle existe et gère le changement Formik
  const handleChange = (e) => {
    // Appeler la fonction onChange passée dans les props si elle existe
    if (props.onChange) {
      props.onChange(e);
    }

    // Appeler Formik handleChange
    props.formik.handleChange(e);
  };
    return (
        <>
            <select
                id={props.id}
                className={props.inputClassName}
                {...props.formik.getFieldProps(props.fieldName)}
                onChange={handleChange} // Appliquer la logique combinée
            >
                {props.options.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                ))}
            </select>
            {props.formik.touched[props.fieldName] && props.formik.errors[props.fieldName] && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{props.formik.errors[props.fieldName]}</div>
                </div>
            )}
        </>
    )
}

export {SelectInput}
