import React from 'react'
import {useIntl} from 'react-intl'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {ClientsListPage} from './pages/ClientsListPage'
import {ClientEditPage} from './pages/ClientEditPage'
import {ClientAddPage} from './pages/ClientAddPage'
import {ClientShowPage} from './pages/ClientShowPage'
import {ClientDetails} from './pages/client/ClientDetails'
import {ClientContacts} from './pages/client/ClientContacts'
import {ClientAdresses} from './pages/client/ClientAdresses'
import {ClientAdresseAdd} from './pages/client/ClientAdresseAdd'
import {ClientAdresseEdit} from './pages/client/ClientAdresseEdit'
import {ClientContactAdd} from './pages/client/ClientContactAdd'
import {ClientContactEdit} from './pages/client/ClientContactEdit'
import {ClientDemandes} from './pages/client/ClientDemandes'
import {ClientAcomptes} from './pages/client/ClientAcomptes'
import {ClientHistoriqueFacturation} from './pages/client/ClientHistoriqueFacturation'
import {ClientEncours} from './pages/client/ClientEncours'
import {ClientDocument} from './pages/client/ClientDocument'
import {ClientRemises} from './pages/client/ClientRemises'
import {ClientParametrage} from './pages/client/ClientParametrage'

const ClientsWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <Routes>
                <Route
                    path='liste'
                    element={
                        <>
                            <ClientsListPage />
                        </>
                    }
                />
                <Route
                    path='ajouter'
                    element={
                        <>
                            <ClientAddPage />
                        </>
                    }
                />
                <Route
                    path=':clientId/modifier'
                    element={
                        <>
                            <ClientEditPage />
                        </>
                    }
                />
                <Route
                    path=':clientId/afficher/*'
                    element={
                        <>
                            <ClientShowPage />
                            <Outlet />
                        </>
                    }
                >
                    <Route
                        index
                        element={
                            <>
                                <ClientDetails />
                            </>
                        }
                    />
                    <Route
                        path='contacts'
                        element={
                            <>
                                <ClientContacts />
                            </>
                        }
                    />
                    <Route
                        path='contacts/ajouter'
                        element={
                            <>
                                <ClientContactAdd />
                            </>
                        }
                    />
                    <Route
                        path='historique-facturation'
                        element={
                            <>
                                <ClientHistoriqueFacturation />
                            </>
                        }
                    />

                    <Route
                        path='client-encours-paiement'
                        element={
                            <>
                                <ClientEncours />
                            </>
                        }
                    />

                    <Route
                        path='contacts/:contactId/modifier'
                        element={
                            <>
                                <ClientContactEdit />
                            </>
                        }
                    />
                    <Route
                        path='adresses'
                        element={
                            <>
                                <ClientAdresses />
                            </>
                        }
                    />
                    <Route
                        path='adresses/ajouter'
                        element={
                            <>
                                <ClientAdresseAdd />
                            </>
                        }
                    />
                    <Route
                        path='adresses/:adresseId/modifier'
                        element={
                            <>
                                <ClientAdresseEdit />
                            </>
                        }
                    />
                    <Route
                        path='demandClient'
                        element={
                            <>
                                <ClientDemandes />
                            </>
                        }
                    />
                    <Route
                        path='acomptes'
                        element={
                            <>
                                <ClientAcomptes />
                            </>
                        }
                    />
                  <Route
                    path='document'
                    element={
                      <>
                        <ClientDocument />
                      </>
                    }
                  />
                  <Route
                    path='remises'
                    element={
                      <>
                        <ClientRemises />
                      </>
                    }
                  />

                  <Route
                    path='parametrage'
                    element={
                      <>
                        <ClientParametrage/>
                      </>
                    }
                  />
                </Route>
                <Route index element={<Navigate to='/vente/clients/liste' />} />
            </Routes>
        </>
    )
}

export {ClientsWrapper}
