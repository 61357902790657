import React from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'

type LocationState = {
  data : any
  idTypePiece: number
  panier: any
  quantity: number
  immatriculation: string
  clientId: number
  demandeId: number
  demandeImmatId: number
}

const ListePieceMarket = () => {
  const location = useLocation()
  const state = location.state as LocationState | undefined

  return (
    <div>
      <div className='row g-5 g-xl-8'>
        {/* begin::Col  */}
        <div className='col-xxl-12'>
          <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
            {/* begin::Header */}
            <div className='row ms-4 mt-4'>
              <Link
                to={`/demandes/enStock/${state?.idTypePiece}`}
                state={{
                  immatriculation: state?.immatriculation,
                  quantity: state?.quantity,
                  clientId: state?.clientId,
                  demandeId: state?.demandeId,
                  panier: state?.panier,
                  demandeImmatId: state?.demandeImmatId,
                }}
              >
                <img src='/media/svg/custom/prev-arrow.svg' width={40} />
              </Link>
            </div>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Positionnement sur le marché</span>
              </h3>
            </div>
            {/* end::Header */}
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table
                  className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                  <tr className='fw-bold text-muted'>
                    <th className=''></th>
                    <th className='min-w-140px'>N°</th>
                    <th className='min-w-140px'>Vendeur</th>
                    <th className='min-w-140px'>Prix € HT</th>
                    <th className='min-w-140px'>Livraison € HT</th>
                    <th className='min-w-140px'>Total € HT</th>
                  </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                  {state?.data?.data?.map((piece, i) => {
                    return (
                      <tr key={i}
                          className={`table-${piece.color} ${piece.highlight ? "font-weight-bold" : ""}`}>
                        <td>
                          <p
                            className={`text-dark ${piece.highlight ? "fw-bold" : ""} d-block fs-6 mb-0`}>
                            {piece.highlight &&
                              String.fromCharCode(10132)
                            }
                          </p>
                        </td>
                        <td>
                          <p
                            className={`text-dark ${piece.highlight ? "fw-bold" : ""} d-block fs-6 mb-0`}>
                            {i + 1}
                          </p>
                        </td>
                        <td>
                          <p
                            className={`text-dark ${piece.highlight ? "fw-bold" : ""} d-block fs-6 mb-0`}>
                            {piece.seller}
                          </p>
                        </td>
                        <td>
                          <p
                            className={`text-dark ${piece.highlight ? "fw-bold" : ""} d-block fs-6 mb-0`}>
                            {(piece.price / 1.2).toFixed(2)}&nbsp;€
                          </p>
                        </td>
                        <td>
                          <p
                            className={`text-dark ${piece.highlight ? "fw-bold" : ""} d-block fs-6 mb-0`}>
                            {(piece.shipping / 1.2).toFixed(2)}&nbsp;€
                          </p>
                        </td>
                        <td>
                          <p
                            className={`text-dark ${piece.highlight ? "fw-bold" : ""} d-block fs-6 mb-0`}>
                            {(piece.total / 1.2).toFixed(2)}&nbsp;€
                          </p>
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
          </div>
        </div>
        {/* end::Col  */}
      </div>
    </div>
  )
}

export default ListePieceMarket