import {useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import {CatalogueEditForm} from '../../../catalogues/components/CatalogueEditForm'
import * as yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {TextInput} from '../../../../../components/inputs/TextInput'
import {ModeleClientParametrage} from '../../../../../components/clientParametrage/Models'

export const handleValidation = yup.object().shape({
  label:yup.string().required("Ce champs est requis."),
});

function ReferentielModal({show, showModal, hasCode, title, submitForm}) {
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    showModal(false);
  }

  const initialValue = {label :"" , code :""}
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: handleValidation,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        //const updatedData =  Object.assign({...data}, values) ;
        submitForm(values, () => {
          formik.resetForm();
          setLoading(false);
          handleClose();
        });
      }, 200);
    },
  });
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Inserer votre {title} ici</Modal.Title>
        </Modal.Header>

        <Form onSubmit={formik.handleSubmit} noValidate className='form'>
          <Modal.Body>
              <Form.Group className="mb-3" controlId="label">
                <Form.Label>Label</Form.Label>
                <TextInput
                  fieldName='label'
                  inputType='text'
                  inputClassName='form-control mb-3 '
                  formik={formik}
                />
              </Form.Group>
              {hasCode && (
                <Form.Group className="mb-3" controlId="code">
                  <Form.Label>Code</Form.Label>
                  <TextInput
                    fieldName='code'
                    inputType='text'
                    inputClassName='form-control mb-3 '
                    formik={formik}
                  />
                </Form.Group>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Annuler
            </Button>
            <Button variant="primary" type='submit' disabled={loading}>
              Enregistrer
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default ReferentielModal;