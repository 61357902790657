import React, {useEffect, useState} from 'react'
import * as Yup from "yup";
import {useFormik} from "formik";
import {TextInput} from "../../../../components/inputs/TextInput";
import {ModeleGenre, ModeleEditCentre} from "../../../../components/Models";
import {useNavigate} from "react-router-dom";
import {SelectInput} from '../../../../components/inputs/SelectInput'
import {getCentres} from '../../../../components/requests'

const genreSchema = Yup.object().shape({
  code: Yup.string().required("Le code est requis"),
  libelle: Yup.string().required("Le libellé est requis"),
  idCentre: Yup.string().required("Le centre est requis"),
})

const GenreForm = (props) => {
  const navigate = useNavigate();
  const [loadingCentre, setLoadingCentre] = useState<boolean>(false)
  const [centres, setCentres] = useState<Array<ModeleEditCentre>>([]);
  const [genreId, setGenreId] = useState(props.genreId);
  const initialValue  =  {...props?.data, idCentre: props?.data.centreDefaut?.id ?? 1}
  const [data, setData] = useState<ModeleGenre>(initialValue)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchPage, setSearchPage] = useState<string>("")

  const getCentresData = () => {
    getCentres(currentPage , searchPage).then(centresRequest => {
        if (centresRequest?.data !== undefined) {
          const transformedData = centresRequest.data.map((item) => ({
            value: item.id,
            label: item.nom,
          }));
          setCentres([...transformedData]);
          setLoadingCentre(true);
        }
    }).catch((e) => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getCentresData();
  }, [])

  const formik = useFormik<ModeleGenre>({
    initialValues: data,
    validationSchema: genreId??genreSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign({...data}, values)

        props.submitForm(updatedData, response => {
          formik.resetForm();
          setLoading(false);
        });
      }, 200)
    },
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Code</label>

            <div className='col-lg-8'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'code'}
                    inputPlaceholder={'Code'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Libelle</label>
            <div className='col-lg-8'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'libelle'}
                    inputPlaceholder={'Libellé'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Centre</label>
            <div className='col-lg-8'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <SelectInput
                    fieldName={'idCentre'}
                    inputClassName={'form-select form-select-solid form-select-lg fw-bold'}
                    options={centres}
                    formik={formik}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          {genreId !== null &&
            <button type='button' className='btn btn-light me-3' disabled={loading} onClick={() => {
              formik.resetForm();
              navigate(`/genres`);
            }}>
              Annuler les modifications
            </button>
          }
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Enregistrer'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                  Enregistrement...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {GenreForm}
