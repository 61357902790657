import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Navigate, Route, Routes} from 'react-router-dom'
import {RolesListPage} from "./RolesListPage";
// import {RoleAddPage} from "./RoleAddPage";
// import {RoleEditPage} from "./RoleEditPage";

const RolesWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Rôles utilisateurs</PageTitle>
      <Routes>

        <Route
          path='/liste'
          element={
            <>
              <RolesListPage />
            </>
          }
        />
        {/*<Route*/}
        {/*  path='/ajouter'*/}
        {/*  element={*/}
        {/*    <>*/}
        {/*      <RoleAddPage />*/}
        {/*    </>*/}
        {/*  }*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path=':roleId/modifier'*/}
        {/*  element={*/}
        {/*    <>*/}
        {/*      <RoleEditPage />*/}
        {/*    </>*/}
        {/*  }*/}
        {/*/>*/}
        <Route index element={<Navigate to='/roles/liste' />} />
      </Routes>
    </>
  )
}

export {RolesWrapper}