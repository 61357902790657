import React from "react";

const RadioListInput = (props) => {
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
    props.formik.handleChange(e);
  };
    return (
      <>
        {props.optionList.map((option, index) => (
          <div className='form-check  mb-6 ' key={'div'+option.label + index} >
            <input
              className ="form-check-input"
              type = "radio"
              key={'option'+option.label + index}
              checked= {(props.formik.values[props.fieldName] ?? "") === option.value}
              value = {option.value}
              name = {props.fieldName}
              onChange={props.formik.handleChange}
            />
            <label className='form-check-label  fw-bold'> {option.label} </label>
          </div>
        ))}
      </>
    )
}

export {RadioListInput}
