import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Navigate, Route, Routes} from 'react-router-dom'
import {CentreListPage} from "./CentreListPage";
import {CentreAddPage} from "./CentreAddPage";
import {CentreEditPage} from "./CentreEditPage";

const CentreWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Centre</PageTitle>
      <Routes>

        <Route
          path='/liste'
          element={
            <>
              <CentreListPage />
            </>
          }
        />
        <Route
          path='/ajouter'
          element={
            <>
              <CentreAddPage />
            </>
          }
        />
        <Route
          path=':centreId/modifier'
          element={
            <>
              <CentreEditPage />
            </>
          }
        />
        <Route index element={<Navigate to='/centres/liste' />} />
      </Routes>
    </>
  )
}

export {CentreWrapper}
