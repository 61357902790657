export const availablesCriterias = [
  {
    label: 'Famille',
    value: 'Famille',
  },
  {
    label: 'SousFamille',
    value: 'SousFamille',
  },
  {
    label: 'TypePiece',
    value: 'TypePiece',
  },
  {
    label: 'ReferenceOE',
    value: 'ReferenceOE',
  },
  {
    label: 'TypeMoteur',
    value: 'TypeMoteur',
  },
  {
    label: 'CodeMoteur',
    value: 'CodeMoteur',
  },
  {
    label: 'DateStockage',
    value: 'DateStockage',
  },
  {
    label: 'Qualite',
    value: 'Qualite',
  },
  {
    label: 'CentreVHU',
    value: 'CentreVHU',
  },
  {
    label: 'MarqueVEH',
    value: 'MarqueVEH',
  },
  {
    label: 'ModeleVEH',
    value: 'ModeleVEH',
  },
  {
    label: 'CNITVEH',
    value: 'CNITVEH',
  },
  {
    label: 'GenreCodeVEH',
    value: 'GenreCodeVEH',
  },
  {
    label: 'Site',
    value: 'Site',
  },
]

export const formatCataloques = [
  {
    label: 'aucun',
    value: 'aucun',
  },
  {
    label: 'xml',
    value: 'xml',
  },
  {
    label: 'json',
    value: 'json',
  },
]

export const civiliteCataloques = [
  {
    label: 'M',
    value: 'M',
  },
  {
    label: 'Mme',
    value: 'Mme',
  },
  {
    label: 'NC',
    value: 'NC',
  },
]

export const availablesOperators = [
  {
    label: 'Egal',
    value: 'eq',
  },
  {
    label: 'Différent',
    value: 'ne',
  },
  {
    label: 'Strictement supérieur',
    value: 'gt',
  },
  {
    label: 'Supérieur ou égal',
    value: 'ge',
  },
  {
    label: 'Strictement inférieur',
    value: 'lt',
  },
  {
    label: 'Inférieur ou égal',
    value: 'le',
  },
]
