import React, {useState} from 'react'
import {
  ModeleClientDemande,
  modeleClientDemandeInitValues,
  ModeleImmatriculation,
} from '../../../../components/Models'
import {KTSVG} from '../../../../_metronic/helpers'
import {TextInput} from '../../../../components/inputs/TextInput'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {getInfoVehicle} from '../../../../components/requests'
import {Tooltip} from 'react-tooltip'

const clientDemandeSchema = Yup.object().shape({
  commentaire: Yup.string().notRequired(),
})

const ResearchVehicleModal = (props) => {
  const {clientId, redirectToClientDemande, handleSubmit} = props
  const navigate = useNavigate()
  const [loadingCatdata, setLoadingCatdata] = useState<boolean>(false)
  const [dataVehicle, setDataVehicle] = useState<ModeleImmatriculation[]>([])
  const [message, setMessage] = useState<string>('')

  const formik = useFormik<ModeleClientDemande>({
    initialValues: modeleClientDemandeInitValues,
    validationSchema: clientDemandeSchema,
    onSubmit: (values) => {},
  })

  // Vérifier les infos (par immatriculation ou par marque et modèle)
  const checkInfo = (immatriculation) => {
    setMessage('')
    setLoadingCatdata(true)
    if (formik.values.immatriculation) {
      handleNavigate(immatriculation)
    } else {
      getInfoVehicle(formik.values.marque, formik.values.modele).then((response) => {
        if (response && response.status == 200 && response.data) {
          setDataVehicle(response.data)
          if (response.data.length == 0) setMessage('Aucun résultat')
          setLoadingCatdata(false)
        }
      })
    }
  }

  // Redirection vers la création de demande
  const handleNavigate = (immat) => {
    const pathname = window.location.pathname
    const regex = /^\/demandes\/create\/(\d+)$/
    const inDemandeCreate = regex.test(pathname)
    if (!inDemandeCreate) {
      navigate(`/demandes/create/${clientId}`, {
        state: {
          immat: [immat],
          redirectToClientDemande: redirectToClientDemande,
        },
      })
    } else {
      handleSubmit(immat)
    }
    setLoadingCatdata(false)
    formik.setFieldValue('immatriculation', '')
    formik.setFieldValue('marque', '')
    formik.setFieldValue('modele', '')
    setDataVehicle([])
  }

  return (
    <>
      <div
        className='modal fade'
        id='exampleModalSearchV'
        aria-hidden='true'
        aria-labelledby='exampleModalToggleLabel2'
        tabIndex={-1}
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalToggleLabel2'>
                Recherche du véhicule
              </h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='mb-5'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <TextInput
                        fieldName={'immatriculation'}
                        inputPlaceholder={'Immatriculation'}
                        inputType={'text'}
                        inputClassName={
                          'form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        }
                        formik={formik}
                      />
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <div className='col-md-6'>
                      <TextInput
                        fieldName={'marque'}
                        inputPlaceholder={'Marque'}
                        inputType={'text'}
                        inputClassName={
                          'form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        }
                        formik={formik}
                        required={formik.values.modele}
                      />
                    </div>
                    <div className='col-md-6'>
                      <TextInput
                        fieldName={'modele'}
                        inputPlaceholder={'Modèle'}
                        inputType={'text'}
                        inputClassName={
                          'form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        }
                        formik={formik}
                        required={formik.values.marque}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row px-3'>
                <table>
                  <tbody>
                    {dataVehicle.length > 0 ? (
                      dataVehicle.map((v) => (
                        <tr key={v.id}>
                          <td>{v.sImmatriculationSiv}</td>
                          <td>{v.smarque}</td>
                          <td>{v.stecModele}</td>
                          <td>
                            <span className='text-primary' style={{cursor: 'pointer'}}>
                              <i
                                onClick={() => handleNavigate(v.sImmatriculationSiv)}
                                className='bi-file-earmark-plus fs-2x'
                                data-bs-dismiss='modal'
                                data-tooltip-id='modal-tooltip'
                                data-tooltip-content='Effectuer une demande'
                                data-tooltip-place='right'
                              ></i>
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>{message}</tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Fermer
              </button>
              <button
                type='button'
                className='btn btn-primary'
                data-bs-dismiss={formik?.values?.immatriculation ? 'modal' : ''}
                onClick={() => {
                  checkInfo(formik.values.immatriculation)
                }}
                disabled={
                  !formik.values.immatriculation && !(formik.values.marque && formik.values.modele)
                }
              >
                {!loadingCatdata && 'Rechercher'}
                {loadingCatdata && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Recherche...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
        <Tooltip id="modal-tooltip" />
      </div>
    </>
  )
}

export default ResearchVehicleModal
