import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {ModeleClientAdresse, modeleClientAdresseInitValues} from "../../../../../../components/Models";
import {editClientAdresse, getClientAdresse} from "../../../../../../components/requests";
import {ClientAdresseForm} from "../../components/ClientAdresseForm";
import {LoadingData} from "../../../../../../components/LoadingData";

const ClientAdresseEdit = () => {
    const navigate = useNavigate();
    let { clientId } = useParams();
    let { adresseId } = useParams();
    const [data, setData] = useState<ModeleClientAdresse>(modeleClientAdresseInitValues);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getClientAdresseData()
    }, [clientId, adresseId]);

    const getClientAdresseData = () => {
        getClientAdresse(clientId, adresseId).then(clientAdresseRequest => {
            if (clientAdresseRequest?.status === 200) {
                if (clientAdresseRequest?.data?.id !== undefined && clientAdresseRequest?.data?.id !== null) {
                    const updatedData = Object.assign({...data}, clientAdresseRequest?.data)
                    if (updatedData.pays?.id !== undefined) {
                        updatedData.idPays = updatedData.pays?.id
                    }
                    if (updatedData.paysFacturation?.id !== undefined) {
                        updatedData.idPaysFacturation = updatedData.paysFacturation?.id
                    }
                    if (updatedData.paysPrimaireLivraison?.id !== undefined) {
                        updatedData.idPaysPrimaireLivraison = updatedData.paysPrimaireLivraison?.id
                    }
                    if (updatedData.paysSecondaireLivraison?.id !== undefined) {
                        updatedData.idpaysSecondaireLivraison = updatedData.paysSecondaireLivraison?.id
                    }
                    setData({...updatedData})
                }
                setLoading(false);
            } else {
                navigate(`/vente/clients/${clientId}/afficher/adresses`);
            }
        });
    }

    const submitForm = async (fieldsToUpdate: Partial<ModeleClientAdresse>, callback): Promise<void> => {
        const updatedData = Object.assign({...modeleClientAdresseInitValues}, fieldsToUpdate)

        var requestOk = false;
        try {
            const clientAdresseRequest = await editClientAdresse(clientId, adresseId, updatedData);
            if (clientAdresseRequest?.status === 200) {
                if (clientAdresseRequest?.data?.id !== undefined && clientAdresseRequest?.data?.id !== null) {
                    requestOk = true;
                }
            }
        } catch (ex) {
            console.error(ex)
        } finally {
            callback();
            if (requestOk) {
                setData({...updatedData})
                navigate(`/vente/clients/${clientId}/afficher/adresses`);
            }
        }

    }

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Modifier une adresse</h3>
                </div>
            </div>

            <div>
                {loading
                    ?
                    <LoadingData />
                    :
                    <ClientAdresseForm clientId={clientId} adresseId={adresseId} data={data} submitForm={submitForm} />
                }
            </div>
        </div>
    )
}

export {ClientAdresseEdit}
