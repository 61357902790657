import React from 'react'
import styles from './Loader.module.css'

const LoadingData = (props) => {
  return (
    <div className='d-flex flex-column flex-center h-100 p-6'>
      <span className={styles.loader}></span>
      <span className='fs-7 fw-bold text-gray-800 mb-0 my-10'>Chargement des données</span>
    </div>
  )
}

export {LoadingData}
