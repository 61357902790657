import React, {useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import SearchBar from '../SearchBar'

const ResearchClientModal = (props) => {
  const {setClientId, setIsCreateViaModal} = props
  const [close, setClose] = useState<boolean>(false)

  // ne pas afficher l'ancienne liste des résultats à l'appuie du bouton close
  const handleClose = () => {
    setClose(true)
  }

  return (
    <div
      className='modal fade'
      id='exampleModalToggle'
      aria-hidden='true'
      aria-labelledby='exampleModalToggleLabel'
      tabIndex={-1}
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='exampleModalToggleLabel'>
              Choix d'un client
            </h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className='modal-body overflow-auto' style={{maxHeight: '500px'}}>
            <SearchBar placeholder={'Rechercher un client'} setClientId={setClientId} close={close} setClose={setClose}/>
          </div>
          <div className='modal-footer'>
            <div className='d-flex w-100 justify-content-between align-items-center'>
              <div className='col-md-10 text-gray-800 fw-bold fs-6'>
                Client non trouvé?{' '}
                <span
                  style={{color: '#9ac331', cursor: 'pointer'}}
                  data-bs-target='#ModalClientCreate'
                  data-bs-toggle='modal'
                  data-bs-dismiss='modal'
                  onClick={() => setIsCreateViaModal(true)}
                >
                  Créer en un
                </span>
              </div>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal' onClick={() => handleClose()}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResearchClientModal