import React, {useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  ModeleCentre,
  ModeleClientDemande,
  ModeleClientDemandePiece,
  modeleDemandePieceInitValues,
  ModeleEditCentre,
  ModeleImmatriculation,
} from '../../../../components/Models'
import {useLocation, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {
  getCatdata,
  getCategoriePiece,
  getCentres,
  getDemandePieceSuggestion,
  getSearchPieceRefConst,
  postDemandes,
  postGenreByCode,
} from '../../../../components/requests'
import {typePieces} from '../../../../components/Data'
import {SelectInput} from '../../../../components/inputs/SelectInput'
import {Autocomplete} from '../../../../components/Autocomplete'
import Swal from 'sweetalert2'
import moment from 'moment/moment'
import {formatTypePiecePourDemande} from '../../../../components/helpers'
import {AutoCompleteRefConst} from '../../../../components/AutoCompleteRefConst'
import {LoadingData} from '../../../../components/LoadingData'
import VehicleData from './VehicleData'
import Select from 'react-select'
import {toast, ToastContainer} from 'react-toastify'
import {Tooltip} from 'react-tooltip'
import ResearchVehicleModal from './ResearchVehicleModal'

const clientDemandeSchema = Yup.object().shape({
  commentaire: Yup.string().notRequired(),
})

const DemandeForm = (props) => {
  const {clientId, redirectToClientDemande} = props
  const navigate = useNavigate()
  const [demandeId] = useState(props.demandeId)
  const [immatriculationValide, setImmatriculationValide] = useState(false)
  const [immatriculation, setImmatriculation] = useState<ModeleImmatriculation>({})
  const [data] = useState<ModeleClientDemande>(props.data)
  const [pieces, setPieces] = useState<Array<ModeleClientDemandePiece>>([])
  const [, setTempsEcoule] = useState<string | null>(null)
  const [suggestionsProduits, setSuggestionsProduits] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [loadingSearchRefConst, setLoadingSearchRefConst] = useState(false)
  const [resultShow, setResultShow] = useState<boolean>(false)
  const [centres, setCentres] = useState<Array<ModeleEditCentre>>([])
  const [currentPage] = useState<number>(1)
  const [searchPage] = useState<string>('')
  const [reloadVerif, setReloadVerif] = useState<boolean>(false)

  // gestion des immats sélectionnées
  const useImmatArray = () => {
    const [immatsArray, setImmatsArray] = useState<any>([])

    const addImmat = (newImmat) => {
      setImmatsArray((prevArray) => {
        const exists = prevArray.some((item) => item.value === newImmat.value)

        if (exists) {
          return prevArray.map((item) => (item.value === newImmat.value ? newImmat : item))
        } else {
          return [...prevArray, newImmat]
        }
      })
    }

    const deleteImmat = (immatToDelete: string) => {
      Swal.fire({
        title: "Supprimer l'immatriculation ?",
        text: 'Cette action est irréversible.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer',
        cancelButtonText: 'Annuler',
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
      }).then((result) => {
        if (result.isConfirmed) {
          setImmatsArray((prev) => prev.filter((immat) => immat.value !== immatToDelete))
          // Supprimer l'immat assignée au pièce
          setImmatPieces((prevData) => {
            const updatedData = {...prevData}
            delete updatedData[immatToDelete]
            return updatedData
          })
          // Supprimer les pièces associées dans pieces
          setPieces((prevPieces) => prevPieces.filter((piece) => piece.immat !== immatToDelete))
          setReloadVerif(true)
        }
      })
    }
    return [immatsArray, addImmat, deleteImmat]
  }

  const [immatsArray, addImmat, deleteImmat] = useImmatArray()
  const [immatSelected, setImmatSelected] = useState('')
  const [immatPieces, setImmatPieces] = useState<Record<string, ModeleClientDemande[]>>({})
  const [immatCentre, setImmatCentre] = useState<{[immatriculation: string]: string | null}>({})
  const [immatAssignCentre, setImmatAssignCentre] = useState('')
  const [centre, setCentre] = useState<string | null>(null)
  const [centreLabel, setCentreLabel] = useState<string | null>(null)
  const [immat] = useState<string | null>(null)
  const [refConst, setRefConst] = useState<string>('')
  const [pieceRefConst, setPieceRefConst] = useState<Array<ModeleClientDemandePiece>>([])
  const [isTous, setIsTous] = useState<boolean>(true)
  const [, setSpinner] = useState<boolean>(false)
  const location = useLocation()
  const lState = location.state as LocationState
  const [dataImmatArr, setDataImmatArr] = useState<ModeleImmatriculation[]>([])
  const [dataImmatSelected, setDataImmatSelected] = useState<ModeleImmatriculation>({})
  const [centreSelected, setCentreSelected] = useState<ModeleCentre[]>(props.centreSelected ?? [])
  const clientDemandePath = `/vente/clients/${clientId}/afficher/demandClient/`
  const DemandeListPath = '/demandes/liste/'
  const [hover, setHover] = useState<number | null>(null)
  const [isVerifying, setIsVerifying] = useState(false)
  const [verifyStatus, setVerifyStatus] = useState<'success' | 'error' | 'not_found' | null>(null)
  const toastId = useRef<string | number | undefined>(undefined)
  const [showNoPieceOnImmat, setShowNoPieceOnImmat] = useState<boolean>(false)
  const [pieceSpinner, setPieceSpinner] = useState<boolean>(false)
  const [vehicleInfo, setVehicleInfo] = useState<boolean>(false)

  interface LocationState {
    immat: any
  }

  // Revérification infos véhicule après suppression d'une immatriculation
  useEffect(() => {
    const lastImmat = immatsArray.at(-1)
    if (reloadVerif && lastImmat != undefined) {
      immatsArray.length != 0 && verifyCatdata(lastImmat.value)
      setImmatSelected(lastImmat.value)
    }
  }, [reloadVerif, immatsArray])

  // gestion des pièces assigner à une immatriculation
  const addPiecesToImmat = (immatriculation: string, newPiece) => {
    setImmatPieces((prevData) => {
      // Vérifiez si l'immatriculation existe déjà
      if (prevData[immatriculation]) {
        const verif = prevData[immatriculation].some(
          (item) => item.typePiece.id === newPiece.typePiece.id
        )

        if (!verif) {
          // Sinon, ajoutez la nouvelle pièce
          return {
            ...prevData,
            [immatriculation]: [
              ...prevData[immatriculation],
              {...newPiece, immat: immatriculation},
            ],
          }
        } else {
          return prevData
        }
      } else {
        // Si l'immatriculation n'existe pas encore, créez une nouvelle entrée
        return {
          ...prevData,
          [immatriculation]: [{...newPiece, immat: immatriculation}],
        }
      }
    })
  }

  // suppression de pièce affectée à une immatriculation
  const removePieceFromImmat = (pieceId, immatToDel) => {
    if (!immatPieces[immatToDel]) return immatPieces // Si l'immatriculation n'existe pas, on ne change rien

    // Filtrer les pièces pour enlever celle avec le pieceId donné
    const updatedPieces = immatPieces[immatToDel].filter(
      (piece) => piece?.typePiece?.id !== pieceId
    )

    // Si plus aucune pièce ne reste, on supprime complètement l'immatriculation
    if (updatedPieces.length === 0) {
      const dataTemp = JSON.parse(JSON.stringify(immatPieces))
      delete dataTemp[immatToDel]
      setImmatPieces(dataTemp)
    } else {
      // Sinon, on met à jour la liste des pièces pour cette immatriculation
      setImmatPieces({
        ...immatPieces,
        [immatToDel]: updatedPieces,
      })
    }
  }

  const formik = useFormik<ModeleClientDemande>({
    initialValues: data,
    validationSchema: clientDemandeSchema,
    onSubmit: (values) => {
      setLoading(true)
    },
  })

  useEffect(() => {
    if (demandeId !== undefined && demandeId !== null) {
      if (data.pieces !== undefined) {
        const tmpPieces = [...data.pieces]
        tmpPieces.map((piece) => {
          if (piece.pieceEntre !== undefined && piece.pieceEntre !== null) {
            piece.numeroPieceEntre = piece.pieceEntre.code
            piece.idPieceEntre = piece.pieceEntre.id
          }
        })
        setPieces(tmpPieces)
      }
      if (data.donneesImmatriculation !== undefined) {
        setImmatriculation(data.donneesImmatriculation)
      }
      setImmatriculationValide(true)
    }
  }, [demandeId])

  const getCentresData = () => {
    getCentres(currentPage, searchPage)
      .then((centresRequest) => {
        if (centresRequest?.data !== undefined) {
          const transformedData = centresRequest.data.map((item) => ({
            value: item.id,
            label: item.nom,
          }))
          setCentres([...transformedData])
          setCentre(transformedData[0])
        }
      })
      .catch((e) => {})
  }

  useEffect(() => {
    immatsArray.length == 0 ? setVehicleInfo(true) : setVehicleInfo(false)
    getCentresData()
    // Sélectionne de la première immat
    if (immatsArray.length == 1) {
      setImmatSelected(immatsArray[0]?.value)
      setDataImmatSelected(dataImmatArr[0])
    }
    setImmatAssignCentre(immatsArray[0]?.value)
    console.log('immatsArray', immatsArray)
  }, [immatsArray])

  useEffect(() => {
    if (pieces !== undefined && pieces !== null && pieces.length) {
      pieces.map((piece) => {
        if (
          piece.id !== undefined &&
          piece.id !== null &&
          piece.typePiece !== undefined &&
          piece.typePiece !== null
        ) {
          getDemandePieceSuggestion(demandeId, piece.id).then((suggestionRequest) => {
            if (suggestionRequest?.status === 200 && piece.id !== undefined) {
              const updatedSuggestions = suggestionsProduits
              if (suggestionRequest?.data !== undefined && suggestionRequest?.data !== null) {
                updatedSuggestions[piece.id] = suggestionRequest?.data
              } else {
                updatedSuggestions[piece.id] = null
              }
              setSuggestionsProduits({...updatedSuggestions})
            }
          })
        }
      })
    }
  }, [pieces])

  useEffect(() => {
    if (demandeId !== undefined && demandeId !== null) {
      const intervalId = setInterval(() => {
        const currentDate = moment()
        const dateDemande = moment(data?.dateCreation)

        var duration = moment.duration(currentDate.diff(dateDemande))

        let totalSeconds: number = duration.asSeconds()
        let hours = Math.floor(totalSeconds / 3600)
        totalSeconds %= 3600
        let minutes = Math.floor(totalSeconds / 60)
        let seconds = totalSeconds % 60

        const hoursStr = String(hours).padStart(2, '0')
        const minutesStr = String(minutes).padStart(2, '0')
        const secondsStr = String(seconds.toFixed(0)).padStart(2, '0')

        setTempsEcoule(`${hoursStr}:${minutesStr}:${secondsStr}`)
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [demandeId])

  // verifier les données immatriculation par l'immatriculation
  useEffect(() => {
    verifyCatdata(immat)
  }, [immat])

  //Vérifie si tout les immat ont au moins une piece
  useEffect(() => {
    Object.keys(immatPieces).length != immatsArray.length
      ? setShowNoPieceOnImmat(true)
      : setShowNoPieceOnImmat(false)
  }, [immatPieces, immatsArray, pieces])

  // reception des données depuis le modal recherche immat
  useEffect(() => {
    if (lState?.immat && lState.immat.length != 0) {
      Promise.all(lState.immat.map((im) => verifyCatdata(im))).then(() => {})
    }
  }, [])

  // Suppression des toast si ToastId est undefined
  useEffect(() => {
    if (toastId.current == undefined) {
      toast.dismiss(toastId.current)
    }
  }, [toastId.current])

  // vérification des données immatriculations par l'immatriculation
  const verifyCatdata = async (immat) => {
    const immatriculation = immat ?? formik.values?.immatriculation
    if (immatriculation) {
      setIsVerifying(true)
      setVerifyStatus(null) // Réinitialiser l'état précédent
      setLoading(false)

      // Créer un toast en mode "loading" et stocker son ID
      toastId.current = toast.loading('Recherche du véhicule en cours...')

      try {
        const immatriculationRequest = await getCatdata(immatriculation)

        if (immatriculationRequest?.status === 200 && immatriculationRequest?.data) {
          if (
            immatriculationRequest.data.sMessageErreur === 'No data for this vehicle' ||
            immatriculationRequest.data.sMessageErreur === null
          ) {
            setImmatriculationValide(true)
            setVerifyStatus('not_found')
          } else {
            const updatedData = {...immatriculation, ...immatriculationRequest.data}
            getGenreByCode(immatriculationRequest)
            setImmatriculation(updatedData)

            const exist = dataImmatArr.some((item) => item.id === immatriculationRequest?.data?.id)
            if (immatriculationRequest?.data && !exist) {
              setDataImmatArr([...dataImmatArr, immatriculationRequest.data])
              setDataImmatSelected(immatriculationRequest.data)
            }

            setImmatriculationValide(true)
            await formik.setFieldValue(
              'immatriculation',
              immatriculationRequest.data.sImmatriculationSiv
            )
            setSpinner(false)
            setVerifyStatus('success')
          }
        } else {
          setImmatriculationValide(false)
          setVerifyStatus('error')
        }
      } catch (ex: any) {
        if (ex.response?.status === 500) {
          setImmatriculationValide(true)
          setSpinner(false)
        }
        setVerifyStatus('not_found')
      } finally {
        setLoading(false)
        setIsVerifying(false)
      }
    }
  }

  // UseEffect pour mettre à jour le toast après la fin de la vérification
  useEffect(() => {
    if (!isVerifying && verifyStatus && toastId.current) {
      if (verifyStatus === 'success') {
        toast.update(toastId.current, {
          render: 'Véhicule identifié',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        })
      } else if (verifyStatus === 'not_found') {
        toast.update(toastId.current, {
          render: 'Véhicule non identifié',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        })
      } else if (verifyStatus === 'error') {
        toast.update(toastId.current, {
          render: 'Erreur lors de la vérification',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        })
      }

      toastId.current = undefined // Réinitialiser l'ID du toast après mise à jour
    }
  }, [isVerifying, verifyStatus])

  // ajout immat dans le state de la location(stockage des immats en cas de rechargment)
  const ajouterImmat = (nouvelleImmat: string) => {
    const prevState = lState || {immat: []}
    if (prevState.immat.includes(nouvelleImmat)) {
      return
    }

    navigate('.', {
      replace: true,
      state: {
        ...prevState,
        immat: [...prevState.immat, nouvelleImmat],
      },
    })
  }

  // récupération du genre pour avoir le centre correspondant
  const getGenreByCode = (immatriculationRequest) => {
    postGenreByCode(immatriculationRequest?.data.sgenre).then((genre) => {
      if (genre?.status === 200 && genre?.data !== undefined) {
        if (immatriculationRequest?.data?.sImmatriculationSiv) {
          const exists = immatsArray.some(
            (item) => item.value === immatriculationRequest?.data?.sImmatriculationSiv
          )
          if (!exists) {
            addImmat({
              value: immatriculationRequest?.data.sImmatriculationSiv,
              label: immatriculationRequest?.data.sImmatriculationSiv,
              centreLabel: genre.data.centreDefaut?.nom ?? '',
            })
            // setter les données de centre et immat à envoyer
            setImmatCentre((prevImmatCentre) => ({
              ...prevImmatCentre,
              [immatriculationRequest?.data.sImmatriculationSiv]: genre.data.centreDefaut?.id,
            }))
            ajouterImmat(immatriculationRequest?.data.sImmatriculationSiv)
          }
        }
      }
    })
  }

  const deletePieceline = (pieceId: number | undefined, immatToDel: string | undefined) => {
    Swal.fire({
      title: 'Attention',
      text: 'Merci de confirmer la suppression',
      icon: 'warning',
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
      showCancelButton: true,
      confirmButtonColor: '#1BC5BD',
      cancelButtonColor: '#F64E60',
    }).then((alertResult) => {
      if (alertResult.isConfirmed) {
        setPieces((prevPieces) => {
          return prevPieces.filter((piece) => {
            if (immatSelected === 'Tous') {
              // Supprimer normalement en fonction du pieceId
              return piece.typePiece?.id !== pieceId
            }
            // Supprimer seulement si la pièce correspond à l'immatriculation sélectionnée
            return !(piece.typePiece?.id === pieceId && piece.immat === immatSelected)
          })
        })

        removePieceFromImmat(pieceId, immatToDel)
      }
    })
  }

  // affichage des données de pièces liées aux immatriculations
  const renderPiecesTable = () => {
    return <>{renderFormRowPieces()}</>
  }

  // affichage des immats recherchées avec leur centre
  const renderImmatTable = () => {
    return <>{renderFormRowImmat()}</>
  }

  const updateFieldValue = (immatConcernee, field, value, idTypePiece) => {
    setPieces((prevPieces) =>
      prevPieces.map((piece) =>
        piece?.immat === immatConcernee && piece?.typePiece?.id === idTypePiece
          ? {...piece, [field]: value}
          : piece
      )
    )

    setImmatPieces((prevState) => {
      const updatedImmatPieces = {...prevState}
      let updatedPieceArr = [...updatedImmatPieces[immatConcernee]]
      const pieceIndex = updatedPieceArr.findIndex((p) => p.typePiece.id === idTypePiece)

      if (pieceIndex !== -1) {
        updatedPieceArr[pieceIndex] = {
          ...updatedPieceArr[pieceIndex],
          [field]: value,
        }
      }
      updatedImmatPieces[immatConcernee] = updatedPieceArr

      return updatedImmatPieces
    })
  }

  // Gestion de l'input quantité(mettre 1 si c'est vide ou négatif ou incorrect)
  const handleBlur = (immatConcernee, idTypePiece) => {
    setPieces((prevPieces) => {
      const updatedPieces = [...prevPieces]
      const piece = updatedPieces.find(
        (p) => p.immat === immatConcernee && p?.typePiece?.id === idTypePiece
      )

      if (
        piece &&
        (piece?.quantite === '' || piece?.quantite === undefined || piece?.quantite <= 0)
      ) {
        const updatedPiece = {...piece, quantite: 1}
        const pieceIndex = updatedPieces.findIndex(
          (p) => p.immat === immatConcernee && p?.typePiece?.id === idTypePiece
        )
        updatedPieces[pieceIndex] = updatedPiece
      }

      return updatedPieces
    })
  }

  // gestion de la sélection de centre
  const handleCentreChange = (selectedOptions) => {
    formik.setFieldValue('u_centre', selectedOptions.value)
    setCentreSelected(selectedOptions)
    setCentre(selectedOptions?.value)
    setCentreLabel(selectedOptions?.label)
  }

  // gestion du transfert de centre
  const handleTransfert = () => {
    // setter le centre transférer à l'immatriculation
    setImmatCentre((prevImmatCentre) => ({
      ...prevImmatCentre,
      [immatAssignCentre]: centre,
    }))

    // setter le centre pour l'affichage immat - centre - action
    addImmat({
      value: immatAssignCentre ?? '',
      label: immatAssignCentre ?? '',
      centreLabel: centreLabel ?? '',
    })
  }

  // affichage des infos de véhicule par immatriculation
  const handleInfoVehicle = (immat) => {
    formik.setFieldValue('immatriculation', immat)
    verifyCatdata(immat)
    // ShowCatData();
  }

  // soumission du formulaire de confirmation de la création de demande
  const SubmitDemande = () => {
    postDemandes(clientId, immatCentre, immatPieces)
      .then((clientDemandeRequest) => {
        if (clientDemandeRequest?.status === 200) {
          if (clientDemandeRequest?.data !== undefined) {
            navigate(redirectToClientDemande ? clientDemandePath : DemandeListPath, {
              state: {showSubmitToast: true},
            })
          }
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  // composant affichage des immatriculations recherchées
  const renderFormRowImmat = () => {
    return immatsArray.map((object, index) => {
      return (
        <tr
          key={index}
          className={immatriculation.sImmatriculationSiv == object.value ? 'bg-gray-200' : ''}
        >
          <td>
            <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>{object.value}</p>
          </td>
          <td>{object.centreLabel}</td>
          <td>
            <div className='d-flex gap-3 justify-content-center'>
              <span className='btn btn-icon btn-bg-light btn-sm'>
                <i
                  className='bi bi-eye fs-2 btn-active-color-primary'
                  onClick={() => handleInfoVehicle(object.value)}
                  style={{cursor: 'pointer'}}
                  data-tooltip-id='tooltip'
                  data-tooltip-content='Infos du véhicule'
                  data-tooltip-place='left'
                ></i>
              </span>
              <span className='btn btn-icon btn-bg-light btn-sm'>
                <i
                  className='bi bi-arrows-angle-contract fs-2'
                  onClick={() => setImmatAssignCentre(object.value)}
                  style={{cursor: 'pointer'}}
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_transfert'
                  data-bs-dismiss='true'
                  data-tooltip-id='tooltip'
                  data-tooltip-content='Transférer'
                  data-tooltip-place='bottom'
                ></i>
              </span>
              <span className='btn btn-icon btn-bg-light btn-sm'>
                <i
                  className='bi bi-trash fs-2'
                  onClick={() => deleteImmat(object.value)}
                  style={{
                    color: hover === index ? '#f1416c' : '',
                    transition: 'color 0.3s ease',
                    cursor: 'pointer',
                  }}
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(null)}
                  data-tooltip-id='tooltip'
                  data-tooltip-content="Supprimer l'immatriculation"
                  data-tooltip-place='right'
                ></i>
              </span>
            </div>
          </td>
        </tr>
      )
    })
  }

  // composant affichage des pièces assignées aux immatriculations
  const renderFormRowPieces = () => {
    return pieces
      .filter((piece) => immatSelected === 'Tous' || piece.immat === immatSelected)
      .map((piece, index) => {
        return (
          <tr key={index}>
            <td>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                {piece.id !== undefined && piece.id !== null
                  ? [
                      piece.typePiece !== undefined &&
                      piece.typePiece !== null &&
                      piece.typePiece?.id !== undefined
                        ? formatTypePiecePourDemande(piece.typePiece)
                        : piece.referenceManuelle,
                    ]
                  : piece.nomTypePiece}
              </p>
            </td>
            <td>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                {piece?.immat}
              </p>
            </td>
            <td>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                {piece?.typePiece?.sousCategorie?.categorie?.nom}
              </p>
            </td>
            <td>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                {piece?.typePiece?.sousCategorie?.nom}
              </p>
            </td>
            <td>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                <input
                  type={'text'}
                  className={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                  name={'commentaire' + index}
                  placeholder={'Commentaire'}
                  value={piece.commentaire}
                  // disabled={isTous}
                  onChange={(e) => {
                    updateFieldValue(piece.immat, 'commentaire', e.target.value, piece.idTypePiece)
                  }}
                />
              </p>
            </td>
            <td>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                <input
                  type={'number'}
                  className={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                  name={'quantite' + index}
                  placeholder={'Quantité'}
                  value={piece.quantite}
                  // disabled={isTous}
                  onChange={(e) => {
                    // empêche l'insertion des valeurs décimal
                    const value = e.target.value
                    if (/^\d+$/.test(value)) {
                      updateFieldValue(piece.immat, 'quantite', value, piece.idTypePiece)
                    }
                  }}
                  min={1}
                  onBlur={() => handleBlur(piece.immat, piece.idTypePiece)}
                />
              </p>
            </td>
            <td>
              <div className='d-flex justify-content-end flex-shrink-0'>
                {piece.id !== undefined &&
                  suggestionsProduits[piece.id] !== undefined &&
                  suggestionsProduits[piece.id] !== null && (
                    <a
                      href={`https://shop.koena.fr/piece?sku=${suggestionsProduits[piece.id]}`}
                      target={'_blank'}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <i className='bi bi-basket fs-5'></i>
                    </a>
                  )}
                {(demandeId === null ||
                  !data?.clientDemandeEtape ||
                  data?.clientDemandeEtape?.id === 1) && (
                  <p
                    onClick={() => {
                      deletePieceline(piece?.typePiece?.id, piece?.immat)
                    }}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <i
                      className='bi bi-trash fs-5'
                      data-tooltip-id='tooltip'
                      data-tooltip-content='Supprimer la pièce'
                      data-tooltip-place='right'
                    ></i>
                  </p>
                )}
              </div>
            </td>
          </tr>
        )
      })
  }

  // composant de la recherche de pièce par nom pièce
  const renderFormSearchPieceAutocomplete = () => {
    if (demandeId && data?.clientDemandeEtape && data?.clientDemandeEtape?.id !== 1) {
      return null
    }
    return (
      <div className='position-relative'>
        <Autocomplete
          placeholder={'Recherche pièce'}
          suggestions={typePieces}
          disabled={isTous}
          onSelect={(suggestion) => {
            setPieceSpinner(true)
            getCategoriePiece(suggestion.value).then((categoriePiece) => {
              if (categoriePiece?.status === 200) {
                if (categoriePiece?.data !== undefined) {
                  setPieceSpinner(false)
                  const updatedPieces = pieces
                  // vérifier si la pièce est déjà sélectionnée pour l'immat
                  const check = pieces.some(
                    (item) => item.idTypePiece == suggestion.value && item.immat == immatSelected
                  )

                  if (!check) {
                    updatedPieces.push(
                      Object.assign(
                        {...modeleDemandePieceInitValues},
                        {
                          idTypePiece: suggestion.value,
                          nomTypePiece: suggestion.label,
                          immat: immatSelected,
                          typePiece: categoriePiece.data,
                          marque: dataImmatSelected.stecMarque,
                          modele: dataImmatSelected.stecModele,
                        }
                      )
                    )
                  }
                  // appel à la fonction de mise à jour des pièces et des immats
                  addPiecesToImmat(immatSelected, pieces?.at(-1))
                }
              }
            })
          }}
        />
        {pieceSpinner && (
          <div
            className='position-absolute'
            style={{
              top: '50%',
              right: '5%',
              transform: 'translateY(-50%)',
            }}
          >
            <span
              className='spinner-border spinner-border-sm align-middle ms-2'
              style={{
                color: '#99a1b7',
                width: '17px',
                height: '17px',
              }}
            ></span>
          </div>
        )}
      </div>
    )
  }

  // composant de la recherche de pièce par le référence constructeur
  const renderFormSearchRefConstructAutocomplete = () => {
    if (demandeId && data?.clientDemandeEtape && data?.clientDemandeEtape?.id !== 1) {
      return null
    }
    return (
      <AutoCompleteRefConst
        placeholder={'Recherche Ref. Constructeur'}
        onChange={(e) => setRefConst(e.target.value)}
        value={refConst}
        resultat={pieceRefConst}
        disabled={isTous}
        resultShow={resultShow}
        onSelect={(piece) => {
          const updatedPieces = pieces
          updatedPieces.push(
            Object.assign(
              {...modeleDemandePieceInitValues},
              {
                idTypePiece: piece.typePiece.id,
                nomTypePiece: piece.typePiece.nom,
                immat: immatSelected,
                typePiece: piece.typePiece,
                refConst: piece.referenceConstructeur,
              }
            )
          )
          // appel à la fonction de mise à jour des pièces et des immats
          addPiecesToImmat(immatSelected, pieces?.at(-1))
          setPieces([...updatedPieces])
        }}
      />
    )
  }

  // gestion recherche de pièce par référence constructeur
  const searchPieceRefConst = (e) => {
    e.preventDefault()
    setLoadingSearchRefConst(true)
    getSearchPieceRefConst(refConst)
      .then((piecesRefConst) => {
        if (piecesRefConst?.status === 200) {
          if (piecesRefConst?.data !== undefined) {
            setResultShow(true)
            setPieceRefConst(piecesRefConst.data)
            setLoadingSearchRefConst(false)
          }
        }
      })
      .catch(() => {
        console.log('error')
      })
  }

  const handleCancelDemande = () => {
    Swal.fire({
      title: 'Attention',
      text: 'Abandonner la demande?',
      icon: 'warning',
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
      showCancelButton: true,
      confirmButtonColor: '#1BC5BD',
      cancelButtonColor: '#F64E60',
    }).then((alertResult) => {
      if (alertResult.isConfirmed) {
        navigate(redirectToClientDemande ? clientDemandePath : DemandeListPath, {
          state: {showCancelToast: true},
        })
      }
    })
  }

  const handleChangeImmatSel = (e) => {
    setImmatSelected(e.target.value)
    if (e.target.value != 'Tous') {
      const objImmat = dataImmatArr.filter((i) => i.sImmatriculationSiv === e.target.value)
      setDataImmatSelected(objImmat[0])
      setIsTous(false)
    } else {
      setIsTous(true)
    }
  }

  const handleAddImmat = (immat, selected) => {
    verifyCatdata(selected ?? immat)
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9'>
          <>
            <div className='card-title mb-4'>
              <h3 className='fw-bolder m-0'>Identifier le véhicule</h3>
            </div>
            {!immatriculationValide ? (
              <LoadingData />
            ) : (
              <>
                <div className='fv-plugins-message-container my-5 text-gray-600'>
                  <ToastContainer position='bottom-right' />
                </div>
                <div className={'row'}>
                  <div className='col-lg-4'>
                    <div className='table-responsive'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bold text-muted text-center'>
                            <th className='min-w-100px'>Immatriculation</th>
                            <th className='min-w-100px'>Centre</th>
                            <th className='min-w-100px'>Actions</th>
                          </tr>
                        </thead>
                        <tbody className='text-center'>{renderImmatTable()}</tbody>
                      </table>
                    </div>
                  </div>
                  <div className='col-lg-8'>
                    {immatriculationValide && (
                      <>
                        <VehicleData
                          immatriculation={immatriculation}
                          vehicleInfo={vehicleInfo}
                          prevArrow={false}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div style={immatriculationValide ? {} : {display: 'none'}}>
                  <div className='card-title mb-4 mt-5'>
                    <h3 className='fw-bolder m-0'>Recherche de pièces</h3>
                  </div>

                  <div className='row mb-6'>
                    <div className='col-md-3 mb-4'>
                      <SelectInput
                        fieldName={'idCentre'}
                        inputClassName={'form-select form-select-solid form-select-lg fw-bold'}
                        options={[{value: 'Tous', label: 'Tous'}, ...immatsArray]}
                        formik={formik}
                        onChange={(e) => handleChangeImmatSel(e)}
                      />
                    </div>
                    <div className='col-md-3 mb-4'>{renderFormSearchPieceAutocomplete()}</div>
                    <div className='col-md-3 mb-4'>
                      {renderFormSearchRefConstructAutocomplete()}
                    </div>
                    <div className='col-md-2 d-flex justify-content-center align-items-start'>
                      <button
                        className='btn btn-info'
                        disabled={refConst.length == 0 || isTous}
                        onClick={(e) => searchPieceRefConst(e)}
                      >
                        {!loadingSearchRefConst ? (
                          'Rechercher'
                        ) : (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Recherche{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    {isTous && (
                      <div className='d-flex gap-2 mb-2 mx-3 align-items-center'>
                        <i className='bi bi-info-square text-warning'></i>
                        <span className={'text-warning'}>
                          Veuillez sélectionner une immatriculation dans la liste pour rechercher
                          une pièce!
                        </span>
                      </div>
                    )}
                    <div className='table-responsive'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-150px'>Référence pièce</th>
                            <th className='min-w-150px'>Immatriculation</th>
                            <th className='min-w-150px'>Catégorie</th>
                            <th className='min-w-150px'>Sous catégorie</th>
                            <th className='min-w-140px'>Commentaire</th>
                            <th className='min-w-140px'>Quantité</th>
                            <th className='min-w-100px text-end'>Actions</th>
                          </tr>
                        </thead>
                        <tbody>{renderPiecesTable()}</tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className='card-footer d-flex flex-column py-6 px-9 gap-5'>
                  <div className='d-flex flex-row justify-content-end gap-5'>
                    <button
                      type='button'
                      className='btn btn-light'
                      onClick={() => handleCancelDemande()}
                    >
                      Annuler
                    </button>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      disabled={loading || Object.keys(immatPieces).length == 0}
                      onClick={SubmitDemande}
                    >
                      {!loading && 'Confirmer'}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Confirmation de la demande...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div>
                    {showNoPieceOnImmat && (
                      <div className='d-flex gap-2 mb-2 mx-3 align-items-center justify-content-end'>
                        <i className='bi bi-info-square text-warning'></i>
                        <span className={'text-warning'}>
                          Veuillez assigner une pièce au moins pour chaque immatriculation!
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
          <ResearchVehicleModal clientId={clientId} handleSubmit={handleAddImmat} />
          <div className='modal fade' tabIndex={-1} id='kt_modal_transfert'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Transfert de centre</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <Select
                    className='react-select-styled react-select-solid react-select-lg'
                    classNamePrefix='react-select'
                    options={centres}
                    fieldName='u_centre'
                    placeholder='Sélectionner centres'
                    value={centreSelected}
                    onChange={(e) => handleCentreChange(e)}
                  />
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                    Fermer
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary'
                    data-bs-dismiss='modal'
                    onClick={() => handleTransfert()}
                  >
                    Transferer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/*Affichage du tooltip*/}
      <Tooltip id='tooltip' />
    </div>
  )
}

export {DemandeForm}
