import React, {useEffect, useRef, useState} from 'react'
import {ModeleClientDemandePiece} from './Models'

const AutoCompleteRefConst = (props) => {
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const ulRef = useRef<HTMLUListElement | null>(null);
  const { resultat, resultShow } = props

  useEffect(() => {
    if (resultat) setShowSuggestions(true)
  }, [resultat])

  const onClick = (index) => {
    props.onSelect(resultat[index]);
    setActiveSuggestion(0);
    setShowSuggestions(false);
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ulRef.current && !ulRef.current.contains(event.target as Node)) {
        setShowSuggestions(false); // Ferme les suggestions
      }
    };

    if (showSuggestions) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSuggestions]);

  const renderSuggestionsList = () => {
    if (showSuggestions) {
      if (resultat.length) {
        return (
          <ul ref={ulRef} className={"suggestions w-100"}>
            {resultat.map((res: ModeleClientDemandePiece, index) => {
              let className = index === activeSuggestion ? "suggestion-active" : "";

              return (
                <li className={className} key={res.id} onClick={() => onClick(index)}>
                  {res?.typePiece?.nom}
                </li>
              );
            })}
          </ul>
        );
      }else{
        return (
          <ul ref={ulRef} className={"suggestions w-100 text-white"} style={{backgroundColor: '#f98893'}}>
            Pièce non trouvée
          </ul>
        );
      }
    }
  };
  return (
    <div className={"autocomplete-container"}>
      <input
        type="text"
        className={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
      {
        (resultShow) && renderSuggestionsList()
      }
    </div>
  );
}

export {AutoCompleteRefConst}
