import React, {useEffect, useState} from 'react'
import * as Yup from "yup";
import {useFormik} from "formik";
import {TextInput} from "../../../../components/inputs/TextInput";
import {ModeleCentre} from "../../../../components/Models";
import {useNavigate} from "react-router-dom";
import {pays} from "../../../../components/Data";
import {SelectInput} from '../../../../components/inputs/SelectInput'

const centreSchema = Yup.object().shape({
  code: Yup.string().required("Le code est requis"),
  nom: Yup.string().required("Le nom est requis"),
  telephone: Yup.string().matches(
    /^(?:\+33|33|0)[\s.-]*[1-9](?:[\s.-]?\d{2}){4}$/,
    'Format du numéro de téléphone invalide'
  ).notRequired(),
  email: Yup.string().notRequired().email("L'adresse mail n'est pas valide"),
})
const CentreForm = (props) => {
  const navigate = useNavigate();
  const [centreId, setCentreId] = useState(props.centreId);
  const [data, setData] = useState<ModeleCentre>(props.data)
  const [loading, setLoading] = useState(false)

  const formik = useFormik<ModeleCentre>({
    initialValues: data,
    validationSchema: centreSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign({...data}, values)

        props.submitForm(updatedData, response => {
          formik.resetForm();
          setLoading(false);
        });
      }, 200)
    },
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <label className='col-lg-2 col-form-label fw-bold fs-6'>Siret</label>
            <div className='col-lg-8'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'siret'}
                    inputPlaceholder={'Siret'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-2 col-form-label required fw-bold fs-6'>Code</label>
            <div className='col-lg-8'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'code'}
                    inputPlaceholder={'Code'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-2 col-form-label required fw-bold fs-6'>Nom</label>
            <div className='col-lg-8'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'nom'}
                    inputPlaceholder={'Nom'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-2 col-form-label fw-bold fs-6'>Email</label>
            <div className='col-lg-8'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'email'}
                    inputPlaceholder={'Email'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-2 col-form-label fw-bold fs-6'>Telephone</label>
            <div className='col-lg-8'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'telephone'}
                    inputPlaceholder={'Telephone'}
                    inputType={'telephone'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-2 col-form-label fw-bold fs-6'>Adresse</label>

            <div className='col-lg-8'>
              <div className='row mb-4'>
                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'adresse'}
                    inputPlaceholder={'Adresse'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>

                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'complementAdresse'}
                    inputPlaceholder={"Complément d'adresse"}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'codePostal'}
                    inputPlaceholder={'Code postal'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>

                <div className='col-lg-6 fv-row'>
                  <TextInput
                    fieldName={'ville'}
                    inputPlaceholder={'Ville'}
                    inputType={'text'}
                    inputClassName={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                    formik={formik}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <SelectInput
                    fieldName={'idPays'}
                    inputClassName={'form-select form-select-solid form-select-lg fw-bold'}
                    options={pays}
                    formik={formik}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          {centreId !== null && (
            <button
              type='button'
              className='btn btn-light me-3'
              disabled={loading}
              onClick={() => {
                formik.resetForm()
                navigate(`/centres`)
              }}
            >
              Annuler les modifications
            </button>
          )}

          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Enregistrer'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Enregistrement...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {CentreForm}
