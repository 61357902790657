import {useState} from 'react'
import {ModeleRoleAndModules} from '../../../../components/Models'
import {Form, Button, InputGroup, Container, Col, Row} from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import {createRole} from '../../../../components/requests'

const RoleRegisterForm = (props) => {
  const {showModalProp, modulesInitial, addDataRole, setLoadingProp} = props
  const initialFieldValidation = {
    libelleRoleValid: false,
    codeRoleValid: false,
    formValid: false,
    formErrors: {libelleRole: '', codeRole: ''},
  }
  const [fieldValidation, setFieldValidation] = useState(initialFieldValidation)

  const initialStateWithStatus = {libelle: '', code: '', modules: [...modulesInitial]}
  const [responseBody, setResponseBody] = useState<ModeleRoleAndModules>(initialStateWithStatus)
  const [lastActionModified, setLastActionModified] = useState<ModeleRoleAndModules>({
    libelle: '',
    code: '',
    modules: [],
    status: true,
  })

  const inputChangeHandler = (event) => {
    if (event != null) {
      const {name, value, checked} = event.target

      if (name !== 'libelle' && name !== 'code') {
        const moduleId = name.split('_')[1]
        const fonctId = name.split('_')[2]

        const updatedResponseBody = {
          ...responseBody,
          modules: responseBody?.modules?.map((module) => {
            if (module.id == moduleId) {
              return {
                ...module,
                fonctionnalites: module?.fonctionnalites?.map((fonctionnalite) => {
                  if (fonctionnalite.id == fonctId) {
                    return {...fonctionnalite, value: checked}
                  }
                  return fonctionnalite
                }),
              }
            }
            return module
          }),
        }
        setResponseBody(updatedResponseBody)
      } else {
        setResponseBody({...responseBody, [name]: value})
        setLastActionModified({...lastActionModified, [name]: value})
        validateField(name, value)
      }
    }
  }

  const validateField = (fieldName: string, value: string) => {
    let fieldValidationErrors = fieldValidation.formErrors
    let libelleRoleValid = fieldValidation.libelleRoleValid
    let codeRoleValid = fieldValidation.codeRoleValid

    switch (fieldName) {
      case 'libelle':
        if (value.length === 0) {
          libelleRoleValid = false
          fieldValidationErrors.libelleRole = 'Ce champ est requis.'
        } else if (value.length !== 0 && value.length < 3) {
          libelleRoleValid = false
          fieldValidationErrors.libelleRole = 'Trop court au moins 3 caractères.'
        } else {
          libelleRoleValid = true
          fieldValidationErrors.libelleRole = ''
        }
        break
      case 'code':
        if (value.length === 0) {
          codeRoleValid = false
          fieldValidationErrors.codeRole = 'Ce champ est requis.'
        } else if (value.length !== 0 && value.length < 3) {
          codeRoleValid = false
          fieldValidationErrors.codeRole = 'Trop court au moins 3 caractères.'
        } else {
          codeRoleValid = true
          fieldValidationErrors.codeRole = ''
        }
        break
      default:
        break
    }

    const validateForm = libelleRoleValid && codeRoleValid
    const newFieldValidation = {
      formErrors: fieldValidationErrors,
      libelleRoleValid: libelleRoleValid,
      codeRoleValid: codeRoleValid,
      formValid: validateForm,
    }
    setFieldValidation(newFieldValidation)
  }

  const errorClass = (error: any) => {
    return error.length === 0 ? false : true
  }

  const errorFeeBack = (error: any) => {
    return error.length === 0 ? '' : error
  }

  const OnBlur = (event) => {
    const {name, value} = event.target
    validateField(name, value)
  }

  const onSubmitHandler = (event) => {
    event.preventDefault()
    showModal(false)
    setLoadingProp(true)
    /*  addDataRole({
                "id": 23,
                "code": responseBody.codeRole,
                "libelle": responseBody.libelleRole
            }) */
    createRole(responseBody)
      .then((roleRequest) => {
        if (roleRequest?.status === 200) {
          if (roleRequest?.data !== undefined) {
            addDataRole(roleRequest.data)
            setLoadingProp(false)
          } else {
            setLoadingProp(false)
          }
        } else {
          setLoadingProp(false)
        }
      })
      .catch((e) => {
        setLoadingProp(false)
      })
  }

  const showModal = (val: boolean) => {
    showModalProp(val)
  }

  const handleChangeAll = (event, module) => {
    let moduleObjAction: any = {}
    if (event != null) {
      const {name, value, checked} = event.target
      let moduleId = `${module.id}`
      let moduleName = `module_${module.id}`

      if (name !== 'libelle' && name !== 'code') {
        const updatedResponseBody = {
          ...responseBody,
          modules: responseBody?.modules?.map((module) => {
            if (module.id == moduleId) {
              return {
                ...module,
                fonctionnalites: module?.fonctionnalites?.map((fonctionnalite) => {
                  return {...fonctionnalite, value: checked}
                }),
              }
            }
            return module;
          }),
          [moduleName]: checked,
        }
        setResponseBody(updatedResponseBody)
        return;
      }
    }

    const newModuleObjAction = {...responseBody.modules, ...moduleObjAction}
    const newlastActionModified = {...lastActionModified.modules, ...moduleObjAction}
    setResponseBody({...responseBody, modules: newModuleObjAction})
    setLastActionModified({...lastActionModified, modules: newlastActionModified})

    return false
  }

  const readProp = (obj, prop) => obj[prop]

  // permet de activer/désactiver le switch "Tous" pour chaque module
  const handleAllInput = (moduleId) : boolean => {
    const foundModule = responseBody?.modules?.find(module => module.id == moduleId);
    return !foundModule?.fonctionnalites?.some(fonctionnalite => fonctionnalite.value == false);
  }

  const GrantAll = (props) => {
    const {module} = props

    return (
      <div className='ms-auto'>
        <Row>
          <Col md={11} className='text-end'>
            <Form.Label>Tous :</Form.Label>
          </Col>
          <Col md={1}>
            <Form.Check type='checkbox' className='mb-4 form-switch'>
              <Form.Check.Input
                className='me-2'
                name={`allGranted_${module.id}`}
                checked = {handleAllInput(module.id)}
                onChange={(e) => handleChangeAll(e, module)}
              />
            </Form.Check>
          </Col>
        </Row>
      </div>
    )
  }

  const ModuleRow = (props) => {
    const {module, fonctionnalite, handleChangeProps} = props
    const handleChange = (event) => {
      return handleChangeProps(event)
    }
    const fonctionnaliteAttr = `${fonctionnalite.libelle.toLowerCase()}_${module.id}_${
      fonctionnalite.id
    }`

    return (
      <Row>
        <Col md={11}>{fonctionnalite.libelle}</Col>

        <Col md={1}>
          <Form.Check type='checkbox' className='mb-4'>
            <Form.Check.Input
              className='me-2'
              name={fonctionnaliteAttr}
              checked={fonctionnalite.value}
              onChange={(e) => handleChange(e)}
            />
          </Form.Check>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Form action='#' onSubmit={onSubmitHandler}>
        <Container>
          <Row className='mb-5'>
            <Col md={12}>
              <InputGroup className='mb-3'>
                <InputGroup.Text>Role:</InputGroup.Text>
                <Form.Control
                  aria-label='Libelle'
                  required
                  isInvalid={errorClass(fieldValidation.formErrors.libelleRole)}
                  placeholder='Libelle'
                  name='libelle'
                  onChange={(e) => inputChangeHandler(e)}
                  onBlur={(e) => OnBlur(e)}
                />
                <Form.Control
                  aria-label='Code'
                  required
                  isInvalid={errorClass(fieldValidation.formErrors.codeRole)}
                  placeholder='Code'
                  name='code'
                  onChange={(e) => inputChangeHandler(e)}
                  onBlur={(e) => OnBlur(e)}
                />

                {!fieldValidation.libelleRoleValid &&
                  fieldValidation.formErrors.libelleRole !== '' && (
                    <Form.Control.Feedback type='invalid'>
                      {errorFeeBack(fieldValidation.formErrors.libelleRole)}
                    </Form.Control.Feedback>
                  )}
                {!fieldValidation.codeRoleValid && fieldValidation.formErrors.codeRole !== '' && (
                  <Form.Control.Feedback
                    style={
                      fieldValidation.libelleRoleValid
                        ? {position: 'relative', left: '52%', top: '-3px'}
                        : {position: 'relative', left: '54%', top: '-25px'}
                    }
                    type='invalid'
                  >
                    {errorFeeBack(fieldValidation.formErrors.codeRole)}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
            </Col>
          </Row>
          {responseBody?.modules?.map((module, index) => (
            <Accordion>
              <Accordion.Item eventKey={module.code}>
                <Accordion.Header> {module.libelle}</Accordion.Header>
                <Accordion.Body>
                  <Row className='mb-3 mt-0'>
                    <Col md={12}>
                      <GrantAll module={module} />
                    </Col>
                  </Row>
                  {module.fonctionnalites.length > 0 &&
                    module.fonctionnalites.map((f) => (
                      <ModuleRow
                        key={`m-input-${f.id}`}
                        module={module}
                        fonctionnalite={f}
                        handleChangeProps={inputChangeHandler}
                      />
                    ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
          <Row className='mt-5'>
            <Col md={12} style={{textAlign: 'right'}}>
              <Button variant='secondary me-5' onClick={() => showModal(false)}>
                Annuler
              </Button>
              <Button variant='primary' type='submit' disabled={!fieldValidation.formValid}>
                Enregistrer
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
}

export {RoleRegisterForm}
