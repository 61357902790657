import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from "react-router-dom";
import {getClientEncours} from "../../../../../../components/requests";
import {LoadingData} from '../../../../../../components/LoadingData'
import {ModeleEncours} from '../../../../../../components/Models'
import moment from 'moment/moment'
import {useAuth} from "../../../../../modules/auth";
import {shouldDisplayFonctionnalite} from "../../../../../../components/helpers";


const ClientEncours = () => {
  const navigate = useNavigate();
  let { clientId } = useParams();
  const [data, setData] = useState<Array<ModeleEncours>>([]);
  const [loading, setLoading] = useState(true);
  const {currentUser} = useAuth()
  useEffect(() => {
    getClientData()
  }, [clientId]);

  const getClientData = () => {
    getClientEncours(clientId).then(clientEncoursRequest => {
      if (clientEncoursRequest?.status === 200) {
        if (clientEncoursRequest?.data !== undefined) {
          setData(clientEncoursRequest?.data)
          setLoading(false);
        }
      } else {
        navigate(`/vente/clients/liste`);
      }
    });
  }
  return (
    <>
      {loading ? (
        <LoadingData />
      ) : (
        <>
          {shouldDisplayFonctionnalite(currentUser, 'VOIR ENCOURS') &&
            <div className='card mb-5 mb-xl-10'>
                <div className='card-header border-0 mt-0'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>En cours</h3>
                    </div>
                </div>
                <div className='card-body p-9'>
                <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300'>
                    <thead>
                        <tr className='fw-bold text-muted'>
                        <th className='w-25'>Date</th>
                        <th className='w-25'>Numéro</th>
                        <th className='w-25'>Total TTC</th>
                        <th className='w-25'>Reste à payer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((clientEncours) => {
                            return (
                                <tr key={clientEncours.id}>
                                    <td>
                                        <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                            {moment(clientEncours?.date).format("DD-MM-YYYY") }
                                        </p>
                                    </td>
                                    <td>
                                        <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                            {clientEncours?.numero}
                                        </p>
                                    </td>
                                    <td>
                                        <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                            {`${clientEncours?.totalTtc} €`}
                                        </p>
                                    </td>
                                    <td>
                                        <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                            {`${clientEncours?.resteAPayer} €`}
                                        </p>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
          }
        </>
      )}
    </>
  )
}

export {ClientEncours}