/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/modules/auth'
import {shouldDisplayFonctionnalite, shouldDisplayModule} from '../../../../components/helpers'

export function AsideMenuMain() {
  const {currentUser} = useAuth()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title='Accueil'
        fontIcon='bi-house'
        bsTitle='Accueil'
        className='py-3'
      />
      {(shouldDisplayFonctionnalite(currentUser, 'VOIR CENTRE') ||
        shouldDisplayFonctionnalite(currentUser, 'VOIR GENRE') ||
        shouldDisplayFonctionnalite(currentUser, 'VOIR ROLE') ||
        shouldDisplayFonctionnalite(currentUser, 'VOIR MAIL CLIENT')) && (
        <AsideMenuItemWithSubMain to='' title='Paramètres' fontIcon='bi-gear'>
          {shouldDisplayModule(currentUser, 'CENTRE') && (
            <AsideMenuItem to='/centres' title='Centres' className='py-3' />
          )}
          {shouldDisplayModule(currentUser, 'GENRE') && (
            <AsideMenuItem to='/genres' title='Genres' className='py-3' />
          )}
          {shouldDisplayModule(currentUser, 'ROLE UTILI') && (
            <AsideMenuItem to='/roles' title='Rôles utilisateurs' className='py-3' />
          )}
          {shouldDisplayModule(currentUser, 'MAIL CLIENT') && (
            <AsideMenuItem to='/courriel' title='Configuration courriel' className='py-3' />
          )}
        </AsideMenuItemWithSubMain>
      )}
      {shouldDisplayModule(currentUser, 'UTILI') && (
        <AsideMenuItem
          to='/utilisateurs'
          title='Utilisateurs'
          bsTitle='Utilisateurs'
          fontIcon='bi-person-lines-fill'
          className='py-3'
        />
      )}
      {shouldDisplayModule(currentUser, 'VENTE') && (
        <AsideMenuItem
          to='/vente'
          title='Client'
          bsTitle='Client'
          fontIcon='bi-basket'
          className='py-3'
        />
      )}
      {shouldDisplayModule(currentUser, 'VEHIC') && (
        <AsideMenuItem
          to='/vehicules'
          title='Véhicules'
          bsTitle='Véhicules'
          fontIcon='bi-car-front'
          className='py-3'
        />
      )}
      {shouldDisplayModule(currentUser, 'CATALOG') && (
        <AsideMenuItemWithSubMain to='' title='Catalogues' fontIcon='bi-folder'>
          <AsideMenuItem to='/catalogues' title='Catalogues' className='py-3' />
          <AsideMenuItem
            to='/recherche-catalogue'
            title='Recherche des catalogues'
            className='py-3'
          />
        </AsideMenuItemWithSubMain>
      )}
      {shouldDisplayModule(currentUser, 'DEMANDE') && (
        <AsideMenuItem
          to='/demandes'
          title='Demandes'
          bsTitle='Demandes'
          fontIcon='bi-person-vcard'
          className='py-3'
        />
      )}
    </>
  )
}
