import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from "react-router-dom";

import {ModeleHistoriqueFacturation} from '../../../../../../components/Models'
import {LoadingData} from '../../../../../../components/LoadingData'
import {getHistoriqueFacturations} from "../../../../../../components/requests";
import moment from 'moment/moment'
import {useAuth} from "../../../../../modules/auth";
import {shouldDisplayFonctionnalite} from "../../../../../../components/helpers";


const ClientHistoriqueFacturation: React.FC = () => {
    const navigate = useNavigate();
    let { clientId } = useParams();
    const [dataHistorique, setDataHistorique] = useState<Array<ModeleHistoriqueFacturation>|[]>([])
    const [loading, setLoading] = useState(true)
    const {currentUser} = useAuth()
    
    useEffect(() => {
        getData()
    }, []);
    
    const getData = ()=>{
        getHistoriqueFacturations(clientId).then(historiqueFact => {
            if (historiqueFact?.status === 200) {
                if (historiqueFact?.data !== undefined) {
                    setDataHistorique(historiqueFact?.data)
                    setLoading(false);
                }
            }else{
                navigate(`/vente/clients/liste`);
            }
        });
    }

  return (
    <>
        {loading ? (
        <LoadingData />
      ) : (
        <>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-header border-0 mt-0'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Historique facturation</h3>
                    </div>
                </div>

                {shouldDisplayFonctionnalite(currentUser, 'VOIR HISTORIQUE') &&
                    <div className='card-body p-9'>

                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                <th className='min-w-150px'>Date</th>
                                <th className='min-w-140px'>Numéro</th>
                                <th className='min-w-140px'>Montant</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {dataHistorique.map((historiqueFact) => {
                                    return (
                                        <tr key={historiqueFact.id}>
                                            <td>
                                                <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                    { moment(historiqueFact?.dateCreation).format("DD-MM-YYYY") }
                                                </p>
                                            </td>
                                            <td>
                                                <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                    {historiqueFact?.numero}
                                                </p>
                                            </td>
                                            <td>
                                                <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                                    {historiqueFact.montant} €
                                                </p>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                }
            </div>
        </>

      )}
    </>
  )
}
export {ClientHistoriqueFacturation}
