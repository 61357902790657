import React, {useEffect, useState} from 'react'
import {ModeleClientAcompte} from '../../../../../../components/Models'
import {getClientAcomptes} from '../../../../../../components/requests'
import {useNavigate, useParams} from "react-router-dom";
import {LoadingData} from "../../../../../../components/LoadingData";
import moment from "moment/moment";
import {useAuth} from "../../../../../modules/auth";
import {shouldDisplayFonctionnalite} from "../../../../../../components/helpers";

const ClientAcomptes = () => {
  const [data, setData] = useState<Array<ModeleClientAcompte>>([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();
  let { clientId } = useParams();
  const {currentUser} = useAuth()

  useEffect(() => {
    getClientData()
  }, [clientId]);

  const getClientData = () => {
    getClientAcomptes(clientId).then((clientAcomptesRequest) => {
      if (clientAcomptesRequest?.status === 200) {
        if (clientAcomptesRequest?.data !== undefined) {
          setData(clientAcomptesRequest?.data)
          setLoading(false)
        }
      } else {
        navigate(`/vente/clients/liste`)
      }
    })
  }

  return (
    <>
      {loading
        ?
        <LoadingData />
        :
        <>
          {shouldDisplayFonctionnalite(currentUser, 'VOIR ACOMPTES') &&
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header border-0'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Acomptes client</h3>
              </div>
            </div>

            <div>
              <div className='card-body p-9'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>Date</th>
                        <th className='min-w-140px'>Numéro Document</th>
                        <th className='min-w-140px'>Acompte versé</th>
                        <th className='min-w-140px'>Acompte utilisé</th>
                        <th className='min-w-140px'>Acompte restant</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {data.map((clientAcompte) => {
                        return (
                          <tr key={clientAcompte.id}>
                            <td>
                              <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                {moment(clientAcompte.date).format('DD/MM/YYYY')}
                              </p>
                            </td>
                            <td>
                              <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                {clientAcompte.numeroDocument}
                              </p>
                            </td>
                            <td>
                              <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                {clientAcompte.acompteVerse ?? 0} €
                              </p>
                            </td>
                            <td>
                              <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                {clientAcompte.acompteUtilise ?? 0} €
                              </p>
                            </td>
                            <td>
                              <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
                                {clientAcompte.acompteRestant ?? 0} €
                              </p>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
            </div>
          </div>
          }
        </>
      }
    </>
  )
}

export {ClientAcomptes}
