import React, {useState, useEffect} from 'react'
import {ModeleClientRemise, ModeleRole} from '../../../../../../components/Models'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {getClientsRemises, deleteRemise} from '../../../../../../components/requests'
import {LoadingData} from '../../../../../../components/LoadingData'
import {RemiseAddModal} from './remise/RemiseAddModal'
import Swal from 'sweetalert2'
import {useAuth} from "../../../../../modules/auth";
import {shouldDisplayFonctionnalite} from "../../../../../../components/helpers";


const ClientRemises = () => {
  const navigate = useNavigate();
  const {currentUser} = useAuth()
  const {clientId} = useParams();
  const initialDataRemise =  [
    {
      critere: '',
      codeCritere: 0,
      typeCritere: '',
      taux: 0,
      dateDebut: '',
      dateFin: '',
      idClient: 0
    }
  ]

  const [data, setData] = useState<Array<ModeleClientRemise>>(initialDataRemise);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isEdition, setIsEdition] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const addDataRemise = (newData:ModeleClientRemise) =>{
    let newDataRemise =  [...data,newData]
    setData(newDataRemise)
  }

  const handleUpdateRemise = (updatedRemise) => {
    setData(prevData =>
      prevData.map(remise => remise.id === updatedRemise.id ? updatedRemise : remise)
    );
  };

  const [updRemise, setUpdRemise] = useState()

  const toggleShowModal = (val: boolean) => {
    setOpenModal(val)
  }

  useEffect(() => {
    getOneClientRemiseData()
  }, [clientId])

    const getOneClientRemiseData = () => {
      getClientsRemises(clientId).then((clientRemisesRequest) => {
      if (clientRemisesRequest?.status === 200) {
        if (Array.isArray(clientRemisesRequest?.data)) {
          setData(clientRemisesRequest.data);
          setLoading(false);
        }
      } else {
        navigate(`/vente/clients/liste`)
      }
    })
  }
  const showAddModal = () => {
    setOpenModal(true)
    setIsEdition(false)
  }
  const showEditModal = (props) => {
    setOpenModal(true)
    setIsEdition(true)
    setUpdRemise(props.lastClientRemise)
  }

  const removeRemise = (remiseId) => {
    Swal.fire({
      title: 'Attention',
      text: 'Merci de confirmer la suppression',
      icon: 'warning',
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
      showCancelButton: true,
      confirmButtonColor: '#1BC5BD',
      cancelButtonColor: '#F64E60',
    }).then((alertResult) => {
      if (alertResult.isConfirmed) {
        setLoading(true)
        deleteRemise(remiseId).then((response) => {
          if (response?.status === 200) {
            setLoading(false)
            getOneClientRemiseData()
          }
        })
      }
    })
  }

  return (
    <>
      {loading ? (
        <LoadingData />
      ) : (
        <>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header border-0'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Remises client</h3>
              </div>
              {shouldDisplayFonctionnalite(currentUser, 'AJOUTER REMISE') &&
              <Link to='' onClick={showAddModal} className='btn btn-primary align-self-center'>
                Ajouter
              </Link>
              }
            </div>
            {shouldDisplayFonctionnalite(currentUser, 'VOIR REMISES') &&
            <div className='card-body p-9'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Type</th>
                    <th>Critere</th>
                    <th>Code</th>
                    <th>Taux</th>
                    <th style={{textAlign: 'center'}}>Date limite</th>
                    <th style={{width: '100px', textAlign: 'center'}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(data) &&
                    data.map((clientRemise) => {
                      return (
                        <tr key={clientRemise.id}>
                          <td>{clientRemise.typeCritere}</td>
                          <td>{clientRemise.critere}</td>
                          <td>{clientRemise?.codeCritere}</td>
                          <td>{clientRemise.taux + '%'}</td>
                          <td
                            className='fw-bold'
                            style={{
                              textAlign: 'center',
                              color:
                                clientRemise?.dateFin && new Date(clientRemise.dateFin) < new Date()
                                  ? '#F86F79'
                                  : '#9ac331',
                            }}
                          >
                            {clientRemise?.dateFin
                              ? new Date(clientRemise.dateFin).toLocaleDateString('fr-FR')
                              : 'Illimitée'}
                          </td>

                          <td className='text-center'>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              {shouldDisplayFonctionnalite(currentUser, 'MODIFIER REMISE') &&
                              <Link
                                to=''
                                onClick={(e) => {
                                  e.preventDefault()
                                  showEditModal({lastClientRemise: clientRemise})
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i className='bi bi-pencil-square fs-5'></i>
                              </Link>
                              }
                              {shouldDisplayFonctionnalite(currentUser, 'SUPPRIMER REMISE') &&
                              <p
                                onClick={() => {
                                  removeRemise(clientRemise.id)
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i className='bi bi-trash fs-5'></i>
                              </p>
                              }
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
            }
          </div>
          {!isEdition ? (
            <RemiseAddModal
              show={openModal}
              clientId={clientId}
              isEdition={isEdition}
              showModal={toggleShowModal}
              addDataRemise={addDataRemise}
              updateDataRemise={null}
              handleUpdateRemise={null}
            />
          ) : (
            <RemiseAddModal
              show={openModal}
              clientId={clientId}
              isEdition={isEdition}
              showModal={toggleShowModal}
              addDataRemise={null}
              updateDataRemise={updRemise}
              handleUpdateRemise={handleUpdateRemise}
            />
          )}
        </>
      )}
    </>
  )
}

export {
  ClientRemises
}
