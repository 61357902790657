export const pays = [
  {
    value: 1,
    label: 'Afghanistan',
  },
  {
    value: 2,
    label: 'Albanie',
  },
  {
    value: 3,
    label: 'Antarctique',
  },
  {
    value: 4,
    label: 'Algérie',
  },
  {
    value: 5,
    label: 'Samoa Américaines',
  },
  {
    value: 6,
    label: 'Andorre',
  },
  {
    value: 7,
    label: 'Angola',
  },
  {
    value: 8,
    label: 'Antigua-et-Barbuda',
  },
  {
    value: 9,
    label: 'Azerbaïdjan',
  },
  {
    value: 10,
    label: 'Argentine',
  },
  {
    value: 11,
    label: 'Australie',
  },
  {
    value: 12,
    label: 'Autriche',
  },
  {
    value: 13,
    label: 'Bahamas',
  },
  {
    value: 14,
    label: 'Bahreïn',
  },
  {
    value: 15,
    label: 'Bangladesh',
  },
  {
    value: 16,
    label: 'Arménie',
  },
  {
    value: 17,
    label: 'Barbade',
  },
  {
    value: 18,
    label: 'Belgique',
  },
  {
    value: 19,
    label: 'Bermudes',
  },
  {
    value: 20,
    label: 'Bhoutan',
  },
  {
    value: 21,
    label: 'Bolivie',
  },
  {
    value: 22,
    label: 'Bosnie-Herzégovine',
  },
  {
    value: 23,
    label: 'Botswana',
  },
  {
    value: 24,
    label: 'Île Bouvet',
  },
  {
    value: 25,
    label: 'Brésil',
  },
  {
    value: 26,
    label: 'Belize',
  },
  {
    value: 27,
    label: "Territoire Britannique de l'Océan Indien",
  },
  {
    value: 28,
    label: 'Îles Salomon',
  },
  {
    value: 29,
    label: 'Îles Vierges Britanniques',
  },
  {
    value: 30,
    label: 'Brunéi Darussalam',
  },
  {
    value: 31,
    label: 'Bulgarie',
  },
  {
    value: 32,
    label: 'Myanmar',
  },
  {
    value: 33,
    label: 'Burundi',
  },
  {
    value: 34,
    label: 'Bélarus',
  },
  {
    value: 35,
    label: 'Cambodge',
  },
  {
    value: 36,
    label: 'Cameroun',
  },
  {
    value: 37,
    label: 'Canada',
  },
  {
    value: 38,
    label: 'Cap-vert',
  },
  {
    value: 39,
    label: 'Îles Caïmanes',
  },
  {
    value: 40,
    label: 'République Centrafricaine',
  },
  {
    value: 41,
    label: 'Sri Lanka',
  },
  {
    value: 42,
    label: 'Tchad',
  },
  {
    value: 43,
    label: 'Chili',
  },
  {
    value: 44,
    label: 'Chine',
  },
  {
    value: 45,
    label: 'Taïwan',
  },
  {
    value: 46,
    label: 'Île Christmas',
  },
  {
    value: 47,
    label: 'Îles Cocos (Keeling)',
  },
  {
    value: 48,
    label: 'Colombie',
  },
  {
    value: 49,
    label: 'Comores',
  },
  {
    value: 50,
    label: 'Mayotte',
  },
  {
    value: 51,
    label: 'République du Congo',
  },
  {
    value: 52,
    label: 'République Démocratique du Congo',
  },
  {
    value: 53,
    label: 'Îles Cook',
  },
  {
    value: 54,
    label: 'Costa Rica',
  },
  {
    value: 55,
    label: 'Croatie',
  },
  {
    value: 56,
    label: 'Cuba',
  },
  {
    value: 57,
    label: 'Chypre',
  },
  {
    value: 58,
    label: 'République Tchèque',
  },
  {
    value: 59,
    label: 'Bénin',
  },
  {
    value: 60,
    label: 'Danemark',
  },
  {
    value: 61,
    label: 'Dominique',
  },
  {
    value: 62,
    label: 'République Dominicaine',
  },
  {
    value: 63,
    label: 'Équateur',
  },
  {
    value: 64,
    label: 'El Salvador',
  },
  {
    value: 65,
    label: 'Guinée Équatoriale',
  },
  {
    value: 66,
    label: 'Éthiopie',
  },
  {
    value: 67,
    label: 'Érythrée',
  },
  {
    value: 68,
    label: 'Estonie',
  },
  {
    value: 69,
    label: 'Îles Féroé',
  },
  {
    value: 70,
    label: 'Îles (malvinas) Falkland',
  },
  {
    value: 71,
    label: 'Géorgie du Sud et les Îles Sandwich du Sud',
  },
  {
    value: 72,
    label: 'Fidji',
  },
  {
    value: 73,
    label: 'Finlande',
  },
  {
    value: 74,
    label: 'Îles Åland',
  },
  {
    value: 75,
    label: 'France',
  },
  {
    value: 76,
    label: 'Guyane Française',
  },
  {
    value: 77,
    label: 'Polynésie Française',
  },
  {
    value: 78,
    label: 'Terres Australes Françaises',
  },
  {
    value: 79,
    label: 'Djibouti',
  },
  {
    value: 80,
    label: 'Gabon',
  },
  {
    value: 81,
    label: 'Géorgie',
  },
  {
    value: 82,
    label: 'Gambie',
  },
  {
    value: 83,
    label: 'Territoire Palestinien Occupé',
  },
  {
    value: 84,
    label: 'Allemagne',
  },
  {
    value: 85,
    label: 'Ghana',
  },
  {
    value: 86,
    label: 'Gibraltar',
  },
  {
    value: 87,
    label: 'Kiribati',
  },
  {
    value: 88,
    label: 'Grèce',
  },
  {
    value: 89,
    label: 'Groenland',
  },
  {
    value: 90,
    label: 'Grenade',
  },
  {
    value: 91,
    label: 'Guadeloupe',
  },
  {
    value: 92,
    label: 'Guam',
  },
  {
    value: 93,
    label: 'Guatemala',
  },
  {
    value: 94,
    label: 'Guinée',
  },
  {
    value: 95,
    label: 'Guyana',
  },
  {
    value: 96,
    label: 'Haïti',
  },
  {
    value: 97,
    label: 'Îles Heard et Mcdonald',
  },
  {
    value: 98,
    label: 'Saint-Siège (état de la Cité du Vatican)',
  },
  {
    value: 99,
    label: 'Honduras',
  },
  {
    value: 100,
    label: 'Hong-Kong',
  },
  {
    value: 101,
    label: 'Hongrie',
  },
  {
    value: 102,
    label: 'Islande',
  },
  {
    value: 103,
    label: 'Inde',
  },
  {
    value: 104,
    label: 'Indonésie',
  },
  {
    value: 105,
    label: "République Islamique d'Iran",
  },
  {
    value: 106,
    label: 'Iraq',
  },
  {
    value: 107,
    label: 'Irlande',
  },
  {
    value: 108,
    label: 'Israël',
  },
  {
    value: 109,
    label: 'Italie',
  },
  {
    value: 110,
    label: "Côte d'Ivoire",
  },
  {
    value: 111,
    label: 'Jamaïque',
  },
  {
    value: 112,
    label: 'Japon',
  },
  {
    value: 113,
    label: 'Kazakhstan',
  },
  {
    value: 114,
    label: 'Jordanie',
  },
  {
    value: 115,
    label: 'Kenya',
  },
  {
    value: 116,
    label: 'République Populaire Démocratique de Corée',
  },
  {
    value: 117,
    label: 'République de Corée',
  },
  {
    value: 118,
    label: 'Koweït',
  },
  {
    value: 119,
    label: 'Kirghizistan',
  },
  {
    value: 120,
    label: 'République Démocratique Populaire Lao',
  },
  {
    value: 121,
    label: 'Liban',
  },
  {
    value: 122,
    label: 'Lesotho',
  },
  {
    value: 123,
    label: 'Lettonie',
  },
  {
    value: 124,
    label: 'Libéria',
  },
  {
    value: 125,
    label: 'Jamahiriya Arabe Libyenne',
  },
  {
    value: 126,
    label: 'Liechtenstein',
  },
  {
    value: 127,
    label: 'Lituanie',
  },
  {
    value: 128,
    label: 'Luxembourg',
  },
  {
    value: 129,
    label: 'Macao',
  },
  {
    value: 130,
    label: 'Madagascar',
  },
  {
    value: 131,
    label: 'Malawi',
  },
  {
    value: 132,
    label: 'Malaisie',
  },
  {
    value: 133,
    label: 'Maldives',
  },
  {
    value: 134,
    label: 'Mali',
  },
  {
    value: 135,
    label: 'Malte',
  },
  {
    value: 136,
    label: 'Martinique',
  },
  {
    value: 137,
    label: 'Mauritanie',
  },
  {
    value: 138,
    label: 'Maurice',
  },
  {
    value: 139,
    label: 'Mexique',
  },
  {
    value: 140,
    label: 'Monaco',
  },
  {
    value: 141,
    label: 'Mongolie',
  },
  {
    value: 142,
    label: 'République de Moldova',
  },
  {
    value: 143,
    label: 'Montserrat',
  },
  {
    value: 144,
    label: 'Maroc',
  },
  {
    value: 145,
    label: 'Mozambique',
  },
  {
    value: 146,
    label: 'Oman',
  },
  {
    value: 147,
    label: 'Namibie',
  },
  {
    value: 148,
    label: 'Nauru',
  },
  {
    value: 149,
    label: 'Népal',
  },
  {
    value: 150,
    label: 'Pays-Bas',
  },
  {
    value: 151,
    label: 'Antilles Néerlandaises',
  },
  {
    value: 152,
    label: 'Aruba',
  },
  {
    value: 153,
    label: 'Nouvelle-Calédonie',
  },
  {
    value: 154,
    label: 'Vanuatu',
  },
  {
    value: 155,
    label: 'Nouvelle-Zélande',
  },
  {
    value: 156,
    label: 'Nicaragua',
  },
  {
    value: 157,
    label: 'Niger',
  },
  {
    value: 158,
    label: 'Nigéria',
  },
  {
    value: 159,
    label: 'Niué',
  },
  {
    value: 160,
    label: 'Île Norfolk',
  },
  {
    value: 161,
    label: 'Norvège',
  },
  {
    value: 162,
    label: 'Îles Mariannes du Nord',
  },
  {
    value: 163,
    label: 'Îles Mineures Éloignées des États-Unis',
  },
  {
    value: 164,
    label: 'États Fédérés de Micronésie',
  },
  {
    value: 165,
    label: 'Îles Marshall',
  },
  {
    value: 166,
    label: 'Palaos',
  },
  {
    value: 167,
    label: 'Pakistan',
  },
  {
    value: 168,
    label: 'Panama',
  },
  {
    value: 169,
    label: 'Papouasie-Nouvelle-Guinée',
  },
  {
    value: 170,
    label: 'Paraguay',
  },
  {
    value: 171,
    label: 'Pérou',
  },
  {
    value: 172,
    label: 'Philippines',
  },
  {
    value: 173,
    label: 'Pitcairn',
  },
  {
    value: 174,
    label: 'Pologne',
  },
  {
    value: 175,
    label: 'Portugal',
  },
  {
    value: 176,
    label: 'Guinée-Bissau',
  },
  {
    value: 177,
    label: 'Timor-Leste',
  },
  {
    value: 178,
    label: 'Porto Rico',
  },
  {
    value: 179,
    label: 'Qatar',
  },
  {
    value: 180,
    label: 'Réunion',
  },
  {
    value: 181,
    label: 'Roumanie',
  },
  {
    value: 182,
    label: 'Fédération de Russie',
  },
  {
    value: 183,
    label: 'Rwanda',
  },
  {
    value: 184,
    label: 'Sainte-Hélène',
  },
  {
    value: 185,
    label: 'Saint-Kitts-et-Nevis',
  },
  {
    value: 186,
    label: 'Anguilla',
  },
  {
    value: 187,
    label: 'Sainte-Lucie',
  },
  {
    value: 188,
    label: 'Saint-Pierre-et-Miquelon',
  },
  {
    value: 189,
    label: 'Saint-Vincent-et-les Grenadines',
  },
  {
    value: 190,
    label: 'Saint-Marin',
  },
  {
    value: 191,
    label: 'Sao Tomé-et-Principe',
  },
  {
    value: 192,
    label: 'Arabie Saoudite',
  },
  {
    value: 193,
    label: 'Sénégal',
  },
  {
    value: 194,
    label: 'Seychelles',
  },
  {
    value: 195,
    label: 'Sierra Leone',
  },
  {
    value: 196,
    label: 'Singapour',
  },
  {
    value: 197,
    label: 'Slovaquie',
  },
  {
    value: 198,
    label: 'Viet Nam',
  },
  {
    value: 199,
    label: 'Slovénie',
  },
  {
    value: 200,
    label: 'Somalie',
  },
  {
    value: 201,
    label: 'Afrique du Sud',
  },
  {
    value: 202,
    label: 'Zimbabwe',
  },
  {
    value: 203,
    label: 'Espagne',
  },
  {
    value: 204,
    label: 'Sahara Occidental',
  },
  {
    value: 205,
    label: 'Soudan',
  },
  {
    value: 206,
    label: 'Suriname',
  },
  {
    value: 207,
    label: 'Svalbard etÎle Jan Mayen',
  },
  {
    value: 208,
    label: 'Swaziland',
  },
  {
    value: 209,
    label: 'Suède',
  },
  {
    value: 210,
    label: 'Suisse',
  },
  {
    value: 211,
    label: 'République Arabe Syrienne',
  },
  {
    value: 212,
    label: 'Tadjikistan',
  },
  {
    value: 213,
    label: 'Thaïlande',
  },
  {
    value: 214,
    label: 'Togo',
  },
  {
    value: 215,
    label: 'Tokelau',
  },
  {
    value: 216,
    label: 'Tonga',
  },
  {
    value: 217,
    label: 'Trinité-et-Tobago',
  },
  {
    value: 218,
    label: 'Émirats Arabes Unis',
  },
  {
    value: 219,
    label: 'Tunisie',
  },
  {
    value: 220,
    label: 'Turquie',
  },
  {
    value: 221,
    label: 'Turkménistan',
  },
  {
    value: 222,
    label: 'Îles Turks et Caïques',
  },
  {
    value: 223,
    label: 'Tuvalu',
  },
  {
    value: 224,
    label: 'Ouganda',
  },
  {
    value: 225,
    label: 'Ukraine',
  },
  {
    value: 226,
    label: "L'ex-République Yougoslave de Macédoine",
  },
  {
    value: 227,
    label: 'Égypte',
  },
  {
    value: 228,
    label: 'Royaume-Uni',
  },
  {
    value: 229,
    label: 'Île de Man',
  },
  {
    value: 230,
    label: 'République-Unie de Tanzanie',
  },
  {
    value: 231,
    label: 'États-Unis',
  },
  {
    value: 232,
    label: 'Îles Vierges des États-Unis',
  },
  {
    value: 233,
    label: 'Burkina Faso',
  },
  {
    value: 234,
    label: 'Uruguay',
  },
  {
    value: 235,
    label: 'Ouzbékistan',
  },
  {
    value: 236,
    label: 'Venezuela',
  },
  {
    value: 237,
    label: 'Wallis et Futuna',
  },
  {
    value: 238,
    label: 'Samoa',
  },
  {
    value: 239,
    label: 'Yémen',
  },
  {
    value: 240,
    label: 'Serbie-et-Monténégro',
  },
  {
    value: 241,
    label: 'Zambie',
  },
]

export const centres = [
  {
    value: 1,
    label: 'SURPLUS AUTO 81',
  },
  {
    value: 2,
    label: 'SURPLUS AUTO 31',
  },
  {
    value: 3,
    label: 'SURPLUS MOTOS',
  },
  {
    value: 4,
    label: 'SURPLUS INDUSTRIES',
  },
]

export const roles = [
  {
    value: 1,
    label: 'Administrateur',
  },
  {
    value: 2,
    label: 'Gestion véhicules',
  },
]

export const typePieces = [
  {
    value: 427,
    label: '[333301] - CALCULATEUR ABS',
  },
  {
    value: 109,
    label: '[333302] - CAPTEUR ABS ARD',
  },
  {
    value: 129,
    label: '[333303] - CAPTEUR ABS AVD',
  },
  {
    value: 612,
    label: '[333304] - UNITE HYDRAULIQUE ABS',
  },
  {
    value: 1,
    label: '[333305] - CAPTEUR ABS ARG',
  },
  {
    value: 168,
    label: '[333306] - CAPTEUR ABS AVG',
  },
  {
    value: 355,
    label: '[333401] - LEVIER DE FREIN A MAIN',
  },
  {
    value: 99,
    label: '[333402] - PEDALE DE FREIN',
  },
  {
    value: 213,
    label: '[333403] - PEDALIER COMPLET',
  },
  {
    value: 225,
    label: '[333501] - BOCAL LIQUIDE FREIN',
  },
  {
    value: 527,
    label: '[333502] - LIMITEUR/REPARTITEUR DE PRESSION AR',
  },
  {
    value: 89,
    label: '[333503] - MAITRE CYLINDRE',
  },
  {
    value: 249,
    label: '[333504] - POMPE A VIDE',
  },
  {
    value: 209,
    label: '[333505] - SERVO-FREIN',
  },
  {
    value: 613,
    label: '[333601] - BOITIER SUSPENSION HYDRACTIVE',
  },
  {
    value: 614,
    label: '[333602] - CONJONCTEUR',
  },
  {
    value: 615,
    label: '[333603] - CORRECTEUR DE HAUTEUR AR',
  },
  {
    value: 616,
    label: '[333604] - CORRECTEUR DE HAUTEUR AV',
  },
  {
    value: 617,
    label: '[333605] - POMPE HYDRAULIQUE LHM',
  },
  {
    value: 618,
    label: '[333606] - REGULATEUR DE RAIDEUR AR',
  },
  {
    value: 619,
    label: '[333607] - RESERVOIR LHM',
  },
  {
    value: 184,
    label: '[333701] - DISQUE AR',
  },
  {
    value: 290,
    label: '[333702] - DISQUE AV',
  },
  {
    value: 238,
    label: '[333801] - ETRIER DE FREIN ARRIERE DROIT',
  },
  {
    value: 305,
    label: '[333802] - ETRIER DE FREIN AVANT DROIT',
  },
  {
    value: 174,
    label: '[333803] - ETRIER DE FREIN ARRIERE GAUCHE',
  },
  {
    value: 284,
    label: '[333804] - ETRIER DE FREIN AVANT GAUCHE',
  },
  {
    value: 620,
    label: '[333901] - FLASQUE ARD',
  },
  {
    value: 439,
    label: '[333902] - TAMBOUR ARD',
  },
  {
    value: 621,
    label: '[333903] - FLASQUE ARG',
  },
  {
    value: 440,
    label: '[333904] - TAMBOUR ARG',
  },
  {
    value: 412,
    label: '[534701] - ABSORBEUR DE PARE-CHOC ARRIERE',
  },
  {
    value: 680,
    label: "[534702] - CAPTEUR D'AIDE AU STATIONNEMENT ARRIERE",
  },
  {
    value: 488,
    label: '[534703] - CROSSE ARRIERE DROITE',
  },
  {
    value: 487,
    label: '[534704] - CROSSE ARRIERE GAUCHE',
  },
  {
    value: 681,
    label: '[534705] - ENJOLIVEUR PARE-CHOC AR',
  },
  {
    value: 682,
    label: '[534706] - GRILLE PARE-CHOC AR',
  },
  {
    value: 683,
    label: '[534707] - JEU SUPPORTS PARE-CHOC AR',
  },
  {
    value: 684,
    label: '[534708] - OBTURATEUR PARE-CHOC AR',
  },
  {
    value: 50,
    label: '[534709] - PARE-CHOC ARRIERE',
  },
  {
    value: 431,
    label: '[534710] - PROTECTION D PARE-CHOC AR',
  },
  {
    value: 685,
    label: '[534711] - PROTECTION G PARE-CHOC AR',
  },
  {
    value: 686,
    label: '[534712] - PROTECTION PARE-CHOC AR',
  },
  {
    value: 140,
    label: '[534713] - RENFORT PARE-CHOC ARRIERE',
  },
  {
    value: 279,
    label: '[534714] - PARE-CHOC ARRIERE',
  },
  {
    value: 2,
    label: '[534715] - SUPPORT CENTRAL DE PARE-CHOC ARRIERE',
  },
  {
    value: 23,
    label: '[534716] - SUPPORT DROIT DE PARE-CHOC ARRIERE',
  },
  {
    value: 44,
    label: '[534717] - SUPPORT GAUCHE DE PARE-CHOC ARRIERE',
  },
  {
    value: 422,
    label: '[534901] - ACTIONNEUR DE HAYON',
  },
  {
    value: 341,
    label: '[534902] - BECQUET DE HAYON',
  },
  {
    value: 386,
    label: '[534903] - CHARNIERE D HAYON',
  },
  {
    value: 387,
    label: '[534904] - CHARNIERE G HAYON',
  },
  {
    value: 343,
    label: '[534905] - DEFLECTEUR HAYON',
  },
  {
    value: 54,
    label: '[534906] - HAYON',
  },
  {
    value: 532,
    label: '[534907] - HAYON INFERIEUR',
  },
  {
    value: 388,
    label: '[534908] - JEU DE CHARNIERES DE HAYON',
  },
  {
    value: 70,
    label: '[534909] - POIGNEE EXTERIEURE DE HAYON',
  },
  {
    value: 82,
    label: '[534910] - PORTE CHARG.HAYON  AR (UTL)',
  },
  {
    value: 687,
    label: '[534911] - PROTECTION HAYON',
  },
  {
    value: 103,
    label: '[534912] - SERRURE DE HAYON',
  },
  {
    value: 350,
    label: '[534913] - VERIN HAYON',
  },
  {
    value: 493,
    label: '[534914] - VERIN DE GLACE HAYON',
  },
  {
    value: 713,
    label: '[534990] - VERIN D HAYON',
  },
  {
    value: 714,
    label: '[534991] - VERIN G HAYON',
  },
  {
    value: 423,
    label: '[535301] - ACTIONNEUR DE COFFRE',
  },
  {
    value: 351,
    label: '[535302] - BARILLET COFFRE AR',
  },
  {
    value: 116,
    label: '[535303] - COFFRE',
  },
  {
    value: 389,
    label: '[535304] - COMPAS D COFFRE AR',
  },
  {
    value: 390,
    label: '[535305] - COMPAS G COFFRE AR',
  },
  {
    value: 688,
    label: '[535306] - DEFLECTEUR COFFRE AR',
  },
  {
    value: 132,
    label: '[535307] - POIGNEE EXTERIEURE DE COFFRE',
  },
  {
    value: 135,
    label: '[535308] - SERRURE DE COFFRE ARRIERE',
  },
  {
    value: 358,
    label: '[535309] - VERIN DE COFFRE',
  },
  {
    value: 742,
    label: '[535310] - JEU DE CHARNIERES DE COFFRE',
  },
  {
    value: 537,
    label: '[536601] - CHARNIERE PORTE BATTANTE ARD',
  },
  {
    value: 506,
    label: '[536602] - POIGNEE EXTERIEURE DE PORTE BATTANTE',
  },
  {
    value: 507,
    label: '[536603] - POIGNEE EXTERIEURE DE PORTE BATTANTE DROITE',
  },
  {
    value: 149,
    label: '[536604] - POIGNEE EXTERIEURE DE PORTE BATTANTE GAUCHE',
  },
  {
    value: 505,
    label: '[536605] - PORTE BATTANTE ARRIERE',
  },
  {
    value: 538,
    label: '[536606] - PORTE BATTANTE ARRIERE DROITE',
  },
  {
    value: 539,
    label: '[536607] - PORTE BATTANTE ARRIERE GAUCHE',
  },
  {
    value: 157,
    label: '[536608] - SERRURE DE PORTE BATTANTE ARRIERE',
  },
  {
    value: 546,
    label: '[536609] - SERRURE DE PORTE BATTANTE ARRIERE DROITE',
  },
  {
    value: 540,
    label: '[536610] - SERRURE DE PORTE BATTANTE ARRIERE GAUCHE',
  },
  {
    value: 508,
    label: '[536611] - GARNITURE PORTE BATTANTE AR',
  },
  {
    value: 541,
    label: '[536612] - CHARNIERE PORTE BATTANTE ARG',
  },
  {
    value: 689,
    label: '[536801] - GIRAFON',
  },
  {
    value: 690,
    label: '[536802] - RIDELLE AR',
  },
  {
    value: 691,
    label: '[537501] - CACHE BAGAGE',
  },
  {
    value: 692,
    label: '[537502] - SUPPORT D TABLETTE PLAGE AR',
  },
  {
    value: 693,
    label: '[537503] - SUPPORT G TABLETTE PLAGE AR',
  },
  {
    value: 178,
    label: '[537504] - PLAGE ARRIERE',
  },
  {
    value: 694,
    label: '[538901] - JUPE AR',
  },
  {
    value: 695,
    label: '[538902] - KIT COMPLET AR SOCIETE',
  },
  {
    value: 696,
    label: '[538903] - TRAVERSE AR',
  },
  {
    value: 662,
    label: '[527101] - ACTIONNEUR SERRURE PORTE AV D',
  },
  {
    value: 663,
    label: '[527102] - ACTIONNEUR SERRURE PORTE AV G',
  },
  {
    value: 411,
    label: '[527103] - BARRILLET DE PORTE AVANT DROIT',
  },
  {
    value: 186,
    label: '[527104] - BOITIER IMPULSION LEVE-GLACE',
  },
  {
    value: 365,
    label: '[527105] - BOITIER VERROUILLAGE CENTRALISE PORTE',
  },
  {
    value: 664,
    label: '[527106] - CARENAGE RETROVISEUR EXT D',
  },
  {
    value: 665,
    label: '[527107] - CARENAGE RETROVISEUR EXT G',
  },
  {
    value: 162,
    label: '[527108] - COMMANDE LEVE-VITRE AVANT DROIT',
  },
  {
    value: 237,
    label: '[527109] - COMMANDE RETROVISEUR',
  },
  {
    value: 462,
    label: '[527110] - COMMANDE RETROVISEUR DROIT',
  },
  {
    value: 253,
    label: '[527111] - COMMANDE RETROVISEUR GAUCHE',
  },
  {
    value: 666,
    label: '[527112] - CONTACTEUR PORTE AV D',
  },
  {
    value: 667,
    label: '[527113] - CONTACTEUR PORTE AV G',
  },
  {
    value: 480,
    label: '[527114] - EMETTTEUR/RECEPTEUR VERROUILLAGE CENTRALISE',
  },
  {
    value: 188,
    label: '[527115] - GARNITURE PORTE AVANT DROITE',
  },
  {
    value: 3,
    label: '[527116] - GLACE DE RETROVISEUR DROIT',
  },
  {
    value: 16,
    label: '[527117] - GLACE DE RETROVISEUR GAUCHE',
  },
  {
    value: 668,
    label: '[527118] - JOINT PORTE AV D',
  },
  {
    value: 669,
    label: '[527119] - JOINT PORTE AV G',
  },
  {
    value: 58,
    label: '[527120] - LEVE-VITRE MANUEL AVANT DROIT',
  },
  {
    value: 33,
    label: '[527121] - LEVE-VITRE MANUEL AVANT GAUCHE',
  },
  {
    value: 83,
    label: '[527122] - LEVE-VITRE ELECTRIQUE AVANT DROIT',
  },
  {
    value: 37,
    label: '[527123] - LEVE-VITRE ELECTRIQUE AVANT GAUCHE',
  },
  {
    value: 299,
    label: '[527124] - POIGNEE EXTERIEURE AVANT DROITE',
  },
  {
    value: 300,
    label: '[527125] - POIGNEE EXTERIEURE AVANT GAUCHE',
  },
  {
    value: 241,
    label: '[527126] - POIGNEE INTERIEURE PORTE AVANT DROITE',
  },
  {
    value: 272,
    label: '[527127] - POIGNEE INTERIEURE PORTE AVANT GAUCHE',
  },
  {
    value: 587,
    label: '[527128] - POMPE VERROUILLAGE CENTRALISE',
  },
  {
    value: 250,
    label: '[527129] - PORTE AVANT DROITE',
  },
  {
    value: 15,
    label: '[527130] - PORTE AVANT GAUCHE',
  },
  {
    value: 354,
    label: '[527131] - PROTECTION PORTE AV D',
  },
  {
    value: 330,
    label: '[527132] - PROTECTION PORTE AV G',
  },
  {
    value: 36,
    label: '[527133] - RETROVISEUR ELECTRIQUE DROIT',
  },
  {
    value: 56,
    label: '[527134] - RETROVISEUR ELECTRIQUE GAUCHE',
  },
  {
    value: 77,
    label: '[527135] - RETROVISEUR MANUEL DROIT',
  },
  {
    value: 96,
    label: '[527136] - RETROVISEUR MANUEL GAUCHE',
  },
  {
    value: 106,
    label: '[527137] - SERRURE DE PORTE AVANT DROITE',
  },
  {
    value: 338,
    label: '[527138] - SERRURE DE PORTE AVANT GAUCHE',
  },
  {
    value: 121,
    label: '[527139] - TIRANT DE PORTE AVANT DROIT',
  },
  {
    value: 145,
    label: '[527140] - TIRANT DE PORTE AVANT GAUCHE',
  },
  {
    value: 153,
    label: '[527141] - BARRILLET DE PORTE AVANT GAUCHE',
  },
  {
    value: 172,
    label: '[527142] - COMMANDE LEVE-VITRE AVANT GAUCHE',
  },
  {
    value: 189,
    label: '[527143] - GARNITURE PORTE AVANT GAUCHE',
  },
  {
    value: 205,
    label: '[527201] - ACTIONNEUR SERRURE PORTE AR D',
  },
  {
    value: 144,
    label: '[527202] - ACTIONNEUR SERRURE PORTE AR G',
  },
  {
    value: 164,
    label: '[527203] - COMMANDE LEVE-VITRE ARRIERE DROIT',
  },
  {
    value: 455,
    label: '[527204] - CONTACTEUR PORTE AR D',
  },
  {
    value: 456,
    label: '[527205] - CONTACTEUR PORTE AR G',
  },
  {
    value: 230,
    label: '[527206] - GARNITURE PORTE ARRIERE DROITE',
  },
  {
    value: 68,
    label: '[527207] - LEVE-VITRE MANUEL ARRIERE DROIT',
  },
  {
    value: 231,
    label: '[527208] - LEVE-VITRE MANUEL ARRIERE GAUCHE',
  },
  {
    value: 75,
    label: '[527209] - LEVE-VITRE ELECTRIQUE ARRIERE DROIT',
  },
  {
    value: 234,
    label: '[527210] - LEVE-VITRE ELECTRIQUE ARRIERE GAUCHE',
  },
  {
    value: 277,
    label: '[527211] - POIGNEE EXTERIEURE ARRIERE DROITE',
  },
  {
    value: 282,
    label: '[527212] - POIGNEE EXTERIEURE ARRIERE GAUCHE',
  },
  {
    value: 55,
    label: '[527213] - POIGNEE INTERIEURE PORTE ARRIERE DROITE',
  },
  {
    value: 224,
    label: '[527214] - POIGNEE INTERIEURE PORTE ARRIERE GAUCHE',
  },
  {
    value: 263,
    label: '[527215] - PORTE ARRIERE DROITE',
  },
  {
    value: 317,
    label: '[527216] - PORTE ARRIERE GAUCHE',
  },
  {
    value: 670,
    label: '[527217] - PROTECTION PORTE AR D',
  },
  {
    value: 671,
    label: '[527218] - PROTECTION PORTE AR G',
  },
  {
    value: 216,
    label: '[527219] - SERRURE DE PORTE ARRIERE DROITE',
  },
  {
    value: 146,
    label: '[527220] - SERRURE DE PORTE ARRIERE GAUCHE',
  },
  {
    value: 165,
    label: '[527221] - COMMANDE LEVE-VITRE ARRIERE GAUCHE',
  },
  {
    value: 19,
    label: '[527222] - GARNITURE PORTE ARRIERE GAUCHE',
  },
  {
    value: 39,
    label: '[527501] - LEVE-VITRE MANUEL PORTE COULISSANTE DROIT',
  },
  {
    value: 67,
    label: '[527502] - LEVE-VITRE MANUEL PORTE COULISSANTE GAUCHE',
  },
  {
    value: 59,
    label: '[527503] - LEVE-VITRE ELECTRIQUE PORTE COULISSANTE DROITE',
  },
  {
    value: 86,
    label: '[527504] - LEVE-VITRE ELECTRIQUE PORTE COULISSANTE GAUCHE',
  },
  {
    value: 97,
    label: '[527505] - POIGNEE EXTERIEURE PORTE COULISSANTE DROITE',
  },
  {
    value: 112,
    label: '[527506] - POIGNEE EXTERIEURE PORTE COULISSANTE GAUCHE',
  },
  {
    value: 45,
    label: '[527507] - POIGNEE INTERIEURE PORTE COULISSANTE DROITE',
  },
  {
    value: 139,
    label: '[527508] - POIGNEE INTERIEURE PORTE COULISSANTE GAUCHE',
  },
  {
    value: 143,
    label: '[527509] - PORTE COULISSANTE DROITE',
  },
  {
    value: 154,
    label: '[527510] - PORTE COULISSANTE GAUCHE',
  },
  {
    value: 169,
    label: '[527511] - SERRURE DE PORTE COULISSANTE DROITE',
  },
  {
    value: 141,
    label: '[527512] - SERRURE DE PORTE COULISSANTE GAUCHE',
  },
  {
    value: 206,
    label: '[527513] - GARNITURE PORTE LAT COULISSANTE',
  },
  {
    value: 672,
    label: '[527801] - ACTIONNEUR PORTILLON AILE AR D',
  },
  {
    value: 673,
    label: '[527802] - ACTIONNEUR PORTILLON AILE AR G',
  },
  {
    value: 222,
    label: '[527803] - AILE ARRIERE DROITE',
  },
  {
    value: 227,
    label: '[527804] - AILE ARRIERE GAUCHE',
  },
  {
    value: 674,
    label: "[527805] - ELARGISSEUR D'AILE ARRIERE DROIT",
  },
  {
    value: 675,
    label: "[527806] - ELARGISSEUR D'AILE ARRIERE GAUCHE",
  },
  {
    value: 432,
    label: '[527807] - ENJOLIVEUR AILE AR D',
  },
  {
    value: 437,
    label: '[527808] - ENJOLIVEUR AILE AR G',
  },
  {
    value: 71,
    label: '[527809] - MECANISME LEVE-GLACE AILE AR D',
  },
  {
    value: 233,
    label: '[527810] - MECANISME LEVE-GLACE AILE AR G',
  },
  {
    value: 556,
    label: '[527811] - MECANISME OUVERTURE GLACE CUSTODE ARD',
  },
  {
    value: 78,
    label: '[527812] - MECANISME+MOTEUR LEVE-GLACE AILE AR D',
  },
  {
    value: 235,
    label: '[527813] - MECANISME+MOTEUR LEVE-GLACE AILE AR G',
  },
  {
    value: 283,
    label: '[527814] - MOTEUR LEVE-GLACE AILE AR D',
  },
  {
    value: 38,
    label: '[527815] - MOTEUR LEVE-GLACE AILE AR G',
  },
  {
    value: 49,
    label: '[527816] - PARE-BOUE ARRIERE DROIT',
  },
  {
    value: 180,
    label: '[527817] - PARE-BOUE ARRIERE GAUCHE',
  },
  {
    value: 309,
    label: '[527818] - PORTILLON CARBURANT AILE AR D',
  },
  {
    value: 313,
    label: '[527819] - PORTILLON CARBURANT AILE AR G',
  },
  {
    value: 397,
    label: '[527820] - PROTECTION AILE AR D',
  },
  {
    value: 398,
    label: '[527821] - PROTECTION AILE AR G',
  },
  {
    value: 676,
    label: '[527822] - TOLE FERMETURE INF AILE AR D',
  },
  {
    value: 677,
    label: '[527823] - TOLE FERMETURE INF AILE AR G',
  },
  {
    value: 557,
    label: '[527824] - MECANISME OUVERTURE GLACE CUSTODE ARG',
  },
  {
    value: 678,
    label: '[528101] - BAS CAISSE D',
  },
  {
    value: 679,
    label: '[528102] - BAS CAISSE G',
  },
  {
    value: 136,
    label: '[321201] - BARRE STAB AR',
  },
  {
    value: 147,
    label: '[321202] - BIELLETTE BARRE STAB ARD',
  },
  {
    value: 152,
    label: '[321203] - BIELLETTE BARRE STAB ARG',
  },
  {
    value: 8,
    label: '[323101] - AMORTISSEUR ARRIERE DROIT',
  },
  {
    value: 605,
    label: '[323102] - RESSORT AMORTISSEUR ARD',
  },
  {
    value: 579,
    label: '[323103] - SPHERES DE SUSPENSSION ARRIERE',
  },
  {
    value: 606,
    label: '[323104] - COUPELLES AMORTISSEURS AR',
  },
  {
    value: 607,
    label: '[323105] - LAME DE RESSORT AR',
  },
  {
    value: 608,
    label: '[323106] - SPHERE CENTRAL DE RAIDEUR AR',
  },
  {
    value: 609,
    label: '[323107] - VERIN SUSPENSION ARD',
  },
  {
    value: 610,
    label: '[323108] - VERIN SUSPENSION ARG',
  },
  {
    value: 12,
    label: '[323109] - AMORTISSEUR ARRIERE GAUCHE',
  },
  {
    value: 611,
    label: '[323110] - RESSORT AMORTISSEUR ARG',
  },
  {
    value: 236,
    label: '[323301] - DEMI-TRAIN ARRIERE DROIT',
  },
  {
    value: 40,
    label: '[323302] - DEMI-TRAIN ARRIERE GAUCHE',
  },
  {
    value: 409,
    label: '[323303] - BRAS AR D',
  },
  {
    value: 267,
    label: '[323304] - FUSEE AR D',
  },
  {
    value: 275,
    label: '[323305] - MOYEU ARRIERE DROIT',
  },
  {
    value: 281,
    label: '[323306] - PIVOT ARRIERE DROIT',
  },
  {
    value: 473,
    label: '[323307] - TIRANT DE CHASSE AR D',
  },
  {
    value: 252,
    label: '[323308] - TRIANGLE INFERIEUR ARRIERE DROIT',
  },
  {
    value: 291,
    label: '[323309] - TRIANGLE SUPERIEUR ARRIERE DROIT',
  },
  {
    value: 410,
    label: '[323310] - BRAS AR G',
  },
  {
    value: 323,
    label: '[323311] - FUSEE AR G',
  },
  {
    value: 287,
    label: '[323312] - MOYEU ARRIERE GAUCHE',
  },
  {
    value: 6,
    label: '[323313] - PIVOT ARRIERE GAUCHE',
  },
  {
    value: 474,
    label: '[323314] - TIRANT DE CHASSE AR G',
  },
  {
    value: 32,
    label: '[323315] - TRIANGLE INFERIEUR ARRIERE GAUCHE',
  },
  {
    value: 7,
    label: '[323316] - TRIANGLE SUPERIEUR ARRIERE GAUCHE',
  },
  {
    value: 391,
    label: '[323401] - TRAIN ARRIERE',
  },
  {
    value: 4,
    label: '[323402] - BERCEAU ARRIERE',
  },
  {
    value: 746,
    label: '[323403] - COMPRESSEUR DE SUSPENSION',
  },
  {
    value: 306,
    label: '[202001] - BOITE A VITESSE MECANIQUE',
  },
  {
    value: 420,
    label: '[202002] - BOITE DE TRANSFERT',
  },
  {
    value: 380,
    label: '[202003] - BOITIER DE COMMANDE BV AUTO',
  },
  {
    value: 381,
    label: '[202004] - CALCULATEUR BOITE AUTOMATIQUE',
  },
  {
    value: 312,
    label: '[202005] - CONTACTEUR FEUX RECUL',
  },
  {
    value: 447,
    label: '[202006] - ECHANGEUR REFROID HUILE BOITE',
  },
  {
    value: 342,
    label: '[202007] - LEVIER DE VITESSES',
  },
  {
    value: 257,
    label: '[202008] - POMMEAU DE VITESSE',
  },
  {
    value: 345,
    label: '[202009] - SELECTEUR DE VITESSE',
  },
  {
    value: 347,
    label: '[202010] - TRINGLERIE DE BOITE',
  },
  {
    value: 325,
    label: '[202011] - BOITE A VITESSE AUTOMATIQUE',
  },
  {
    value: 471,
    label: '[202101] - PONT ARRIERE',
  },
  {
    value: 472,
    label: '[202102] - PONT AVANT',
  },
  {
    value: 404,
    label: '[203101] - FLECTOR',
  },
  {
    value: 331,
    label: '[203102] - CARDAN ARRIERE DROITE',
  },
  {
    value: 5,
    label: '[203103] - CARDAN AVANT DROITE',
  },
  {
    value: 465,
    label: '[203104] - TRANSMISSION LONGITUDINALE AVANT',
  },
  {
    value: 332,
    label: '[203105] - CARDAN ARRIERE GAUCHE',
  },
  {
    value: 52,
    label: '[203106] - CARDAN AVANT GAUCHE',
  },
  {
    value: 405,
    label: '[203107] - TRANSMISSION LONGITUDINALE ARRIERE',
  },
  {
    value: 595,
    label: '[203108] - TRANSMISSION LONGITUDINALE CENTRALE',
  },
  {
    value: 322,
    label: "[155001] - COLLECTEUR D'ADMISSION",
  },
  {
    value: 327,
    label: "[155002] - COLLECTEUR D'ECHAPPEMENT",
  },
  {
    value: 362,
    label: '[155101] - CATALYSEUR',
  },
  {
    value: 585,
    label: '[155102] - FLEXIBLE ECHAPPEMENT AV',
  },
  {
    value: 363,
    label: '[155103] - PRECATALYSEUR',
  },
  {
    value: 9,
    label: '[155104] - SILENCIEUX ARRIERE',
  },
  {
    value: 470,
    label: '[155105] - SILENCIEUX AV',
  },
  {
    value: 374,
    label: '[155106] - SILENCIEUX INTERMEDIAIRE',
  },
  {
    value: 399,
    label: '[155107] - SONDE LAMBDA',
  },
  {
    value: 419,
    label: '[155108] - TUBE ECHAPPEMENT',
  },
  {
    value: 723,
    label: '[155109] - FILTRE A PARTICULES',
  },
  {
    value: 453,
    label: '[155209] - COLLIER ECHAPPEMENT',
  },
  {
    value: 590,
    label: '[155210] - MANCHON',
  },
  {
    value: 417,
    label: '[155211] - SILENTBLOC ECHAPPEMENT',
  },
  {
    value: 418,
    label: '[155212] - SUPPORT ECHAP',
  },
  {
    value: 328,
    label: '[344001] - BAC DE ROUE DE SECOURS',
  },
  {
    value: 10,
    label: '[344002] - CRIC',
  },
  {
    value: 18,
    label: '[344003] - CRIC + MANIVELLE',
  },
  {
    value: 42,
    label: '[344004] - ENJOLIVEUR DE ROUE',
  },
  {
    value: 476,
    label: '[344005] - MANIVELLE DE CRIC',
  },
  {
    value: 481,
    label: '[344006] - PANIER DE ROUE DE SECOURS',
  },
  {
    value: 66,
    label: '[345001] - JANTE ALUMINIUM 1',
  },
  {
    value: 69,
    label: '[345002] - JANTE TOLE 1',
  },
  {
    value: 715,
    label: '[345003] - JANTE ALUMINIUM 2',
  },
  {
    value: 716,
    label: '[345004] - JANTE ALUMINIUM 3',
  },
  {
    value: 717,
    label: '[345005] - JANTE ALUMINIUM 4',
  },
  {
    value: 718,
    label: '[345006] - JANTE ALUMINIUM 5',
  },
  {
    value: 719,
    label: '[345007] - JANTE TOLE 2',
  },
  {
    value: 720,
    label: '[345008] - JANTE TOLE 3',
  },
  {
    value: 721,
    label: '[345009] - JANTE TOLE 4',
  },
  {
    value: 722,
    label: '[345010] - JANTE TOLE 5',
  },
  {
    value: 120,
    label: '[346001] - ROUE DE SECOURS',
  },
  {
    value: 344,
    label: '[131001] - BOITE A AIR',
  },
  {
    value: 574,
    label: '[131002] - CARBURATEUR',
  },
  {
    value: 268,
    label: '[131003] - POMPE A CARBURANT ELECTRIQUE',
  },
  {
    value: 269,
    label: '[131004] - POMPE ALIMENTATION CARBURANT',
  },
  {
    value: 30,
    label: '[131201] - BOUCHON DE RESERVOIR A CARBURANT',
  },
  {
    value: 360,
    label: '[131202] - GOULOTTE A CARBURANT',
  },
  {
    value: 289,
    label: '[131203] - JAUGE A CARBURANT',
  },
  {
    value: 41,
    label: '[131204] - REFROIDISSEUR CARBURANT',
  },
  {
    value: 11,
    label: '[131205] - RESERVOIR DE CARBURANT',
  },
  {
    value: 739,
    label: '[131206] - RESERVOIR AD BLUE',
  },
  {
    value: 740,
    label: '[131207] - RESERVOIR CERINE',
  },
  {
    value: 315,
    label: '[131290] - TRAPPE A CARBURANT',
  },
  {
    value: 463,
    label: '[131301] - COMPRESSEUR D AIR',
  },
  {
    value: 53,
    label: "[131302] - ECHANGEUR D'AIR",
  },
  {
    value: 108,
    label: '[131303] - TURBO',
  },
  {
    value: 533,
    label: '[132101] - ACTUATEUR DE RALENTI',
  },
  {
    value: 535,
    label: '[132102] - BLOC MONOPOINT',
  },
  {
    value: 536,
    label: '[132103] - BOITIER INJECTION',
  },
  {
    value: 134,
    label: '[132104] - BOITIER PAPILLON',
  },
  {
    value: 142,
    label: '[132105] - CALCULATEUR MOTEUR',
  },
  {
    value: 434,
    label: '[132106] - CAPTEUR PEDALE ACCELERATEUR',
  },
  {
    value: 163,
    label: '[132107] - CAPTEUR PMH',
  },
  {
    value: 226,
    label: "[132108] - DEBIMETRE D'AIR",
  },
  {
    value: 242,
    label: '[132109] - DISTRIBUTEUR INJECTION',
  },
  {
    value: 589,
    label: '[132110] - ELECTROVANNE',
  },
  {
    value: 371,
    label: '[132111] - INJECTEUR',
  },
  {
    value: 214,
    label: "[132112] - PEDALE D'ACCELERATEUR",
  },
  {
    value: 264,
    label: "[132113] - POMPE D'AMORCAGE GASOIL",
  },
  {
    value: 384,
    label: '[132114] - POMPE HAUTE PRESSION',
  },
  {
    value: 373,
    label: '[132115] - POMPE INJECTION',
  },
  {
    value: 548,
    label: '[132116] - POTENTIOMETRE ACCELERATEUR',
  },
  {
    value: 494,
    label: '[132117] - POULIE CRANTEE POMPE INJECTION',
  },
  {
    value: 296,
    label: "[132118] - RAMPE D'INJECTION",
  },
  {
    value: 307,
    label: '[132119] - SUPPORT DE FILTRE CARBURANT',
  },
  {
    value: 448,
    label: '[132120] - TUYAUX INJECTEURS',
  },
  {
    value: 318,
    label: '[132121] - VANNE EGR',
  },
  {
    value: 738,
    label: '[132122] - POMPE ALIMENTATION AD BLUE',
  },
  {
    value: 356,
    label: '[132190] - INJECTEUR 1',
  },
  {
    value: 733,
    label: '[132191] - INJECTEUR 2',
  },
  {
    value: 734,
    label: '[132192] - INJECTEUR 3',
  },
  {
    value: 735,
    label: '[132193] - INJECTEUR 4',
  },
  {
    value: 736,
    label: '[132194] - INJECTEUR 5',
  },
  {
    value: 737,
    label: '[132195] - INJECTEUR 6',
  },
  {
    value: 457,
    label: '[581101] - GLACE AR TOIT OUVRANT',
  },
  {
    value: 370,
    label: '[581102] - GLACE TOIT OUVRANT',
  },
  {
    value: 542,
    label: '[582101] - GLACE DEFLECTEUR PORTE AV D',
  },
  {
    value: 543,
    label: '[582102] - GLACE DEFLECTEUR PORTE AV G',
  },
  {
    value: 414,
    label: '[582103] - GLACE FIXE PORTE AV D',
  },
  {
    value: 415,
    label: '[582104] - GLACE FIXE PORTE AV G',
  },
  {
    value: 190,
    label: '[582105] - GLACE PORTE AV D',
  },
  {
    value: 170,
    label: '[582106] - GLACE PORTE AV G',
  },
  {
    value: 63,
    label: '[582201] - GLACE COULISSANTE AV PORTE AR D',
  },
  {
    value: 175,
    label: '[582202] - GLACE COULISSANTE AV PORTE AR G',
  },
  {
    value: 568,
    label: '[582203] - GLACE DEFLECTEUR PORTE AR D',
  },
  {
    value: 569,
    label: '[582204] - GLACE DEFLECTEUR PORTE AR G',
  },
  {
    value: 468,
    label: '[582205] - GLACE FIXE PORTE AR D',
  },
  {
    value: 469,
    label: '[582206] - GLACE FIXE PORTE AR G',
  },
  {
    value: 72,
    label: '[582207] - GLACE PORTE AR D',
  },
  {
    value: 177,
    label: '[582208] - GLACE PORTE AR G',
  },
  {
    value: 79,
    label: '[582301] - GLACE COULISSANTE PORTE COULISSANTE D',
  },
  {
    value: 179,
    label: '[582302] - GLACE COULISSANTE PORTE COULISSANTE G',
  },
  {
    value: 485,
    label: '[582303] - GLACE FIXE PORTE COULISSANTE D',
  },
  {
    value: 486,
    label: '[582304] - GLACE FIXE PORTE COULISSANTE G',
  },
  {
    value: 84,
    label: '[582305] - GLACE PORTE COULISSANTE D',
  },
  {
    value: 181,
    label: '[582306] - GLACE PORTE COULISSANTE G',
  },
  {
    value: 509,
    label: '[583101] - GLACE AR COULISSANTE PANNEAU LAT D',
  },
  {
    value: 510,
    label: '[583102] - GLACE AR COULISSANTE PANNEAU LAT G',
  },
  {
    value: 511,
    label: '[583103] - GLACE AR PANNEAU LAT D',
  },
  {
    value: 512,
    label: '[583104] - GLACE AR PANNEAU LAT G',
  },
  {
    value: 513,
    label: '[583105] - GLACE AV COULISSANTE PANNEAU LAT D',
  },
  {
    value: 514,
    label: '[583106] - GLACE AV COULISSANTE PANNEAU LAT G',
  },
  {
    value: 515,
    label: '[583107] - GLACE AV PANNEAU LAT D',
  },
  {
    value: 516,
    label: '[583108] - GLACE AV PANNEAU LAT G',
  },
  {
    value: 517,
    label: '[583109] - GLACE AVEC CADRE PANNEAU LAT D',
  },
  {
    value: 518,
    label: '[583110] - GLACE AVEC CADRE PANNEAU LAT G',
  },
  {
    value: 519,
    label: '[583111] - GLACE COULISSANTE PANNEAU LAT D',
  },
  {
    value: 520,
    label: '[583112] - GLACE COULISSANTE PANNEAU LAT G',
  },
  {
    value: 521,
    label: '[583113] - GLACE PANNEAU LAT D',
  },
  {
    value: 522,
    label: '[583114] - GLACE PANNEAU LAT G',
  },
  {
    value: 576,
    label: '[583115] - GLACE SUP PANNEAU LAT D',
  },
  {
    value: 577,
    label: '[583116] - GLACE SUP PANNEAU LAT G',
  },
  {
    value: 185,
    label: '[583201] - GLACE AR CUSTODE D',
  },
  {
    value: 251,
    label: '[583202] - GLACE AR CUSTODE G',
  },
  {
    value: 187,
    label: '[583203] - CUSTODE AVANT DROITE',
  },
  {
    value: 254,
    label: '[583204] - CUSTODE AVANT GAUCHE',
  },
  {
    value: 191,
    label: '[583205] - GLACE CUSTODE D',
  },
  {
    value: 258,
    label: '[583206] - GLACE CUSTODE G',
  },
  {
    value: 194,
    label: '[583207] - GLACE MOBILE CUSTODE D',
  },
  {
    value: 259,
    label: '[583208] - GLACE MOBILE CUSTODE G',
  },
  {
    value: 199,
    label: '[583301] - GLACE AILE AR D',
  },
  {
    value: 261,
    label: '[583302] - GLACE AILE AR G',
  },
  {
    value: 441,
    label: '[583303] - GLACE AILE AV',
  },
  {
    value: 443,
    label: '[583304] - GLACE AILE AV D',
  },
  {
    value: 442,
    label: '[583305] - GLACE AILE AV G',
  },
  {
    value: 565,
    label: '[583401] - GLACE AR D PAVILLON',
  },
  {
    value: 566,
    label: '[583402] - GLACE AR G PAVILLON',
  },
  {
    value: 567,
    label: '[583403] - GLACE AR PAVILLON',
  },
  {
    value: 581,
    label: '[583404] - GLACE AV D PAVILLON',
  },
  {
    value: 580,
    label: '[583405] - GLACE AV G PAVILLON',
  },
  {
    value: 570,
    label: '[583406] - GLACE AV PAVILLON',
  },
  {
    value: 367,
    label: '[583407] - TOIT PANORAMIQUE',
  },
  {
    value: 228,
    label: '[583501] - GLACE HAYON',
  },
  {
    value: 13,
    label: '[583502] - GLACE LUNETTE AR',
  },
  {
    value: 551,
    label: '[583503] - GLACE SEPARATION HABITACLE',
  },
  {
    value: 534,
    label: '[583504] - GLACE SUP HAYON',
  },
  {
    value: 549,
    label: '[583601] - GLACE AR CABINE',
  },
  {
    value: 523,
    label: '[583602] - GLACE D PANNEAU AR',
  },
  {
    value: 524,
    label: '[583603] - GLACE G PANNEAU AR',
  },
  {
    value: 21,
    label: '[583604] - GLACE PORTE BATTANTE',
  },
  {
    value: 544,
    label: '[583605] - GLACE PORTE BATTANTE D',
  },
  {
    value: 545,
    label: '[583606] - GLACE PORTE BATTANTE G',
  },
  {
    value: 31,
    label: '[583701] - PARE-BRISE',
  },
  {
    value: 329,
    label: '[426001] - BOITIER PRETENSIONNEUR',
  },
  {
    value: 88,
    label: '[426002] - CEINTURE ARRIERE DROITE',
  },
  {
    value: 76,
    label: '[426003] - CEINTURE AVANT DROITE',
  },
  {
    value: 280,
    label: '[426004] - ANCRAGE CEINTURE ARRIERE DROIT',
  },
  {
    value: 210,
    label: '[426005] - ANCRAGE CEINTURE AVANT DROIT',
  },
  {
    value: 211,
    label: '[426006] - PRETENSIONNEUR AVANT DROIT',
  },
  {
    value: 93,
    label: '[426007] - CEINTURE ARRIERE GAUCHE',
  },
  {
    value: 34,
    label: '[426008] - CEINTURE ARRIERE CENTRALE',
  },
  {
    value: 203,
    label: '[426009] - CEINTURE AVANT GAUCHE',
  },
  {
    value: 550,
    label: '[426010] - CEINTURE AVANT CENTRALE',
  },
  {
    value: 340,
    label: '[426011] - ANCRAGE CEINTURE ARRIERE GAUCHE',
  },
  {
    value: 450,
    label: '[426012] - ANCRAGE CEINTURE ARRIERE CENTRAL',
  },
  {
    value: 14,
    label: '[426013] - ANCRAGE CEINTURE AVANT GAUCHE',
  },
  {
    value: 547,
    label: '[426014] - ANCRAGE CEINTURE AVANT CENTRAL',
  },
  {
    value: 35,
    label: '[426015] - PRETENSIONNEUR AVANT GAUCHE',
  },
  {
    value: 578,
    label: '[427001] - AIRBAG ASSISE ARD',
  },
  {
    value: 454,
    label: '[427002] - AIRBAG COLONNE DIRECTION',
  },
  {
    value: 558,
    label: '[427003] - AIRBAG LATERAL ARD',
  },
  {
    value: 466,
    label: '[427004] - AIRBAG PORTE AVD',
  },
  {
    value: 81,
    label: '[427005] - AIRBAG SIEGE AVD',
  },
  {
    value: 115,
    label: '[427006] - AIRBAG TABLEAU DE BORD',
  },
  {
    value: 47,
    label: '[427007] - AIRBAG RIDEAU DROIT',
  },
  {
    value: 176,
    label: '[427008] - AIRBAG VOLANT',
  },
  {
    value: 349,
    label: '[427009] - CONTACTEUR ANNULAIRE AIRBAG',
  },
  {
    value: 467,
    label: '[427010] - AIRBAG ASSISE ARG',
  },
  {
    value: 559,
    label: '[427011] - AIRBAG LATERAL ARG',
  },
  {
    value: 504,
    label: '[427012] - AIRBAG PORTE AVG',
  },
  {
    value: 201,
    label: '[427013] - AIRBAG SIEGE AVG',
  },
  {
    value: 215,
    label: '[427014] - AIRBAG RIDEAU GAUCHE',
  },
  {
    value: 745,
    label: '[427015] - CALCULATEUR AIRBAG',
  },
  {
    value: 529,
    label: '[164101] - ALLUMEUR',
  },
  {
    value: 400,
    label: "[164102] - BOBINE D'ALLUMAGE",
  },
  {
    value: 571,
    label: '[164103] - BOITIER ALLUMAGE',
  },
  {
    value: 530,
    label: '[164104] - DISTRIBUTEUR ALLUMAGE',
  },
  {
    value: 572,
    label: '[164105] - MODULE ALLUMAGE',
  },
  {
    value: 531,
    label: '[164106] - TETE ALLUMEUR',
  },
  {
    value: 426,
    label: '[164501] - BOITIER DE PRECHAUFFAGE',
  },
  {
    value: 17,
    label: '[164502] - BOUGIE PRECHAUFFAGE',
  },
  {
    value: 46,
    label: '[165001] - BATTERIE',
  },
  {
    value: 94,
    label: '[165101] - DEMARREUR',
  },
  {
    value: 117,
    label: '[165201] - ALTERNATEUR',
  },
  {
    value: 376,
    label: '[165202] - SUPPORT ALTERNATEUR',
  },
  {
    value: 424,
    label: '[165301] - BOITE A FUSIBLES HABITACLE',
  },
  {
    value: 425,
    label: '[165302] - PLATINE FUSIBLE HABITACLE',
  },
  {
    value: 726,
    label: '[165303] - BOITE A FUSIBLES MOTEUR',
  },
  {
    value: 766,
    label: '[165304] - BPGA - BOITIER FUSIBLE BATTERIE',
  },
  {
    value: 138,
    label: '[165401] - KLAXON',
  },
  {
    value: 394,
    label: '[165501] - FAISCEAU AV',
  },
  {
    value: 393,
    label: '[165502] - FAISCEAU ELECTRIQUE CHAUFFAGE',
  },
  {
    value: 156,
    label: '[165503] - FAISCEAU MOTEUR',
  },
  {
    value: 171,
    label: '[165504] - FAISCEAU TABLEAU DE BORD',
  },
  {
    value: 64,
    label: '[311101] - AMORTISSEUR AVANT DROIT',
  },
  {
    value: 599,
    label: '[311102] - RESSORT AMORTISSEUR AVD',
  },
  {
    value: 600,
    label: '[311103] - SPHERE ANTI-AFFAISSEMENT',
  },
  {
    value: 601,
    label: '[311104] - SPHERE CENTRAL DE RAIDEUR AV',
  },
  {
    value: 602,
    label: '[311105] - SPHERES ACCUMULATEURS',
  },
  {
    value: 560,
    label: '[311106] - SPHERES DE SUSPENSSION AVANT',
  },
  {
    value: 603,
    label: '[311107] - NECESSAIRE ANCRAGE AMORTISSEURS AV',
  },
  {
    value: 80,
    label: '[311108] - VERIN SUSPENSION AVD',
  },
  {
    value: 102,
    label: '[311109] - VERIN SUSPENSION AVG',
  },
  {
    value: 126,
    label: '[311110] - AMORTISSEUR AVANT GAUCHE',
  },
  {
    value: 604,
    label: '[311111] - RESSORT AMORTISSEUR AVG',
  },
  {
    value: 65,
    label: '[311201] - BARRE STAB AV',
  },
  {
    value: 20,
    label: '[311202] - BIELLETTE BARRE STAB AVD',
  },
  {
    value: 22,
    label: '[311203] - BIELLETTE BARRE STAB AVG',
  },
  {
    value: 118,
    label: '[311301] - DEMI-TRAIN AVANT DROIT',
  },
  {
    value: 60,
    label: '[311302] - DEMI-TRAIN AVANT GAUCHE',
  },
  {
    value: 407,
    label: '[311303] - BRAS AVD',
  },
  {
    value: 128,
    label: '[311304] - FUSEE AVD',
  },
  {
    value: 122,
    label: '[311305] - MOYEU AVANT DROIT',
  },
  {
    value: 274,
    label: '[311306] - PIVOT AVANT DROIT',
  },
  {
    value: 104,
    label: '[311307] - TIRANT DE CHASSE AVD',
  },
  {
    value: 288,
    label: '[311308] - TRIANGLE INFFERIEUR AVANT DROIT',
  },
  {
    value: 107,
    label: '[311309] - TRIANGLE SUPPERIEUR AVANT DROIT',
  },
  {
    value: 408,
    label: '[311310] - BRAS AVG',
  },
  {
    value: 62,
    label: '[311311] - FUSEE AVG',
  },
  {
    value: 124,
    label: '[311312] - MOYEU AVANT GAUCHE',
  },
  {
    value: 321,
    label: '[311313] - PIVOT AVANT GAUCHE',
  },
  {
    value: 326,
    label: '[311314] - TIRANT DE CHASSE AVG',
  },
  {
    value: 105,
    label: '[311315] - TRIANGLE INFFERIEUR AVANT GAUCHE',
  },
  {
    value: 25,
    label: '[311316] - TRIANGLE SUPPERIEUR AVANT GAUCHE',
  },
  {
    value: 29,
    label: '[311401] - TRAIN AV',
  },
  {
    value: 61,
    label: '[311402] - BERCEAU AVANT',
  },
  {
    value: 91,
    label: '[311403] - SUPPORT TRIANGLE INFERIEUR AVD',
  },
  {
    value: 114,
    label: '[311404] - SUPPORT TRIANGLE INFERIEUR AVG',
  },
  {
    value: 379,
    label: '[808001] - AMPOULE XENON',
  },
  {
    value: 196,
    label: '[808002] - BOITIER XENON PHARE',
  },
  {
    value: 562,
    label: '[808003] - BOITIERS PHARE ANTIBROUILLARD D',
  },
  {
    value: 563,
    label: '[808004] - BOITIERS PHARE ANTIBROUILLARD G',
  },
  {
    value: 436,
    label: '[808005] - CLIGNOTANT DROIT',
  },
  {
    value: 433,
    label: '[808006] - CLIGNOTANT GAUCHE',
  },
  {
    value: 202,
    label: '[808007] - ENJOLIVEUR PHARE D',
  },
  {
    value: 219,
    label: '[808008] - ENJOLIVEUR PHARE G',
  },
  {
    value: 218,
    label: '[808009] - FEUX AV D',
  },
  {
    value: 131,
    label: '[808010] - FEUX AV G',
  },
  {
    value: 698,
    label: '[808011] - GLACE PHARE D',
  },
  {
    value: 699,
    label: '[808012] - GLACE PHARE G',
  },
  {
    value: 564,
    label: '[808013] - JEU BOITIERS PHARE ANTIBROUILLARD',
  },
  {
    value: 700,
    label: '[808014] - JEU PHARES ANTIBROUILLARD',
  },
  {
    value: 498,
    label: '[808015] - MOTEUR ESSUIE-PHARE D',
  },
  {
    value: 499,
    label: '[808016] - MOTEUR ESSUIE-PHARE G',
  },
  {
    value: 207,
    label: '[808017] - ANTIBROUILLARD AVANT DROIT',
  },
  {
    value: 24,
    label: '[808018] - ANTIBROUILLARD AVANT GAUCHE',
  },
  {
    value: 220,
    label: '[808019] - PHARE DROIT',
  },
  {
    value: 133,
    label: '[808020] - PHARE GAUCHE',
  },
  {
    value: 495,
    label: '[808021] - PHARE LONGUE PORTEE AV D',
  },
  {
    value: 496,
    label: '[808022] - PHARE LONGUE PORTEE AV G',
  },
  {
    value: 334,
    label: "[808023] - REPETITEUR D'AILE DROIT",
  },
  {
    value: 336,
    label: "[808024] - REPETITEUR D'AILE GAUCHE",
  },
  {
    value: 701,
    label: '[808025] - SUPPORT PHARE D',
  },
  {
    value: 702,
    label: '[808026] - SUPPORT PHARE G',
  },
  {
    value: 724,
    label: '[808027] - FEU DIURNE AVANT DROIT',
  },
  {
    value: 725,
    label: '[808028] - FEU DIURNE AVANT GAUCHE',
  },
  {
    value: 703,
    label: '[809001] - ECLAIREUR DE PLAQUE',
  },
  {
    value: 704,
    label: '[809002] - ECRAN FEU AR D',
  },
  {
    value: 705,
    label: '[809003] - ECRAN FEU AR G',
  },
  {
    value: 706,
    label: '[809004] - ENJOLIVEUR INF FEU AR D',
  },
  {
    value: 707,
    label: '[809005] - ENJOLIVEUR INF FEU AR G',
  },
  {
    value: 262,
    label: '[809006] - FEU ARRIERE INTERIEUR DROIT',
  },
  {
    value: 265,
    label: '[809007] - FEU ARRIERE INTERIEUR GAUCHE',
  },
  {
    value: 273,
    label: '[809008] - FEU ARRIERE DROIT',
  },
  {
    value: 286,
    label: '[809009] - FEU ARRIERE GAUCHE',
  },
  {
    value: 276,
    label: '[809010] - FEU DE BROUILLARD ARRIERE DROIT',
  },
  {
    value: 297,
    label: '[809011] - FEU DE BROUILLARD ARRIERE GAUCHE',
  },
  {
    value: 458,
    label: '[809012] - FEU DE BROUILLARD ARRIERE CENTRAL',
  },
  {
    value: 489,
    label: '[809013] - FEU DE RECUL',
  },
  {
    value: 482,
    label: '[809014] - FEU DE RECUL DROIT',
  },
  {
    value: 555,
    label: '[809015] - FEU DE RECUL GAUCHE',
  },
  {
    value: 245,
    label: '[809016] - FEU STOP SUPPLEMENTAIRE',
  },
  {
    value: 353,
    label: '[303001] - NEIMAN',
  },
  {
    value: 26,
    label: '[303002] - BOITIER DE DIRECTION',
  },
  {
    value: 503,
    label: '[303003] - CALCULATEUR DIRECTION ASSISTEE',
  },
  {
    value: 229,
    label: '[303004] - COLONNE DE DIRECTION',
  },
  {
    value: 28,
    label: '[303005] - CREMAILLERE',
  },
  {
    value: 246,
    label: '[303006] - KIT SERRURES + NEIMAN',
  },
  {
    value: 451,
    label: '[303007] - LECTEUR DE CARTE',
  },
  {
    value: 182,
    label: '[303008] - MOTEUR ELECTRIQUE DA',
  },
  {
    value: 183,
    label: '[303009] - POMPE DE DIRECTION ASSISTEE',
  },
  {
    value: 298,
    label: '[303010] - POULIE POMPE DIRECTION ASSISTEE',
  },
  {
    value: 597,
    label: '[303011] - RELAIS DE DIRECTION',
  },
  {
    value: 575,
    label: '[303012] - VERIN DIRECTION',
  },
  {
    value: 314,
    label: '[303013] - VOLANT',
  },
  {
    value: 243,
    label: '[303101] - CARDAN DE DIRECTION',
  },
  {
    value: 406,
    label: '[303102] - COQUILLE DE VOLANT',
  },
  {
    value: 598,
    label: '[303103] - FLEXIBLE DIRECTION ASSISTEE',
  },
  {
    value: 319,
    label: '[303104] - RESERVOIR DE DIRECTION ASSISTEE',
  },
  {
    value: 320,
    label: '[303105] - ROTULE DE DIRECTION D',
  },
  {
    value: 43,
    label: '[303106] - ROTULE DE DIRECTION G',
  },
  {
    value: 223,
    label: '[513701] - ABSORBEUR DE PARE-CHOC AVANT',
  },
  {
    value: 625,
    label: "[513702] - CAPTEUR D'AIDE AU STATIONNEMENT AVANT",
  },
  {
    value: 626,
    label: '[513703] - CROSSE D PARE-CHOCS AV',
  },
  {
    value: 627,
    label: '[513704] - CROSSE G PARE-CHOCS AV',
  },
  {
    value: 628,
    label: '[513705] - ENJOLIVEUR D PARE-CHOC AV',
  },
  {
    value: 629,
    label: '[513706] - ENJOLIVEUR G PARE-CHOC AV',
  },
  {
    value: 630,
    label: '[513707] - ENJOLIVEUR PARE-CHOC AV',
  },
  {
    value: 631,
    label: '[513708] - GRILLE DROITE DE PARE-CHOC AVANT',
  },
  {
    value: 632,
    label: '[513709] - GRILLE GAUCHE DE PARE-CHOC AVANT',
  },
  {
    value: 633,
    label: '[513710] - GRILLE DE PARE-CHOC AVANT',
  },
  {
    value: 634,
    label: '[513711] - JEU OBTURATEURS PHARES ADDIT. PARE-CHOC AV',
  },
  {
    value: 635,
    label: '[513712] - JEU SUPPORTS PARE-CHOC AV',
  },
  {
    value: 636,
    label: '[513713] - OBTURATEUR PHARE ADDITIONNEL D PARE-CHOC AV',
  },
  {
    value: 637,
    label: '[513714] - OBTURATEUR PHARE ADDITIONNEL G PARE-CHOC AV',
  },
  {
    value: 125,
    label: '[513715] - PARE-CHOC AVANT',
  },
  {
    value: 638,
    label: '[513716] - PROTECTION D PARE-CHOC AV',
  },
  {
    value: 639,
    label: '[513717] - PROTECTION G PARE-CHOC AV',
  },
  {
    value: 421,
    label: '[513718] - PROTECTION PARE-CHOC AV',
  },
  {
    value: 640,
    label: '[513719] - RENFORT D PARE-CHOC AV',
  },
  {
    value: 641,
    label: '[513720] - RENFORT G PARE-CHOC AV',
  },
  {
    value: 256,
    label: '[513721] - RENFORT PARE-CHOC AVANT',
  },
  {
    value: 271,
    label: '[513722] - PARE-CHOC AVANT',
  },
  {
    value: 642,
    label: '[513723] - SPOILER AV D',
  },
  {
    value: 643,
    label: '[513724] - SPOILER AV G',
  },
  {
    value: 644,
    label: '[513725] - SUPPORT CENT PARE-CHOC AV',
  },
  {
    value: 645,
    label: '[513726] - SUPPORT D PARE-CHOC AV',
  },
  {
    value: 646,
    label: '[513727] - SUPPORT G PARE-CHOC AV',
  },
  {
    value: 647,
    label: '[513728] - SUPPORT PLAQUE DE POLICE AV',
  },
  {
    value: 192,
    label: '[514101] - CALANDRE',
  },
  {
    value: 648,
    label: '[514102] - ENJOLIVEUR CALANDRE',
  },
  {
    value: 491,
    label: '[514103] - ENJOLIVEUR DE CALANDRE DROIT',
  },
  {
    value: 492,
    label: '[514104] - ENJOLIVEUR DE CALANDRE GAUCHE',
  },
  {
    value: 195,
    label: '[514105] - GRILLE DE CALANDRE',
  },
  {
    value: 501,
    label: '[514106] - GRILLE DE CALANDRE DROITE',
  },
  {
    value: 502,
    label: '[514107] - GRILLE DE CALANDRE GAUCHE',
  },
  {
    value: 649,
    label: '[514108] - SIGLE DE CALANDRE',
  },
  {
    value: 650,
    label: '[514109] - SUPPORT CALANDRE',
  },
  {
    value: 651,
    label: '[514110] - SUPPORT CALANDRE D',
  },
  {
    value: 652,
    label: '[514111] - SUPPORT CALANDRE G',
  },
  {
    value: 303,
    label: '[515101] - AILE AVANT DROITE',
  },
  {
    value: 159,
    label: '[515102] - AILE AVANT GAUCHE',
  },
  {
    value: 444,
    label: "[515103] - ELARGISSEUR D'AILE AVANT DROITE",
  },
  {
    value: 445,
    label: "[515104] - ELARGISSEUR D'AILE AVANT GAUCHE",
  },
  {
    value: 247,
    label: '[515105] - PARE-BOUE AVANT DROIT',
  },
  {
    value: 98,
    label: '[515106] - PARE-BOUE AVANT GAUCHE',
  },
  {
    value: 430,
    label: '[515107] - PROTECTION AILE AV D',
  },
  {
    value: 428,
    label: '[515108] - PROTECTION AILE AV G',
  },
  {
    value: 653,
    label: '[515501] - BEQUILLE CAPOT',
  },
  {
    value: 324,
    label: '[515502] - CAPOT',
  },
  {
    value: 244,
    label: '[515503] - COMPAS D CAPOT',
  },
  {
    value: 310,
    label: '[515504] - COMPAS G CAPOT',
  },
  {
    value: 497,
    label: '[515505] - GRILLE CAPOT',
  },
  {
    value: 654,
    label: '[515506] - GRILLE D CAPOT',
  },
  {
    value: 655,
    label: '[515507] - GRILLE G CAPOT',
  },
  {
    value: 27,
    label: '[515508] - JEU DE CHARNIERES DE CAPOT',
  },
  {
    value: 656,
    label: '[515509] - JOINT CAPOT',
  },
  {
    value: 657,
    label: '[515510] - SERRURE DE CAPOT',
  },
  {
    value: 658,
    label: '[515511] - SIGLE CAPOT',
  },
  {
    value: 392,
    label: '[515512] - VERIN DROIT DE CAPOT',
  },
  {
    value: 57,
    label: '[515513] - VERIN GAUCHE DE CAPOT',
  },
  {
    value: 659,
    label: '[516001] - ARMATURE AV',
  },
  {
    value: 73,
    label: '[516002] - FACE AVANT',
  },
  {
    value: 92,
    label: '[516003] - PASSAGE ROUE AV D',
  },
  {
    value: 119,
    label: '[516004] - PASSAGE ROUE AV G',
  },
  {
    value: 148,
    label: '[516005] - TOLE FACE AV',
  },
  {
    value: 660,
    label: '[516006] - TOLE PORTE PHARE D',
  },
  {
    value: 661,
    label: '[516007] - TOLE PORTE PHARE G',
  },
  {
    value: 464,
    label: '[516008] - TRAVERSE INFERIEURE ARMATURE AVANT',
  },
  {
    value: 446,
    label: '[516009] - TRAVERSE SUPERIEURE ARMATURE AVANT',
  },
  {
    value: 167,
    label: '[516501] - BAIE DE PARE-BRISE',
  },
  {
    value: 743,
    label: '[516502] - BAGUETTE PARE-BRISE AVANT DROITE',
  },
  {
    value: 744,
    label: '[516503] - BAGUETTE PARE-BRISE AVANT GAUCHE',
  },
  {
    value: 479,
    label: '[771201] - INTERIEUR COMPLET',
  },
  {
    value: 123,
    label: '[771401] - APPUI TETE AV',
  },
  {
    value: 561,
    label: '[771402] - BANQUETTE AVANT',
  },
  {
    value: 500,
    label: '[771403] - SIEGE AVANT CENTRAL',
  },
  {
    value: 130,
    label: '[771404] - SIEGE AVANT DROIT',
  },
  {
    value: 137,
    label: '[771405] - SIEGE AVANT GAUCHE',
  },
  {
    value: 730,
    label: '[771409] - APPUI TETE AVANT DROIT',
  },
  {
    value: 731,
    label: '[771410] - APPUI TETE AVANT GAUCHE',
  },
  {
    value: 48,
    label: '[772401] - APPUI TETE AR',
  },
  {
    value: 526,
    label: '[772402] - BANQUETTE AR SUPL-7PLACES',
  },
  {
    value: 435,
    label: '[772403] - KIT BANQUETTE ARRIERE COMPLETE',
  },
  {
    value: 158,
    label: '[772404] - SIEGE AR COMPLET',
  },
  {
    value: 478,
    label: '[772405] - SIEGE SUPPLEMENTAIRE ARRIERE DROIT',
  },
  {
    value: 477,
    label: '[772406] - SIEGE SUPPLEMENTAIRE ARRIERE GAUCHE',
  },
  {
    value: 173,
    label: '[772407] - SIEGE SUPPLEMENTAIRE CENTRAL',
  },
  {
    value: 727,
    label: '[772408] - APPUI TETE ARRIERE CENTRAL',
  },
  {
    value: 728,
    label: '[772409] - APPUI TETE ARRIERE DROIT',
  },
  {
    value: 729,
    label: '[772410] - APPUI TETE ARRIERE GAUCHE',
  },
  {
    value: 475,
    label: '[751201] - BARRE DE TOIT',
  },
  {
    value: 51,
    label: '[751202] - CIEL DE TOIT',
  },
  {
    value: 74,
    label: '[751203] - PARE-SOLEIL DROIT',
  },
  {
    value: 90,
    label: '[751204] - PLAFONNIER',
  },
  {
    value: 697,
    label: '[751205] - POIGNEE DE MAINTIEN',
  },
  {
    value: 101,
    label: '[751206] - RETROVISEUR INTERIEUR',
  },
  {
    value: 110,
    label: '[751207] - PARE-SOLEIL GAUCHE',
  },
  {
    value: 732,
    label: '[751208] - GALERIE',
  },
  {
    value: 375,
    label: '[751501] - VIDE POCHE',
  },
  {
    value: 208,
    label: '[811101] - BALAIS ESSUIE-GLACE',
  },
  {
    value: 395,
    label: "[811102] - BRAS D'ESSUIE-GLACE ARRIERE",
  },
  {
    value: 239,
    label: "[811103] - BRAS D'ESSUIE-GLACE AVANT",
  },
  {
    value: 552,
    label: '[812101] - MECANISME ESSUIE-GLACE AR',
  },
  {
    value: 85,
    label: '[812102] - MECANISME ESSUIE-GLACE AV',
  },
  {
    value: 396,
    label: "[812103] - MOTEUR D'ESSUIE GLACE ARRIERE",
  },
  {
    value: 87,
    label: "[812104] - MECANISME + MOTEUR D'ESSUIE GLACE AVANT",
  },
  {
    value: 708,
    label: '[813101] - POMPE LAVE-GLACE AR',
  },
  {
    value: 294,
    label: '[813102] - POMPE DE LAVE GLACE',
  },
  {
    value: 525,
    label: '[813103] - RESERVOIR LAVE-GLACE AR',
  },
  {
    value: 221,
    label: '[813104] - RESERVOIR DE LAVE GLACE AVANT',
  },
  {
    value: 95,
    label: '[212101] - BUTEE EMBRAYAGE HYDRAULIQUE',
  },
  {
    value: 596,
    label: '[212102] - CABLE EMBRAYAGE',
  },
  {
    value: 385,
    label: '[212103] - CONVERTISSEUR',
  },
  {
    value: 100,
    label: '[212104] - EMETTEUR EMBRAYAGE',
  },
  {
    value: 111,
    label: '[212105] - KIT EMBRAYAGE',
  },
  {
    value: 155,
    label: "[212106] - PEDALE D'EMBRAYAGE",
  },
  {
    value: 198,
    label: '[212107] - RECEPTEUR EMBRAYAGE',
  },
  {
    value: 113,
    label: '[411201] - ALLUME CIGARE',
  },
  {
    value: 197,
    label: '[411202] - AUTORADIO',
  },
  {
    value: 378,
    label: '[411203] - CENDRIER',
  },
  {
    value: 490,
    label: '[411204] - MONTRE',
  },
  {
    value: 260,
    label: '[411205] - TAPIS DE SOL',
  },
  {
    value: 150,
    label: '[402001] - COMMANDE SOUS-VOLANT',
  },
  {
    value: 361,
    label: '[402002] - COMMANDE CLIGNOTANT',
  },
  {
    value: 364,
    label: '[402003] - COMMANDE DE CHAUFFAGE',
  },
  {
    value: 368,
    label: '[402004] - COMMANDE ECLAIRAGE',
  },
  {
    value: 359,
    label: '[402005] - COMMANDE ESSUIE-GLACE',
  },
  {
    value: 429,
    label: '[402006] - COMMANDE REGULATEUR DE VITESSE',
  },
  {
    value: 622,
    label: '[402007] - COMMANDE DE FREIN A MAIN ELECTRIQUE',
  },
  {
    value: 200,
    label: '[402090] - BOUTON DE WARNING',
  },
  {
    value: 452,
    label: '[402091] - INTERRUPTEUR DE PHARE',
  },
  {
    value: 449,
    label: '[403001] - AFFICHEUR DIGITAL',
  },
  {
    value: 160,
    label: '[403002] - BLOC COMPTEURS',
  },
  {
    value: 623,
    label: '[403003] - CABLE COMPTEUR',
  },
  {
    value: 624,
    label: '[403004] - COMPTE TOURS',
  },
  {
    value: 166,
    label: '[403005] - COMPTEUR',
  },
  {
    value: 161,
    label: '[403501] - BOITE A GANTS',
  },
  {
    value: 528,
    label: '[403502] - CASQUETTE SUPPERIEURE DE TABLEAU DE BORD',
  },
  {
    value: 127,
    label: '[403503] - CONSOLE',
  },
  {
    value: 586,
    label: '[403504] - ENTOURAGE COMPTEUR',
  },
  {
    value: 151,
    label: '[403505] - TABLEAU DE BORD',
  },
  {
    value: 377,
    label: '[403506] - PORTE BOITE A GANTS',
  },
  {
    value: 193,
    label: '[192101] - BUSE DE VENTILATEUR',
  },
  {
    value: 591,
    label: '[192102] - DURIT RADIATEUR',
  },
  {
    value: 401,
    label: '[192103] - MOTEUR VENTILATEUR RADIATEUR',
  },
  {
    value: 212,
    label: '[192104] - POMPE A EAU',
  },
  {
    value: 217,
    label: '[192105] - RADIATEUR',
  },
  {
    value: 232,
    label: '[192106] - RADIATEUR / CONDENSEUR CLIM',
  },
  {
    value: 333,
    label: '[192107] - RADIATEUR HUILE',
  },
  {
    value: 204,
    label: '[192108] - SUPPORT DE VENTILATEUR',
  },
  {
    value: 402,
    label: '[192109] - SUPPORT RADIATEUR',
  },
  {
    value: 240,
    label: "[192110] - VASE D'EXPANSION",
  },
  {
    value: 403,
    label: '[192111] - VENTILATEUR DE REFROIDISSEMENT',
  },
  {
    value: 248,
    label: '[193101] - COMPRESSEUR DE CLIMATISATION',
  },
  {
    value: 255,
    label: '[193102] - CONDENSEUR DE CLIMATISATION',
  },
  {
    value: 592,
    label: '[193103] - FILTRE HABITACLE',
  },
  {
    value: 335,
    label: '[193104] - SUPPORT DE COMPRESSEUR',
  },
  {
    value: 593,
    label: '[193105] - TUYAU DE CLIMATISATION',
  },
  {
    value: 337,
    label: '[194101] - BLOC DE CHAUFFAGE COMPLET',
  },
  {
    value: 266,
    label: '[194102] - MOTEUR CHAUFFAGE',
  },
  {
    value: 278,
    label: '[194103] - VENTILATEUR DE CHAUFFAGE',
  },
  {
    value: 339,
    label: '[194104] - MOTEUR VOLET DE CHAUFFAGE',
  },
  {
    value: 270,
    label: '[194105] - RADIATEUR DE CHAUFFAGE',
  },
  {
    value: 293,
    label: '[194106] - RESISTANCE DE CHAUFFAGE',
  },
  {
    value: 302,
    label: '[194107] - VENTILATEUR DE CHAUFFAGE',
  },
  {
    value: 594,
    label: '[194108] - WEBASTO',
  },
  {
    value: 292,
    label: '[101001] - Moteur',
  },
  {
    value: 295,
    label: '[101101] - BLOC MOTEUR',
  },
  {
    value: 383,
    label: '[101201] - POULIE DE VILEBREQUIN',
  },
  {
    value: 301,
    label: '[101202] - VOLANT MOTEUR',
  },
  {
    value: 304,
    label: '[102101] - CACHE CULBUTEURS',
  },
  {
    value: 308,
    label: '[102102] - CULASSE',
  },
  {
    value: 484,
    label: "[102103] - POULIE D'ARBRE A CAMES",
  },
  {
    value: 416,
    label: '[102201] - BIELLETTE ANTI-COUPLE MOTEUR',
  },
  {
    value: 483,
    label: '[102202] - SUPPORT BOITE DE VITESSE',
  },
  {
    value: 357,
    label: '[102203] - SUPPORT MOTEUR',
  },
  {
    value: 573,
    label: "[103101] - CARTER D'HUILE",
  },
  {
    value: 366,
    label: '[103102] - JAUGE A HUILE',
  },
  {
    value: 311,
    label: '[103103] - POMPE A HUILE',
  },
  {
    value: 438,
    label: '[103104] - PLAQUE SOUS MOTEUR',
  },
  {
    value: 285,
    label: '[103105] - PUITS DE JAUGE',
  },
  {
    value: 584,
    label: '[103106] - REFROIDISSEUR DE FILTRE A HUILE',
  },
  {
    value: 316,
    label: '[103107] - SUPPORT FILTRE A HUILE',
  },
  {
    value: 741,
    label: '[103108] - CACHE MOTEUR SUPERIEUR',
  },
  {
    value: 346,
    label: '[901501] - ATTELAGE',
  },
  {
    value: 709,
    label: '[901502] - BOITIER D ACCROCHAGE',
  },
  {
    value: 348,
    label: '[901503] - BOULE ATTELAGE',
  },
  {
    value: 352,
    label: '[901504] - FAISCEAU ATTELAGE',
  },
  {
    value: 710,
    label: '[901505] - KIT ECLAIRAGE REMORQUE',
  },
  {
    value: 711,
    label: '[901506] - PRISE MALE',
  },
  {
    value: 712,
    label: '[901507] - SUPPORT ATTELAGE',
  },
  {
    value: 372,
    label: '[548501] - MOTEUR TOIT OUVRANT',
  },
  {
    value: 369,
    label: '[548502] - TOIT OUVRANT',
  },
  {
    value: 460,
    label: '[548601] - CAPOTE',
  },
  {
    value: 413,
    label: '[548602] - COUVERCLE DE CAPOTE',
  },
  {
    value: 461,
    label: '[548603] - HARD-TOP',
  },
  {
    value: 459,
    label: '[548604] - MECANISME DE DEVERROUILLAGE DE CAPOTE',
  },
  {
    value: 553,
    label: '[548605] - VERIN D CAPOTE',
  },
  {
    value: 582,
    label: '[548606] - VERIN DROIT DE COUVERCLE DE CAPOTE',
  },
  {
    value: 554,
    label: '[548607] - VERIN G CAPOTE',
  },
  {
    value: 583,
    label: '[548608] - VERIN GAUCHE DE COUVERCLE DE CAPOTE',
  },
  {
    value: 382,
    label: '[555501] - CAISSE',
  },
]

export const typeSecuriteCourriel = [
  {
    value: 'SSL',
    label: 'SSL',
  },
  {
    value: 'TLS',
    label: 'TLS',
  },
  {
    value: 'Aucun',
    label: 'Aucun',
  },
]
