import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {RemiseForm} from './RemiseForm';
import {ModeleClientRemise, modeleClientRemiseInitValues} from '../../../../../../../components/Models'
import {createRemise, editRemise} from '../../../../../../../components/requests'
import {useNavigate} from "react-router-dom"
import Swal from 'sweetalert2'

const RemiseAddModal = ({ show,isEdition,clientId,showModal,addDataRemise, updateDataRemise, handleUpdateRemise }) => {

  const navigate = useNavigate();
  const submitForm = async (fieldsToUpdate: Partial<ModeleClientRemise>): Promise<void> => {
    const updatedData = Object.assign({...modeleClientRemiseInitValues}, fieldsToUpdate)
    var requestOk = false;
    if (!updatedData[0].critere) {
      setTimeout(() => {
        showModal(false)
        Swal.fire({
          title: 'Error',
          text: 'Critere obligatoire',
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: "#1BC5BD",
        });
      }, 200)
    } else if (!updatedData[0].taux) {
      setTimeout(() => {
        showModal(false)
        Swal.fire({
          title: 'Error',
          text: 'Taux obligatoire',
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: "#1BC5BD",
        });
      }, 200)
    } else {

      try {
        const clientRequest = updatedData[0].id > 0 ? await editRemise(clientId, updatedData[0].id, updatedData[0]) : await createRemise(clientId, updatedData[0]);
        if (clientRequest?.status === 200) {
          if (clientRequest?.data?.id !== undefined && clientRequest?.data?.id !== null) {
            requestOk = true;
            /*addDataRemise(clientRequest.data)*/
            if (isEdition) {
              // Mettre à jour la remise dans le tableau du parent
              handleUpdateRemise(clientRequest.data);
            } else {
              // Ajouter la nouvelle remise
              addDataRemise(clientRequest.data);
            }
            navigate(`/vente/clients/${clientId}/afficher/remises/`)
          }
          showModal(false)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        if (requestOk) {
          navigate(`/vente/clients/${clientId}/afficher/remises`);
        }
        showModal(false)
      }
    }

  }

  return (
    <>
      <Modal
        size='lg'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        show={show}
      >
        <div className='modal-header pt-4 pb-2'>
          <h2 className='pt-4'>{isEdition ? 'Modification remise' : 'Ajouter une remise'}</h2>
          <div
            className='btn btn-icon btn-sm btn-light-primary ms-2'
            onClick={() => showModal(false)}
          >
            <KTIcon className='fs-2' iconName='cross' />
          </div>
        </div>
        <div className='modal-body mx-2 my-4'>
          <RemiseForm showModal={showModal} clientId={clientId} updDataRemise={isEdition ? updateDataRemise : null} submitForm={submitForm}/>
        </div>
      </Modal>
    </>
  )
}

export {RemiseAddModal}