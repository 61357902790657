import {renderNiveauDelai, renderStatut} from '../../../../../components/renders'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {postClientDemandeAssign} from '../../../../../components/requests'
import {useAuth} from '../../../../modules/auth'
import {ModeleClientDemande} from '../../../../../components/Models'
import {Link} from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

const DemandeStatut = (props) => {
  const {data, tempsEcoule, setIsAssign, clientId, redirectToClientDemande} = props
  const {currentUser} = useAuth()
  const [demandeClient, setDemandeClient] = useState<ModeleClientDemande | undefined>(data)
  const [isAssigned, setIsAssigned] = useState<boolean>(false)
  const [loadAssign, setLoadAssign] = useState<boolean>(false)

  const handleAssignement = () => {
    if (data && data.client.id && data.id && currentUser?.id) {
      setLoadAssign(true)
      postClientDemandeAssign(data.client.id, data.id, currentUser.id).then((response) => {
        if (response.status == 200) {
          setDemandeClient(response?.data)
          setIsAssign(true)
          setIsAssigned(true)
          setLoadAssign(false)
        }
      })
    }
  }

  useEffect(() => {
    if (data) setDemandeClient(data)
  }, [data])

  useEffect(() => {
    if (demandeClient && demandeClient?.clientDemandeEtape?.code != 'NOUVE') {
      setIsAssigned(true)
      setIsAssign(true)
    }
  }, [demandeClient])

  return (
    <>
      <div>
        {redirectToClientDemande ? (
          <Link
            to={`/vente/clients/${clientId}/afficher/demandClient/`}
            data-tooltip-id="tooltip"
            data-tooltip-content="Revenir en arrière"
            data-tooltip-place="right"
          >
            <img src='/media/svg/custom/prev-arrow.svg' width={40} className='mb-5' />
          </Link>
        ) : (
          <Link
            to={`/demandes/liste`}
            data-tooltip-id="tooltip"
            data-tooltip-content="Revenir en arrière"
            data-tooltip-place="right"
          >
            <img src='/media/svg/custom/prev-arrow.svg' width={40} className='mb-5' />
          </Link>
        )}
        <div className='row mb-2 align-items-center'>
          <label className='col-lg-4 col-form-label fw-bold fs-6 text-gray-600'>Statut</label>
          <p className={'col-lg-8 col-form-label mb-0 text-gray-800'}>
            {/*proposée / quantité*/}
            {renderStatut(demandeClient?.clientDemandeEtape)}
          </p>
        </div>
        <div className='row mb-2 align-items-center'>
          <label className='col-lg-4 col-form-label fw-bold fs-6 text-gray-600'>Date</label>
          <p className={'col-lg-8 col-form-label mb-0 text-gray-800'}>
            {moment(demandeClient?.dateCreation).format('DD/MM/YYYY à HH:mm')}
          </p>
        </div>
        <div className='row mb-2 align-items-center'>
          <label className='col-lg-4 col-form-label fw-bold fs-6 text-gray-600'>Temps écoulé</label>
          <p className={'col-lg-8 col-form-label mb-0 text-gray-800'}>
            {renderNiveauDelai(demandeClient?.dateCreation)}
            <span className={'fw-bold fs-6 ms-2'}>{tempsEcoule}</span>
          </p>
        </div>
        <div className='row mb-2 align-items-center'>
          <label className='col-lg-4 col-form-label fw-bold fs-6 text-gray-600'>Assignée à</label>
          <div className='col-lg-8 col-form-label'>
            {!isAssigned ? (
              <button type='button' className='btn btn-primary' onClick={() => handleAssignement()}>
                Me l'assigner
                {loadAssign && (
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                )}
              </button>
            ) : (
              <p className={'col-lg-8 col-form-label mb-0 text-gray-800'}>
                {demandeClient?.utilisateurAssigne?.id !== undefined &&
                  demandeClient?.utilisateurAssigne?.prenom +
                    ' ' +
                    demandeClient?.utilisateurAssigne?.nom}
              </p>
            )}
          </div>
        </div>
      </div>
      {/*Affichage du tooltip*/}
      <Tooltip id="tooltip" />
    </>
  )
}
export {DemandeStatut}
