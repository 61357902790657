import React, {useEffect, useState, useCallback} from 'react'
import {formatTypePiecePourDemande} from '../../../../components/helpers'
import {ModeleClientDemande} from '../../../../components/Models'
import {SelectInput} from '../../../../components/inputs/SelectInput'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Link, useParams} from 'react-router-dom'
import {ShopModal} from './Televendeur/ShopModal'
import PieceStatus from './Televendeur/PieceStatus'
import {Tooltip} from 'react-tooltip'
import {getDemandeProposee} from '../../../../components/requests'

const clientDemandeSchema = Yup.object().shape({
  commentaire: Yup.string().notRequired(),
})

const ListePiece = (props: any) => {
  const {
    immatsArray,
    pieces,
    isAssign,
    setPiecesProps,
    panier,
    setPanier,
    data,
    setDemandeClient,
    redirectToClientDemande,
  } = props
  const [immatSelected, setImmatSelected] = useState('Tous')
  const [, setIsTous] = useState<boolean>(true)
  const [, setLoading] = useState(false)
  const [panierFiltered, setPanierFiltered] = useState(panier)
  const params = useParams()
  const demandeId = Number(params?.demandId)

  const handleChangeImmatSel = (e: any) => {
    setImmatSelected(e.target.value)
  }

  const formik = useFormik<ModeleClientDemande>({
    initialValues: data,
    validationSchema: clientDemandeSchema,
    onSubmit: (values: ModeleClientDemande) => {
      console.log(values)
    },
  })

  const getDemandeProp = useCallback(() => {
    getDemandeProposee(demandeId).then((clientDemandeProp) => {
      if (clientDemandeProp?.status == 200 && clientDemandeProp?.data != undefined) {
        if (setPanier) setPanier([])
        const updatedPanier = [
          ...panier,
          ...clientDemandeProp?.data.filter(
            (item) => !panier.some((existingItem) => existingItem.id === item.id)
          ),
        ]
        setPanier(updatedPanier)
        setPanierFiltered(updatedPanier)
      }
    })
  }, [demandeId])

  useEffect(() => {
    if (demandeId) {
      getDemandeProp()
    }
  }, [demandeId])

  const handleSetPieceId = (idTypePiece: any) => {
    const valueFiltered = [...panier]
    setPanierFiltered(valueFiltered.filter((p) => p.idTypePiece === idTypePiece))
  }

  useEffect(() => {
    renderFormRowPieces()
  }, [panier, setPanier])

  const handleDismiss = () => {
    setPanierFiltered(panier)
  }

  const handlePanierFiltered =(newPanier) => {
    setPanierFiltered(newPanier)
  }

  const renderFormRowPieces = () => {
    return pieces
      .filter(
        (piece: any) =>
          immatSelected === 'Tous' || piece.clientDemandeImmat?.immatriculation === immatSelected
      )
      .map((piece: any, index: number) => {
        let aValider = panier.some(
          (item: any) =>
            item.idTypePiece === piece.typePiece?.id &&
            item.immat === piece.clientDemandeImmat?.immatriculation &&
              item.statut === "A_PROPO"
        )
        return (
          <tr key={index}>
            <td>
              <p className='text-gray-700 fw-bold text-hover-primary d-block fs-6 mb-0'>
                {piece.id !== undefined && piece.id !== null
                  ? [
                      piece.typePiece !== undefined &&
                      piece.typePiece !== null &&
                      piece.typePiece?.id !== undefined
                        ? formatTypePiecePourDemande(piece.typePiece)
                        : piece.referenceManuelle,
                    ]
                  : piece.typePiece.code}
              </p>
            </td>
            <td>
              <p className='text-gray-700 fw-bold text-hover-primary d-block fs-6 mb-0'>
                {piece.clientDemandeImmat?.immatriculation}
              </p>
            </td>
            <td>
              <p className='text-gray-700 fw-bold text-hover-primary d-block fs-6 mb-0'>
                {piece.typePiece?.sousCategorie?.categorie?.nom}
              </p>
            </td>
            <td>
              <p className='text-gray-700 fw-bold text-hover-primary d-block fs-6 mb-0'>
                {piece.typePiece?.sousCategorie?.nom}
              </p>
            </td>
            <td>
              <p className='text-gray-700 fw-bold text-hover-primary d-block fs-6 mb-0'>
                <input
                  type={'text'}
                  className={'form-control form-control-lg form-control-solid mb-3 mb-lg-0'}
                  name={'commentaire' + index}
                  placeholder={'Commentaire'}
                  disabled={true}
                  value={piece.commentaire}
                />
              </p>
            </td>
            <td>
              <p className='text-gray-700 fw-bold text-hover-primary d-block fs-6 mb-0'>
                {piece.quantite}
              </p>
            </td>
            <td>
              <span
                key={piece.id}
                className='d-flex flex-column gap-2 cursor-pointer'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                data-tooltip-id='tooltip'
                data-tooltip-content='Voir le(s) proposition(s)'
                data-tooltip-place='left'
                onClick={(e: any) => handleSetPieceId(piece.typePiece?.id)}
              >
                <PieceStatus piece={piece} />
              </span>
            </td>
            <td className='text-end'>
              {aValider && (
                <>
                  <img
                    src='/media/svg/custom/sendEmail.svg'
                    width='20'
                    className='mx-3'
                    style={{cursor: 'pointer'}}
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_1'
                    data-tooltip-id='tooltip'
                    data-tooltip-content='Envoyer les suggestions au client'
                    data-tooltip-place='left'
                  />
                </>
              )}
              {isAssign && piece?.quantite != piece?.proposee && (
                <Link
                  to={`/demandes/enStock/${piece.typePiece?.id}`}
                  state={{
                    immatriculation: piece.clientDemandeImmat?.immatriculation,
                    quantity: piece?.quantite - (piece?.proposee ?? 0),
                    clientId: piece.demande?.client?.id,
                    demandeId: piece.demande?.id,
                    panier: panier,
                    demandeImmatId: piece.clientDemandeImmat?.id,
                    redirectToClientDemande: redirectToClientDemande,
                    refConst: piece.referenceConstructeur,
                    marque: piece.marque,
                    modele: piece.modele,
                  }}
                  data-tooltip-id='tooltip'
                  data-tooltip-content='Rechercher la pièce'
                  data-tooltip-place='right'
                >
                  <img src='/media/svg/custom/searchP.svg' width='25' alt='Voir' />
                </Link>
              )}
            </td>
          </tr>
        )
      })
  }

  return (
    <>
      <div className='row'>
        <div className='col-sm mb-4'>
          <div className='col-md-5'>
            <SelectInput
              fieldName={''}
              inputClassName={'form-select form-select-solid form-select-lg fw-bold'}
              options={[{value: 'Tous', label: 'Tous'}, ...immatsArray]}
              formik={formik}
              onChange={(e: any) => handleChangeImmatSel(e)}
            />
          </div>
        </div>
        <div className='col-sm mb-4'>
          <div>
            {isAssign && (
              <ShopModal
                clientId={params?.clientId}
                demandeId={params?.demandId}
                setPiecesProps={setPiecesProps}
                panier={panierFiltered}
                setPanier={setPanier}
                handleDismiss={handleDismiss}
                panierFiltered={handlePanierFiltered}
                redirectToClientDemande={redirectToClientDemande}
                setDemandeClient={setDemandeClient}
              />
            )}
          </div>
        </div>
        <div>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Référence pièce</th>
                  <th className='min-w-150px'>Immatriculation</th>
                  <th className='min-w-150px'>Catégorie</th>
                  <th className='min-w-150px'>Sous catégorie</th>
                  <th className='min-w-140px'>Commentaire</th>
                  <th className='min-w-140px'>Quantité</th>
                  <th className='min-w-140px'>Statut</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              <tbody>{pieces.length > 0 && renderFormRowPieces()}</tbody>
            </table>
          </div>
        </div>
      </div>
      {/*Affichage du tooltip*/}
      <Tooltip id='tooltip' />
    </>
  )
}
export {ListePiece}
