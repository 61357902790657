import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {
    ModeleCentre,
    ModeleUtilisateur,
    modeleUtilisateurInitValues
} from "../../../components/Models";
import {editUtilisateur, getCentres, getClient, getUtilisateur} from "../../../components/requests";
import {LoadingData} from "../../../components/LoadingData";
import {UtilisateurForm} from "./components/UtilisateurForm";

const UtilisateurEditPage = () => {
    const navigate = useNavigate();
    let { utilisateurId } = useParams();
    const [data, setData] = useState<ModeleUtilisateur>(modeleUtilisateurInitValues);
    const [loading, setLoading] = useState(true);
    const [centreSel, setCentreSel] = useState<ModeleCentre[]>([]);
    const location = useLocation();
    // Récupérer la valeur du paramètre profil de l'URL
    const queryParams = new URLSearchParams(location.search);
    const showProfil = queryParams.get('profil');

    useEffect(() => {
        getUtilisateurData()
    }, [utilisateurId, showProfil]);

    const getUtilisateurData = () => {
        getUtilisateur(utilisateurId).then(utilisateurRequest => {
            if (utilisateurRequest?.status === 200) {
                if (utilisateurRequest?.data?.utilisateur.id !== undefined && utilisateurRequest.data?.utilisateur?.id !== null) {
                    const updatedData = Object.assign({...data}, utilisateurRequest?.data.utilisateur);

                    if (updatedData.role?.id !== undefined) {
                        updatedData.idRole = updatedData.role?.id
                    }

                    setData({...updatedData})

                    const transformedData = utilisateurRequest.data?.centre?.map((item) => ({
                        value: item.id,
                        label: item.nom,
                    }));
                    setCentreSel([...transformedData]);
                }
                setLoading(false);
            } else {
                navigate(`/utilisateurs`);
            }
        }).catch((e) => {
            setLoading(false);
            navigate(`/utilisateurs`);
        });
    }

    const submitForm = async (fieldsToUpdate: Partial<ModeleUtilisateur>, callback): Promise<void> => {
        const updatedData = Object.assign({...data}, fieldsToUpdate)

        var requestOk = false;
        try {
            const clientRequest = await editUtilisateur(utilisateurId, updatedData);
            if (clientRequest?.status === 200) {
                requestOk = true;
            }
        } catch (ex) {
            console.error(ex)
        } finally {
            callback();
            if (requestOk) {
                setData({...updatedData})
                navigate(`/utilisateurs`);
            }
        }
    }

    return (
      <div className='card mb-5 mb-xl-10'>
          <div className='card-header border-0'>
              <div className='card-title m-0'>
                  {showProfil ? (
                    <h3 className='fw-bolder m-0'>Mon profil</h3>
                  ) : (
                    <h3 className='fw-bolder m-0'>Modifier l'utilisateur</h3>
                  )}
              </div>
          </div>

          <div>
              {loading ? (
                <LoadingData />
              ) : (
                <UtilisateurForm utilisateurId={utilisateurId} data={data} submitForm={submitForm} centreSelected={centreSel}/>
              )}
          </div>
      </div>
    )
}

export {UtilisateurEditPage}
