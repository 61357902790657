import {getClientDemandeImmat} from '../../../../components/requests'
import {useParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {VehicleInfo} from '../Components/VehicleInfo'
import {ListePiece} from '../Components/ListePiece'
import {ModeleClientDemande, ModeleClientDemandePiece} from '../../../../components/Models'
import {useLocation} from 'react-router'
import {LoadingData} from '../../../../components/LoadingData'
import ClientInfos from '../Components/ClientInfos'
import {toast, ToastContainer} from 'react-toastify'
import {usePanier} from './Televendeur/PanierContext'

type LocationState = {
  panier: any
  redirectToClientDemande: boolean
  showSendedEmailToast: boolean
  idToastMailSend: any
}

const DemandeTraitement = () => {
  const params = useParams()
  const clientId: string | undefined = params?.clientId
  const demandeId: string | undefined = params?.demandId
  const [immatsArray, setImmatsArray] = useState<
    {value: string; label: string; centreLabel: string}[]
  >([])
  const [demandeClient, setDemandeClient] = useState<ModeleClientDemande | null>(null)
  const [pieces, setPieces] = useState<Array<ModeleClientDemandePiece>>([])
  const [demandeImmat, setDemandeImmat] = useState<any>([])
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const state = location.state as LocationState | null
  const [isAssign, setIsAssign] = useState<boolean>(false)
  const [immatValid, setImmatValid] = useState<boolean>(true)
  const {panier, setPanier} = usePanier()

  const getDemande = () => {
    getClientDemandeImmat(demandeId).then((clientDemandeReq) => {
      if (clientDemandeReq?.status == 200 && clientDemandeReq?.data != undefined) {
        const dataDemandeImmat: any[] = [...clientDemandeReq.data.clientDemandeImmat]
        setDemandeImmat(clientDemandeReq.data.clientDemandeImmat)
        setDemandeClient(clientDemandeReq.data.clientDemandePiece[0]?.demande)
        setPieces(clientDemandeReq.data.clientDemandePiece)
        clientDemandeReq.data.clientDemandeImmat.length == 0
          ? setImmatValid(false)
          : setImmatValid(true)

        if (dataDemandeImmat.length != 0) {
          dataDemandeImmat?.map((item) => {
            const immat: string = item?.immatriculation ?? ''
            const exists: boolean = immatsArray.some((existingItem) => existingItem.value === immat)
            if (!exists) {
              setImmatsArray((prevArray) => {
                // Ajoute une nouvelle immat si elle n'existe pas
                return [
                  ...prevArray,
                  {
                    value: item?.immatriculation ?? '',
                    label: item?.immatriculation ?? '',
                    centreLabel: item?.centre?.nom ?? '',
                  },
                ]
              })
            }
          })
        }
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    getDemande()
  }, [])

  useEffect(() => {
    if (state?.showSendedEmailToast) {
      toast.success('Envoi des propositions terminé  avec succès', {
        hideProgressBar: true,
        autoClose: 2000
      })
    }
  }, [location.state])

  return (
    <>
      {!loading && <ClientInfos />}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body border-top p-9'>
          {loading ? (
            <LoadingData />
          ) : immatValid ? (
            <>
              <div className=' d-flex flex-column gap-10'>
                <div>
                  <VehicleInfo
                    demandeId={demandeId}
                    clientId={clientId}
                    immatsArray={immatsArray}
                    data={demandeClient}
                    isAssign={isAssign}
                    setAssign={setIsAssign}
                    redirectToClientDemande={state?.redirectToClientDemande}
                  />
                </div>
                <div>
                  <ListePiece
                    pieces={pieces}
                    demandeImmat={demandeImmat}
                    immatsArray={immatsArray}
                    isAssign={isAssign}
                    setPiecesProps={setPieces}
                    setPanier={setPanier}
                    panier={panier}
                    demandeEtape={demandeClient?.clientDemandeEtape?.code}
                    redirectToClientDemande={state?.redirectToClientDemande}
                    setDemandeClient={setDemandeClient}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className='text-center'>Aucun résultat associé à l'immatriculation</div>
          )}
        </div>
      </div>
      <ToastContainer position='bottom-right' autoClose={3000} />
    </>
  )
}
export {DemandeTraitement}
