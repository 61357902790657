import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {ModeleCentre, modeleCentreInitValues} from "../../../components/Models";
import {editCentre, getCentre} from "../../../components/requests";
import {LoadingData} from "../../../components/LoadingData";
import {CentreForm} from "./components/CentreForm";

const CentreEditPage = () => {
  const navigate = useNavigate();
  let { centreId } = useParams();
  const [data, setData] = useState<ModeleCentre>(modeleCentreInitValues);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getCentreData()
  }, [centreId]);

  const getCentreData = () => {
    getCentre(centreId).then(centreRequest => {
      if (centreRequest?.status === 200) {
        if (centreRequest?.data?.id !== undefined && centreRequest?.data?.id !== null) {
          const updatedData = Object.assign({...data}, centreRequest?.data)
          if (updatedData.pays?.id !== undefined) {
            updatedData.idPays = updatedData.pays?.id
          }
          setData({...updatedData})
        }
        setLoading(false);
      } else {
        navigate(`/centres`);
      }
    }).catch((e) => {
      setLoading(false);
      navigate(`/centres`);
    });
  }

  const submitForm = async (fieldsToUpdate: Partial<ModeleCentre>, callback): Promise<void> => {
    const updatedData = Object.assign({...data}, fieldsToUpdate)

    var requestOk = false;
    try {
      const clientRequest = await editCentre(centreId, updatedData);
      if (clientRequest?.status === 200) {
        requestOk = true;
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      callback();
      if (requestOk) {
        setData({...updatedData})
        navigate(`/centres`);
      }
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Modifier un centre</h3>
        </div>
      </div>

      <div>
        {loading
          ?
          <LoadingData />
          :
          <CentreForm centreId={centreId} data={data} submitForm={submitForm}/>
        }
      </div>
    </div>
  )
}

export {CentreEditPage}
